<template>
<div>
</div>
</template>
<script>
import axios from 'axios'
export default {
  created () {
    this.getCode();
  },
  methods: {
    // 从URL获取code
    getCode(){
      const {code} = this.$route.query
      if(!code){
        this.$router.replace('https://jzbusiness.jianzhinet.cn/qwLogin')
      }else{
        axios({
          url:'/wechat/public/auth2?code=' + code,
        }).then(res=>{
          this.$router.push('/')
        })
      }
    },
    // 后台接口获取suite_access_token
    getSuiteAccessToken(callback){
      axios({
        url:'https://jzbusiness.jianzhinet.cn/wechat/public/getThirdToken',
      }).then(res=>{
        if(res.code==200){
          callback(res.msg)
        }
      })
    },
  },
}
</script>