<template>
<div :style="`width:100vw;height:${mh&&mh>0?mh+'px':'100vh'};`" style="overflow: hidden;">
  <!-- 顶部返回部分 -->
  <div class="topBack">
    <div class="backIcon" @click="$common.returnEvent()">
      <i class="el-icon-arrow-left"></i>
    </div>
    <span>地图定位</span>
  </div>
  <iframe :src="`/amap/amap.html?lgt=${obj.lgt}&lat=${obj.lat}`" width="100%" height="100%">
  </iframe>
</div>
</template>

<script>
export default {
  data() {
    return {
      mh: null,//高度
      obj:{},//经纬度对象
    }
  },
  created () {
    this.obj=this.$router.getParams()
    this.mh=this.$common.getHeight()
  },
}
</script>
<style scoped>
*{
  box-sizing: border-box;
}
  /* 顶部返回区域 */
  .topBack{
    width: 100vw;
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    background-color: #ffffff;
    color: #141419;
    font-size: 1.13rem;
    font-weight: 600;
    font-family: "PingFang SC";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .backIcon{
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
  }
</style>