<template>
    <div class="" style="height: 90px;">

        <div class="h_nav">
            <div class="wanqi_wd dw">
                <div class="nav_list">
                    <div class="nav_left">
                        <router-link class="pc" :to="{ name: 'homePage' }"
                        ><img src="@/assets/images/pc/logo.svg" alt="兼职网"/>
                        </router-link>
                        <router-link class="mb" :to="{ name: 'mhome' }"
                        ><img src="@/assets/images/pc/logo.svg" alt="兼职网"/>
                            <b
                                    style="
                color: #fff;
                display: inline-block;
              "
                            >.</b
                            >
<!--                            <i style="color: #fff;transform: translateY(4px);display: inline-block;">做 兼 职 就 上 兼 职-->
<!--                                网</i>-->
                        </router-link>
                        <div class="nav_con">
                            <ul class="mobs">
                                <li
                                        v-for="(item, index) in wqtNavlist"
                                        :key="index"
                                        :class="item.url == navindex || item.routeChildren.includes(navindex) ? 'active' : ''"
                                        @mouseenter.prevent="mousemoveNav(item)"
                                        @mouseleave="mouseoutNav"
                                        style="position: relative"
                                >
                                    <span @click="nav_tab(item.url, item.outSite)">{{ item.title }}</span>
                                    <div class="about-me" v-show="hoverUrl == item.url && item.nav=='about'">
                                        <div class="about-me-item" @click="contactUs">
                                            <img src="@/assets/images/pc/about_lxwm_fill.png" alt=""/>
                                            <img src="@/assets/images/pc/about_lxwm.png" alt=""/>
                                            <div>联系我们</div>
                                        </div>
                                        <div class="about-me-item" @click="typeIndex  = 1">
                                            <img src="@/assets/images/pc/about_zjz_fill.png" alt=""/>
                                            <img src="@/assets/images/pc/about_zjz.png" alt=""/>
                                            <div>找兼职</div>
                                        </div>
                                        <div class="about-me-item" @click="typeIndex  = 2">
                                            <img src="@/assets/images/pc/about_wyzp_fill.png" alt=""/>
                                            <img src="@/assets/images/pc/about_wyzp.png" alt=""/>
                                            <div>我要招聘</div>
                                        </div>
                                    </div>

                                    <div class="header-nav"
                                         v-show="hoverUrl == item.url && item.children && item.children.length">
                                        <div class="header-nav-item" v-for="item in item.children"
                                             @click="toHoverNav(item)">
                                            <div class="header-nav-icon">
                                                <img :src="item.iconPath" alt="">
                                            </div>
                                            <div class="header-nav-icon">
                                                <img :src="item.iconSelectedPath" alt="">
                                            </div>
                                            <div class="header-nav-item-title">{{ item.title }}</div>
                                            <div class="header-nav-item-desc">{{ item.desc }}</div>
                                        </div>
                                    </div>
                                    <!--                 <el-button @click="nav_tab(item.url, index)" type="primary" plain>{{ item.title }}</el-button> -->
                                </li>
                            </ul>
                            <!-- <ul class="mob">
                              <li
                                v-for="(item, index) in navlist"
                                :key="index"
                                :class="item.url == navindex ? 'active' : ''"
                                @click="nav_tab(item.url, index)"
                              >
                                <span >{{ item.title }}</span>
                              </li>
                            </ul> -->
                        </div>
                    </div>

<!--                    <div class="position-box">-->
<!--                        <div class="position" :class="typeIndex=='1'?'job':''" @click="typeIndex  = 1">找兼职</div>-->
<!--                        <div class="position" :class="typeIndex == 2?'job':''" @click="typeIndex  = 2">我要招聘</div>-->
<!--                    </div>-->
                    <div class="nav_right">

                        <img src="@/assets/images/pc/location.png" alt="兼职网"/>
                        <div>{{ cityAddress | setCity }}</div>
                        <div class="qiehuan" @click="switchCity">[ 切换城市 ]</div>

                        <div class="avatar" v-if="infoAvar">
                            <img :src="infoAvar" />
                        </div>

                       <span v-if="!infoAvar" class="logIn" @click="logins">登录</span>
                    </div>
                    <!--        <div class="nav_right" v-if="!userinfo">-->
                    <!--          ><span @click="reg">注册</span>-->
                    <!--        </div>-->
<!--                    <div class="user_top" v-if="userinfo" @click="user_xx = !user_xx,u_ceng=false">-->
<!--                        <div class="user_top_pic">-->
<!--                            <img :src="userinfo.pic" alt="兼职网" srcset=""/>-->
<!--                        </div>-->
<!--                        <span-->
<!--                        ><a style="color: #333" rel="nofollow">{{ userinfo.name }}</a-->
<!--                        ><b v-show="user_mm && !user_xx"></b-->
<!--                        ></span>-->
<!--                        <div class="djck">点击查看</div>-->
<!--                        &lt;!&ndash; <b class="el-icon-arrow-down"></b> &ndash;&gt;-->
<!--                    </div>-->
                    <div class="el-icon-menu menu" @click="u_ceng=!u_ceng,user_xx=false"></div>
                </div>
                <div class="user_ceng" @click="user_xx = false" v-if="user_xx"></div>
                <ul class="user_xx" v-if="user_xx">
                    <li @click="clickLink(1)">
                        <img src="@/assets/images/pc/my_jz.png" alt="兼职网" srcset=""/>
                        <span>我的兼职</span>
                    </li>
                    <li @click="clickLink(2)">
                        <img src="@/assets/images/pc/my_xx.png" alt="兼职网" srcset=""/>
                        <span>我的消息</span>
                        <p v-if="user_mm"></p>
                    </li>
                    <li @click="clickLink(3)">
                        <img src="@/assets/images/pc/user.png" alt="兼职网" srcset=""/>
                        <span>个人信息</span>
                    </li>
                    <li @click="tc = true">
                        <img src="@/assets/images/pc/esc.png" alt="兼职网" srcset=""/>
                        <span>退出登录</span>
                    </li>
                </ul>
            </div>
            <div class="block" v-if="tc ">
                <div class="ceng">
                    <!-- <span class="el-icon-error" @click="tc = false"></span> -->
                    <h5>退出登录</h5>
                    <div class="c_btn">
                        <p class="c_qx" @click="tc = false">取消</p>
                        <p class="c_qr" @click="exit">确认</p>
                    </div>
                </div>
            </div>
            <div class="u_ceng" v-if="u_ceng" @click="u_ceng=false">
                <ul>
                    <li v-for="(item, index) in navlist" :key="index" @click="nav_tab(item.url, index)">
                        {{ item.title }}
                    </li>
                </ul>
            </div>
        </div>


        <div class="tan-box" v-if="typeIndex != 0 " @click="typeIndex = 0">
            <div class="fun-box" @click.stop>
                <div class="fun-box-title">
                    <div :class="typeIndex == 1?'findJob':''" @click.stop="typeIndex = 1">找兼职</div>
                    <div :class="typeIndex == 2?'recruitment':''" @click.stop="typeIndex = 2">我要招聘</div>
                </div>
                <div :class="typeIndex=='1'?'fun-box-bottom' :'fun-box-bottomT'">
                    <!--找兼职的狂-->
                    <div class="part-time-job" v-if="typeIndex =='1'">
                        <img src="@/assets/images/pc/xiaochengxu.png"/>
                        <div class="biaoZhu">微信扫码进入小程序</div>
                    </div>

                    <!--我要招聘-->
                    <div class="hire" v-if="typeIndex =='2'">
                        <div class="hireTitle">留下您的联系方式</div>
                        <div class="hireInputBox">
                            <input placeholder="请输入您的姓名" v-model="name">
                            <input placeholder="请输入您的手机号" type="tel" v-model="phone">
                        </div>
                        <div class="hireFun" @click="submit">提交</div>
                    </div>
                </div>
            </div>
        </div>
        <loginMask @close="close" v-if="showLogin" />
    </div>


</template>

<script>
import Bus from '@/assets/js/Bus'
import Vue from "vue";
import axios from "@/axios";
import Index from "@/views/h5/home/index.vue";
import login from "@/views/h5/login.vue";
import loginMask from './loginMask.vue'
import { mapState } from "vuex";

export default {
    name: "hnav",
    computed: {
        ...mapState(['infoAvars']),
        login() {
            return login
        }
    },
    components: {Index,loginMask},
    props: {
        msg: String,
    },
    data() {
        return {
          showLogin:false,
            name: "",
            phone: "",
            typeIndex: 0,  //控制显示第几个
            u_ceng: false,
            usershow: false, //注册显示隐藏
            user_xx: false, //个人信息显示隐藏
            user_mm: false,
            tc: false, //退出提示框
            userinfo: "",
            wqtNavlist: [
                {
                    title: "首页",
                    url: 'homePage',
                    routeChildren: []
                },
                {
                    title: "兼职",
                    url: 'PartTJ',
                    hasNav: true,
                    children: [
                        {
                            id: 1,
                            dictValue:"1",
                            iconPath: require('@/assets/images/pc/pt_jzzw.png'),
                            iconSelectedPath: require('@/assets/images/pc/pt_jzzw_h.png'),
                            title: '兼职职位',
                            desc: 'PART-TIME POSITIONS'
                        }, {
                            id: 2,
                            dictValue:"2",
                            iconPath: require('@/assets/images/pc/pt_kcjz.png'),
                            iconSelectedPath: require('@/assets/images/pc/pt_kcjz_h.png'),
                            title: '在家做',
                            desc: 'DO IT AT HOME'
                        }, {
                            id: 3,
                            dictValue:"3",
                            iconPath: require('@/assets/images/pc/pt_bdjz.png'),
                            iconSelectedPath: require('@/assets/images/pc/pt_bdjz_h.png'),
                            title: '本地兼职',
                            desc: 'LOCAL PART-TIME'
                        }, {
                            id: 4,
                            dictValue:"4",
                            iconPath: require('@/assets/images/pc/pt_qbgw.png'),
                            iconSelectedPath: require('@/assets/images/pc/pt_qbgw_h.png'),
                            title: '全部岗位',
                            desc: 'ALL POSITIONS'
                        }
                    ],
                    routeChildren: []
                },
                {
                    title: "企业服务",
                    url: "ranking",
                    outSite: true,
                    routeChildren: []
                },
                {
                    title: "资讯",
                    url: "informationHome",
                    hasNav: true,
                    children: [
                        {
                            id: 4,
                            iconPath: require('@/assets/images/pc/header_zx/topic.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/topic_h.png'),
                            // iconPath: require('@/assets/images/pc/header_zx/zx_djfg.png'),
                            // iconSelectedPath: require('@/assets/images/pc/header_zx/zx_djfg_h.png'),
                            title: '资讯专题',
                            desc: 'INFORMATION TOPIC',
                        },
                        {
                            id: 1,
                            iconPath: require('@/assets/images/pc/header_zx/zx_xwzx.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_xwzx_h.png'),
                            title: '新闻资讯',
                            desc: 'NEWS AND INFORMATION'
                        },
                        {
                            id: 2,
                            iconPath: require('@/assets/images/pc/header_zx/zx_jzxx.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_jzxx_h.png'),
                            title: '兼职信息',
                            desc: 'PART TIME INFORMATION'
                        }, {
                            id: 3,
                            iconPath: require('@/assets/images/pc/header_zx/zx_gzjq.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_gzjq_h.png'),
                            title: '工作技巧',
                            desc: 'WORK SKILLS'
                        }, {
                            id: 4,
                            iconPath: require('@/assets/images/pc/header_zx/zx_xysh.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_xysh_h.png'),
                            title: '校园生活',
                            desc: 'CAMPUS LIFE'
                        }, {
                            id: 4,
                            iconPath: require('@/assets/images/pc/header_zx/zx_zcgs.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_zcgs_h.png'),
                            title: '职场故事',
                            desc: 'WORKPLACE STORY'
                        }, {
                            id: 4,
                            iconPath: require('@/assets/images/pc/header_zx/zx_zqff.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_zqff_h.png'),
                            title: '赚钱方法',
                            desc: 'MONEY MAKING METHODS'
                        },  {
                            id: '',
                            iconPath: require('@/assets/images/pc/header_zx/zx_gdzx.png'),
                            iconSelectedPath: require('@/assets/images/pc/header_zx/zx_gdzx_h.png'),
                            title: '更多资讯',
                            desc: 'MORE INFORMATION'
                        }],
                    routeChildren: []
                },
                // {
                //     title: "万企合作",
                //     url: "enterprise",
                //     hasNav: true,
                //     children: [
                //         {
                //             id: 1,
                //             dictValue: 1,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_mqzp.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_mqzp_h.png'),
                //             title: '名企直聘',
                //             desc: 'ENTERPRISE RECRUITMENT'
                //         }, {
                //             id: 2,
                //             dictValue: 2,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_llpg.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_llpg_h.png'),
                //             title: '蓝领普工',
                //             desc: 'BLUE-COLLAR WORKERS'
                //         }, {
                //             id: 3,
                //             dictValue: 3,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_csls.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_csls_h.png'),
                //             title: '超市零售',
                //             desc: 'SUPERMARKET RETAIL'
                //         }, {
                //             id: 4,
                //             dictValue: 4,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_cyjd.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_cyjd_h.png'),
                //             title: '餐饮酒店',
                //             desc: 'CATERING HOTEL'
                //         }, {
                //             id: 4,
                //             dictValue: 5,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_dzsw.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_dzsw_h.png'),
                //             title: '电子商务',
                //             desc: 'E-COMMERCE'
                //         }, {
                //             id: 4,
                //             dictValue: 6,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_ggyx.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_ggyx_h.png'),
                //             title: '广告营销',
                //             desc: 'ADVERTISING'
                //         }, {
                //             id: 4,
                //             dictValue: 7,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_shfw.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_shfw_h.png'),
                //             title: '生活服务',
                //             desc: 'LIVING SERVICES'
                //         }, {
                //             id: 4,
                //             dictValue: 12,
                //             iconPath: require('@/assets/images/pc/wqhz/wqhz_gdhy.png'),
                //             iconSelectedPath: require('@/assets/images/pc/wqhz/wqhz_gdhy_h.png'),
                //             title: '更多行业',
                //             desc: 'MORE INDUSTRIES'
                //         }],
                //     routeChildren: []
                // },

                {
                    title:"商务合作",
                    url:"cooperation",
                    outSite:true,
                    routeChildren:[]
                },{
                    title: "关于我们",
                    url: "about",
                    nav: 'about',
                    routeChildren: ['contact']
                },

            ],
            showHoverNav: false,
            showAbout: false,
            navindex: "index",
            navChildren: [],
            hoverUrl: '',
            infoAvar:""
        };
    },
    filters:{
      setCity(e){
          console.log(e)
        if(e.length>=4 && e != undefined){
          return e.slice(0,3) + "..."
        }else{
          return e
        }
      }
    },
    created() {
        this.navindex = this.$route.name;
        this.getInformationClassList()
        if (JSON.parse(localStorage.getItem("userInfo"))) {
            // this.getdu();
        }
    },
    beforeMount() {
        this.$common.fixedPos();
        const loca = window.localStorage.getItem("point")
        let userInfo = window.localStorage.getItem("userInfo")
        if(userInfo != '' && userInfo != undefined && userInfo != null){
            this.infoAvar = JSON.parse(userInfo).imgUrl;
            console.log("头像=>",userInfo)
        }
        // this.infoAvar =
        if (loca != null) {
            let json = JSON.stringify(loca)
            this.$common.sendLogH5('position', {longitude: json.lng, latitude: json.lat})
        }
        console.log("定位=>", loca)
        this.$bus.$on('getStudentName', (typeIndex) => {
            this.typeIndex = typeIndex
        })
    },
    beforeDestroy() {
        this.$bus.$off('getStudentName')
    },
    methods: {
      switchCity(){
        this.$router.push({
          name: 'hotCity',
        })
      },
      close(){
        this.showLogin=false
      },
        //提交联系方式
        submit() {
            console.log("123")
            if (this.name == '') {
                this.$notify.error({
                    title: '错误',
                    message: '姓名不可为空'
                });
                return;
            }
            var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;

            if (!reg.test(this.phone)) {
                this.$notify.error({
                    title: '错误',
                    message: '手机号格式错误'
                });
                return;
            }

            axios({
                method: "POST",
                baseUrl: process.env.VUE_APP_JZWB_URL2,
                needPrivate:true,
                url: "/official/enterprise/insertExchange",
                data: {
                    dataSource: '14',
                    name: this.name,
                    phone: this.phone
                }
            }).then((res) => {
                console.log("123=>", res)
                if (res.code == 200) {
                    this.name = "";
                    this.phone = "";
                    this.$notify({
                        title: '成功',
                        message: '提交成功',
                        type: 'success'
                    });
                    this.typeIndex = 0;
                }else if(res.code == 500){
                    this.name = "";
                    this.phone = "";
                    this.typeIndex = 0;
                    this.$notify({
                        title: '成功',
                        message: res.msg,
                        type: 'success'
                    });
                    console.log(err);
                }
            }).catch((err) => {

            });
        },
        nav_tab(e, isOutSite) {
          console.log(e);
          if(e == "cooperation"){
            // this.$router.push({name: e});
            window.open(location.origin + '/cooperation/index.html', "_blank")
          }else{
            this.u_ceng = false
            if (e == 'home') {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                if (flag) {
                    this.$router.push({name: 'mhome'});
                } else {
                    this.$router.push({name: e});
                }
            } else {
                if (isOutSite) {
                    window.open('https://business.jianzhinet.cn/', "_blank")
                } else {
                    this.$router.push({name: e});
                }

            }
            this.navindex = e;
          }

        },
        //跳转
        clickLink(type) {
            this.user_xx = false;
            switch (type) {
                case 1:
                    this.$router.push("/myuser/job");
                    break;
                case 2:
                    this.$router.push("/myuser/news");
                    break;
                case 3:
                    this.$router.push("/myuser/information");
                    break;
            }
        },
        reg() {
            this.$router.push("/register");
        },
        contactUs() {
            this.$router.push("/contact");
        },
        toHoverNav(item) {
            console.log("路由=>",item)
            console.log(this);


            if (this.hoverUrl === 'about') {
                return
            }
            console.log('this.$route.namethis.$route.namethis.$route.name', this.$route.name)
            if (this.hoverUrl === this.$route.name && !item.path) {
                this.$router.push({
                    name: 'empty',
                    params: {
                        id: item.dictValue,
                        name: this.$route.name
                    }
                });
                this.hoverUrl = '';
                return
            }
            console.log(item)
            this.$router.push({
                name: item.path?item.path:this.hoverUrl,
                params: {
                    id: item.dictValue,
                },
            });
            this.hoverUrl = '';
        },
        logins(){
          this.showLogin=true
            // this.$router.push({
            //     name: 'login',
            // });
        },
        setindex() {
            this.$router.push("/");
        },
        getInformationClassList() {
            this.$axios({
                method: "get",
                url: "/official/enterprise/getInformationClassList",
                params: {
                    pageNum: 1,
                    pageSize: 50,
                },
            })
                .then((res) => {
                    if (res.code === 200) {
                        let informationClassList = res.rows.slice(0, 6);
                        informationClassList.unshift({
                            dictLabel: '资讯专题',
                            path: 'thematicList',
                            dictValue: ''
                        })
                        informationClassList.push({
                            dictLabel: '更多资讯',
                            dictValue: ''
                        })
                        this.wqtNavlist[3].children.map((item, index) => {
                            if (index < informationClassList.length) {
                                item.id = informationClassList[index].dictValue
                                item.title = informationClassList[index].dictLabel
                                return Object.assign(item, informationClassList[index])
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        exit() {
            let _this = this;
            _this.tc = false;
            this.$notify({
                message: "退出成功",
                type: "success", title: "成功",
                duration: 1000,
                onClose() {
                    localStorage.removeItem("userInfo"); //清除信息
                    localStorage.removeItem("token");
                    localStorage.removeItem("statu_name")
                    localStorage.removeItem('userID')
                    _this.userinfo = "";
                    _this.user_xx = false;
                    Bus.$emit('tc_marke', false)
                    _this.$router.push("/");
                },
            });
        },
        mousemoveNav(item) {
            if (item.hasNav) {
                this.showHoverNav = true
                this.navChildren = item.children
            }
            if (item.nav == 'about') {
                this.showAbout = true
            }
            this.hoverUrl = item.url
        },
        mouseoutNav() {
            this.showHoverNav = false
            this.showAbout = false
            this.hoverUrl = ''
        },
        //获取已读信息
        getdu() {
            this.$axios({
                method: "POST",
                url: "/api/center/unread_messages",
            })
                .then((res) => {
                    localStorage.setItem("statu_name", res.data);
                    if (res.data > 0) {
                        this.user_mm = true;
                    } else {
                        this.user_mm = false;

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 获取信息
        getuser() {
            this.$axios({
                method: "POST",
                url: "/api/center/my_info",
            })
                .then((res) => {
                    this.userinfo = res.data;
                })
                .catch((error) => {
                });
        },
    },
    mounted() {
        this.$bus.$on("setImg", (data) => {
            this.infoAvar = data;
            this.$store.commit('SET_USERINFO',data)
        })
    },
    watch: {
        $route() {
            this.userinfo = JSON.parse(localStorage.getItem("userInfo"));
            this.navindex = this.$route.name;
            // if(this.userinfo){this.getdu(),this.getuser()}
            //   deep: true;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.h_nav .wanqi_wd {
  width: 1310px;
  margin: auto;
  max-width: fit-content;
  z-index: 99;
}

.u_ceng, .menu {
  display: none;
}

.mobs .el-button {
  padding: 10px 17px;
}

.djck {
  width: 63px;
  height: 26px;
  border-radius: 12px;
  text-align: center;
  line-height: 26px;
  background: #00CC88;
  font-size: 12px;
  color: #fff;
}

.nav_con ul.mob {
  display: none;
}

.el-button--primary.is-plain {
  background: linear-gradient(145deg, #FF6C00, #FFCE24);
  border: none;
  color: #fff;
  transition: .3s;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
}

.el-button--primary.is-plain:hover {
  background: linear-gradient(145deg, #FF8A00, #FFD324) !important;
  border-color: #ff901f;
  color: #FFF;
  box-shadow: #bc190d 1px 10px 20px;
  transform: translateY(-5px);
}

.user_ceng {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
}

.nav_con ul li.active {
  font-weight: bold;
  color: #00A870
}

.nav_con ul li.active::after {
  display: block;
  width: 20px;
  height: 3px;
  content: "";
  color: #fff;
  background: #00A870;
  background-size: 100%;
  margin: 0 auto;
  transform: translateY(-30px);
  border-radius: 17.5px;
}

.nav_con ul li.active .el-button--primary.is-plain, .el-button--primary.is-plain:focus {
  box-shadow: #a90500 1px 12px 8px 3px;
  background: linear-gradient(145deg, #FF6C00, #FFCE24);
  border: none;
  // box-shadow: #bc190d 1px 10px 20px;
  // transform: translateY(-2px);

}

.nav_con ul li span {
  cursor: pointer;
    font-size: 16px;
}

.dw {
  position: relative;
}

/*  //头部 */
.nav_right span {
  cursor: pointer;
}

.nav_right a {
  color: #333;
}

.h_nav {
  width: 100%;
  height: 90px;
  background: #fff;
  line-height: 90px;
  color: #333333;
  position: fixed;
  top: 0;
  z-index: 10;
}

.nav_list {
  display: flex;
  justify-content: space-between;
  width: 1310px;
  position: relative;
}

.nav_list .nav_left a {
  white-space: nowrap;
}

.nav_list .nav_left img {
  width: 340px;
  vertical-align: middle;
  cursor: pointer;
}

.nav_list .nav_left b {
  font-size: 38px;
  font-weight: bold;
  margin: 0 5px;
  line-height: 45px;
}

.nav_list i {
  font-weight: bold;
  font-size: 22px;
  font-style: italic;
}

.nav_con {
  margin-left: 50px;
}

.nav_con ul {
  display: flex;
}

.nav_con ul li {
  // width:63px;
  text-align: center;
  //margin-right: 25px;
  height: 90px;
  padding: 0 29px;
  //min-width: 50px;
}

.nav_con ul li:hover {
  color: #00A870 !important;
}

.nav_list .nav_right {
  font-size: 17px;
  white-space: nowrap;
}

.nav_list .nav_right span:nth-child(1)::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 9px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 15px;
}

.nav_list .nav_left {
  display: flex;
}

.user_top {
  display: flex;
  justify-content: space-between;
  // width: 150px;
  align-items: center;
  cursor: pointer;
}

.user_top span b {
  width: 13px;
  height: 13px;
  display: inline-block;
  background: rgba(255, 18, 0, 1);
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  top: 20px;
}

.user_top_pic {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  line-height: 38px;
  overflow: hidden;
  display: inline-block;
}

.user_top span a {
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding-left: 15px;
}

.user_top_pic img {
  width: 100%;
  height: 100%;
  vertical-align: super;
}

.user_xx {
  color: #333333;
  width: 160px;
  position: absolute;
  right: 0;
  text-align: center;
  height: 265px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  padding-top: 12px;
  font-size: 16px;
  top: 90px;
}

.user_xx li {
  cursor: pointer;
  position: relative;
  line-height: 64px;
}

.user_xx li span {
  padding-left: 10px;
  transform: translateY(-3px);
  display: inline-block;
}

.user_xx li img {
  transform: translateY(-24px);
}

.user_xx li a {
  display: block;
}

.user_xx li p {
  width: 13px;
  height: 13px;
  background: #ff1200;
  position: absolute;
  right: 15px;
  top: 10px;
  border-radius: 50%;
}

.block {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ceng {
  width: 428px;
  height: 272px;
  border-radius: 46px;
  background: rgba(255, 255, 255, 1);
  margin: 0 auto;
  padding-top: 40px;
  position: relative;
}

.ceng .c_qx {
  width: 106px;
  height: 40px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  color: #00CC88;
  border: #00CC88 1px solid;
  text-align: center;
  line-height: 40px;
  margin-right: 40px;
  cursor: pointer;
  box-shadow: #def4ec 0px 13px 12px 0px;
}

.ceng .c_qr {
  width: 106px;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  background-image: linear-gradient(#00CC88, #37D600);
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  box-shadow: #def4ec 0px 13px 12px 0px;
}

.ceng .c_btn {
  display: flex;
  width: 260px;
  margin: 0 auto;
  margin-top: 60px;
}

.ceng h5 {
  font-size: 22px;
  color: #333333;
  text-align: center;
}

.ceng span {
  color: #000;
  font-size: 30px;
  position: absolute;
  top: -25px;
  right: -30px;
  cursor: pointer;
}

.pc {
  display: block;
}

.mb {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .el-button--primary.is-plain {
    font-size: 15px;
  }

  .mobs .el-button {
    padding: 10px;
  }

}

@media only screen and (max-width: 780px) {
  .nav_list {
    //width: auto;
  }

  .mb {
    display: block;
  }

  .pc {
    display: none;
  }

  .h_nav .wanqi_wd {
    //width: 90%;
    max-width: inherit;
  }

  .nav_list .nav_left img {
    width: 280px;
  }

  .nav_list i {
    font-size: 15px;
  }

  .nav_list .nav_right {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .menu {
    font-size: 25px;
    line-height: 60px;
    display: block;
  }

  .u_ceng {
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    height: 100vh;
    width: 100%;
  }

  .u_ceng ul {
    background: #fff;
    color: #333;
    text-align: center;
    padding-top: 15px;
  }

  .u_ceng ul img {
    width: 60px;
    border-radius: 50%;
  }

  .h_nav {
    height: 60px;
    line-height: 60px;
  }

  .nav_con ul.mob {
    display: flex;
  }

  .nav_con ul.mobs {
    display: none;
  }


  .nav_list .nav_left img {
    width: 6rem;
  }

  .user_top_pic {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .nav_list .nav_left b {
    font-size: 22px;
    font-weight: bold;
    margin: 0 5px;
    line-height: 45px;
    display: none !important;

  }

  .nav_list .nav_right {
    font-size: 14px;
  }

  .nav_list .nav_right span[data-v-29e8c3c6]:nth-child(1)::after {
    margin: 0 5px;
  }

  .nav_list i {
    font-size: 0.8rem;
    display: none !important;
  }

  .nav_list .nav_con {
    margin-left: 0;
  }

  .user_top {
    width: auto;
  }

  .user_top span a {
    // display: none;
    font-size: 14px;
  }

  .user_top span b {
    width: 12px;
    height: 12px;
    transform: translate(-5px, -10px);

  }

  .user_xx {
    top: 60px;
  }

  .nav_con ul li:nth-child(1) {
    margin-right: 3px;
  }

  .nav_con ul li {
    width: 56px;
    margin: 0 5px;
    font-size: 14px;
  }

  .ceng {
    width: 80%;
    height: 230px;
    padding-top: 30px;
    border-radius: 15px;
  }

  .djck {
    display: none;
  }
}

.nav_right {
  display: flex;
  align-items: center;
  //width: 180px;
}

.nav_right > img {
  width: 23px;
}

.nav_right > div {
  color: #00a870ff;
  font-size: 13px;
  font-family: "PingFang SC";
}

.qiehuan {
  color: #999999ff !important;
  // font-size: 4.38px !important;
  font-family: "PingFang SC" !important;
  cursor: pointer !important;
}

.header-nav {
  width: 1200px;
  position: fixed;
  background: #ffffff;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 23.33px 22.5px 0 rgba(50, 50, 50, 0.45);
  display: flex;
  padding: 18px 45px 14px;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: normal;
}

.header-nav-item {
  width: 250px;
  border-radius: 20px;
  background: #f7f7f7;
  text-align: center;
  padding: 18px 0;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.header-nav-item .header-nav-icon {
  width: 56px;
  height: 56px;
  margin-bottom: 13px;
  display: block;
}

.header-nav-item .header-nav-icon img {
  width: 42px;
  height: 42px;
  margin: 7px auto;
  display: block;
}

.header-nav-item .header-nav-icon:nth-child(1) {
  width: 56.67px;
  height: 56.67px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 #ebebeb;
}

.header-nav-item .header-nav-icon:nth-child(2) {
  display: none;
  width: 56.67px;
  height: 56.67px;
  border-radius: 15px;
  opacity: 1;
  background: #07bb7eff;
  box-shadow: 0 4.17px 8.33px 0 #ebebebff;
}

.header-nav-item:hover .header-nav-icon:nth-child(1) {

  display: none;
}

.header-nav-item:hover .header-nav-icon:nth-child(2) {
  display: block;
}

.header-nav-item-title {
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 3px;
}

.header-nav-item-desc {
  opacity: 0.4;
  color: #999999;
  font-size: 12px;
}

.position-box {
  display: flex;
  align-items: center;
}

.position {
  //height: 22.5px;
  border-radius: 2.5px;
  border: 0.42px solid #00a870ff;
  text-align: center;
  line-height: 22.5px;
  color: #00a870ff !important;
  //font-size: 11.67px !important;
  font-family: "PingFang SC" !important;
  padding: 0px 10px;
  margin-right: 15px;
  cursor: pointer;
    font-size: 16px;
}

.tan-box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.fun-box {
    width: 695.61px;
    height: 517.4px;
  border-radius: 22px;
  background: #07BB7E;
}

.fun-box-title {
  display: flex;
}

.fun-box-title > div {
  flex: 1;
  line-height: 80px;
  font-size: 39.06px;
  font-weight: 700;
  font-family: "PingFang SC";
  text-align: center;
  color: #fff;
  cursor: pointer;
    font-size: 28.24px;
    font-weight: 700;
    font-family: "PingFang SC";

}

.findJob {
  background-color: #fff;
  color: #08BB7E !important;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  animation: 0.5s ease-in;
}

.recruitment {
  background-color: #fff;
  color: #08BB7E !important;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  animation: 0.5s ease-in;
}

.recruitment:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: -15px;
  bottom: 0;
  background: #fff;
  background: radial-gradient(circle at 0 0, transparent 15px, #fff 15px);
}

.findJob:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: -15px;
  bottom: 0;
  background: #fff;
  background: radial-gradient(circle at 100% 0, transparent 15px, #fff 15px);
}

//120
.fun-box-bottom {
  width: 100%;
  height: 438px;
  background-color: #fff;
  background-image: url("@/assets/images/pc/PartTimeJob.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  border-radius: 0px 15px 15px 15px;
  display: flex;
  justify-content: flex-end;
  padding: 46px;
}

.fun-box-bottomT {
  width: 100%;
  height: 438px;
  background-color: #fff;
  background-image: url("@/assets/images/pc/PartTimeJob.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  border-radius: 15px 0px 15px 15px;
  display: flex;
  justify-content: flex-end;
  padding: 46px;
}

.part-time-job {
    width: 290px;
    height: 285px;
  border-radius: 18.33px;
  background: #ffffffff;
  box-shadow: 1.67px 0 69.17px 12.5px rgba(90, 90, 90, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.part-time-job>img{
    width: 210px;
    height: 210px;
    margin-top: 21px;
}
.biaoZhu {
  width: 100%;
  height: 31.67px;
  color: #333333ff;
  font-size: 18px;
  font-family: "PingFang SC";
  text-align: center;
  margin: 17px 0px 25px 0;
}

.hireTitle {
  width: 348px;
  height: 50.83px;
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  font-family: "PingFang SC";
  text-align: center;
}

.hireInputBox input {
  display: block;
    width: 348.44px;
    height: 42.5px;
  border-radius: 5px;
  background: #f2f6fe;
  border: none;
  font-size: 18px;
  color: #939393;
  padding-left: 21px;
}

.hireInputBox input:nth-child(1) {
  margin-top: 47px;
}

.hireInputBox input:nth-child(2) {
  margin-top: 26px;
  margin-bottom: 37px;
}

.hireFun {
  width: 348.44px;
  height: 45.5px;
  border-radius: 5px;
  background: linear-gradient(180deg, #07bb7eff 0%, #07bb7eff 100%);
  text-align: center;
  line-height: 45.5px;
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  font-family: "PingFang SC";
  cursor: pointer;
}

.job {
  background: #b3e5d5ff;
}

.about-me {
  width: 121.67px;
  height: 165px;
  border-radius: 0 0 12px 12px;
  background: #ffffffff;
  box-shadow: 0 10px 13.33px 0 #54545466;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.about-me-item {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #11694B;
  margin-top: 10px;
  cursor: pointer;
}

.about-me-item:hover {
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  color: #ffffff;
}

.about-me-item:hover img:nth-child(1) {
  display: none;
}

.about-me-item:hover img:nth-child(2) {
  display: block;
}

.about-me-item img {
  margin-left: 15px;
  margin-right: 6px;
  display: block;
  width: 20px !important;
  height: 20px;
}

.about-me-item img:nth-child(2) {
  display: none;
}
.logIn{
    width: 61.67px;
    height: 24.89px;
    border-radius: 17.5px;
    border: 0.42px solid #00a870ff;
    color: #00a870;
    font-size: 13.33px;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 24.89px;
    margin-left: 20.83px;
}
.avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 47px;
}
.avatar>img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
@media only screen and (max-width: 1700px) {
    .fun-box {
        width: 660px;
        height: 470px;
        border-radius: 22px;
        background: #07BB7E;
    }
    .fun-box-bottom{
        width: 100%;
        height: 438.6px;
        background-color: #fff;
        background-image: url("@/assets/images/pc/PartTimeJob.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom left;
        border-radius: 0px 15px 15px 15px;
        display: flex;
        justify-content: flex-end;
        padding: 37px;
    }
    .fun-box-bottomT{
        width: 100%;
        height: 438.6px;
        background-color: #fff;
        background-image: url("@/assets/images/pc/PartTimeJob.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom left;
        border-radius: 0px 0px 15px 15px;
        display: flex;
        justify-content: flex-end;
        padding: 37px;
    }
    .part-time-job {
        width: 278px;
        height: 306px;
        border-radius: 18.33px;
        background: #ffffff;
        box-shadow: 1.67px 0 69.17px 12.5px rgba(90, 90, 90, 0.12);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 16px;
    }
    .part-time-job>img{
        width: 200px;
        height: 200px;
    }
    .biaoZhu{
        color: #333333;
        font-size: 17.6px;
        font-family: "PingFang SC";
        text-align: center;
    }
    .fun-box-title > div {
        flex: 1;
        line-height: 80px;
        font-size: 25.06px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        color: #fff;
        cursor: pointer;
    }
    .hireTitle {
        width: 330px;
        height: 50.83px;
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
    }
    .hireInputBox > input{
        display: block;
        width: 330px;
        height: 52.5px;
        border-radius: 5px;
        background: #f2f6fe;
        border: none;
        font-size: 17px;
        color: #939393;
        padding-left: 21px;
    }
    .hireInputBox input:nth-child(1) {
        margin-top: 13px;
    }
    .hireFun{
        width: 330px;
        height: 52.5px;
        border-radius: 5px;
        background: linear-gradient(180deg, #07bb7eff 0%, #07bb7eff 100%);
        text-align: center;
        line-height: 52.5px;
        color: #ffffffff;
        font-size: 22px;
        font-weight: 700;
        font-family: "PingFang SC";
        cursor: pointer;
    }
    .hireInputBox input[data-v-79058c6e]:nth-child(2) {
        margin-top: 26px;
        margin-bottom: 30px;
    }
}

</style>
