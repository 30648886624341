<template>

  <div class="renwu_topT">
    <swiper :options="mySwiperOption" v-if="bannerList.length>0">
      <swiper-slide v-for=" (item,index) in bannerList" :key="item.id" >
        <div v-if="item.videoUrl == '' || item.videoUrl == null" style="height: 100%;position: relative" :data-no="item.id">
          <img :data-set="index" style="width: 100%;height: 100%;" :src="item.imgUrl" alt="兼职网"/>

        </div>
        <div v-else  style="height: 100%;position: relative;" :data-no="item.id">
          <img :data-set="index" style="width: 100%;height: 100%;" :src="item.imgUrl" alt="兼职网" />
          <!---播放按钮-->
          <div class="play-box" >
            <img :data-set="index" src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687435651878Group%20427322971.png"  alt="兼职网"/>
          </div>
          <!-- <video controls  width='100%' height='100%'><source  :src="item.videoUrl"  type='video/mp4'></video> -->
        </div>
      </swiper-slide>
    </swiper>
      <div class="funBox">
          <div @click="setTypeIndex(1)" class="swiperFun" v-if="buttonshow">
              找兼职
          </div>
          <div class="swiperFunT" @click="setTypeIndex(2)" v-if="buttonshow">我要招聘</div>
      </div>

    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>

    <!---视频播放框-->
    <div class="viode-box" v-if="videoShow" @click="videoShow = !videoShow">
      <div class="video-mess">
        <video id="startvideo" controls  width='100%' height='100%' autoplay="autoplay"><source  :src="playVideo"  type='video/mp4' ></video>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "banner",
  data(){
    return{
      buttonshow:false,
      videoShow:false,  //控制视频不展示
      playVideo:"", //视频播放的地址
      mySwiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click:(e)=> {
              console.log("数据=>",e.target.dataset.set)
              console.log("数据=>",e)
              let index = e.target.dataset.set
            let item  = this.bannerList[index]
              if(item.jumpUrl != "" && item.jumpUrl != undefined && item.jumpUrl != null){
                  let name = ''
                  let params = {}
                  if(item.jumpUrl.indexOf('?') > '-1'){
                      name = item.jumpUrl.split('?')[0];
                      console.log("name=>",name)
                      let param = item.jumpUrl.split('?')[1]
                      param.split('&').map(item=>{
                          params[item.split('=')[0]] = item.split('=')[1]
                      })
                  }
                  name = item.jumpUrl.split('?')[0];
                  this.$router.push({
                      name: name,
                      params: Object.assign({},{
                          id: item.jumpUrl,
                          name: this.$route.meta.metaInfo.title,
                      },params),
                  });
              }
              if(item.videoUrl != null && item.videoUrl != ''){
                this.videoShow = true;
                this.playVideo = item.videoUrl
                var video = document.getElementById("startvideo");
                if(video != null){
                  video.play();
                }
            }else {
                // if(even.srcElement.outerText == "找兼职"){
                //     this.$bus.$emit('getStudentName', 1)
                // }else if(even.srcElement.outerText == "我要招聘"){
                //     this.$bus.$emit('getStudentName', 2)
                // }
            }

          }
        }
      }
    }
  },

  props:{
    bannerList:Array
  },
  // watch: {
  //   bannerList: {
  //     //为什么即使监听到数据变化了，还需要放入$nextTick？因为v-for渲染需要时间，在没有渲染完成之前，不能初始化swiper
  //     immediate: true,
  //     handler() {
  //       //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
  //       this.$nextTick(function() {
  //         var mySwiper = new Swiper(this.$refs.cur, {
  //           auto:3000,
  //           loop: true,
  //           navigation: {
  //             nextEl: ".swiper-button-next",
  //             prevEl: ".swiper-button-prev",
  //           },
  //         });
  //       });
  //     },
  //   },
  // },
    beforeMount() {
        if(this.$route.name == "informationHome"){
            this.buttonshow = false;
            console.log("123=>",this.buttonshow)
        }else {
            this.buttonshow = true;
            console.log("456=>",this.buttonshow)
        }
    },
    methods:{
    setTypeIndex(e){
        this.$bus.$emit('getStudentName', e)
    },
    swiperleft(){

    },
    swiperright(){

    },
    setVideo(e){
      this.videoShow = true;
      this.playVideo = e.videoUrl;
      var video = document.getElementById("startvideo");
      //调用video标签的播放函数
      video.play();
    }
  }
}
</script>

<style scoped>
.funBox{
    width: 21vw;
    position: absolute;
    z-index: 9;
    top: 67vh;
}
.renwu_topT{
  position: relative;
  width: 100%;
  /*height: 468px;*/
}

.renwu_top {
  position: relative;
  width: 100%;
  /* height: 540px; */
  /* height: 440px; */
}
.viode-box{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-mess{
  width: 1200px;
  margin: auto;
  height: 600px;
}
.play-box{
  position: absolute;
  right: 150px;
  bottom: 60px;
}
.play-box>img{
  width: 100px;
  height: 100px;
}
/deep/.swiper-button-next{
}
.swiper-button-prev, .swiper-button-next{
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /*color: var(--swiper-navigation-color, var(--swiper-theme-color));*/
    color: #fff;
}
/deep/ .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 32px;
}
.swiperFun{
    width: 133.33px;
    height: 50px;
    border-radius: 45.83px;
    background: #fffa39ff;
    text-align: center;
    line-height: 50px;
    position: absolute;
    left: 54px;
    bottom: 114px;
    box-shadow: 0 3.33px 19.17px 0 #62626280, inset 3.33px 3.33px 4.17px 0 #fffc98, inset -5px -1.67px 11.67px 0 #c1bd0d;
    color: #6484ffff;
    font-size: 23.33px;
    font-family: "Yuanti SC";
    cursor: pointer;
}
.swiperFunT{
    width: 133.33px;
    height: 50px;
    border-radius: 45.83px;
    background: #ffffff;
    text-align: center;
    line-height: 50px;
    position: absolute;
    left: 212.33px;
    bottom: 114px;
    box-shadow: 0 3.33px 11.67px 0 rgba(77,77,77,0.8), inset 0 0 8.33px 0 #a8a8a8;
    color: #07BB7E;
    font-size: 23.33px;
    font-family: "Yuanti SC";
    cursor: pointer;
}
</style>
