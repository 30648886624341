<template>
  <div>
      <div class="headerImg">
          <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1683204323931lQLPJv9Nlk10plLNB5vNEHSwhPL1regRVrwES1DgdoAyAA_4212_1947.png" />
      </div>
      <div class="BreadCrumbs">
          <span @click="goHome" style="color: #333333">首页<i class="el-icon-arrow-right"></i></span>
          <span @click="goAbout">关于我们<i class="el-icon-arrow-right"></i></span>
          <span style="color: #00A870">联系我们</span>
      </div>

      <!--兼职网帮你解决问题-->
      <div class="module-boxs">
          <div class="MainHeading">
              兼职网帮你解决问题
          </div>
          <div class="subtitle">
              兼职中遇到问题请联系官方客服
          </div>

          <div class="module-mess-box">
              <div class="helpList">
                  <div class="helpListM">
                      <div class="helpListMTitle">
                          <img src="@/assets/images/pc/auditIcon.png" />
                          多重审核
                      </div>
                      <div class="helpListMTitleT">
                          专业团队层层过滤，确保发布工作真实合规
                      </div>
                  </div>

                  <div class="helpListM">
                      <div class="helpListMTitle">
                          <img src="@/assets/images/pc/reportIcon.png" />
                          举报管家
                      </div>
                      <div class="helpListMTitleT">
                          在线举报快速响应，保障纯净兼职环境
                      </div>
                  </div>

                  <div class="helpListM">
                      <div class="helpListMTitle">
                          <img src="@/assets/images/pc/guaranteeIcon.png" />
                          兼职保障
                      </div>
                      <div class="helpListMTitleT">
                          制定安全保障，安全可信赖
                      </div>
                  </div>
                  <div class="helpListM">
                      <div class="helpListMTitle">
                          <img src="@/assets/images/pc/carefreeIcon.png" />
                          薪资无忧
                      </div>
                      <div class="helpListMTitleT">
                          平台担保薪资权益，违规商家扣除保证金
                      </div>
                  </div>



              </div>

              <div class="contactBox">
                  <div class="contactTop">
                      <img src="@/assets/images/pc/platformPhone.png" />

                      <div class="contactTitle">平台官方热线</div>
                      <div class="contactPhone">0531-88962990</div>
                  </div>


                  <div class="contactTops">
                      <img src="@/assets/images/pc/wX.png" />

                      <div class="contactTitle">官方二维码</div>
                      <div class="contactImg">
                          <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1683204421426lQLPJw6COWDyrdLNAZDNAZCwcJiwSuLdbJEES1F7FcCDAA_400_400.png" alt="兼职网官方二维码"/>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!--合作联系-->
      <div class="module-boxs">
          <div class="MainHeading">
              合作联系
          </div>

          <div class="module-mess-box">
              <div class="cooperationList">
                  <div class="cooperationListTitle">客服咨询</div>
                  <div class="cLM">
                      <div class="cLMimg">
                          <img src="@/assets/images/pc/platformPhone.png" alt="兼职网客服咨询"/>
                      </div>

                      <div class="cLMM">
                          <div class="cLMMTitle">客服热线：</div>
                          <div class="cLMMI">0531-88962990</div>
                      </div>
                  </div>
                  <div class="cLMTime">
                      工作时间：周一至周日9:00-21:00
                  </div>
              </div>


              <div class="cooperationList">
                  <div class="cooperationListTitle">商户合作</div>
                  <div class="cLM">
                      <div class="cLMimg">
                          <img src="@/assets/images/pc/cooperationImg.png" alt="兼职网商户合作"/>
                      </div>

                      <div class="cLMM">
                          <div class="cLMMTitle">业务热线：</div>
                          <div class="cLMMI">19963883657</div>
                      </div>
                  </div>
                  <div class="cLMTime" >
                      工作时间：周一至周日9:00-21:00
                  </div>
              </div>


              <div class="cooperationList">
                  <div class="cooperationListTitle">业务联系</div>
                  <div class="cLMs">
                      <div class="cLMimg">
                          <img src="@/assets/images/pc/wX.png" alt="兼职网业务联系"/>
                      </div>

                      <div class="cLMM" style="display: flex;align-items: center">
                          <div>
                              <div class="cLMMTitle">企业微信</div>
                              <div class="cLMMIs">客服二维码</div>
                          </div>
                          <div style="margin-left: 16px">
                              <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16867312386731686731211963.png" style="width: 100px"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!--企业地址-->
      <div  class="module-boxs">
          <div class="MainHeading">
              企业地址
          </div>

          <div class="module-mess-box">
              <div class="addImg">
                  <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1683204548298lQLPJwDm2_9YcpLNAerNAruwBPGliMgkblQES1H4qMDPAA_699_490.png" />
              </div>

              <div class="addInfo">
                  <div class="addInfoName">兼职网（济南）信息技术有限公司</div>
                  <div class="addInfoAdd">地址：山东省济南市天桥区明湖广场3号楼2016室</div>
                  <div class="addInfoPhone">电话：0531-88962990 </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "contact",
    methods:{
        goHome(){
            this.$router.push({
                name:"homePage"
            })
        },
        goAbout(){
            this.$router.push({
                name:"about"
            })
        }
    }
}
</script>
<style src="@/assets/css/pc/contact.css" scoped></style>
