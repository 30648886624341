<template>
  <!-- 求职保障 -->
<div class="needJob">
  <!-- 顶部返回部分 -->
  <div class="topBack">
    <div class="backIcon" @click="$common.returnEvent()">
      <i class="el-icon-arrow-left"></i>
    </div>
    <span>公司主页</span>
  </div>
  <!-- 顶部 -->
  <div class="topMsg">
    <div class="topMsg_l">
      <div style="width: 18rem;">{{enterpriseName}}</div>
      <div>
        <img src="@/assets/image/mobileImgs/lanV.png" alt="蓝V">
        <span>已通过企业认证</span>
      </div>
    </div>
    <img :src="enterpriseLogo" alt="公司logo">
  </div>
  <div class="topBacc">
    <img src="@/assets/image/mobileImgs/topBacc.png" alt="顶部背景">
  </div>
  <div class="topMsg2">
    <div>
      <div>已入驻兼职网</div>
      <div class="topMsg2_day">{{enterpriseInfo.settledMonths ? enterpriseInfo.settledMonths > 0 ? enterpriseInfo.settledMonths + '个月' : '未满1个月' : '--'}}</div>
    </div>
    <div>
      <div>用户评价</div>
      <van-rate v-model="enterpriseInfo.appraise" color="#DA8505" size="16px" readonly />
    </div>
  </div>
  <!-- 信息 -->
  <div class="contMsg_title">
    <img src="@/assets/image/mobileImgs/ComIcon.png" alt="小图标">
    <span>企业基本信息</span>
  </div>
  <div class="lists">
    <div class="list_item" v-for="(item,index) in list" :key="index">
      <div>{{ item.title }}</div>
      <div>{{ enterpriseInfo[item.cont] }}</div>
    </div>
    <div class="lineClass">
      <span>以上信息由<img src="@/assets/image/mobileImgs/tyc.png" alt="天眼查">天眼查提供</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name:'company-m',
  data() {
    return {
      list:[
        {title:'企业全称',cont:'unitName'},
        {title:'法人姓名',cont:'legalPersonName'},
        {title:'统一社会信用代码',cont:'creditCode'},
        {title:'所属行业',cont:'categoryBig'},
        {title:'成立时间',cont:'enterpriseEstablishmentTime'},
        {title:'注册资本',cont:'registeredFund'},
        {title:'注册地址',cont:'companyRegisteredAddress'},
        {title:'所在地',cont:'regLocation'},
        {title:'登记机关',cont:'regInstitute'},
        {title:'经营期限自',cont:'certificateStartTime'},
        {title:'经营期限至',cont:'certificateEndTime'},
        {title:'企业类别范围',cont:'companyCategoryRange'},
        {title:'企业类型',cont:'companyOrgType'},
        {title:'经营状态',cont:'regStatus'},
        {title:'经营范围',cont:'businessScope'},
      ],
      enterpriseInfo:{},
      enterpriseId:'',
      enterpriseName:'',
      enterpriseLogo:'',
    }
  },
  created () {
    let qy=this.$router.getParams()
    this.enterpriseId = qy.id 
    this.enterpriseName = qy.name
    this.enterpriseLogo = qy.logo
    if(qy.id){
      this.getEnterprise()
    }
  },
  methods: {
    getEnterprise() {
      this.$common.showLoading()
      this.$axios({
        method:'POST',
        url:'/enterprise/enterprise/getEnterpriseInfo',
        params:{
          id:this.enterpriseId
        }
      }).then(res => {
        this.$common.hideLoading()
        if (res.code == 200) {
          this.enterpriseInfo = res.data
          if(res.data&&(!res.data.appraise || res.data.appraise<=0 || res.data.appraise>5)){
            this.enterpriseInfo['appraise'] = 5
          }
          if(res.data&&res.data.certificateStartTime&&!res.data.certificateEndTime){
            this.enterpriseInfo['certificateEndTime'] = '永久'
          }
        } else {
          this.enterpriseInfo['appraise'] = 5
        }
      })
    },
  },
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
/* 顶部返回区域 */
.topBack{
  width: 100vw;
  height: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  background-color: #0E1233;
  color: #ffffff;
  font-size: 1.13rem;
  font-weight: 600;
  font-family: "PingFang SC";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.backIcon{
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4rem;
}
.needJob{
  padding: 3rem 0 1.69rem;
  background-color: #0E1233;
}
.topMsg{
  display: flex;
  justify-content: space-between;
  padding: 0 0.81rem;
  margin: 1.25rem 0;
}
.topMsg_l{
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1;
  letter-spacing: 1px;
}
.topMsg_l>div>span{
  color: #ffffffff;
  font-size: 0.63rem;
  font-weight: 600;
  font-family: "PingFang SC";
  vertical-align: middle;
  line-height: 1;
}
.topMsg_l>div>img{
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}
.topMsg>img{
  width: 2.81rem;
  height: 2.81rem;
}
.topBacc,.topBacc>img{
  width: 21.88rem;
  height: 9.56rem;
  margin: 0 auto;
}
.topMsg2{
  width: 21.88rem;
  height: 4.19rem;
  border-radius: 0.38rem;
  background-color: #282947;
  margin: 0.5rem auto 1.38rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
}
.topMsg2>div>div:first-child{
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  line-height: 0.88rem;
  text-align: center;
  margin-bottom: 0.43rem;
}
.topMsg2_day{
  color: #ffffffff;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 0.88rem;
  text-align: center;
}
.contMsg_title{
  width: 21.88rem;
  margin: 0 auto;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 0.88rem;
}
.contMsg_title>img{
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.25rem;
}
.lists{
  width: 21.75rem;
  border-radius: 0.38rem;
  background: #282947;
  margin: 0.88rem auto 0;
  padding: 1.44rem 0.63rem;
}
.list_item{
  font-weight: 500;
  font-family: "PingFang SC";
  margin-bottom: 0.63rem;
}
.list_item>div:first-child{
  color: #898989ff;
  font-size: 0.75rem;
}
.list_item>div:last-child{
  color: #ffffffff;
  font-size: 0.88rem;
}
.lineClass{
  width: 11.5rem;
  height: 1.31rem;
  border-bottom:1px solid #74778c;
  color: #ffffff;
  font-size: 0.63rem;
  font-weight: 400;
  font-family: "PingFang SC";
  position: relative;
  margin: 0 auto;
}
.lineClass>span{
  width: 8.5rem;
  height: 1.31rem;
  line-height: 1.31rem;
  display: inline-block;
  text-align: center;
  position: absolute;
  bottom: -0.66rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #282947;
}
.lineClass>span>img{
  width: 0.63rem;
  height: 0.63rem;
  vertical-align: middle;
  margin: 0 1px;
}
</style>