<template>
  <!-- 首页 -->
  <div style="position: relative;">
    <!-- 吸顶区域 -->
    <div class="stickyTop" v-if="isSticky&&wxManagerList&&wxManagerList.topChange">
      <div class="stickyTop_fst">
        <img :src="wxManagerList.topChange" style="width:90%" alt="兼职网logo">
      </div>
      <!-- 吸顶--类型 -->
      <div class="middleView_tabs_outer" style="margin: 0.4rem 0 0.6rem;">
        <div class="middleView_tabs hideScroll">
          <div v-for="(item,index) in classifyList" :key="index" @click="chooseItem(index)" style="position: relative;">
            <div class="hotdiv"></div>
            <div class="hotdiv-ab"><img v-show="hotIndex==index" :src="hotIcon" alt="hot"></div>
            <div class="tab_cont" :class="chooseIndex==index?'tab_cont_checked':''">
              {{ item.classifyName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 顶部区域 -->
    <div v-if="wxManagerList&&wxManagerList.topDefault">
      <div class="topView" :style="{'background': 'url(' + wxManagerList.homeBack + ')'}">
        <div class="topView_fst">
          <img :src="wxManagerList.topDefault" style="width:90%" alt="兼职网logo">
        </div>
      </div>
    </div>
    <!-- 中间部分 -->
    <div class="middleView">
      <!-- 类型 -->
      <div class="middleView_tabs_outer">
        <div class="middleView_tabs hideScroll">
          <div v-for="(item,index) in classifyList" :key="index" @click="chooseItem(index)" style="position: relative;">
            <div class="hotdiv"></div>
            <div class="hotdiv-ab"><img v-show="hotIndex==index" :src="hotIcon" alt="hot"></div>
            <div class="tab_cont" :class="chooseIndex==index?'tab_cont_checked':''">
              {{ item.classifyName }}
            </div>
          </div>
        </div> 
      </div>
      <!-- 轮播&榜单 -->
      <homeTop @videoClick="videoClick" style="margin: 0.5rem 0 0rem;" />

      <van-sticky offset-top="1.7rem" @change="stickyChange">
        <div :style="isSticky?'opacity:0;':'opacity:1;'">
          <div style="height: 1px;width:1px;"></div>
        </div>
      </van-sticky>

      <!-- 列表区域 -->
      <van-list
        style="margin: 0.5rem 0;"
        v-model="taskLoad.load"
        :finished="taskLoad.noMore"
        finished-text="- 没有更多内容了哦 -"
        @load="loadNextPage"
      >
        <van-cell v-for="(item,index) in taskList" :key="index">
          <jobItem :refresh="true" :itemObj="item" :keyd="index" @refreshd="refreshd" />
        </van-cell>
      </van-list>
    </div>
    <van-overlay :show="showVideo" @click="showVideo = false" z-index="999">
      <div class="wrapper" @click.stop>
        <video autoplay v-if="showVideo" :src="videourl" controls></video>
      </div>
    </van-overlay>
    <jobMask ref="jobMask" @comfirm="comfirm" @reset="reset" :popupNum="1" />
  </div>
  </template>
  
  <script>
  const imgarrs = [
    'https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681180934796%E5%9B%BE%E7%89%87.gif',//白色定位logo
    'https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681719878665home_address3.gif',//绿色定位logo
    'https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/d4c37f5a-96ab-a7c4-1ab3-11b405069a43-hot.gif',//hot
  ]
  import homeTop from '@/components/h5/homeTop.vue'
  import setSet from "@/assets/js/setSet.js"
  export default {
    name:'homePage-m',
    components: {
      homeTop,
    },
    data() {
      return {
        wxManagerList:null,
        setSet:setSet,
        latitude:0,
        longitude:0,
        isLogin: false,//是否登录
        isSticky:false,//是否吸顶
        locationLogo:imgarrs[0],
        locationLogo2:imgarrs[1],
        hotIcon:imgarrs[2],
        areaName:"",//定位地址
        searchList: [{dictLabel:'请输入搜索内容',dictCode:0}], //搜索切换滑块
        classifyList:[],//分类列表
        hotIndex:1,//hot下标
        chooseIndex:0, //选中类型下标
        checkFilter:1,//筛选下标
        //任务列表查询
        taskData: {
          pageNum: 1, //查询第几页
          pageSize: 20,
          queryTypes: ["1", "0", "0", "0", "0", "0"],//默认根据算法查
          // queryTypes: ["1", "0", "0", "0", "0", "0"],//默认根据算法查
          positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
          positionType: 1, //1兼职职位，2课程职位
          latitude:0,
          longitude:0,
          orderByField:'',
        },
        taskLoad:{
          load:false,
          empty:false,
          noMore:false,
        },
        taskList:[],//职位列表
        showVideo:false,//打开视频预览
        videourl:'',//视频地址
        screenData: {//筛选查询参数
          pageNum: 1, //查询第几页
          pageSize: 10,
          levelId: '',
          type: '',
          pSex: '',
          level: '',
          longitude: 0,
          latitude: 0,
          orderByField:'',
        },
        screenInfo: { //筛选条件
          pSex: '',
          positionSecond: '',
          positionFirst: '',
        },
        getAdTaskList:'/app/home/getLessonRecommendList',//推广接口职位
        needLoadAd:true,//是否需要加载广告位
        errorCode:false,//服务器是否错误
      }
    },
    activated(){
      console.log("执行了");
      let token = localStorage.getItem('token')
      this.isLogin = !!token
    },
    created () {
      this.$common.fixedPos().then(res=>{
        this.handlerCreate()
      }).catch(err=>{
        this.handlerCreate()
      })
  
    },
    mounted () {
    },
    methods: {
      handlerCreate(){
        // console.log(32423423432423423423)
        // let lat = this.latitude
        // let lgt = this.longitude
        // let area = localStorage.getItem('point')
        // if(area){
        //   area = JSON.parse(area)
        //   lat = area.lat
        //   lgt = area.lng
        //   this.areaName=area.city
        // }
        // this.taskData.latitude = lat ? lat : 0
        // this.taskData.longitude = lgt ? lgt : 0
        // this.screenData.latitude = lat ? lat : 0
        // this.screenData.longitude = lgt ? lgt : 0
        // this.latitude = lat ? lat : 0
        // this.longitude = lgt ? lgt : 0
        // this.getHotSearch()
        this.getNavigationList()
        this.getsWxManage();
        // this.getTaskList()
      },
      refreshd(){
        this.taskData={
          pageNum: 1,
          pageSize: 6,
          queryTypes: ["1", "0", "0", "0", "0", "0"],
          positionState: 1,
          positionType: 1,
          latitude:this.latitude,
          longitude:this.longitude,
          orderByField:''
        }
        this.taskData.pageNum=1
        document.documentElement.scrollTop = 0;
        this.needLoadAd=true
        this.getTaskList()
      },
      reset(){
        let newindex = this.chooseIndex
        this.chooseIndex = '-1'
        this.chooseItem(newindex)
      },
      // 筛选确定
      comfirm(e){
        if (!e || (!e.pSex && !e.levelId)) {
          this.taskData.pageNum = 1
          this.taskList = [] //初始化数据
          this.getTaskList()
          return
        }
        this.checkFilter=4
        this.screenData={
          pageNum:1,
          pageSize: 6,
          levelId:e.levelId,
          level:e.levelId ? e.level : '',
          pSex:e.pSex,
          type:this.chooseIndex,
          latitude:this.taskData.latitude,
          longitude:this.taskData.longitude,
          positionFirst:e.positionFirst,
          positionSecond:e.positionSecond?e.positionSecond:'',
        }
        this.taskList = []
        this.getScreenList()
      },
      // 筛选结果展示
      getScreenList() {
        this.taskLoad.load=true
        // this.$common.showLoading()
        this.$axios({
          method:'POST',
          url:'/app/homeScreen/getHomeScreenT',
          data:this.screenData,
        }).then(res=>{
          // this.$common.hideLoading()
          this.taskLoad.load=false
          if(res.code==200){
            this.taskLoad.noMore=Boolean(!res.rows || res.rows.length<this.screenData.pageSize)
            if(this.screenData.pageNum == 1){
              this.taskList=res.rows
            }else{
              this.taskList.push(...res.rows)
            }
          }
        })
      },
      // 轮播视频点击
      videoClick(url){
        this.videourl=url
        this.showVideo=true
      },
      // 吸顶状态
      stickyChange(e){
        this.isSticky=e
      },
      // 分类选中
      chooseItem(index){
        if (this.chooseIndex == index) {
          return false
        }
        this.taskLoad = {
          load:false,
          empty:false,
          noMore:false,
        }
        this.chooseIndex=index;
        this.getTaskList();
        // this.filterChange(1,true)
        // // 1：在家做 2：在本地 3：在线课程
        // if (index == 0) { //如果是推荐
        //   this.taskData.queryTypes[0] = '1' //查询分类列表
        //   this.taskData.queryTypes[3] = '0'
        // } else if (index == 1 || index == 2 || index == 3) {
        //   this.taskData.queryTypes[0] = '0' //查询分类列表
        //   this.taskData.queryTypes[3] = index //查询分类列表
        // } else {
        //   this.taskData.queryTypes[0] = '0' //查询分类列表
        //   this.taskData.queryTypes[3] = this.classifyList[index].id //查询分类列表
        // }
        // this.taskData.pageNum = 1 //初始化页数
        // this.taskList = [] //初始化数据
        // this.$forceUpdate()
        // this.getTaskList()
      },
      getsWxManage() {
        this.$axios({
          url:'/platform/wxManage/list',
          method:'GET',
          // data:{
          //   pageNum: 1,
          //   pageSize: 100,
          //   appId:setSet.appid
          // },
          params:{
            pageNum: 1,
            pageSize: 100,
            appId:setSet.appid
          }
        }).then(res=>{
          if(res&&res.rows&&res.rows.length>0) {
            this.wxManagerList = res.rows[0];
          }
        })
      },
      // 获取分类列表
      getNavigationList() {
        // this.classifyList = [{
        //     navName: '推荐'
        //   },{
        //     navName: '在家做'
        //   },{
        //     navName: '在本地'
        //   },{
        //     navName: '在线课程'
        //   }
        // ]
        this.$axios({
          url:'/jlApplets/classify/list',
          method:'GET',
          params:{
            pageNum: 1,
            pageSize: 100,
            appId:setSet.appid
          },
        }).then(res=>{
          if(res.code==200){
            // this.classifyList.push(...res.rows)
            this.classifyList = [{
              id: -1,
              classifyName: "全部"
            }, ...res.rows]
          }
        })
      },
      // 热门搜索
      getHotSearch(){
        this.$axios({
          url:'/app/message/getSearchType',
        }).then(res=>{
          if (res.code == 200) {
            this.searchList = res.rows
          } else {
            this.searchList = [{dictLabel:'请输入搜索内容',dictCode:0}]
          }
        })
      },
      // 筛选选中,bool：重置筛选值为推荐，类型切换时使用
      filterChange(i,bool){
        if(i==4){
          this.$refs.jobMask.maskSwitch(true)
          return false
        }else if(this.checkFilter==i){
          return false
        }
        this.checkFilter=i
  
        this.taskLoad = {
          load:false,
          empty:false,
          noMore:false,
        }
        if(i==1){
          this.taskData.queryTypes[0] = this.chooseIndex === 0 ? '1' : '0'
          this.taskData.queryTypes[2] = '0'
          this.taskData.queryTypes[3] = this.chooseIndex === 0 ? '0' : this.chooseIndex > 3 ? this.classifyList[this.chooseIndex].id : this.chooseIndex
          this.taskData.orderByField = '' //附近调用
        }else if(i==2){
          this.taskData.queryTypes[0] = '0'
          this.taskData.queryTypes[2] = '1'
          this.taskData.queryTypes[3] = this.chooseIndex === 0 ? '0' : this.chooseIndex > 3 ? this.classifyList[this.chooseIndex].id : this.chooseIndex
          this.taskData.orderByField = '' //附近调用
        }else if(i==3){
          this.taskData.queryTypes[0] = '0'
          this.taskData.queryTypes[2] = '0'
          this.taskData.queryTypes[3] = '2'
          this.taskData.orderByField = 'distance' //附近调用
        }
        if(!bool){
          this.taskData.pageNum = 1 //初始化页数
          this.taskList = [] //初始化数据
          this.getTaskList() //重新调用
        }
      },
      // 加载下一页
      loadNextPage(){
        // console.log('loadNextPage',this.needLoadAd)
        if(this.errorCode){
          return false;
        }
        if(!this.taskLoad.noMore){
          if(this.needLoadAd){
            this.needLoadAd=false
          }else{
            this.taskData.pageNum++
          }
          this.getTaskList()
        }
      },
      // 获取任务列表
      getTaskList() {
        let loadUrl='/jlApplets/essay/list'
        // if(this.needLoadAd){
        //   loadUrl=this.getAdTaskList
        // }
        this.taskLoad.load=true
        this.$axios({
          method:'GET',
          url:loadUrl,
          params:{
            pageNum:this.taskData.pageNum,
            pageSize:this.taskData.pageSize,
            appletAppId:setSet.appid,
            randomSort:1,
            displayStatus:1,
            classifyId:this.chooseIndex==0?'':this.classifyList[this.chooseIndex].id
          },
        }).then(res=>{
          // console.log(11111111111);
          this.errorCode=false
          // this.$common.hideLoading()
          this.taskLoad.load=false

          if (res.rows && res.rows.length > 0) {
            this.taskLoad.noMore=Boolean(res.rows.length<this.taskData.pageSize);
						if (this.taskData.pageNum == 1) {
							this.taskList = res.rows.concat(res.rows,res.rows,res.rows,res.rows)
						} else {
							this.taskList.push(...res.rows)
						}
					} else {
						this.load = 'noMore'
					}
        }).catch(err=>{
          // console.log(222222222222);
          // this.$toast('服务器错误,请稍后刷新重试！')
          this.errorCode=true
        })


        return false;
        // // this.$common.showLoading()
        // let loadUrl='/app/home/getTaskList'
        // console.log('this.needLoadAd',this.needLoadAd)
        // if(this.needLoadAd){
        //   loadUrl=this.getAdTaskList
        // }
        // this.taskLoad.load=true
        // this.$axios({
        //   method:'POST',
        //   url:loadUrl,
        //   params:{
        //     pageNum:this.taskData.pageNum,
        //     pageSize:this.taskData.pageSize,
        //   },
        //   data:this.taskData,
        // }).then(res=>{
        //   this.errorCode=false
        //   // this.$common.hideLoading()
        //   this.taskLoad.load=false
        //   if(res.code==200){
        //     if(this.needLoadAd){
        //       this.taskList=res.rows
        //       this.needLoadAd=false
        //       this.getTaskList()
        //     }else{
        //       this.taskLoad.noMore=Boolean(res.rows.length<this.taskData.pageSize)
        //       if(this.taskData.pageNum==1){
        //         this.taskLoad.empty=Boolean(res.rows.length==0)
        //         this.taskList.push(...res.rows)
        //         this.taskData.pageNum++
        //         this.getTaskList()
        //       }else{
        //         this.taskList.push(...res.rows)
        //       }
        //     }
        //   }
        // }).catch(err=>{
        //   this.$toast('服务器错误,请稍后刷新重试！')
        //   this.errorCode=true
        // })
      },
      // 跳转搜索页
      toLinkSearch(){
        this.$router.push('/homeSearch-m')
      },
      linkLogin(){
        this.$common.directDelParams('h5LoginParam')
        this.$router.push('/login-m')
      },
    },
  }
  </script>
  
  <style scoped>
  *{
    box-sizing: border-box;
  }
  /* 顶部区域 */
  .topView{
    width: 100vw;
    height: 5rem;
    /* background: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681697864727home_top2.png') no-repeat top/cover; */
    padding-top: 0.75rem;
    background-repeat: no-repeat;
    background-position:top;
    background-size:cover;
  }
  .topView_fst{
    height: 3.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .topView_fst>img{
    width: 14rem;
    /* height: 2.38rem; */
  }
  .topView_fst>span,.stickyTop_fst>span{
    width: 3.13rem;
    height: 1.25rem;
    line-height: calc(1.25rem - 2px);
    border-radius: 0.13rem;
    opacity: 1;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    color: #08c075;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    display: inline-block;
  }
  .topView_secd{
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  .topView_secd_l{
    margin-right: 0.5rem;
  }
  .topView_secd_l>img,.stickyTop_sed_l>img{
    width: 1.38rem;
    height: 1.38rem;
    vertical-align: middle;
  }
  .topView_secd_l>span,.stickyTop_sed_l>span{
    width: 3.38rem;
    height: 1.38rem;
    display: inline-block;
    color: #ffffff;
    font-size: 0.88rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: left;
    line-height: 1.38rem;
    margin-left: 0.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  .vnb{
    width: 16.13rem;
    height: 1.88rem;
    border-radius: 1.88rem;
    border: 1px solid #d2d2d2;
  }
  
  /* 中间部分 */
  .middleView{
    width: 100vw;
    border-radius: 0.78rem 0.78rem 0 0;
    transform: translateY(-0.38rem);
    background-color: #ffffff;
    padding: 0.1rem 0 0;
    position: relative;
    margin-top: -0.4rem;
  }
  .middleView_tabs_outer{
    width: 100%;
    height: 2.5rem;
    overflow: hidden;
    padding: 0 1rem;
    margin-top: -9px;
  }
  .middleView_tabs{
    white-space: nowrap;
    overflow-x: scroll;
  }
  .middleView_tabs>div{
    height: 2.6rem;
    line-height: 1.3rem;
    display: inline-block;
  }
  .middleView_tabs>div:not(:last-child){
    margin-right: 1.53rem;
  }
  .hotdiv{
    height: 1.2rem;
    text-align: left;
  }
  .hotdiv-ab{
    height: 1.2rem;
    position: absolute;
    top:-1px;
    left:-15px
  }
  .hotdiv>img{
    width: 2.5rem;
    height: 1rem;
    margin-right: -0.8rem;
  }
  .hotdiv-ab>img{
    width: 1.5rem;
    height: 0.7rem;
    margin-right: -0.8rem;
  }
  .tab_cont{
    height: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    position: relative;
    color: #666666;
    font-size: 1rem;
    font-weight: 500;
    font-family: "PingFang SC";
  }
  .tab_cont_checked{
    height: 1.3rem;
    color: #313131;
    font-weight: 700;
    font-family: "PingFang SC";
    line-height: 1.3rem;
  }
  .tab_cont_checked::after{
    content: '';
    width: 80%;
    height: 0.38rem;
    border-radius: 0.25rem;
    background: linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  /* 筛选 */
  .middleView_filter{
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  .middleView_filter_l>span,.middleView_filter_r{
    width: 2.5rem;
    height: 1.38rem;
    line-height: 1.38rem;
    text-align: center;
    border-radius: 0.25rem;
    background-color: #eaeaea;
    display: inline-block;
    margin-right: 0.66rem;
    color: #666666;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    letter-spacing: 1px;
  }
  .middleView_filter_r{
    width: 3.46rem;
    margin-right: 0;
  }
  .checkFilter{
    background-color: #EDFFF8 !important;
    color: #07BE78 !important;
  }
  .wrapper{
    width: 100vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
  .wrapper>video{
    width: 94%;
    height: auto;
    object-fit: cover;
  }
  .stickyTop{
    width: 100vw;
    height: 6.38rem;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 0.56rem 0;
  }
  .stickyTop_fst{
    display: flex;
    justify-content: space-between;
    padding: 0 0.69rem;
  }
  .stickyTop_fst>img{
    width: 12.63rem;
    /* height: 1.81rem; */
  }
  .stickyTop_fst>span{
    background-color: #07BE77;
    color: #ffffff;
  }
  .stickyTop_sed{
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding-left: 0.8rem;
  }
  div /deep/ .van-cell{
    background-color: transparent;
    padding: 0;
  }
  </style>
  