<template>
  <div>
    <!-- 职位列表每一项 -->
    <div class="itemClass" :class="bacc[keyd % 4]" @click="itemClick(itemObj)">
      <div v-show="itemObj.essayRegionName" class="itemClass_fst">
        <!-- {{ itemObj.essayRegionName+'1111111111' }} -->
      </div> 
      <div class="itemClass_scd">
        <div class="oneLineText1" style="flex: 1;margin-right: 0.5rem;font-size: 15px">{{itemObj.title || '暂无'}}</div>
        <span>
          {{itemObj.price}}
        </span>
      </div>
      
      <div class="itemClass_last">
        <!-- isAtl ：  1线上， 2线下 -->
        <!-- <div v-if="itemObj.distance !=-1 && itemObj.isAtl == '2'" class="itemClass_last_left">
          <div class="itemClass_last_left_text">
            <div class="itemClass_last_left_textOne">
              <span style="max-width: 10rem;display: inline-block;" class="oneLineText">{{itemObj.enterprise&&itemObj.enterprise.enterpriseName || ' '}}</span>
              <img class="itemClass_last_l_rz" src="@/assets/images/h5/home/renzheng.png" alt="企业认证" />
            </div>
            <div class="itemClass_last_left_textTwo">
              <span style="vertical-align: middle;" v-if="itemObj.distance != -1">距您<span style="color: #07BE77;    vertical-align: middle;">&nbsp;{{ itemObj.distance }}&nbsp;</span>km</span>
              <span style="max-width: 7rem;display: inline-block;vertical-align: middle;" class="oneLineText" v-if="itemObj.detailedProcess">&nbsp;{{ itemObj.detailedProcess || "" }}</span>
            </div>
          </div>
        </div>
        <div class="itemClass_last_l" v-else>
          <div v-if="type=='positionExpand'">{{itemObj.enterpriseName || ' '}}</div>
          <div v-else>{{itemObj.compenyName || ' '}}</div>
          <img class="itemClass_last_l_rz" src="@/assets/images/h5/home/renzheng.png" alt="企业认证" />
        </div> -->
        <div class="itemClass_last_l">
          <!-- <div>{{itemObj.companyName || ' '}}</div> -->
          <div class="tags">
        <span v-for="(tag,tagIndex) in itemObj.tagInfo" :key="tagIndex" :class="tagC[tagIndex % 3]">
          {{ tag.tagName }}
        </span>
      </div>
        </div>
        <div class="itemClass_last_r box">立即报名</div>
      </div>
    </div>
    <!-- 刷新 -->
    <!-- <div v-if="refresh && keyd % 10 == 0 && keyd > 0">
      <div class="refreshClass" @click="refreshd">
        <img src="@/assets/images/h5/home/jiazai.png" alt="加载">
        <span>点击刷新更多优质岗位</span>
      </div>
    </div> -->
  </div>
  </template>
  
  <script>
  const bacc=['colorOne','colorTwo','colorThree','colorFour']
  const tagC=['tagOne','tagTwo','tagThree']
  export default {
    props: {
      itemObj: {
        type: Object,
        default: {},
      },
      keyd:{
        type:Number,
        default:0,
      },
      refresh:{
        type:Boolean,
        default:false,
      },
      type:{
        type:String,
        default:"",
      }
    },
    data() {
      return {
        bacc,
        tagC,
        typeList: ['其他', '小时', '天', '周', '月', '次', '单'],
      }
    },
    methods: {
      itemClick(item){
        let token = true//localStorage.getItem('token')
        if(!token){
          this.$router.push('/login-m')
        }else if(item.positionType == 1) {
          this.$router.push({
            name: "emptyH5-m",
            params: {
              id: item.id,
              name: 'taskDetail-m'
            },
          });
          // this.$router.push({path:'/taskDetail-m',name:'taskDetail-m',params:{id:item.id}})
        }else{
          console.log("@2222222222")
          this.$router.push({
            name: "emptyH5-m",
            params: {
              id: item.id,
              name: 'courseDetail-m'
            },
          });
          // this.$router.push({path:'/courseDetail-m',name:'courseDetail-m',params:{id:item.id}})
        }
      },
      refreshd(){
        this.$emit('refreshd',true)
      },
    },
  }
  </script>
  
  <style scoped>
  *{
    box-sizing: border-box;
  }
  /* 渐变背景色 */
  .colorOne{
    background: linear-gradient(145deg, #e4effe 0%, #ffffff 40%) !important;
  }
  .colorTwo{
    background: linear-gradient(145deg, #ffe5d6 0%, #ffffff 40%) !important;
  }
  .colorThree{
    background: linear-gradient(145deg, #f9f1fb 0%, #ffffff 40%) !important;
  }
  .colorFour{
    background: linear-gradient(145deg, #e5f6ff 0%, #ffffff 40%) !important;
  }
  /* 变色tag */
  .tagOne{
    background-color: #E8F8FF;
    color: #13B6FF;
  }
  .tagTwo{
    background-color: #FFECF2;
    color: #EF5285;
  }
  .tagThree{
    background-color: #E6FFFC;
    color: #12C9B5;
  }
  /* item样式 */
  .itemClass{
    width: 21.52rem;
    /* height: 7.31rem; */
    border-radius: 0.75rem;
    box-shadow: 0.19rem 0.06rem 1.19rem 0 #97979738;
    margin: 0 auto 0.25rem;
    padding: 0.2rem 0.63rem;
  }
  .itemClass_fst{
    color: #818897;
    font-size: 0.63rem;
    font-weight: 600;
    font-family: "PingFang SC";
    line-height: 1.25rem;
  }
  .itemClass_scd{
    font-family: "PingFang SC";
    line-height: 1.25rem;
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.1rem 0;
  }
  .itemClass_scd>span:first-child{
    width: 10rem;
    height: 1.25rem;
  }
  .itemClass_scd>span:last-child{
    color: #fe0000;
    font-size: 0.88rem;
    font-weight: 600;
  }
  .tags>span{
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.13rem;
    display: inline-block;
    font-size: 0.63rem;
    font-weight: 400;
    font-family: "PingFang SC";
    margin-right: 0.5rem;
    padding: 0 0.25rem;
  }
  .itemClass_last_l,.itemClass_last_left_textOne{
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.63rem;
    font-weight: 500;
    font-family: "PingFang SC";
    line-height: 1.25rem;
  }
  .itemClass_last_l>img,.itemClass_last_left>img{
    width: 1.38rem;
    height: 1.38rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .itemClass_last_l>.itemClass_last_l_rz,.itemClass_last_left_textOne>.itemClass_last_l_rz{
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin: 0 0 0 0.2rem;
  }
  .itemClass_last{
    display: flex;
    justify-content: space-between;
    margin-top: 0.26rem;
  }
  .itemClass_last_r{
    width: 4.25rem;
    height: 1.63rem;
    line-height: 1.63rem;
    text-align: center;
    border-radius: 1.63rem;
    background-color: #07BE77;
    color: #ffffff;
    font-size: 0.63rem;
    font-weight: 600;
    font-family: "PingFang SC";
  }
  .itemClass_last_left{
    display: flex;
    align-items: center;
  }
  .itemClass_last_left_textTwo{
    color: #818897;
    font-size: 0.63rem;
    font-weight: 400;
    font-family: "PingFang SC";
    line-height: 0.63rem;
  }
  .itemClass_last_left_textOne{
    line-height: 1.25rem;
  }
  .refreshClass{
    width: 11.13rem;
    height: 2.5rem;
    border-radius: 1.31rem;
    background-color: #07be7733;
    margin: 0 auto 0.6rem;
    text-align: center;
  }
  .refreshClass>img{
    width: 1.19rem;
    height: 1.19rem;
    vertical-align: middle;
    animation: dbmNb 1s linear infinite;
  }
  
  .oneLineText1{
    overflow:hidden;
  
  text-overflow:ellipsis;
  
  display:-webkit-box;
  
  -webkit-box-orient:vertical;
  
  -webkit-line-clamp:2;
  }
  .refreshClass>span{
    color: #333333;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    line-height: 2.5rem;
    vertical-align: middle;
    margin-left: 0.32rem;
  }
  @keyframes dbmNb {
    0%{
      transform:rotate(0deg);
    }
    100%{
      transform:rotate(360deg);
    }
  }
  
  .box {
    position: relative;
     width: 4.25rem;
    height: 1.63rem;
    animation: breath 2s ease-in-out infinite;
  }
  
  .box::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    /* border: 1px solid #000; */
    opacity: 0;
    animation: breath-border 2s ease-in-out infinite;
  }
  
  @keyframes breath {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes breath-border {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  </style>
  