<template>
  <div>
    <!--兼职筛选框-->
    <div class="box">
        <div class="boxMess">
            <div class="inpuBox">
                <div class="inputLeft">
                    <div class="inputLeftBos" >
                        <div @click="setType">
                        {{types}} <i class="el-icon-arrow-down"></i>
                        </div>
                        <input placeholder="输入关键词,例如产品经理、设计" @keyup="GetpositionList" v-model="positionTitle" />
                    </div>

                    <div class="sos" @click="goPart">
                        <img src="@/assets/images/pc/sos.png" />
                    </div>

                    <div class="inputLeftType" v-if="typeShow" @mouseout="typeShow = false">
                        <div v-if="types!= '兼职'"  @click="setTypeT('兼职')">兼职</div>
                        <div v-if="types!= '课程'" @click="setTypeT('课程')">课程</div>
                    </div>
                </div>

                <!--二维码那些-->
                <div class="inputRight">
                    <div class="inputRightText">
                        <div @click="type = 1"  :class="type==1?'xuanzhog':''">兼职网APP</div>
                        <div @click="type = 2" :class="type==2?'xuanzhog':''">兼职网小程序</div>
                    </div>
                    <div class="inputRightImg">
                        <img v-show="type == 2" src="@/assets/images/pc/xiaochengxu.png"/>
                        <img v-show="type == 1" src="@/assets/images/pc/App.png"/>
                    </div>
                </div>


                <!---下拉框-->
                <div class="positionBox" v-if="positionList">
                    <div v-for="item in positionList" :key="item.id" @click="goParts(item)">{{item.positionTitle}}</div>
                </div>
            </div>
        </div>


        <!---热门城市-->
        <div class="hotCity">
            <div class="cityTitle">热门城市</div>
            <div class="cityImgBox">
                <div class="cityLeft">
                    <div class="cityImg" @click="getPosition('北京')">
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850102433541.png" />
                        <span>北京</span>
                    </div>
                    <div class="cityImg" @click="getPosition('上海')">
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850102592822.png" />
                        <span>上海</span>
                    </div>
                    <div class="cityImg" @click="getPosition('广州')">
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850103219387.png" />
                        <span>广州</span>
                    </div>
                    <div class="cityImg" @click="getPosition('深圳')">
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850103100166.png" />
                        <span>深圳</span>
                    </div>
                </div>

                <div class="cityRight">
                    <div class="cityImg" @click="getPosition('天津')">
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850102726493.png" />
                        <span>天津</span>
                    </div>
                    <div class="cityRightTwo">
                        <div class="cityImg" @click="getPosition('杭州')">
                            <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850102864284.png" />
                            <span>杭州</span>
                        </div>
                        <div class="cityImg" @click="getPosition('成都')">
                            <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16850102977065.png" />
                            <span>成都</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="background-color: #fff;" :class="oldScrollTop?'abcT':''">
        <!---搜索-->
        <div class="sosBox">
            <div class="sosTitle">搜索:</div>
            <input placeholder="输入城市中文名称，寻找当地心仪的工作" @keyup="getAreaList" v-model="areaName" />

            <div class="cityList" v-if="areaNameList">
                <div v-for="(item) in areaNameList" :key="item.id" @click="getPosition(item.areaName)">{{item.areaName}}</div>
            </div>
        </div>

        <!---abcd-->
        <div class="abc">
            <div class="abcBox">
                <div v-for="(item,index) in cityList" :key="index" @click="goAddresT(item.name)">
                    {{ item.name }}
                </div>
            </div>
        </div>
        </div>

    </div>

    <!--地址-->
    <div class="" :class="oldScrollTop?'addresT':'addres'">
        <div class="addresBox" v-for="(item,index) in cityList" :key="index"  :id="item.name">
            <div class="addresTitle" >{{ item.name }}</div>

            <div class="addresMess">
                <div :title="item2.areaName" v-for="(item2,index2) in item.list" :key="index2" @click="setPoint(item2)">{{item2.areaName | setAreaName}}</div>
            </div>
        </div>
    </div>


    <!--返回顶部按钮-->
    <div :class="topFalse?'backBoxT':'backBox'">
        <div class="back" @click="goTop">
            <i class="el-icon-arrow-up"></i>
        </div>
        <div class="backTitle">TOP</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import index from '../h5/index.vue';
export default {
  components: { index },
    data(){
        return{
            cityList:[],
            oldScrollTop:false,
            areaName:"",
            areaNameList:[],
            timeout:"",
            positionTitle:"",
            timeoutT:"",
            positionList:[],
            topFalse:false,
            type:1,
            typeShow:false,
            types:"兼职",
            positionType:"1"
        }
    },
    filters:{
        setAreaName(e){
            if(e.length>8){
                return e.slice(0,8) + "..."
            }else{
                return e
            }
        }
    },
    beforeMount(){
        this.getCity();
    },
    mounted(){
        window.addEventListener("scroll", this.scrolling)
    },
    methods:{
        getCity(){
            this.$axios({
                url:"/official/enterprise/getAreaCityList"
            }).then((res)=>{
                if(res.code == 200){
                    this.cityList = res.data
                }
            })
        },
        scrolling() {
            // 滚动条距文档顶部的距离
            let scrollTop = document.documentElement.scrollTop
            console.log(scrollTop)
            // 滚动条滚动的距离
            if(scrollTop >= 600 && scrollTop <= 5800){
                this.oldScrollTop = true
            }else{
                this.oldScrollTop = false
            }

            if(scrollTop >= 5165){
                this.topFalse = true
            }else{
                this.topFalse = false
            }
            // let scrollStep = scrollTop - this.oldScrollTop;
            // // 更新——滚动前，滚动条距文档顶部的距离
            // this.oldScrollTop = scrollTop;
            // if (scrollStep < 0) {
            // } else {
            // }
        },
        getAreaList(){
            if(this.timeout){
                clearTimeout(this.timeout) // 规定时间内若定时器存在则清除
                this.areaNameList = [];
            }
            this.timeout = setTimeout(() => {
                if(this.areaName != ''){
                    this.areaList();
                }
            }, 800);
        },
        areaList(){
            this.$axios({
                url:"/official/enterprise/AreaList",
                params:{
                    areaName:this.areaName
                }
            }).then(res=>{
                if(res.code == 200 && res.rows != []){
                    this.areaNameList = res.rows;
                }
            })
        },
        //获取职位标题
        GetpositionList(){
            if(this.timeoutT){
                clearTimeout(this.timeoutT) // 规定时间内若定时器存在则清除
                this.positionList = []
            }
            this.timeoutT = setTimeout(() => {
                if(this.positionTitle != ''){
                    this.positionLists();
                }
            }, 800);
        },
        positionLists(){
            let isMunicipality;


            let e = JSON.parse(localStorage.getItem("point")).city
            if(e == '北京' || e == "天津" || e == "上海" || e=="重庆"){
                isMunicipality = '0'
            }else{
                isMunicipality = '1'
            }
            this.$axios({
                url:"/official/enterprise/positionList",
                params:{
                    adCode:localStorage.getItem("adcode"),
                    isMunicipality:isMunicipality,
                    positionType:this.positionType,
                    positionTitle:this.positionTitle
                }
            }).then(res=>{
                console.log(res)
                if(res.code == 200 && res.rows!= []){
                    this.positionList = res.rows
                }
            })
        },

        //返回顶部
        goTop(){
            document.documentElement.scrollTop = 0
        },
        ///滚动到指定模块
        goAddresT(e,index){
            if(e == 'A'){
                window.scrollTo({
                    top:593,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "B"){
                window.scrollTo({
                    top:802,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "C"){
                window.scrollTo({
                    top:1090,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "D"){
                window.scrollTo({
                    top:1335,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "E"){
                window.scrollTo({
                    top:1565,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "F"){
                window.scrollTo({
                    top:1695,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "G"){
                window.scrollTo({
                    top:1882,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "H"){
                window.scrollTo({
                    top:2062,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "J"){
                window.scrollTo({
                    top:2473,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "K"){
                window.scrollTo({
                    top:2776,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "L"){
                window.scrollTo({
                    top:2948,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "M"){
                window.scrollTo({
                    top:3316,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "N"){
                window.scrollTo({
                    top:3431,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "P"){
                window.scrollTo({
                    top:3611,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "Q"){
                window.scrollTo({
                    top:3798,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "R"){
                window.scrollTo({
                    top:4043,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "S"){
                window.scrollTo({
                    top:4166,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "T"){
                window.scrollTo({
                    top:4569,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "W"){
                window.scrollTo({
                    top:4821,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "X"){
                window.scrollTo({
                    top:5044,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "Y"){
                window.scrollTo({
                    top:5356,
                    left:0,
                    behavior: "smooth"
                });
            }else if(e == "Z"){
                window.scrollTo({
                    top:5705,
                    left:0,
                    behavior: "smooth"
                });
            }
            // document.getElementById(e).scrollIntoView();
        },
        //设值经纬度
        setPoint(e){
            let data = {
                city : e.areaName,
                lat : e.latitude,
                lng : e.longitude
            }
            localStorage.setItem("point",JSON.stringify(data))
            Vue.prototype.cityAddress= e.areaName;
            this.getPosition(e.areaName)
        },
        getPosition(e){
            Vue.prototype.cityAddress= e;
            let _this = this;
            if(e == '北京' || e == "天津" || e == "上海" || e=="重庆"){
                localStorage.setItem("dJ","dj")
            }else{
                 console.log("删除=>",e)
                localStorage.removeItem("dJ")
            }
            let data = JSON.parse(localStorage.getItem("point"))
            data.city = e

            localStorage.setItem("point",JSON.stringify(data))

            AMap.plugin('AMap.Geocoder', function() {
                var geocoder = new AMap.Geocoder({
                    city: e // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                })
                geocoder.getLocation( e, function (status, result) {
                    if (status === "complete" && result.info === "OK") {

                        let adcode = result.geocodes[0].adcode
                        let city = result.geocodes[0].formattedAddress
                        // result为对应的地理位置详细信息
                        // this.place为返回的具体地理位置信息，里面无法使用return回来！
                        // this.$router.push({
                        //     name: 'PartTJ',
                        //     params: {
                        //         // id: e.jumpUrl,
                        //         cityCode: adcode,
                        //     },
                        // });
                        localStorage.setItem("adcode",adcode)
                        _this.getHotCityList(adcode)
                    }
                })
                // 使用geocoder做地理/逆地理编码
            })
        },
        getHotCityList(e){
            this.$router.push({
                name: 'PartTJ',
                params: {
                    id: e.jumpUrl,
                    cityCode: e,
                },
            });
        },
        //跳转兼职页面
        goPart(){
            var point = localStorage.getItem("point")
            let city = JSON.parse(point).city

            this.getPosition(city)
            this.$router.push({
                name: 'PartTJ',
                params: {
                    positionTitle: this.positionTitle,
                },
            });

        },
        goParts(e){
            this.$router.push({
                name: "positionDetail",
                params: {
                    id: e.id,
                    name: this.$route.meta.metaInfo.title,
                    firstOrder:"切换城市",

                },
            });
        },
        //setType
        setType(){
            this.typeShow = !this.typeShow
        },
        setTypeT(e){
            if(e == '兼职'){
                this.positionType = 1
            }else{
                this.positionType = 2
            }
            this.types = e
            this.typeShow = false
        }
    }
}
</script>

<style src="@/assets/css/pc/hotCity.css" scoped></style>
