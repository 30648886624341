import { render, staticRenderFns } from "./RegistrationDetails.vue?vue&type=template&id=24048600&scoped=true"
import script from "./RegistrationDetails.vue?vue&type=script&lang=js"
export * from "./RegistrationDetails.vue?vue&type=script&lang=js"
import style0 from "./RegistrationDetails.vue?vue&type=style&index=0&id=24048600&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24048600",
  null
  
)

export default component.exports