<template>

</template>

<script>
export default {
  name: "empty",
  data(){
    return{

    }
  },
  created() {
    console.log("empty created");
    this.$router.replace({
      name: this.$route.params.name,
      params: {
        id: this.$route.params.id,
      },
    })
  },
}

</script>

<style scoped>

</style>
