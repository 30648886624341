<template>
	<div class="Business">
		<van-sticky>
			<div class="top">
				<van-nav-bar title="商务合作" left-text="" left-arrow @click-left="onClickLeft" @nav-bar-height="2.75" />

			</div>
		</van-sticky>
		<div class="content">
			<div class="content_topTmgLeft">
				<div class="content_top_form">
					<div class="form_title">
						企业合作
					</div>
					<div class="form_subhead">
						Business Coorporation
					</div>
					<div>
						<van-cell-group inset :border="false">
							<van-field placeholder="企业名称/员工名称" class="form_input" v-model="formData.name"
								maxlength="20" />
						</van-cell-group>
					</div>
					<div>
						<van-cell-group inset :border="false">
							<van-field placeholder="手机号码" type="number" class="form_input" v-model="formData.phone"
								maxlength="11" />
						</van-cell-group>
					</div>
					<div>
						<van-cell-group inset :border="false">
							<van-field placeholder="验证码" class="form_input" v-model="formData.code">
								<template #button>
									<div style="font-size: 9px;color: #c7a374;" v-if="CodeTrue" @click="Code()">获取验证码
									</div>
									<div style="font-size: 9px;color: #c7a374;" v-else>{{count}}s后重新发送</div>
								</template>
							</van-field>
						</van-cell-group>
					</div>
					<div class="form_button" @click="sumbit">
						提交申请
					</div>
				</div>
			</div>
			<div class="content_topTmgRight" style="position: relative;">
				<div class="" style="position: absolute; top: 0.75rem;text-align: center; width: 100%;">
					<div class="content_topTmgLeft_img2" style="display: flex;justify-content: center;">
						<van-image :src="topImgLeft2" width="159px"/>
					</div>
					<div class="content_topTmgLeft_text" style="display: flex;justify-content: center;">
						Friendly Service Never Ends
					</div>

					<div class="content_topTmgLeft_img1" style="margin-left: -25px;margin-top: -20px;">
						<van-image height="110px" width="140px" :src="topImgLeft1" />
					</div>
				</div>
				<van-image width="100%" :src="topImgRight" class="right_bottom_img" />
			</div>
			<div class="content-icon">
				<van-icon name="arrow-down" style="font-size: 13px;margin-left: 0px;margin-top: 2px;"  />
			</div>


		</div>

		<div class="introduce1">
			<div class="introduce1_title">
				<div class="introduce1_title_h1">
					兼职网商家，您的明智选择
				</div>
				<div class="introduce1_title_h2">
					优质用户资源，高质量引流渠道，为商家需求提供强有力的保障
				</div>
			</div>

			<div class="introduce1_bg">
				<div class="introduce1_centent1">
					<div class="introduce1_centent_border">
					</div>
					<div class="introduce1_centent1_img">
						<div class="introduce1_centent1_icon">
							<van-image :src="introduce1Img1" width="20px" />
						</div>
						<div class="introduce1_centent1_tilte">
							高度智能匹配业务资源
						</div>
						<div class="introduce1_centent1_subhead">
							<div>智算3.0系统更快捷处理简历</div>
							<div>用工过程随时管控，人工智能高效处理</div>
						</div>
					</div>
				</div>
				<div class="introduce1_centent2">
					<div class="" style="display: flex;width: 100%;justify-content: space-between;">
						<div class="introduce1_centent2_left box">
							<div class="introduce1_centent2_icon">
								<van-image :src="introduce1Img2" width="20px" />
							</div>
							<div class="introduce1_centent1_tilte">
								优质资源等您来招
							</div>
							<div class="introduce1_centent1_subhead">
								<!-- <div>极速发布职位</div>
								<div>千万真实大学生免费应聘岗位</div> -->
							</div>
						</div>
						<div class="introduce1_centent2_right box">
							<div class="introduce1_centent2_icon">
								<van-image :src="introduce1Img3" />
							</div>
							<div class="introduce1_centent1_tilte">
								大数据算法推荐
							</div>
							<div class="introduce1_centent1_subhead" >
								<!-- <div>人才匹配，采用智能推荐算法</div>
								<div>多维度筛选，为企业实现精准人群招聘</div> -->
							</div>
						</div>
					</div>
					<div class="" style="display: flex;;width: 100%;justify-content: space-between;">
						<div class="introduce1_centent1_subhead" style="width: 122px;">
							<div>极速发布职位</div>
							<div>真实大学生免费应聘岗位</div>
						</div>
						<div class="introduce1_centent1_subhead" style="width: 7.8125rem;">
							<div>人才匹配，采用智能推荐算法</div>
							<div>多维度筛选，为企业实现精准人群招聘</div>
						</div>
					</div>
				</div>


				<div class="introduce1_centent3">
					<div class="introduce1_centent3_left box">
						<div class="introduce1_centent2_icon">
							<van-image :src="introduce1Img4" />
						</div>
						<div class="introduce1_centent1_tilte">
							行业标杆企业，大平台有保障
						</div>
						<div class="introduce1_centent1_subhead">
							<div>人力资源行业标杆企业</div>
							<div>海量用户共同选择</div>
						</div>
					</div>
					<div class="introduce1_centent3_right box">
						<div class="introduce1_centent2_icon">
							<van-image :src="introduce1Img5" />
						</div>
						<div class="introduce1_centent1_tilte">
							多渠道分发，优质流量任您选择
						</div>
						<div class="introduce1_centent1_subhead">
							<div>优选简历、聚量媒体精准引流，</div>
							<div>商家只需等待即可获客，三大产品矩阵满足商家各种流量需求</div>
						</div>
					</div>
					<div class="content-icon1">

					</div>
				</div>
			</div>


		</div>

		<div class="introduce2">
			<div class="introduce1_title">
				<div class="introduce1_title_h1">
					兼职网实力见证
				</div>
				<div class="introduce1_title_h2">
					卓越的兼职网商家管理平台，带给您的数字背后凝聚着我们专业的实力
				</div>
			</div>
			<div class="introduce1_centent_border" style="margin-top: 5px;">
			</div>
			<div class="introduce2_itembox" style="margin-top: 1.5rem;">
				<div class="introduce2_items" v-for="(item,index) in introduce2Item" :key="index">
					<div class="items_img">
						<van-image width="100%" :src="item.src" />
					</div>
					<div
						style="width: 100%; padding: 7px 0 9px; background-color: #ffffff; position: absolute; left: 0; right: 0; bottom: 0;">
						<div class="items_title" style="margin-bottom: 8px;">
							{{item.title}}
						</div>
						<div class="items_subhead">
							{{item.subhead}}
						</div>

					</div>
<!--					<div class="items_tips">-->
<!--						{{item.tips}}<span style="font-size: 10px;">{{item.tipsUnit}}</span>-->
<!--					</div>-->
				</div>
			</div>
			<div class="content-icon2">
				<!-- <van-icon name="arrow-down" style="font-size: 10px;" /> -->
			</div>
		</div>

		<div class="introduce3">
			<div class="introduce1_title">
				<div class="introduce1_title_h1">
					实力品牌资质展示
				</div>
				<div class="introduce1_title_h2">
					企业发展多年以来，荣获知识产权60余项，涵盖注册商标9项、发明及外观专利3项、软件著作权50余项等诸多资质
				</div>
			</div>
			<div class="introduce1_centent_border" style="margin-top: 5px;">
			</div>
			<div class="introduce3_carousel">
				<el-carousel :interval="3000" type="card"
					style="height: 7.5rem; padding-top: 1rem;  overflow-y: hidden;text-align: center; background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686722357905Slice%20878%403x.png');background-size: 100%;background-repeat: no-repeat;"
					indicator-position="none" ref="swiper" >
					<el-carousel-item style="height:6.5rem;" v-for="(item,index) in swiper2List" :key="item">
						<img @click="onChange(index)" style=" height: 6rem" :src="item" alt="" />
					</el-carousel-item>
				</el-carousel>
				<div class="swiperL" @click="$refs.swiper.prev()">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="swiperR" @click="$refs.swiper.next()">
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="content-icon3">

				</div>
			</div>
		</div>

		<!-- <div class="introduce4">
			<div class="introduce1_title">
				<div class="introduce1_title_h1" style="color: #ffffff;">
					加入兼职网商家平台，开启资源引流新时代
				</div>
				<div class="introduce1_title_h2" style="color: rgba(255, 255, 255, 0.8);">
					目前已有数万家企业与我们合作，我们热切期盼您的到来！加入兼职网商家平台，下一个受益的就是你！
				</div>
			</div>
			<div class="introduce4_btn">
				<div @click="">
					企业合作</div>
			</div>
		</div> -->
		<div class="introduce5">
			<div class="introduce1_title">
				<div class="introduce1_title_h1">
					联系我们
				</div>
				<div class="introduce1_title_h2">
					您可以通过打电话、发邮件或者现场联系我们，咨询入驻兼职网，我们将为您提供24小时全天候咨询服务
				</div>
			</div>
			<div class="introduce1_centent_border">
			</div>
			<div class="" style="display:flex;justify-content: space-evenly;">
				<div class="introduce5_Items" v-for="(item,index) in introduce5Item" :key="index">
					<div class="introduce1_centent2_icon">
						<van-image :src="item.icon" />
					</div>
					<div class="introduce5_ItemTitle">
						{{item.title}}
					</div>
					<div class="introduce5_ItemText" @click="contactUs(item,index)" :data-clipboard-text="item.text">
						{{item.text}}
					</div>
				</div>
			</div>
		</div>

		<div class="introduce6">
			<van-image :src="mapImg" style="height: 100%;" />
		</div>

		<div class="introduce7">
			<div class="introduce7_first">
				<div class="firstItem">热门兼职</div>
				<div class="firstItem_after"></div>
				<div class="firstItem">名企直聘</div>
				<div class="firstItem_after"></div>
				<div class="firstItem">联系我们</div>
				<div class="firstItem_after"></div>
				<div class="" style="font-size: 6px;">Copyright © 2017-2023 兼职网(山东)信息技术有限公司</div>
			</div>
			<div class="introduce7_second">
				<div style="display: flex;justify-content: center;">
					<div style="zoom: 0.9">鲁ICP备2022033828号-1</div>
					<div style="margin-left: 1.5rem;display: flex;align-items: center;">
						<img style="width: 0.69rem;height: 0.69rem;" src="@/assets/image/mobile/beian.png" alt="鲁公网安">
						<div style="zoom: 0.9">鲁公网安备37010502001905号</div>
					</div>
				</div>
			</div>
		</div>

		<div class="introduce8">
			<div class="introduce8_left introduce8_btn" @click="hotLine(introduce5Item[1].text)">
				<van-image width="24px" :src="phoneLogo" style="margin-right: 10px;" /> 客服热线
			</div>
			<div class="introduce8_right introduce8_btn" @click="onLine()">
				<van-image width="24px" :src="kefuLogo" style="margin-right: 10px;" />在线客服
			</div>
		</div>


		<div class="popup_bg" v-if="sumbitSuccess" @touchmove.prevent>
			<div class="popupbox_bg" style="width: 100%;">
				<van-image width="100%" :src="popupBg" />
			</div>
			<div class="popup">

				<div class="popup_logo">
					<van-image width="100px" :src="popupLogo" />
				</div>
				<div class="popup_success">
					提交成功
				</div>
				<div class="popup_tips">
					<div>您已提交申请成功</div>
					<div>我们将在24小时内与您取得联系，请保持通话畅通</div>
				</div>
				<div class="popup_btn" @click="sumbitSuccess = false">
					确定
				</div>
			</div>
		</div>
		<van-image-preview v-model="show1" :images="swiper2List" @change="onChange" :startPosition="startPosition1">
			<template v-slot:index1>第{{ index1 }}页</template>
		</van-image-preview>
		</van-image-preview>
	</div>
</template>

<script>
	import Clipboard from 'clipboard'
	export default {
		data() {
			return {
				//小图标
				topImgLeft1: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686638091591Slice%20848%403x.png',
				topImgLeft2: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686621103748Slice%20849%403x.png',
				topImgRight: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686562622968lQLPJyB8RKJqv4bNCqzNEACw9JlntVjg8EwEfT52W4AGAA_4096_2732.png",
				introduce1Img1: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686566517741Slice%20851%403x.png",
				introduce1Img2: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686566646348Slice%20852%403x.png',
				introduce1Img3: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686566761644Slice%20853%403x.png',
				introduce1Img4: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686566812267Slice%20855%403x.png',
				introduce1Img5: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686566867918Slice%20854%403x.png',


				introduce2Item: [{
						src: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686302632534iwEcAqNwbmcDAQTRBUkF0Qc5BrDnbC0fGIG0NwR5e9dmgKkAB9MAAAAA4_YXTggACaJpbQoAC9IAEzEl.png_720x720q90.jpg",
						title: "365天全年保障",
						subhead: '7*24*365，我们提供全天全年无休的商家贴心服务,时刻守候您的用户需求',
						tips: '',
						tipsUnit: ''
					},
					{
						src: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686302652061iwEcAqNwbmcDAQTRBUkF0Qc5BrAThrCCm1UIqwR5e9dmQKkBB9MAAAAA4_YXTggACaJpbQoAC9IAFDTT.png_720x720q90.jpg",
						title: "客户满意度95%以上",
						subhead: 'VIP服务团队成员，五年以上服务经验，标准化和定制化服务流程完美结合',
						tips: '',
						tipsUnit: ''
					},
					{
						src: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686302672635iwEcAqNwbmcDAQTRBUkF0Qc5BrCGASYjqQxU7wR5e9dmQKkAB9MAAAAA4_YXTggACaJpbQoAC9IAEVav.png_720x720q90.jpg",
						title: "客户入驻流量屡创新高",
						subhead: '依托平台强大的资源与科技，已吸引众多企业争相入驻，让您出行无忧',
						tips: '',
						tipsUnit:""
					},
					{
						src: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686302693325iwEcAqNwbmcDAQTRBUkF0Qc5BrAtgXTuBxtuDAR5e9dmgKkBB9MAAAAA4_YXTggACaJpbQoAC9IAN2QN.png_720x720q90.jpg",
						title: "优质资源量不断突破",
						subhead: '通过高效便捷的平台资源，不断提升优化管理流程，有效吸引大量优质资源',
						tips: '',
						tipsUnit: ''
					}
				],

				swiper2List: [
					"http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640110287aptitude1.jpg",
					"http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640194767aptitude5.png",
					"http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640220936aptitude2.png",
					'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640259483aptitude6.png',
					'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640280925aptitude3.png',
					'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640355615aptitude7.jpg',
					// 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640310825aptitude4.jpeg',
					'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16867935606741686793541393.png',
					// 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640387300aptitude8.jpeg',
					// "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640220936aptitude2.png",
					'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686640575181aptitude9.jpeg'
				],


				introduce5Item: [{
						icon: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686620030560Slice%20858%403x.png',
						title: '公司邮箱',
						text: '629666098@qq.com'
					},
					{
						icon: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686620081763Slice%20859%403x%20(1).png',
						title: '客服热线',
						text: '19906413192'
					},
					{
						icon: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686641048279Slice%20860%403x%20(2)%EF%BC%881%EF%BC%89.png',
						title: '在线客服',
						text: '7x24小时在线'
					}
				],
				mapImg: "http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16867152372160335.jpg",


				//提交数据
				formData: {
					name: '',
					phone: '',
					code: '',
					isUserId: "false"
				},
				CodeTrue: true, //获取验证码按钮
				count: 60,

				sumbitSuccess: false,
				popupLogo: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686377036545Slice%20885.png',
				popupBg: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686385402125Slice%20884.png',


				show1: false, //图片预览
				index1: 0,
				startPosition1: 0, //图片索引
				swiper1List: [],
				phoneLogo: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686566935699Slice%20859%403x.png',
				kefuLogo: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686567105684Slice%20860%403x.png',
			}

		},

		mounted() {

		},
		methods: {

			onClickLeft() {
				this.$router.back()
			},

			//获取验证码
			Code() {
				// this.$axios.defaults.baseURL = "http://192.168.0.108:8888";
				let _this = this;
				let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
				if (reg.test(this.formData.phone)) {
					this.set = setInterval(function() {
						_this.CodeTrue = false
						_this.count--;
						if (_this.count == '0') {
							_this.CodeTrue = true;
							_this.count = 60;
							clearInterval(this.set);
						}
					}, 1100)
					this.$axios({
						url: '/app/user/getSms' + '/' + _this.formData.phone,
						method: "get",
						needPrivate: true,
					}).then(res => {
						if (res.code == 200) {
							console.log(_this.formData.phone);
							console.log("[验证码发送]", res);
						} else if (res.code == 400) {
							console.log("[验证码发送]", res);
							_this.$toast.fail(res.msg);
						}
					})
				} else {
					this.$toast.fail('请检查您的手机号是否正确');
				}
			},
			//
			sumbit() {
				// this.$axios.defaults.baseURL = "http://192.168.0.108:8888";
				if (this.formData.entName == '') {
					return this.$toast.fail('请填写企业/或员工名称!');
				}
				if (this.formData.phone == '') {
					return this.$toast.fail('请填写手机号！')
				}
				if (this.formData.code == '') {
					return this.$toast.fail('请填写验证码!');
				}
				let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
				if (reg.test(this.formData.phone)) {
					this.formData.token = localStorage.getItem('token')
					this.$axios({
						method: "post",
						url: "/enterprise/count/add",
						data: this.formData,
					}).then((res) => {
						if (res.code == '200') {
							this.formData = {
								name: '',
								phone: '',
								code: '',
								isUserId: "false"
							}
							this.CodeTrue = true
							clearInterval(this.set);
							this.sumbitSuccess = true
						} else {
							this.$toast.fail(res.msg)
						}
					}).catch((error, res) => {
						this.$toast.fail(error)
					})
				} else {
					this.$toast.fail('请检查您的手机号是否正确');
				}
			},


			//图片预览事件
			onChange(index) {
				this.show1 = true
				this.index1 = index;
				this.startPosition1 = index
			},
			hotLine(text) {
				window.location.href = 'tel:' + text
			},
			onLine() {
				window.location.href = 'https://work.weixin.qq.com/kfid/kfceadcea55e01d2a7c'
			},
			contactUs(item, index) {
				if (item.title == '公司邮箱') {
					var clipboard = new Clipboard('.introduce5_ItemText')
					clipboard.on('success', e => {
						console.log('复制成功', e)
						this.$toast.fail('复制成功！')
						// 释放内存
						clipboard.destroy()
					})
					clipboard.on('error', e => {
						// 不支持复制
						this.$toast.fail('该浏览器不支持自动复制')
						console.log('该浏览器不支持自动复制')
						// 释放内存
						clipboard.destroy()
					})
				}
				if (item.title == '客服热线') {
					this.hotLine(item.text)
				}
				if (item.title == '在线客服') {
					this.onLine()
				}
			}

		}
	}
</script>

<style scoped>
	.Business {
		background-color: #FFFFFF;
		padding-bottom: 80px;
	}

	.content {
		position: relative;
		background: linear-gradient(97.5deg, #c5aab7ff 0%, #888badff 49%, #2a69b0ff 100%);
		width: 100%;
		height: 251px;
		/* padding: 0 7px 15px 7px; */
		box-sizing: border-box;
		display: flex;
	}

	.content_topTmgLeft {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		margin-left: 15px;
	}

	.content_top_form {
		background-color: #FFFFFF;
		width: 10.125rem;
		height: 13rem;
		border-radius: 7px;
		padding-top: 3px;
	}

	.content_top_form .form_title {
		color: #4f4f4f;
		font-size: 15px;
		font-weight: 700;
		font-family: "PingFang SC";
	}

	.content_top_form .form_subhead {
		color: #898686ff;
		font-size: 12px;
		font-weight: 500;
		font-family: "PingFang SC";
		margin-bottom: 0.375rem;
	}

	.content_top_form .form_input {
		height: 30px;
		color: #878787;
		font-size: 10px;
		font-weight: 500;
		font-family: "PingFang SC";
		border-radius: 15px;
		background: #efefef;
		text-align: left;
		box-sizing: border-box;
		margin-bottom: 0.625rem;
		display: flex;
		align-items: center;
	}

	.content_top_form .form_button {
		width: 6.4375rem;
		height: 1.875rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 14.75px;
		border: 0.5px solid #ffffff;
		background: #c7a374;
		color: #ffffffff;
		font-size: 12px;
		font-weight: 500;
		font-family: "PingFang SC";
	}

	.content_topTmgLeft_img1 {
		display: flex;
		justify-content: flex-start;
		/* margin-right: -31px; */
		/* transform: translateX(-50%); */
	}

	.content_topTmgLeft_text {
		color: #ffffffff;
		font-size: 10px;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: center;
		line-height: 15px;
	}

	.content_topTmgRight {
		display: flex;
		align-items: flex-end;
	}

	.content_topTmgRight .right_bottom_img {}

	.content-icon {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 15px);
		/* padding: 0px 10px 0px 10px; */
		background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686711095934lQLPJw_k7Vsdv6bNC1XNC7mwIJyaVE7Sc-8Ef4zMkIDfAA_3001_2902.png');
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: 0%;
		color: #c7a374ff;
		height: 35px;
		width: 35px;
		text-align: center;
		line-height: 32px;
	}

	.content-icon1 {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 30px);
		/* padding: 0px 10px 0px 10px; */
		background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16867274175250342.png');
		width: 40px;
		height: 40px;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: 0%;
		color: #c7a374ff;
		z-index: 2;
	}

	.introduce1 {
		margin-top: 11px;
		position: relative;
	}

	.introduce1_title_h1 {
		color: #424242;
		font-size: 18px;
		font-weight: 700;
		font-family: "PingFang SC";
		text-align: center;
	}

	.introduce1_title_h2 {
		color: #818181;
		font-size: 12px;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: center;
		margin-top: 4px;
		margin-bottom: 5.5px;
	}

	.introduce1_centent1 {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;

	}

	.introduce1_bg {
		background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686303415146iwEcAqNwbmcDAQTRAu8F0QHYBrAObclGrPK07gR5e9dlwKkAB9MAAAAA4_YXTggACaJpbQoAC9FWig.png_720x720q90.jpg');
		background-repeat: no-repeat;
		background-size: 100%;
		padding-bottom: 15px;
		box-sizing: border-box;
	}

	.introduce1_centent_border {
		width: 25px;
		height: 1px;
		background-color: #c7a374ff;
		margin: 0 auto;
		margin-bottom: 10px;
	}

	.introduce1_centent1_icon {
		display: flex;
		justify-content: center;
		margin-bottom: 5px;
	}

	.introduce1_centent1_tilte {
		color: #595959;
		font-size: 0.625rem;
		font-weight: 700;
		font-family: "PingFang SC";
		text-align: center;
	}

	.introduce1_centent1_subhead {
		color: #848484;
		font-size: 0.5rem;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: center;
		line-height: 15px;
	}

	.introduce1_centent2 {
		/* display: flex;
		justify-content: space-between; */
		padding: 0 18px;
	}

	.introduce1_centent2_icon {
		/* border: 1px dashed #afb0b2; */
		padding: 1px;
		width: 25px;
		height: 25px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		margin: 0 auto;
	}

	.box {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.introduce1_centent2_left {
		width: 122px;

	}

	.introduce1_centent2_right {
		width: 122px
	}

	.introduce1_centent3 {
		margin-top: 8px;
		display: flex;
		justify-content: space-around;
		position: relative;
		align-items: flex-start;

	}

	.introduce1_centent3_left {
		width: 165px
	}

	.introduce1_centent3_right {
		width: 51%
	}

	.introduce1 .content-icon {
		background-color: #F2F2F2
	}



	.introduce2 {
		padding-top: 18px;
		position: relative;
		background-color: #f2f2f2;
	}

	/* .introduce2 .introduce1_title {
		padding: 0 32px;
	} */
	.introduce2 .introduce1_title_h2 {
		/* width: 282px; */
		margin: 0 auto;
	}

	.introduce2 .introduce1_centent_border {
		margin: 0 auto;
	}

	.introduce2_itembox {
		/* padding: 0 15px; */
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		margin-top: 4px;
		padding-bottom: 21px;

	}

	.introduce2_items {
		width: 169px;
		/* border: 1px seagreen solid; */
		margin-bottom: 9px;
		position: relative;
		box-sizing: border-box;
		background-color: #ffffffff;
		padding-bottom: 8px;
	}

	.introduce2_items .items_img {
		width: 169px;
	}

	.introduce2_items .items_title {
		color: #333333ff;
		font-size: 12px;
		font-weight: 700;
		font-family: "PingFang SC";
		text-align: center;
		/* margin-top: 4px; */
	}

	.introduce2_items .items_subhead {
		color: #858484ff;
		font-size: 0.55rem;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: center;
		line-height: 12.5px;
		text-align: center;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		/* padding: 0 6px; */
	}

	.introduce2_items .items_tips {
		position: absolute;
		top: 8px;
		left: 6.5px;
		color: #ffffffff;
		font-weight: 700;
		font-family: "PingFang SC";
		font-size: 16px;
		padding: 4px 4.5px;
		background-color: rgba(0, 0, 0, 0.5);
		min-width: 48px;
		text-align: center;
	}

	.introduce2_items:nth-child(even) .items_tips {
		background-color: rgba(199, 163, 116, 0.55)
	}

	.content-icon2 {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 12px);
		/* padding: 0px 10px 0px 10px; */
		background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16867141548860333.jpg');
		width: 35px;
		height: 35px;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: 0%;
		color: #c7a374ff;
	}

	.introduce3 {
		padding-top: 16px;
		padding-bottom: 37px;
		position: relative;
	}

	.introduce3 .introduce1_title_h2 {
		/* width: 345px; */
		margin: 0 auto;
	}

	.introduce3_carousel {
		height: 6.5rem;
		position: relative;
	}

	.swiperL,
	.swiperR {
		position: absolute;
		top: 50%;
		/* transform: translateY(-50%); */
		color: #7d7d7d;
		font-size: 1.6rem;
		font-weight: bold;
		z-index: 99;
	}

	.swiperL {
		left: 0;
	}

	.swiperR {
		right: 0;
	}

	.content-icon3 {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50px);
		/* padding: 0px 10px 0px 10px; */
		background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16867143440520334.jpg');
		width: 35px;
		height: 35px;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: 0%;
		color: #c7a374ff;
	}

	.introduce4 {
		background-color: #3b4f91ff;
		padding-top: 1.2rem;
		height: 274px;
		position: relative;
	}

	.introduce4 .introduce1_title {
		width: 345px;
		margin: 0 auto;
		color: #ffffffff;
	}

	.introduce4_btn {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 50%;
	}

	.introduce4_btn>div {
		color: #ffffff;
		font-weight: 700;
		font-size: 15px;
		background-color: #c7a374;
		padding: 8px 14px;
		width: 107px;
		height: 32px;
		border-radius: 26px;
		line-height: 16px;
		text-align: center;
		margin: 0 auto;
	}

	.introduce5 {
		padding-top: 21px;
		padding-bottom: 41px;
		background-color: #F8F8F8;
	}

	.introduce5_Items {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		text-align: center;
		margin-top: 21px;
	}

	.introduce5 .introduce5_ItemTitle {
		color: #3f3f3f;
		font-size: 12px;
		font-weight: 400;
		font-family: "PingFang SC";
	}

	.introduce5 .introduce1_title_h2 {
		/* padding: 0 10px 0 15px; */
	}

	.introduce5 .introduce5_ItemText {
		color: #c7a374ff;
		font-size: 12px;
		font-weight: 700;
		font-family: "PingFang SC";
	}


	.introduce6 .van-image {
		display: inline;
	}

	.introduce7 {
		padding-top: 20px;
		background-color: #F8F8F8;
	}

	.introduce7_first {
		display: flex;
		font-size: 5px;
		font-weight: 500;
		font-family: "PingFang SC";
		line-height: 14.31px;
		letter-spacing: 0.27px;
		align-items: center;
		padding-left: 0.875rem;
		justify-content: center;

	}

	.introduce7_first .firstItem {
		color: #28a3efff;
		font-size: 0.3125rem;
		font-weight: 500;
		font-family: "PingFang SC";
		letter-spacing: 0.27px;
		margin-right: 2px;
	}

	.firstItem_after {
		height: 5px;
		width: 1px;
		background-color: #d9d9d9;
		margin-right: 2px;
	}

	.introduce7_second {
		display: flex;
		color: #181818ff;
		font-size:7px;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: center;
		line-height: 14.31px;
		letter-spacing: 0.27px;
		display: flex;
		justify-content: space-around;
	}

	.popup_bg {
		height: 100vh;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;

	}

	.popup_bg .popup {
		width: 279px;
		padding-bottom: 15px;
		box-sizing: border-box;
		background-color: #ffffff;
		border-radius: 8.5px;
		text-align: center;
		z-index: 9999;
	}

	.popupbox_bg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, calc(-50% - 100px));
		z-index: 1000;
	}

	.popup_logo {
		width: 100px;
		margin: 0 auto;
		margin-top: -1.9375rem;
		height: 100%;
	}

	.popup_success {
		color: #dbba8e;
		font-size: 18px;
		font-weight: 700;
		font-family: "PingFang SC";
		margin: 0.5625rem 0;
	}

	.popup_tips {
		color: #bebebe;
		font-size: 10px;
		font-weight: 500;
		font-family: "PingFang SC";
	}

	.popup_btn {
		width: 111px;
		height: 32px;
		border-radius: 20px;
		background: #dbba8e;
		color: #ffffff;
		font-size: 12px;
		font-weight: 700;
		font-family: "PingFang SC";
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 16px auto 0;

	}

	.content_topTmgLeft .van-cell-group--inset {
		border-radius: 15px
	}

	.introduce8 {
		width: 100%;
		height: 70px;
		box-sizing: border-box;
		padding: 15px 0;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		font-size: 12px;
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		z-index: 99999;
	}

	.introduce8_btn {
		width: 140px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #3f3f3f;
		font-weight: 400;
		font-family: "PingFang SC";
		border-radius: 5px;
	}

	.introduce8_left {
		border: 0.5px solid #07BE77;

		border-image: linear-gradient(0deg, #07BE77 0%, #05A79B 50%, #05A79B 100%) 1, 1, 1, 1;
	}

	.introduce8_right {
		background: linear-gradient(225deg, #07be77ff 0%, #05a79bff 100%);
		color: #ffffff;
	}
</style>
