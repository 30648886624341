<template>
    <div>
        <van-sticky>
            <van-nav-bar title="报名单详情" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <div class="box_top" @click="gotoDetail">
            <div style="display: flex;justify-content: space-between;">
                <div class="title">{{ listArray.positionTitle }}</div>
                <div style="margin-right: 0.63rem;line-height: 3.4rem;"><i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="monye">
                <div v-if="listArray.amountType == 1"> {{ listArray.amount / 100 }}元/{{ typeList[listArray.settlementUnit]
                }}
                </div>
                <div v-if="listArray.amountType == 2">{{ listArray.amountBegin / 100 }}-{{ listArray.amountEnd / 100 }}元/{{
                    typeList[listArray.settlementUnit] }}</div>
            </div>
            <div class="company">
                <div class="img">
                    <div><img style="width: 1.38rem;margin-right: 0.35rem;border-radius: 50%;" :src="timelogo.entLogo"
                            alt="entLogo"></div>
                    <div style="color: #858080ff;font-size: 0.75rem;line-height: 1.5rem;">{{ listArray.enterpriseName }}
                    </div>
                </div>
                <div style="margin-right: 0.63rem;font-size: 0.63rem;color: #07BB7E;line-height: 1.5rem;"
                    @click.stop="Complaintfeedback"><img style="width: 1rem;margin-bottom: 0.2rem;margin-right: 0.1rem;"
                        src="@/assets/image/mine/Slice 456.png" alt="投诉反馈">投诉反馈</div>
            </div>
        </div>
        <div class="box_center">
            <div v-if="listArray.enrollmentStatus == 1" class="status">待录取</div>
            <div v-if="listArray.enrollmentStatus == 2" class="status">已录取</div>
            <div v-if="listArray.enrollmentStatus == 3" class="status">录取失败</div>
            <div v-if="listArray.enrollmentStatus == 1" class="remind">主动联系商家，录取概率更高哦 <span class="span_1"
                    @click="Contactmerchant=true">点我联系商家<i class="el-icon-arrow-right"></i></span></div>
            <div v-if="listArray.enrollmentStatus == 2" class="remind">立即联系商家，根据商家安排开始工作吧 <span class="span_2" @click="Contactmerchant=true">一键触达商家<i
                        class="el-icon-arrow-right" ></i></span></div>
            <div v-if="listArray.enrollmentStatus == 3" class="remind">海量岗位在等着你，不用遗憾 <span class="span_3" @click="$router.push('/homePage-m')">去看看其它岗位<i
                        class="el-icon-arrow-right"></i></span></div>
            <div class="Registrationstatus">
                <div>
                    <div class="statusimg"><img v-if="listArray.enrollmentStatus == 1" style="width:2.75rem"
                            src="@/assets/image/mine/Slice 451.png" alt="报名"> <img v-if="listArray.enrollmentStatus != 1"
                            src="@/assets/image/mine/Group 427321453.png" alt="报名"></div>
                    <div :class="listArray.enrollmentStatus == 1 ? 'statusname' : 'statusname2'">报名</div>
                </div>
                <div v-if="listArray.enrollmentStatus != 3" style="margin-left: 3.88rem;">
                    <div class="statusimg"><img v-if="listArray.enrollmentStatus == 1" style="width:2.75rem"
                            src="@/assets/image/mine/Group 427321471.png" alt="录取"> <img
                            v-if="listArray.enrollmentStatus == 2" src="@/assets/image/mine/Group 427321454.png" alt="录取">
                    </div>
                    <div :class="listArray.enrollmentStatus == 1 ? 'statusname2' : 'statusname'">录取</div>
                </div>
                <div v-if="listArray.enrollmentStatus == 3" style="margin-left: 3.88rem;">
                    <div class="statusimg"><img style="width:2.75rem" src="@/assets/image/mine/Group 427321456.png"
                            alt="未录取"></div>
                    <div :class="listArray.enrollmentStatus == 1 ? 'statusname2' : 'statusname'">未录取</div>
                </div>
            </div>
            <div class="Registrationresults">
                <div v-if="listArray.enrollmentStatus == 1" class="triangle_1"></div>
                <div v-if="listArray.enrollmentStatus != 1" class="triangle_2"></div>
                <div class="resultscontent">
                    <div style="display: flex;margin-bottom: 1.13rem;" v-if="listArray.enrollmentStatus == 3">
                        <div :class="listArray.enrollmentStatus == 3 ? 'boder' : 'boder2'"></div>
                        <div>
                            <div class="content">很遗憾 你未被录取</div>
                            <div class="time">{{ timelogo.statusTime}}</div>
                        </div>
                    </div>
                    <div style="display: flex;margin-bottom: 1.13rem;"
                        v-if="listArray.enrollmentStatus == 2 ">
                        <div
                            :class="listArray.enrollmentStatus == 2 && listArray.enrollmentStatus != 3 ? 'boder' : 'boder2'">
                        </div>
                        <div>
                            <div class="content">恭喜你已经被录取</div>
                            <div class="time">{{ timelogo.statusTime}}</div>
                        </div>
                    </div>
                    <div style="display: flex;margin-bottom: 1.13rem;">
                        <div :class="listArray.enrollmentStatus == 1 ? 'boder' : 'boder2'"></div>
                        <div>
                            <div class="content">你已报名成功</div>
                            <div class="time">{{ timelogo.bmTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="recommendations">相关推荐</div>
            <div class="underline"></div>
            <jobItem v-for="(item, index) in taskList" :key="index" :itemObj="item" @itemClick="itemClick"></jobItem>
            <div v-if="Nomore" style="text-align: center;    color: #666666;font-size: 0.75rem;font-weight: 500;">- 没有更多内容了哦 -</div>
        </div>
        <van-popup v-model="Contactmerchant" round position="bottom"
            :style="{ height: 13.5 + 'rem' }">
            <div style="display: flex;flex-direction: column;">
                <div class="Contact_sj">联系商家</div>
                <div class="vx">
                    <div><img style="height:1.88rem;margin-right: 0.31rem;" src="@/assets/image/mine/Slice 483.png" alt="">微信：{{ wechatNum }}</div>
                    <div @click="copyWx"><img style="height: 2.19rem;" src="@/assets/image/mine/Slice 484.png" alt=""></div>
                </div>
                <div class="sure" @click="clicksure">确认报名</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: 'RegistrationDetails-m',
    data() {
        return {
            listArray: {},//报名详情列表
            typeList: ['其他', '小时', '天', '周', '月', '次', '单'],
            timelogo: {},
            taskList: [],
            // 默认算法
            queryTypes: ["1", "0", "0", "0", "0", "0"],
            Nomore: false,
            Contactmerchant: false,//联系商家
            wechatNum:"",
        }
    },
    created() {
        this.listArray = this.$router.getParams()
        console.log(this.listArray, "this.listArray");
        this.gittimelogo()
        this.gitlist()
        this.getWxChat()
    },
    methods: {
        //确认报名
        clicksure(){
            this.Contactmerchant=false
            // this.$JsCopy.iosCopy(this.wechatNum)
            this.$common.onCopy(this.wechatNum)
            this.$toast('微信号复制成功，请到微信上添加老师微信!')
        },
        //获取vx
        getWxChat() {
            let userId =JSON.parse(localStorage.getItem('userInfo'))
            this.$axios({
                url: '/app/message/getAppWechatNum',
                params: {
                    id: this.listArray.positionId,
                    userId: userId.id
                },
            }).then(res => {
                if (res.code == 200) {
                    this.wechatNum = res.data.wechatNum
                }
            })
        },
        //复制vx号
        copyWx() {
            // this.$JsCopy.iosCopy(this.wechatNum)
            this.$common.onCopy(this.wechatNum)
            this.$toast('微信号复制成功，请到微信上添加老师微信!')
        },
        //投诉反馈
        Complaintfeedback() {
            window.open('https://work.weixin.qq.com/kfid/kfceadcea55e01d2a7c')
        },
        onClickLeft() {
            this.$router.push('/Myregistration-m');
        },
        //获取时间logo
        gittimelogo() {
            this.listArray = this.$router.getParams()
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.$axios({
                method: 'post',
                url: '/app/collect/getCollectStatus',
                data: {
                    positionId: this.listArray.positionId,
                    positionType: this.listArray.enrollmentStatus <= 2 ? '2' : '3',
                    userId: userInfo.id
                }
            }).then((res) => {
                if (res.code == 200) {
                    this.timelogo = res.data
                }
            })
        },
        //获取推荐列表
        gitlist() {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.$axios({
                method: 'post',
                url: '/app/home/getLessonRecommendList',
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                data: {
                    pageNum: 1, //查询第几页
                    pageSize: 10,
                    // 默认算法
                    queryTypes: ["1", "0", "0", "0", "0", "0"],
                    positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
                    positionType: 1, //1兼职职位，2课程职位
                    userId: userInfo.id
                }
            }).then((res) => {
                if (res.code == 200) {
                    this.taskList = res.rows
                    if (res.rows.length == 20) {
                        this.Nomore = true
                    }
                }
            })
        },
        //列表点击
        itemClick() {

        },
        // 前往详情
        gotoDetail() {
            //判断是什么方式报名
            if (this.listArray.positionType == 1) {
                this.$router.push({ name: 'taskDetail-m', params: { id: this.listArray.positionId } })
                // this.$router.push('taskDetail-m?id=' + this.listArray.positionId)
            } else {
                this.$router.push({ name: 'courseDetail-m', params: { id: this.listArray.positionId } })
                // this.$router.push('courseDetail-m?id=' + this.listArray.positionId)
            }
        },
    }

}
</script>

<style scoped lang="less">
.box_top {
    display: flex;
    flex-direction: column;
    width: 21.88rem;
    height: 8.15rem;
    margin: auto;
    margin-top: 0.63rem;
    border-radius: 0.5rem;
    background: #ffffffff;

    .company {
        display: flex;
        justify-content: space-between;

        .img {
            display: flex;
            margin-left: 0.63rem;
        }
    }

    .title {
        color: #000000ff;
        font-size: 1.13rem;
        font-weight: 600;
        margin: 0.88rem;
        margin-left: 0.63rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .monye {
        color: #fe0000ff;
        font-weight: 600;
        font-family: "PingFang SC";
        font-size: 1rem;
        margin-top: 0.25rem;
        margin-left: 0.63rem;
        margin-bottom: 0.63rem;
    }
}

.box_center {
    width: 21.87rem;
    // height: 15.69rem;
    border-radius: 0.5rem;
    background: #ffffffff;
    margin: auto;
    margin-top: 0.56rem;
    padding-top: 0.75rem;
    padding-bottom: 0.56rem;

    .status {
        color: #000000ff;
        font-size: 1rem;
        font-weight: 600;
        margin-left: 0.63rem;
        margin-bottom: 0.5rem;

    }

    .remind {
        color: #605e5eff;
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 0.63rem;

        .span_1 {
            color: #07BB7E;
        }

        .span_2 {
            color: #07BB7E;
        }

        .span_3 {
            color: #E35B42;
        }
    }

    .Registrationstatus {
        display: flex;
        margin-top: 1.44rem;
        margin-left: 2.88rem;

        .statusname {
            color: #000000ff;
            font-size: 0.88rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.69rem;
        }

        .statusname2 {
            color: #D1D0D0;
            font-size: 0.88rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.69rem;
        }
    }

    .Registrationresults {
        width: 20.63rem;
        // height: 3.44rem;
        border-radius: 0.31rem;
        opacity: 1;
        background: #f1f4f5ff;
        margin: auto;
        margin-top: 1.88rem;
        position: relative;

        .triangle_1 {
            position: absolute;
            top: -1.3rem;
            left: 2.8rem;
            width: 0rem;
            height: 0rem;
            border: 0.7rem solid;
            border-color: transparent transparent #F1F4F5 transparent;
            opacity: 1;
        }

        .triangle_2 {
            position: absolute;
            top: -1.3rem;
            left: 9.8rem;
            width: 0rem;
            height: 0rem;
            border: 0.7rem solid;
            border-color: transparent transparent #F1F4F5 transparent;
            opacity: 1;
        }

        .resultscontent {
            display: flex;
            flex-direction: column;
            padding-top: 0.56rem;
            margin-left: 1.06rem;

            .boder {
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 50%;
                border: 0.2rem solid #07BB7E;
                margin-top: 0.2rem;
                margin-right: 0.5rem;
            }

            .boder2 {
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 50%;
                // border: 0.2rem solid #07BB7E;
                background-color: #D1D0D0;
                margin-top: 0.2rem;
                margin-right: 0.5rem;
            }

            .content {
                color: #000000ff;
                font-size: 0.88rem;
                font-weight: 500;
            }

            .time {
                color: #a7a7a7ff;
                font-size: 0.75rem;
                font-weight: 500;
            }
        }
    }
}

.recommendations {
    color: #000000ff;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1.44rem;
    margin-top: 1.13rem;
    z-index: 3;
}

.underline {
    width: 3.94rem;
    height: 0.38rem;
    opacity: 1;
    background: linear-gradient(-90deg, #06ad941a 0%, #07bb7eff 83%);
    margin-left: 1.44rem;
    margin-top: -0.4rem;
    margin-bottom: 1.31rem;
}

.Contact_sj {
    color: #333333ff;
    font-size: 1.25rem;
    font-weight: 600;
    margin: auto;
    margin-top: 1.5rem;
}

.vx {
    display: flex;
    justify-content: space-between;
    margin-left: 1.25rem;
    margin-right: 1.38rem;
    margin-top: 1.44rem;
    margin-bottom: 1.44rem;
}
.sure {
    width: 21.56rem;
    height: 3.13rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    color: #ffffffff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    line-height: 3.13rem;
    margin: auto;
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}

/deep/.van-nav-bar .van-icon {
    color: #000000;
    font-size: 1.3rem;
}
</style>