<template>
  <div>
      <!--顶部-->
      <div class="nave">
          <div class="nave-box">
              <div class="breadCrumbs">
                  <div class="breadCrumb">
                      <span @click="goHome" >首页 <i class="el-icon-arrow-right" style="margin-right: 5px"></i></span>

                  </div>
                  <div class="breadCrumb">
                      <span @click="goInformation" >资讯 <i class="el-icon-arrow-right" style="margin-right: 5px;"></i></span>
                  </div>
                  <span class="zhuanti">专题</span>
              </div>

              <div class="title-box">
                  <div class="nav-title">资讯专题</div>
                  <div class="nav-mess">
                      互联网时代副业必备技能，助力开辟第二赛道！
                  </div>
              </div>

          </div>

          <!--专题盒子-->
          <div class="topicBox">
              <!--上面专题分类-->
              <div class="topicForder" >
                  <div :class="articleShow?'topicForderbottomT':'topicForderbottom'" >
                      <div class="topicForderlist" v-for="(item,index) in topicListS.slice(0,5)" @click="gothematicDetails(item)">
                          <div class="listImg">
                              <img v-if="index == 0" src="@/assets/images/pc/topicIcon1.png" />
                              <img v-if="index == 1" src="@/assets/images/pc/topicIcon2.png" />
                              <img v-if="index == 2" src="@/assets/images/pc/topicIcon3.png" />
                              <img v-if="index == 3" src="@/assets/images/pc/topicIcon4.png" />
                              <img v-if="index == 4" src="@/assets/images/pc/topicIcon5.png" />
                          </div>
                          <div class="listTitleBox">
                              <div class="listTitle">{{item.topicName | setTop}}</div>
                              <div class="listArticle">{{item.zxNum}}篇文章</div>
                          </div>
                      </div>

                      <div class="topicForderlist" @click="setArticleShowT" >
                          <div class="listImg">
                              <img src="@/assets/images/pc/listImg.png" />
                          </div>
                          <div class="listTitleBox">
                              <div class="listTitle">更多专题</div>
                              <div class="listArticle">{{setNum}}篇文章</div>
                          </div>
                      </div>
                  </div>

                  <!--下面显示的盒子-->
                  <div class="topicFordertop" v-if="articleShow">
                      <div class="topicFordertopBox">
                          <div class="topicFordertopList" v-for="item in topicList.slice(5,topicList.length)" @click="gothematicDetails(item)">
                              <div class="topListImg">
                                  <img src="@/assets/images/pc/topListImg.png" />
                              </div>
                              <div class="topTitle">{{item.topicName |setTopicName}}</div>
                              <div class="topListArticle">{{item.zxNum}}篇文章</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>




      <!--下面的专题-->
      <div class="topicSecondLevel">
          <div class="SecondLevelMess" v-for="item in topicList" :key="item.id"  @click="gothematicDetails(item)">
              <a :href="item.id" onclick="return false">
                  <div class="messImage">
                      <div class="messImageTitle">专题</div>
                      <img :src="item.imgUrl" />
                  </div>
                  <div class="messBox">
                      <div class="messTitle">{{item.topicName}}</div>
                      <div class="messTitleT">{{item.title}}</div>
                      <div class="messExplain">
                          <div>
                              {{item.introduction}}
                          </div>
                      </div>

                      <div class="messBottom">
                          <div class="messTime">
                              <i class="el-icon-time"></i>
                              <span>{{item.createTime}}</span>
                          </div>

                          <div class="messFun">
                              <img class="messFunChoose" src="@/assets/images/pc/timeFun.png" />
                              <img class="messFunSelect" src="@/assets/images/pc/timeFunSelect.png" />
                          </div>
                      </div>
                  </div>
              </a>

          </div>
      </div>


      <!--分页器-->
      <div class="pageBox">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="12"
              layout="prev, pager, next, jumper"
              :total="totals">
          </el-pagination>
      </div>
  </div>
</template>

<script>
export default {
    name: "thematicList",
    data(){
        return {
            articleShow:false,
            currentPage3: 1,
            topicList:[],
            topicListS:[],
            totals:0,
            imgList:[

            ]
        }
    },

    mounted() {
        this.getInformationTICList();
    },
    filters:{
        setTop(e){
            if (e.length>6){
                return e.slice(0,6) + '...'
            }else {
                return e
            }
        },
        setTopicName(e){
            if (e.length>5){
                return e.slice(0,5)
            }else {
                return e
            }
        }
    },
    computed:{
        setNum(){
            let arrt = JSON.stringify(this.topicList);
            arrt = JSON.parse(arrt)
            let arr = arrt.splice(5,arrt.length)
            let num = 0;
            arr.forEach(item =>{
                num += Number(item.zxNum)
            })
            return num
        },
    },
    methods:{

        setArticleShowT(){
            this.articleShow = !this.articleShow
        },
        handleSizeChange(e){
            console.log(e)
        },
        handleCurrentChange(e){
            console.log(e)
            this.currentPage3 = e;
            this.getInformationTICList();
        },
        gothematicDetails(e){
            console.log(e)
            this.$router.push({
                name: 'thematicDetails',
                params:{
                    name: e.topicName,
                    Second:"资讯",
                    item:e
                }
            });
        },
        getInformationTICList(){
            this.$axios({
                url:"/official/enterprise/getInformationTICList?pageNum=" + this.currentPage3 + "&pageSize=12",
                method:"POST"
            }).then(res=>{
                console.log("res=>",res)
                if(res.code == 200){
                    if(this.topicListS == ''){
                        this.topicListS = res.rows;
                    }
                    this.topicList = res.rows;
                    this.totals = res.total;
                }else {
                    this.$notify({
                        title: '错误',
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },
        /*跳转首页*/
        goHome(){
            this.$router.push({
                name:"homePage"
            })
        },
        /*跳转资讯页面*/
        goInformation(){
            this.$router.push({
                name:"informationHome"
            })
        }
    }
}
</script>

<style src="@/assets/css/pc/thematicList.css" scoped></style>
