<template>
    <div style="height: 100vh;">
        <div class="minetopbg" @click="$router.push('/Myresume-m')">
            <div style="margin-left:0.75rem;display: flex;">
                <div><van-image round width="4.38rem" height="4.38rem" :src="userInfo.imgUrl" /></div>
                <div style="margin-left: 0.63rem;display: flex;flex-direction: column;justify-content: space-around;">
                    <p style=" color: #ffffffff;font-size: 1.13rem;">{{ userInfo && userInfo.nickname || '点击这里设置名称' }}</p>
                    <p style=" color: #ffffffff;font-size: 0.75rem;">用户ID：{{ userInfo && userInfo.id || '' }}</p>
                </div>
            </div>
            <div class="baoming">
                <div class="baomingneir" v-for="(item, index) in pluginsList" :key="index" @click="goMyregistration(item)">
                    <p style="color: #000000ff;font-size: 1rem;font-weight: 600; margin-bottom: 0.69rem;">{{ item.num }}</p>
                    <p style=" color: #999999ff;font-size: 0.75rem;font-weight: 500;">{{ item.title }}</p>
                </div>
            </div>
        </div>
        <div class="jianli">
            <div style="margin-top: 0.63rem;margin-left: 0.38rem;"><img style="width: 5.38rem;height: 1.35rem;"
                    src="@/assets/image/mine/mine_resume_title.png" alt=""></div>
            <p style="">完善简历，您录取的概率将成倍提升</p>
            <div class="tianxie">
                <div style="margin-left: 0.8rem;margin-top: 0.75rem;"><img style="height: 1.7rem;"
                        src="@/assets/image/mine/ming_resume_name.png" alt=""></div>
                <div style="display: flex;justify-content: space-between;padding-left: 0.8rem;padding-right: 0.79rem;margin-top: 0.15rem;border-bottom: 0.1rem solid #F5F5F7;padding-bottom: 0.3rem;"
                    @click="$router.push('/Myresume-m')">
                    <div><img style="height: 1.2rem;" src="@/assets/image/mine/mine_resume_tianxie.png" alt=""><span
                            style="font-size: 0.79rem;">继续完成简历</span></div>
                    <div><span style="font-size: 0.79rem;color: #07BB7E;">去填写</span><img style="height: 1.2rem;"
                            src="@/assets/image/mine/mine_resume_you.png" alt=""></div>
                </div>
                <div style="display: flex;justify-content: space-between;margin-left: 0.8rem;margin-right: 0.79rem;margin-top: 0.27rem;"
                    @click="show = true">
                    <div><img style="height: 1.2rem;" src="@/assets/image/mine/mine_resume_qiuzhi.png" alt=""><span
                            style="font-size: 0.79rem;">求职偏好</span></div>
                    <div><span style="font-size: 0.79rem;color: #07BB7E;">去填写</span><img style="height: 1.2rem;"
                            src="@/assets/image/mine/mine_resume_you.png" alt=""></div>
                </div>
            </div>
            <div class="mine_resume_bg"><img style=" width: 9.13rem;height: 12.06rem;"
                    src="@/assets/image/mine/mine_resume_bg.png" alt="简历图片"></div>
        </div>
        <div class="fuwu">服务列表</div>
        <div style="display: flex;">
            <div v-for="(item, index) in serveList" :key="index"
                style="width: 4.5rem;height: 3.44rem;margin-left: 0.94rem ;margin-top: 1rem;" @click="clickrouter(item)">
                <div style="text-align: center;"><img
                        :class="{ 'resume-active': index == 0, 'chat-active': index == 1, 'collect-active': index == 2, 'chat-active' : index == 3}"
                        style="width: 2.2rem;" :src="item.icon" alt=""></div>
                <p style="color: #000000ff;font-size: 0.95rem;font-weight: 500;margin-top: 1rem;text-align: center;">
                    {{ item.title }}</p>
            </div>
        </div>
        <van-popup v-model="show" round position="bottom" :style="{ height: 40 + 'rem' }">
            <div class="qiuzhi"><img src="@/assets/image/mine/mine_qiuzhi.png" alt="求职偏好"></div>
            <div
                style=" color: #999999ff;font-size: 0.69rem;font-weight: 500;height: 1.5rem;margin-left: 0.75rem;line-height: 1.5rem;">
                我们根据选择为您选择精准岗位</div>
            <div class="gangwei">
                <div><img src="@/assets/image/mine/mine_gangwei.png" alt="行业岗位"></div>
                <div>行业岗位</div>
            </div>
            <div class="gangweicontent" style="">
                <div v-for="(item, index) in applyTypeList" :key="index"
                    :class="item.userIsType ? 'gangweiname' : 'gangweiname2'" @click="applyTypeMen(item)">
                    <div>{{ item.name }}</div>
                </div>
            </div>
            <button class="button" @click="updateTagList" v-if="isUpdateTagList">保存（{{ applyNum }}/5）</button>
            <button class="button" v-if="!isUpdateTagList">保存（{{ applyNum }}/5）</button>
        </van-popup>
        <!-- 未登录弹窗 -->
        <van-popup v-model="showlogin" round position="bottom" :close-on-click-overlay="false"
            :style="{ height: 16 + 'rem' }">
            <div class="gologin" @click="gologin">去登录</div>
            <div class="nologin" @click="nologin">取消</div>
        </van-popup>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pluginsList: [{
                title: '全部',
                num: '0',
                id: 0
            },
            {
                title: '待录取',
                num: '0',
                id: 1
            },
            {
                title: '已录取',
                num: '0',
                id: 2
            },
            {
                title: '已结束',
                num: '0',
                id: 3
            },
            ],
            serveList: [{
                title: '设置',
                icon: require('@/assets/image/mine/mine_shezhi.png'),
                id: 0,
                url: '/pagesTwo/my/setAll/setAll'
            },
            {
                title: '客服',
                icon: require('@/assets/image/mine/mine_kefu.png'),
                id: 1
            },
            {
                title: '收藏',
                icon: require('@/assets/image/mine/collect.png'),
                id: 3,
            },
			{
				title:'商务合作',
				icon: require(('@/assets/image/mine/Slice 857.png')),
				id:4,
			}
            ],
            userInfo: {}, //用户信息
            show: false,//求职偏好
            applyTypeList: [],//岗位内容
            applyNum: 0, //选中了多少条
            positionList: [], //求职癖好
            isUpdateTagList: true, //提交保存求职偏好防抖
            showlogin: false
        }
    },
    created() {
        let user = localStorage.getItem('userInfo')
        if (user) {
            console.log(user, 'user')
            this.getUserInfo() //获取用户资料
            this.selectEnrollCount() //个人中心数据统计
        } else {
            console.log('未登录');
            // this.showlogin = true
        }
        this.getTagList()
    },
    methods: {
        //点击跳转
        clickrouter(e) {
            //跳转设置
            if (e.id == 0) {
                this.$router.push('/setup-m')
            }
            if (e.id == 1) {
                window.open('https://work.weixin.qq.com/kfid/kfceadcea55e01d2a7c')
            }
            //跳转收藏
            if (e.id == 3) {
                this.$router.push('/collect-m')
            }
			if(e.id == 4){
				this.$router.push('/Business-m')
			}
        },
        //跳转我的报名
        goMyregistration(e) {
            console.log(e, "e");
            this.$router.push({ name: 'Myregistration-m', params: e })
        },
        /**
             * @api 刷新缓存- 获取用户资料
             */
        getUserInfo() {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            // console.log(userInfo,userInfo.id);
            if (userInfo && userInfo.id) {
                this.$axios({
                    method: "post",
                    url: "/app/user/getUserInfo",
                    params: {
                        id: userInfo.id,
                    },
                }).then((res) => {
                    if (res.code == 200) {
                        console.log(res, "res1111");
                        let userInfo2 = JSON.stringify(res.data)
                        localStorage.setItem('userInfo', userInfo2)
                        this.userInfo = res.data
                    } else {
                        localStorage.removeItem('userInfo')
                        // this.userInfo = {}
                    }
                })
            }
        },
        /**
             * @api 个人中心数据统计
             */
        selectEnrollCount() {
            this.$axios({
                method: "post",
                url: "/app/position/selectEnrollCount",
            }).then((res) => {
                if (res.code == 200) {
                    this.pluginsList[0].num = res.data.allCount //全部
                    this.pluginsList[1].num = res.data.waitCount //待录取
                    this.pluginsList[2].num = res.data.alreadyCount //已录取
                    this.pluginsList[3].num = res.data.notCount //未录取
                    // this.pluginsList[4].num = res.data.collect //收藏
                } else {
                    this.pluginsList[0].num = 0 //全部
                    this.pluginsList[1].num = 0 //待录取
                    this.pluginsList[2].num = 0 //已录取
                    this.pluginsList[3].num = 0 //未录取
                }
            })
        },
        /**
            * @api 求职偏好
            */
        getTagList() {
            this.$axios({
                method: 'post',
                url: '/app/user/getTagList'
            }).then((res) => {
                if (res.code == 200) {
                    this.applyTypeList = res.rows
                    this.positionList = []
                    this.applyTypeList.forEach((items) => {
                        if (items.userIsType) {
                            this.positionList.push(items.id)
                        }
                    })
                    this.applyNum = 0
                    res.rows.forEach(item => {
                        if (item.userIsType) {
                            this.applyNum++
                        }
                    })
                } else {
                    this.applyTypeList = []
                }
            })
        },
        /**
            * @api 求职偏好名字点击事件
            */
        applyTypeMen(item) {
            if (this.applyNum < 5) {
                console.log('[1]')
                item.userIsType = !item.userIsType
                if (item.userIsType) {
                    this.applyNum++
                } else {
                    this.applyNum--
                }
                this.positionList = []
                this.applyTypeList.forEach((items) => {
                    if (items.userIsType) {
                        this.positionList.push(items.id)
                    }
                })

            } else {
                console.log('[2]')
                this.positionList.forEach(items => {
                    if (item.id == items) {
                        item.userIsType = !item.userIsType
                        if (item.userIsType) {
                            this.applyNum++
                        } else {
                            this.applyNum--
                        }
                    }
                })
            }
        },
        /**
           * @api 求职偏好保存
           */
        updateTagList() {
            console.log(this.positionList, "this.positionList");
            if (this.positionList.length <= 0) {
                console.log('请选择求职偏好')

            } else {
                this.isUpdateTagList = false //防抖
                this.$axios({
                    method: 'post',
                    url: '/app/user/updateTagList',
                    data: {
                        positionList: this.positionList,
                    },
                }).then(res => {
                    this.isUpdateTagList = true
                    if (res.code == 200) {
                        this.show = false
                        this.$toast.success('保存成功')
                    } else {
                        console.log('接口异常')
                    }
                })
            }
        },
        //去登录
        gologin() {
            this.$router.push('/login-m')
        },
        //取消登录
        nologin() {
            this.$router.push('/homePage-m')
        }

    }

}
</script>

<style scoped lang="less">
.minetopbg {
    width: 23.44rem;
    height: 16.19rem;
    opacity: 1;
    background: linear-gradient(-0.7deg, #f5f5f7ff 0%, #07bb7eff 68%);
    padding-top: 4.63rem;

    .baoming {
        width: 21.94rem;
        height: 5.81rem;
        border-radius: 0.5rem;
        opacity: 1;
        background: #ffffffff;
        margin: auto;
        margin-top: 1.5rem;
        display: flex;

        .baomingneir {
            margin: auto;
            text-align: center;
            // line-height: 5.81rem;
        }
    }
}

.jianli {
    width: 21.94rem;
    height: 12.06rem;
    border-radius: 0.5rem;
    opacity: 1;
    background: linear-gradient(203.1deg, #07bb7eff 0%, #06ad94ff 100%);
    margin: auto;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;

    // background-image: url(@/assets/image/mine/mine_resume_bg.png);
    p {
        height: 1.88rem;
        opacity: 1;
        color: #F5F5F7;
        font-size: 0.63rem;
        font-weight: 500;
        line-height: 1.75rem;
        margin-left: 0.38rem;
    }

    .tianxie {
        position: relative;
        width: 21.19rem;
        height: 6.83rem;
        border-radius: 0.5rem;
        opacity: 1;
        background: #ffffffff;
        margin: auto;
        z-index: 11;
    }

    .mine_resume_bg {
        position: absolute;
        top: 0rem;
        right: 0;
        z-index: 5;
    }
}

.fuwu {
    height: 1.81rem;
    opacity: 1;
    color: #000000ff;
    font-size: 1rem;
    font-weight: 600;
    font-family: "PingFang SC";
    text-align: left;
    line-height: 1.75rem;
    margin-left: 0.75rem;
    margin-top: 1rem;
}

.qiuzhi {
    margin-top: 1.75rem;
    margin-left: 0.75rem;

    img {
        height: 2.0rem;
    }
}

.gangwei {
    display: flex;
    margin-top: 0.88rem;
    margin-left: 0.75rem;

    img {
        height: 1.5rem;
    }
}

.gangweicontent {
    margin-left: 0.75rem;
    margin-top: 1.19rem;
    margin-right: 1.31rem;
    display: flex;
    flex-wrap: wrap;

    .gangweiname {
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        // width: 100%;
        height: 2rem;
        border-radius: 0.25rem;
        opacity: 1;
        border: 0.1rem solid #06AD94;
        background: #FFFFFF;
        text-align: center;
        line-height: 2rem;
        color: #06AD94;
        font-size: 0.88rem;
        box-sizing: border-box;
    }

    .gangweiname2 {
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        // width: 100%;
        height: 2rem;
        border-radius: 0.25rem;
        border: 0.01rem solid #f1f1f1ff;
        opacity: 1;
        text-align: center;
        line-height: 2rem;
        color: #666666;
        background: #f1f1f1ff;
        font-size: 0.88rem;
    }
}

.button {
    margin-left: 0.75rem;
    width: 21.94rem;
    height: 3.25rem;
    border-radius: 0.75rem;
    opacity: 1;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    color: #FFFFFF;
    margin-top: 2.5rem;
}

.gologin {
    width: 18.81rem;
    height: 3.25rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    text-align: center;
    line-height: 3.25rem;
    color: #FFFFFF;
    margin: auto;
    margin-top: 2.5rem;
}

.nologin {
    width: 18.81rem;
    height: 3.25rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    text-align: center;
    line-height: 3.25rem;
    color: #FFFFFF;
    margin: auto;
    margin-top: 2rem;
}

.resume-active {
    animation: 3s loader linear infinite;
}

.chat-active {
    animation: 1s shake linear infinite;
}

.collect-active {
    animation: 2s shine linear infinite;
}

/* 360度旋转 */
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* 左右晃动 */
@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(20deg);
    }

    40% {
        transform: rotate(-20deg);
    }

    60% {
        transform: rotate(10deg);
    }

    80% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }

}

/* 闪烁 */
@keyframes shine {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    25% {
        opacity: 0.8;
        transform: scale(0.9);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    75% {
        opacity: 0.8;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}</style>