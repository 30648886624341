import { render, staticRenderFns } from "./PartTJ.vue?vue&type=template&id=40d939df&scoped=true"
import script from "./PartTJ.vue?vue&type=script&lang=js"
export * from "./PartTJ.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/pc/homePage.css?vue&type=style&index=0&id=40d939df&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/pc/PartTJ.css?vue&type=style&index=1&id=40d939df&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d939df",
  null
  
)

export default component.exports