<template>
  <div>
      <!--顶部-->
      <div class="nav">
          <div class="navBox">
              <!--面包屑-->
              <div class="crumbs">
                  <div>
                      <span @click="goPageHome" >首页 <i class="el-icon-arrow-right"></i> </span>
                  </div>
                  <div v-if="Second">
                      <span v-if="title" @click="$router.back()">{{Second}} <i class="el-icon-arrow-right"></i> </span>
                  </div>
                  <div>
                      <span v-if="title" @click="$router.back()">{{title}} <i class="el-icon-arrow-right"></i> </span>
                  </div>

                  <span v-if="titleT">{{titleT}} <i class="el-icon-arrow-right"></i></span >
                  <span>{{topicMess.title}}</span>
              </div>

              <!--专题表头-->
              <div class="topic">
                  <div class="topicBox">
                      <div class="topicLftBox">
                          <div class="topicLeft">
                              <img :src="topicMess.imgUrl" />
                          </div>
                      </div>

                      <div class="topicRight">
                          <div class="topicTitle">
                              {{topicMess.title}}
                          </div>
                          <div class="topicCount">
                              {{topicMess.introduction}}
                          </div>

                          <div class="topicTime">
                              <i class="el-icon-time"></i> {{topicMess.createTime | setTime}}
                          </div>
                      </div>
                  </div>
                  <img class="topicImg" src="@/assets/images/pc/leaf.png" />
              </div>

              <div class="sort">
                  <div @click="setPositive">
                      <img v-if="sortIndex" src="@/assets/images/pc/upward.png" />
                      <img v-else src="@/assets/images/pc/upwardT.png" />
                      <span  :class="sortIndex?'sortText':''">正序</span>
                  </div>
                  <div @click="setFlashback">
                      <img v-if="sortIndexT" src="@/assets/images/pc/down.png">
                      <img v-else src="@/assets/images/pc/downT.png" />
                      <span :class="sortIndexT?'':'sortText'">倒序</span>
                  </div>
              </div>
          </div>



      </div>


      <!--列表-->
      <div class="messBox">
          <div class="messBoxLeft">


                  <div class="leftList" v-for="(item,index) in topicList"  @click="toDetail(item)">
                                    <a  :href="item.id" onclick="return false">
                      <div class="leftListImg">
                        <img :src="item.imgUrl" />
                          <div>{{(index + 1) | setNum(currentPage3)}}</div>
                      </div>

                      <div class="letRight">
                          <div class="leftRightTitle">{{item.title}}</div>
                          <div class="leftRightCount" >
                              {{getRichText(item)}}
                          </div>

                          <div class="leftRightBottom">
                              <div class="leftRightTime"><i class="el-icon-time" style="margin-right: 5px"></i>{{item.createTime | setTime}}</div>
                              <div class="tabBox">
                                  <div v-for="(item2,index2) in item.tagName"  @click.stop="goThematicDetails(item2)">{{item2.topicName | setitem2(item.tagName,index2)}}</div>
                              </div>
                          </div>
                      </div>
                                    </a>

                  </div>


                  <div class="leftImg" v-if="topicList.length<=0">
                  <img src="@/assets/images/pc/Frame.png"/>
              </div>
            <!--分页器-->
              <div style="display: flex;justify-content: center" v-if="topicList.length > 0">
                  <div class="block">
                      <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :current-page.sync="currentPage3"
                          :page-size="10"
                          layout="prev, pager, next, jumper"
                          :total="total">
                      </el-pagination>
                  </div>
              </div>
          </div>


          <div class="messBoxRight">
              <!--热门推荐-->
              <div class="hotBox">
                  <div class="hotBoxTitle">
                      <img src="@/assets/images/pc/HotIcon.png" />
                      <span>热门推荐</span>
                  </div>
                  <a v-for="item in hotList" :href="item.id" onclick="return false">

                  <div class="hotList"  @click="toDetail(item)">
                          <div class="hotListLeft">
                              <img :src="item.imgUrl" />
                          </div>
                          <div class="hotListCount">
                              <div class="hotListCountTitle">{{item.title}}</div>

                              <div class="hotListBottom">
                                  <div class="hotListTime">
                                      <i class="el-icon-time" style="margin-right: 5px"></i>{{item.createTime | setTime}}
                                  </div>
                                  <div class="hotListFun">
                                      查看详情 <i class="el-icon-arrow-right"></i>
                                  </div>
                              </div>
                          </div>
                  </div>
                  </a>

              </div>


              <div class="messBoxRightT">
                  <div class="hotBoxTitle">
                      <img src="@/assets/images/pc/Recommendedtopic.png" />
                      <span>推荐专题</span>
                  </div>

                  <div class="topicBoxx">
                      <a  v-for="item in topicListT" :href="item.id" onclick="return false">
                          <div class="topicList">
                              <!--底部图片-->
                              <img class="topicListImg" :src="item.imgUrl" />
                              <!--文字-->
                              <div class="descriptionBox">
                                  <div class="descriptionTitle">{{item.topicName}}</div>
                                  <div class="descriptionTitleT">{{item.title}}</div>
                                  <div class="descriptionFun" @click="goThematicDetails(item)">查看专题 </div>
                              </div>
                          </div>
                      </a>

                  </div>

                  <div class="topicFun" @click="goThematicList">
                      查看全部专题<span class="dian"></span>
                  </div>
              </div>
          </div>


      </div>
  </div>
</template>

<script>
export default {
    name: "thematicDetails",
    data(){
        return{
            sortIndex:true,
            sortIndexT:true,
            topicMess:{},
            topicList:[],
            hotList:[],
            topicListT:[],
            orderType:"1",
            title:"",  //一级标题标题,
            titleT:'',  //二级标题
            currentPage3:1,
            total:0,
            Second:""
        }
    },
    mounted() {
        console.log("获取到的值=>",this.$router.getParams())
        this.topicMess = this.$router.getParams().item;
        this.title = this.$router.getParams().name;
        this.Second = this.$router.getParams().Second;
        this.getInformationTIIList();
        this.getInformationTRIList();
        this.getInformationTRCList();
    },
    computed:{
    },
    filters:{
        setTime(e){
            if(e != undefined && e != null && e!= ''){
                return e.split(' ')[0]
                console.log(e.split(' '))
            }
        },
        setitem2(e,arr,i){
            console.log(e,arr,i,'哈哈哈')
            if(i < 2){
                return e
            }else {
                let num = arr[0].topicName.length + arr[1].topicName.length
                console.log("chng=>",num)
                if(num < 20){
                    console.log("3=>",arr)
                    if(num <= 10){
                        return e
                    }else {
                        return e.slice(0,(20-num)) + '...'
                    }
                }else {
                    return '...'
                }
            }

        },
        setNum(e,i){
            return (i-1) * 10 + e
        }
    },
    methods:{
        getRichText(item) {
            // 提取文字
            const myDiv = document.createElement("div");
            myDiv.innerHTML = item.content;
            return myDiv.innerText;
        },
        handleSizeChange(e){
          console.log(e)
        },
        handleCurrentChange(e){
            this.currentPage3 = e;
            this.getInformationTIIList();
        },
        goPageHome(){
          this.$router.push({
              name:"homePage"
          })
        },
        toDetail(item) {
            this.$router.push({
                name: `informationDetail`,
                params: {
                    id: item.id,
                    name: this.$route.meta.metaInfo.title,
                    routeNameT:this.title
                },
            });
        },
        goThematicDetails(e){
            this.$router.push({
                name: 'empty',
                params: {
                    id: e.id,
                    title:this.title,
                    item:e,
                    titleT:this.titleT,
                    name: this.$route.name,
                    Second : this.$router.getParams().Second
                }
            });
        },
        setPositive(){
            this.sortIndex = true;
            this.sortIndexT = true;
            this.orderType = 1;
            this.getInformationTIIList();
        },
        setFlashback(){
            this.sortIndex = false;
            this.sortIndexT = false;
            this.orderType = 2;
            this.getInformationTIIList();
        },
        getInformationTIIList(){
            this.$axios({
                url:"/official/enterprise/getInformationTIIList",
                data:{
                    pageNum:this.currentPage3,
                    pageSize:'10',
                    ztId:this.topicMess.id,
                    orderType:this.orderType,
                    websiteType:2
                },
                method:"POST"
            }).then(res=>{
                if(res.code == 200){
                    this.topicList = res.rows;
                    this.total = res.total
                }
            })
        },
        getInformationTRIList(){
            this.$axios({
                url:"/official/enterprise/getInformationTRIList?pageNum=1&pageSize=3",
                data:{
                    // pageNum:1,
                    // pageSize:3,
                    websiteType:2
                },
                method:"POST"
            }).then(res=>{
                if(res.code == 200){
                    this.hotList = res.rows
                }
            })
        },

        getInformationTRCList(){
            this.$axios({
                url:"/official/enterprise/getInformationTRCList?pageNum=1&pageSize=6&id=" + this.topicMess.id,
                method:"POST"
            }).then(res=>{
                console.log('推荐专题=>',res)
                if(res.code == 200){
                    this.topicListT = res.rows
                }
            })
        },
        goThematicList(){
            console.log("进来")
            this.$router.push({
                name:"thematicList"
            })
        }


    }
}
</script>

<style src="@/assets/css/pc/thematicDetails.css" scoped>

</style>