import homePage from '@/views/h5/home/index.vue'
import courseDetail from '@/views/h5/detail/courseDetail.vue'
import taskDetail from '@/views/h5/detail/taskDetail.vue'
import kongKim from '@/views/h5/home/kongKim.vue'
import homeSearch from '@/views/h5/home/search.vue'
import emptyH5 from '@/views/h5/emptyH5.vue'
import getAmap from '@/views/h5/detail/getAmap.vue'
let routerArray = [
  {
    path: '/homePage',
    name: 'homePage',
    component: homePage,
    meta: {
      mobile: true,
      metaInfo: {
        title: '兼职网首页',
        keywords: '兼职网首页',
        description: '兼职网首页',
      }
    },
  },
  {
    path: '/courseDetail',
    name: 'courseDetail',
    component: courseDetail,
    meta: {
      mobile: true,
      metaInfo: {
        title: '课程详情',
        keywords: '课程详情',
        description: '课程详情',
      }
    },
  },
  {
    path: '/taskDetail',
    name: 'taskDetail',
    component: taskDetail,
    meta: {
      mobile: true,
      metaInfo: {
        title: '线下兼职详情',
        keywords: '线下兼职详情',
        description: '线下兼职详情',
      }
    },
  },
  {
    path: '/kongKim',
    name: 'kongKim',
    component: kongKim,
    meta: {
      mobile: true,
      metaInfo: {
        title: '榜单',
        keywords: '榜单',
        description: '榜单',
      }
    },
  },
  {
    path: '/homeSearch',
    name: 'homeSearch',
    component: homeSearch,
    meta: {
      mobile: true,
      metaInfo: {
        title: '搜索',
        keywords: '搜索',
        description: '搜索',
      }
    },
  },
  {
    path: '/emptyH5',
    name: 'emptyH5',
    component: emptyH5,
    meta: {
      mobile: true,
      metaInfo: {
        title: '空',
        keywords: '空',
        description: '空',
      }
    },
  },
  {
    path: '/getAmap',
    name: 'getAmap',
    component: getAmap,
    meta: {
      mobile: true,
      metaInfo: {
        title: '地图',
        keywords: '地图',
        description: '地图',
      }
    },
  },
]

export default routerArray