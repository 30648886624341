<template>
<div>
</div>
</template>
<script>
export default {
  created () {
    this.redicturi();
  },
  methods: {
    redicturi() {
      // 重定向 URL
      let redirect_uri = encodeURI('http://test.jianzhinet.cn/#/qwAuthentication'); 
      // 【应用详情】-【回调配置】对应的 suite_id 和 suite_secret 信息
      const suite_id = 'wwbce3bfa061e4d802';
      // 授权链接 URL
      let login_url =`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${suite_id}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      // 向授权链接 URL 跳转
      window.location.href = login_url
    }
  },
}
</script>