<template>
    <!-- 成功页面 -->
    <div class="successClass">
        <!-- 顶部返回部分 -->
        <div class="topBack">
            <div class="backIcon" @click="backTo">
                <i class="el-icon-arrow-left"></i>
            </div>
            <span>报名成功</span>
        </div>
        <div class="success_card">
            <div class="card_top_line">
                <div style="margin-bottom: 3rem;">名额锁定成功</div>
                <div>复制添加联系方式，获取专属福利</div>
                <div class="topIcon">
                    <span style="margin-top: 0.3rem;background-color: #08bd75;">
                        <i class="el-icon-check"></i>
                    </span>
                </div>
                <div class="bottomIcon">
                    <span style="margin-top: 0.7rem;"></span>
                </div>
            </div>
            <div class="card_fulika">
                <div class="card_fulika_title">课程专属福利</div>
                <div class="grab_mid_flex">
                    <div>
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1679489185280one.png" />
                        <div>答疑互动</div>
                    </div>
                    <div>
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1679489225747two.png" />
                        <div>课程资料</div>
                    </div>
                    <div>
                        <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1679489256602three.png" />
                        <div>惊喜福利</div>
                    </div>
                </div>
            </div>
            <div class="callSj">
                <span>联系老师</span>
                <span>待完成</span>
            </div>
            <div class="callTs">请添加老师联系方式完成录取</div>
            <div class="callWx" v-show="wechatNum">
                <div>
                    <img src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680681577898wxx.png" />
                    <span>微信：{{ wechatNum }}</span>
                </div>
                <div @click="copyWx">复制添加</div>
            </div>
            <!-- 倒计时 -->
            <div class="timeOut">
                <div class="timeOut_one">
                    <van-count-down millisecond :time="times" :format="'mm:ss:SS'" @finish="finishTime" ref="timeOut">
                        <template #default="timeData">
                            <span class="block">{{ timeData.minutes | add0 }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ timeData.seconds | add0 }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ timeData.milliseconds | handlerMsd }}</span>
                        </template>
                    </van-count-down>
                    <span>后过期</span>
                </div>
                <div class="timeOut_two">对接兼职工作请尽快添加微信，以免过期</div>
            </div>
            <div class="wxcard" v-show="wechatUrl">
                <img :src="wechatUrl" alt="微信二维码" />
            </div>
            <div @click="copyWx" v-show="wechatUrl || wechatNum" class="addBtn">
                长按保存二维码添加老师微信
                <span class="addBtn_hot">+49%录取率</span>
            </div>
        </div>
        <div class="moduleTitle" style="width: 4.4rem;">
            <span class="moduleTitle_fst">相关推荐</span>
            <span class="moduleTitle_sed"></span>
        </div>
        <div class="elLoadingdiv" v-if="loadlist">
            <i class="el-icon-loading"></i> 加载中
        </div>
        <template v-else>
            <jobItem v-for="(item, index) in taskList" :key="index" :itemObj="item"></jobItem>
            <div class="noMoreClass">- 没有更多内容了哦 -</div>
        </template>
    </div>
</template>

<script>
export default {
    name:'Resuccessful-m',
    data() {
        return {
            wechatUrl: '',
            wechatNum: '',
            taskList:[],
            loadlist:false,
            times:1000*180
        }
    },
    created() {
        let user = JSON.parse(localStorage.getItem('userInfo'))
        let qy = this.$router.getParams()
        if (qy.id && user && user.id) {
            let pid = qy.id
            let uid = user.id
            this.getWxChat(pid, uid);
        }
        this.gitlist()
    },
    methods: {
        backTo() {
            this.$bus.$emit('refresh', true)
            this.$router.go(-1)
        },
        getWxChat(pid, uid) {
            if (!pid || !uid) {
                return false;
            }
            this.$axios({
                url: '/app/message/getAppWechatNum',
                params: {
                    id: pid,
                    userId: uid
                },
            }).then(res => {
                if (res.code == 200) {
                    this.wechatUrl = res.data.wechatUrl
                    this.wechatNum = res.data.wechatNum
                }
            })
        },
        copyWx() {
            // this.$JsCopy.iosCopy(this.wechatNum)
            this.$common.onCopy(this.wechatNum)
            this.$toast('微信号复制成功，请到微信上添加老师微信!')
        },
        //获取推荐列表
        gitlist() {
            this.loadlist=true
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.$axios({
                method: 'post',
                url: '/app/home/getLessonRecommendList',
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                data: {
                    pageNum: 1, //查询第几页
                    pageSize: 10,
                    // 默认算法
                    queryTypes: ["1", "0", "0", "0", "0", "0"],
                    positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
                    positionType: 1, //1兼职职位，2课程职位
                    userId: userInfo.id
                }
            }).then((res) => {
                this.loadlist=false
                console.log(res, "res555");
                if (res.code == 200) {
                    this.taskList = res.rows

                }
            })
        },
        // 倒计时结束
        finishTime(){
            this.$refs.timeOut.reset()
        },
    },
    filters: {
        add0(value){
            let num = value<10 ? '0'+value : value
            return num
        },
        handlerMsd(value) {
            let newval = (value / 1000) * 60
            newval = newval<10 ? '0'+newval : newval
            return Math.floor(newval);
        },
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box;
}

.successClass {
    width: 100vw;
    min-height: 100vh;
    background-color: #f6f5f8;
    padding: 2.75rem 0;
}

.topBack {
    width: 100vw;
    height: 2.75rem;
    line-height: 2.75rem;
    background-color: #ffffff;
    color: #141419;
    font-size: 1.13rem;
    font-weight: 600;
    font-family: "PingFang SC";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 1rem;
    z-index: 100;
}

.backIcon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.44rem;
}

/* 模块标题 */
.moduleTitle {
    height: 1.41rem;
    line-height: 1.41rem;
    display: inline-block;
    color: #333333;
    font-size: 1rem;
    font-weight: 600;
    font-family: "PingFang SC";
    text-align: left;
    letter-spacing: -0.02rem;
    position: relative;
    margin: 0 0 0.59rem 0.81rem;
}

.moduleTitle>span {
    display: inline-block;
}

.moduleTitle_fst {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    letter-spacing: 0.05rem;
}

.moduleTitle_sed {
    width: 100%;
    height: 0.38rem;
    background: linear-gradient(-90deg, #06ad941a 0%, #07bb7eff 83%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/* 内容 */
.success_card {
    width: 21.94rem;
    border-radius: 1.56rem;
    background-color: #ffffff;
    margin: 1rem auto 1.38rem;
    padding: 1rem 1.22rem;
}

.card_top_line {
    padding-left: 1.38rem;
    border-left: 0.06rem solid #e5e5e5;
    color: #333333;
    font-size: 1.13rem;
    font-weight: bold;
    font-family: "PingFang SC";
    line-height: 1.63rem;
    position: relative;
}

.topIcon,
.bottomIcon {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background-color: #ffffff;
    position: absolute;
    left: -1rem;
    text-align: center;
}

.topIcon {
    top: 0;
}

.bottomIcon {
    bottom: 0;
}

.topIcon>span,
.bottomIcon>span {
    width: 1.13rem;
    height: 1.13rem;
    line-height: calc(1.13rem - 2px);
    text-align: center;
    color: #ffffff;
    font-size: 0.8rem;
    border-radius: 50%;
    border: 1px solid #08bd75;
    display: inline-block;
}

.card_fulika {
    width: 18rem;
    border-radius: 0.94rem;
    background-color: #f6f7fb;
    margin: 0.56rem auto 0.75rem;
    padding: 0.75rem 0.88rem;
}

.card_fulika_title {
    color: #666666;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 1.63rem;
}

.grab_mid_flex {
    display: flex;
    justify-content: space-between;
    color: #666666;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 1.63rem;
    margin-top: 0.69rem;
}

.grab_mid_flex>div>img {
    width: 2.33rem;
    height: 2.43rem;
}

.callSj {
    display: flex;
    justify-content: space-between;
    color: #999999;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 1.63rem;
}

.callSj>span:first-child {
    color: #333333;
    font-size: 1rem;
    font-weight: bold;
}

.callTs {
    color: #999999;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    line-height: 1.63rem;
}

.callWx {
    display: flex;
    justify-content: space-between;
    margin: 1.19rem auto 1.5rem;
}

.callWx>div:first-child {
    color: #333333;
    font-size: 0.88rem;
    font-weight: 500;
    font-family: "PingFang SC";
    display: inline-block;
}

.callWx>div:first-child>img {
    width: 1.88rem;
    height: 1.88rem;
    margin-right: 0.38rem;
    vertical-align: middle;
}

.callWx>div:last-child {
    width: 4.38rem;
    height: 1.75rem;
    line-height: calc(1.75rem - 2px);
    border: 1px solid #07bd79;
    color: #07bd79;
    letter-spacing: 1px;
    font-size: 0.88rem;
    font-weight: 500;
    font-family: "PingFang SC";
    display: inline-block;
    text-align: center;
    border-radius: 0.3rem;
}

.wxcard {
    text-align: center;
}

.wxcard>img {
    width: 14.88rem;
    height: 20.63rem;
    border-radius: 0.3rem;
    margin: 0 auto;
    object-fit: contain;
}

.addBtn {
    width: 18.5rem;
    height: 3.13rem;
    line-height: 3.13rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    margin: 2.56rem auto 0;
    position: relative;
}

.addBtn_hot {
    position: absolute;
    top: -0.7rem;
    right: 0.42rem;
    width: 5.4rem;
    height: 1.38rem;
    line-height: calc(1.38rem - 2px);
    border: 1px solid #ffffff;
    border-radius: 0.88rem 0.88rem 0.88rem 0;
    background-color: #f5514a;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
}

.elLoadingdiv{
    height: 3rem;
    line-height: 2rem;
    text-align: center;
    color: #999999;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "PingFang SC";
}
.noMoreClass{
  height: 2rem;
  text-align: center;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}
.timeOut_one{
    display: flex;
    align-items: center;
    justify-content: center;
}
.timeOut_one>span{
    margin-left: 0.6rem;
    color: #999999;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "PingFang SC";
}
.timeOut_one .block{
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #ee0a24;
}
.timeOut_one .colon {
    display: inline-block;
    margin: 0 4px;
    color: #ee0a24;
}
.timeOut_two{
    text-align: center;
    line-height: 3;
    color: #333333;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "PingFang SC";
}
</style>
