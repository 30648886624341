<template>
  <div class="userys">
    <div class="content">
         <h4>兼职网平台隐私政策</h4> 
         
      
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >重要提示</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑"
              >兼职网（济南）信息技术有限公司（以下称</span
            >“兼职网”）</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >尊重并保护所有使用兼职网平台、以及由兼职网（济南）信息技术有限公司所有或运营的</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >兼职网</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >相关平台服务的用户（以下称</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）个人隐私与信息安全。鉴于此，兼职网公司制定本《兼职网平台个人信息保护政策》（以下称</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >本政策</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）与您确认关于您在使用兼职网公司提供的服务期间，兼职网公司将收集、存储、使用、披露和保护您的个人信息的相关事宜。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >本政策适用于兼职网公司提供的所有产品和服务。您访问我方平台，使用我方平台提供的服务，及未设独立个人信息保护政策的我方平台网站、服务器、及</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >或登录相关客户端，均适用本政策。如我们及关联公司的产品或服务中使用了兼职网公司提供的产品或服务</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >（例如使用兼职网平台账户登录）</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >但未设独立个人信息保护政策的，则本政策同样适用于该部分产品或服务。我们及关联公司就其向您提供的产品或服务单独设立有个人信息保护政策的，则相应产品或服务适用相应个人信息保护政策。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >兼职网平台深知个人信息对您的重要性，并会尽全力保护您对自身的个人信息的安全可控。我们致力于维持您对我们的信任，保护您的个人信息，恪守合法、正当、必要和诚信的个人信息处理原则。同时，兼职网平台承诺，采取相应的安全保护措施来保护您的个人信息。兼职网公司特别提请未满</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >18</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >周岁的未成年人（特别是未满十四周岁的儿童）及其监护人的注意，我们已设置未成年人保护专门章节</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们如何保护未成年人的信息</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >，请您仔细阅读。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >需要特别说明的是，本政策不适用于其他任何第三方向您提供的服务，第三方向您提供的服务适用其向您说明的个人信息保护政策。例如兼职网平台上的知识工作者（以下也称</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >服务商</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >或</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >商家</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）依托兼职网平台向您提供服务时，您向知识工作者提供的个人信息不适用本政策。故我们在此善意提醒您，您在向知识工作者</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >服务商提供信息时，应保持合理警惕，谨慎提供您的个人信息。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >在使用兼职网公司提供的各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >粗体下划线标识的条款，您应重点阅读、确保充分理解。本政策经您自主自愿点击确认同意后具有合同效力，故请您谨慎阅读并遵照执行。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >如对本政策内容或者对我们如何处理您的个人信息有任何疑问、意见或建议，您可通过本政策第十节提供的方式与我们联系。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >本政策将帮助您了解以下内容：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >一、本政策下的术语和定义</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >二、我们如何收集和使用您的个人信息</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">三、我们如何使用</span> Cookie
          和同类技术</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >四、我们如何共享、转让、转移、公开披露您的个人信息</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >五、我们如何存储您的个人信息</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >六、我们如何保护您的个人信息安全</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >七、您如何管理您的信息</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >八、我们如何保护未成年人的信息</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >九、我们如何更新个人信息保护政策</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >十、您可以通过何种方式联系我们</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >十一、法律效力与协议管辖</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >一、定义</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们的产品与</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >或服务</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >：包括但不限于我们提供的软件、网站、服务以及包含的相关产品或服务功能。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >个人信息</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >：指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处</span
          >
          <span style="font-family: 微软雅黑">理后的信息。</span></span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >3、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >敏感个人信息</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >：一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危</span
          >
          <span style="font-family: 微软雅黑">害的个人信息，包括</span></span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >等信息，以及</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              ><span style="font-family: 微软雅黑">不满十</span>
              <span style="font-family: 微软雅黑"
                >四周岁未成年人的个人信息。</span
              ></span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >敏感个人信息在本政策中会进行加粗和下划线提示。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >4</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >匿名化处理</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >：</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >指个人信息经过处理无法识别特定自然人且不能复原的过程。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >5、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >删除</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >6</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >兼职网平台（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >：</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >指兼职网平台关联版本。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >二、我们如何收集和使用您的个人信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">根据相关法律法规要求，</span>
          <span style="font-family: 微软雅黑"
            >我们将根据向您提供产品或服务基本功能所需，收集必要范围内的个人信息，如您拒绝提供，您可能无法正常使用我们的产品或服务；此外，您可以主动选择使用我们的产品或服务的附加功能并授权我们收集个人信息，如您拒绝提供，仅可能无法为您提供该附加功能而不会影响您使用基本功能。此外，本政策所指必要范围个人信息是指消费侧用户个人信息，而不包括服务供给侧用户个人信息。</span
          ></span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您理解并同意：</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >1</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、兼职网平台属于网络服务平台。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >服务范围存在差异，基本</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >附加功能及收集使用的个人信息类型、范围等可能会有所区别，请您以具体的产品</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >服务功能为准；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、我们如所有互联网企业一样持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗或者页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的机会，且在征得您明示同意后继续收集、使用。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >3</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、针对下述收集和使用个人信息的具体功能场景，如您拒绝提供该场景下我们所需的个人信息的，将可能导致您无法使用该功能或者影响您的使用效果，但不影响您使用兼职网平台提供的其他功能或者服务。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">（一）</span>
            <span style="font-family: 微软雅黑">我们仅会出于以下目的</span></span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >，</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >收集和使用您的个人信息</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >1</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、帮助您成为我们的用户</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >1</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）账户注册与登录</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们为您提供基于用户账户的兼职网平台服务。当您主动选择使用我们部分服务并根据页面引导注册成为兼职网平台账户</span
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >时，</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您可能需要向我们提供您的手机号码、短信验证码并设置</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >密码</span
            ></span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">您使用第三方平台账号（微信</span
          >/微博/QQ账号）登录时，为了满足法律规定的网络实名制要求，在您使用发布、评论及其他要求实名认证的功能与服务前，我们将另行收集您的手机号码以完成实名认证。第三方平台将同步一部分信息给我们，如头像、昵称或ID。如您拒绝授权，您将无法通过第三方平台账号登录兼职网平台，但这不影响其他产品和服务的正常使用。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）账户信息维护</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您可以进入账号设置修改或补充您的昵称、头像、性别、</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >地址</span
            ></span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、邮箱、手机号码以及您的身份验证信息，这些信息均属于您的</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >账户信息</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >。您补充的账户信息将有助于我们为您提供个性化的商品推荐和更优的服务体验，但如您不提供此类信息，不会影响您使用本产品其他相关服务。为保证交易辨识度，您的昵称、头像将公开显示。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >3</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）账号实名认证</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >根据部分法律法规要求（如《电子商务法》第二十七条规定的经营者真实信息登记核验义务），当我们需要对您进行实名认证以进一步确保交易安全的相关业务场景中，您可能需要向我们提供相应的个人身份信息（身份证照或身份证信息）、生物识别特征（静态或动态的面部特征）。根据您选择的认证方式，需要的信息会有所不同。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >人脸识别认证：需要身份证照、生物识别特征。个人银行卡认证：需要姓名、身份证号、银行卡号、银行预留手机号。手持证件认证：需要姓名、身份证号、手持身份证照。企业实名认证：需要营业执照、法人身份证照、法人手机号（选用）。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >上述信息我们将用于平台违规调查、依法配合司法机关取证或者其他经您明确单独授权使用的场景。请您知悉，在部分交易场景下或者基于您的服务提供商身份，提交您的上述信息是法律法规和维护交易安全环境所必要的个人信息。</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们深知上述信息对您的重要性，因此我们将在采取严格的措施的前提下，对您基于实人认证所提交的个人信息进行人工审核对比，并承诺仅在维护交易安全的目的下进行内部存储和使用。部分功能需要完成实名认证方可使用（例如支付账户管理、电子合同等），您可自主选择适合您的认证方式。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、为您发送必要的通知或者交易消息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >为了向您进行必要的消息告知、身份验证安全验证，我们可能会向您收集一种或多种联系方式（电子邮箱、联系电话、传真号码、</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >联系地址</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >、账号站内信），并且可能会在运营过程中向您通过上述方式发送消息通知。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >3</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、为您提供商品或服务信息展示</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >为了向您提供商品或服务内容推荐展示功能，我们可能会收集以下信息。请注意，以下信息在去标识化处理后，属于无法识别特定自然人身份的信息。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold; font-size: 16px"
          >（1）</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >设备信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们可能会收集您所使用的设备信息（包括设备名称、设备型号、</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >MAC</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >地址、</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >IMEI</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、软件列表、唯一设备标识符、操作系统版本和应用程序版本、设备配置、设备环境软硬件特征信息）、设备所在位置相关信息（包括您授权的</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >GPS</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >位置以及</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >WLAN</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >接入点、蓝牙和基站传感器信息）。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >请您注意，单独的设备信息、浏览器类型或仅搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的单独的设备信息、浏览器类型或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的设备信息、浏览器类型或搜索关键词信息作为您的个人信息，按照本隐私政策对其进行处理与保护。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）服务记录</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们可能会通过日志方式（操作日志、服务日志）对您在兼职网平台的操作进行记录、存储，包括浏览、点击查看、搜索查询、收藏、</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >交易</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >、售后、关注分享信息、发布信息，以及</span
          >IP地址、浏览器类型、访问日期和时间。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们收集这些信息是为了向您提供符合您需求的个性化服务展示，如您拒绝提供上述权限将可能导致您无法使用个性化服务展示功能。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >4</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、为您提供搜索功能</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >当您使用搜索功能时，我们可能需要收集您的如下信息：</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >搜索关键词、</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >浏览记录</span
            ></span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >及时间、搜索时间以及与其互动的次数。我们收集这些信息主要用于向您提供您所需要的内容和可能更感兴趣的服务，同时改进我们的商品和服务。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >5</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、为您提供信息查询／咨询功能</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >您在兼职网产品的使用过程中，如需享受信息查询／咨询服务，需在服务提供页面提供</span
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >您</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >的需求内容与本人联系方式，由兼职网平台客服处理。我们收集这些信息是为了提供您所需要的信息查询、服务咨询、售前售后咨询内容。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >6</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、帮助您完成下单及订单管理</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >为</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务，您在兼职网平台下单时，需提供您的需求内容、本人联系方式、</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >订单信息</span
            ></span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（包括交易名称、交易号、支付金额、交易时间），同时该订单支付页面中会展示您的</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >交易名称、交易号以及您应支付的金额</span
            ></span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >。若您未注册兼职网平台账户，在您下单过程中，平台将自动为您注册兼职网平台账户。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">您可以通过您的后台查看</span
          >“我的订单”，为便于您了解查询订单信息并对订单信息进行管理。当您在工具市场选择好对应的工具产品后，即可打开购买页面进行工具产品下单，下单成功并完成支付后，该工具产品购买成功。因工具产品均为线上产品，购买成功的产品会按照产品约定直接生效。您已知晓并同意，工具市场的产品在购买后会按照最初设定的产品约定进行生效。您所购买的工具产品均可以在“我的工具”页面查看该工具产品的购买记录，</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >包括购买的工具名称、订购时间和到期时间。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >7、帮助您完成支付</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >当您在兼职网平台下单后，您可以选择兼职网平台的关联公司或与兼职网平台合作的第三方支付机构（包括支付宝支付、微信支付及银联、网联等支付通道，以下称</span
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >支付机构</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >）所提供的支付服务。为了协助上述支付机构确认您的支付指令并完成支付，我们可能会将您的兼职网平台</span
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >订</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >单号与交易金额</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >信息在必要范围内与他们共享。请您知悉，此时上述信息的共享是必要的，如您拒绝可能无法完成交易订单的顺利支付。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >8</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、为您提供交付产品及服务功能</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >如您以雇主身份使用兼职网平台，当您下单并进入后续订单服务和交付环节时，为了帮助您顺利完成订单服务，我们可能会将您的个人姓名、兼职网平台账号、订单需求信息与兼职网平台服务商在达成订单服务目的的必要范围内共享，用于兼职网平台服务商通过虚拟号码和您取得联系并沟通订单进展、交付事宜。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >如您以服务商身份使用兼职网平台，您在兼职网平台上传的文件将会向交易对方（如雇主）进行展示，以便其与您顺利开展交易。请您知悉，兼职网平台将会储存您上传的文件，如其中涉及部分个人信息的，您应当确保已经取得个人信息主体的同意。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >9</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、为您提供客服及争议处理服务</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >为便于与您联系，尽快帮助您解决问题或记录相关问题的处理方案及结果，</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们可能会需要收集如下个人信息：兼职网平台账号，联系方式（包括电话号码、电子邮箱或您向我们主动提供的其他联系方式），您与我们或您与平台上合作商家或者其他方的</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >沟通信息（包括文字</span
            ></span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span
              style="
                font-family: 微软雅黑;
                line-height: 21px;
                color: rgb(38, 38, 38);
                font-size: 16px;
              "
              >/</span
            ></span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >图片</span
            ></span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span
              style="
                font-family: 微软雅黑;
                line-height: 21px;
                color: rgb(38, 38, 38);
                font-size: 16px;
              "
              >/</span
            ></span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >音视频</span
            ></span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span
              style="
                font-family: 微软雅黑;
                line-height: 21px;
                color: rgb(38, 38, 38);
                font-size: 16px;
              "
              >/</span
            ></span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >通话记录），订单信息</span
            ></span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >当您要求我们协助处理交易退款、交易纠纷、发票相关、</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >资金往来</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >、账号注销等，您有可能会在与我们的客服人员沟通时，提供上述信息外的其他信息。例如，为了确定您的身份，可能会需要您使用账号的认证手机来电，或者需要您提供认证手机的入网证明。</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们收集这些信息是为了调查平台上发生的订单交易事实，帮助您解决问题，如您拒绝提供可能导致我们的客服无法为您快速地处理纠纷、提供客户服务。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >10、为您提供安全保障</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >为了履行网络安全法定义务，保持兼职网平台信息系统正常运转，基于反欺诈和反黑产合法目的，我们可能会收集您的</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >浏览信息</span
            ></span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >、</span
          ></strong
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >订单信息</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >、您常用的软件信息、设备信息（设备唯一标识符），来综合判断您的账号风险，并甄别利用平台进行的恶意操作（包括但不限于刷单、套现、</span
          >“薅羊毛”等）。同时可能会记录一些我们认为有风险的链接，我们也会收集访问流量以排查可能存在的风险。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >11</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、调取设备权限为您提供其他服务</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >为向您提供更便捷、更优质、个性化的产品或服务，努力提升您的体验，我们在其他服务中可能会收集和使用您的个人信息。</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >如果您不同意开启相关权限，不会影响您使用兼职网网的基本服务</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >，</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >但您可能无法获得这些其他服务给您带来的用户体验。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >12</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、征得授权同意的例外</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您充分理解并同意，基于法律法规和相关规范要求，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >修改、删除、注销、撤回同意、索取信息的请求：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >1</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）与个人信息控制者履行法律规定的义务相关的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）与国家安全、国防安全直接相关的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >3</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）与公共安全、公共卫生、重大公共利益直接相关的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >4</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）与刑事侦查、起诉、审判和判决执行等直接相关的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >5</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >6</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）所涉及的个人信息是个人信息主体自行向社会公众公开的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >7</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）根据个人信息主体要求签订和履行合同所必需的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >8</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >9</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）维护所提供的产品或服务的安全稳定运行所必需的，如发现、处置产品及</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >/</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >或服务的故障；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >10</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >11</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >13</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >、其他</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >1</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得了个人信息主体的有效、真实的同意。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第九条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >3</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的均会事先获得您的同意。此外，若我们涉及从任何第三方间接收集您的信息的，我们均将要求他们已事前获得您的明确有效同意。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            ><span style="font-family: 微软雅黑">（</span>4）</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您知悉并同意，您在平台的部分交易行为将经处理后生成数据摘要，并将数据摘要上传到兼职网服务台，用于后续争议纠纷处理、版权保护。</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >同样地，您关于争议维权和版权保护的数据也将匿名化处理后上传。我们承诺上传的数据中不包含可以直接识别或者关联您个人身份的敏感信息。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >三、我们如何使用</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >&nbsp;Cookie
          </span></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >和同类技术</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（一）</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >&nbsp;Cookie</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、为实现您体验的个性化需求，使您获得更好的体验。我们会在您的计算机上发送一个或多个名为Cookies的小数据文件，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的浏览偏好等数据进而为您提供偏好选择、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、我们不会将Cookies
          用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">（二）</span>
            <span style="font-family: 微软雅黑">网站信标和像素标签</span></span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">除</span
          >Cookie外，我们还可能会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至公司网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（三）</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >&nbsp;Do Not Track</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（请勿追踪）</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">很多网络浏览器均设有</span>Do Not
          Track功能，该功能可向网站发布Do Not
          Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do
          Not Track，那么公司的所有网站都会尊重您的选择。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >四、我们如何共享、转让、转移、公开披露您的个人信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">（一）</span>
            <span style="font-family: 微软雅黑">信息的共享</span></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >除下列情形外，我们不会与任何其他第三方共享您的个人信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >1</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">、</span>
            <span style="font-family: 微软雅黑"
              >在获得您的明确同意或授权或您主动选择的情况下共享</span
            ></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >获得您的明示授权或明确同意后，我们会与其他公司、组织和个人分享您的个人信息。例如，如果我们委托第三方（如服务商）向您提供产品或服务，我们会在征得您明示同意后将上述信息共享给第三方，如您拒绝提供此类信息，我们将无法完成相关交付服务。只有取得您的单独明示同意，我们才会共享您的敏感个人信息。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">、</span>
            <span style="font-family: 微软雅黑"
              >向我方的关联方分享您的个人信息</span
            ></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >为便于我们基于关联账号共同向您提供服务，便于您快速达成平台订单交易或者推荐您可能感兴趣的信息，同时保护我方关联公司或其他用户或公众的人身财产安全免遭侵害，在经过您的授权同意后，我们可能会将您的部分个人信息在达成上述目的的必要范围内与我们的关联公司共享。我们的关联公司可能会通过电子邮件、站内信、手机短信和传真等方式向您发送服务状态的通知、营销活动及其他商业性电子信息。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们只会共享必要的个人信息（如为便于您使用我方账号登录并使用我们关联公司产品或服务，我们会向关联公司共享您必要的个人信息），且受本个人信息保护政策中所声明目的的约束。如果我们共享您的敏感个人信息或关联公司改变个人信息的使用及处理目的，将再次征求您的单独明示同意。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span style="font-family: 微软雅黑; font-weight: bold; font-size: 16px"
          >3、</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >与授权合作伙伴共享</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >您，我们仅会出于本隐私信息保护政策授权同意我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到为其履行职责所需信息，且不得将此信息用于其他任何目的。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >目前，我们的授权合作伙伴包括以下类型：</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(255, 0, 0);
            font-size: 16px;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >2</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）兼职网平台服务商</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">实现您购买产品</span
          >/服务的需求（包括享受售后服务），我们会将您的</span
        ><strong
          ><span style="text-decoration: underline"
            ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
              >订单信息与交易相关的信息</span
            ></span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >共享给我们平台的第三方商家。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >4</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）与我们共同提供某项服务的合作伙伴／企业／机构</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >为解决您的需求，实现业务办理，我们需要向业务报备审核、开通业务的机构（包括银行、政府机构、政府园区）、企业服务外包（注册、服务、人力资源）提供您的姓名、联系方式、身份信息、公司信息。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >5</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >）第三方</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >SDK</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >类服务商</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >为了使您能够接收信息推送、在第三方平台分享信息、支付服务等功能，我们的应用中会嵌入授权合作伙伴的</span
          >SDK或其他类似的应用程序。如您使用华为/小米/魅族/VIVO/OPPO手机时，华为/小米/魅族/VIVO/OPPO推送SDK需要读取您的硬件型号、操作系统版本、设备配置、唯一设备识别符，用于推送/下发通知栏消息。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >请您理解，除上述我们可能间接收集您的个人信息之外，第三方</span
          >SDK服务商行为遵循其自身的隐私条款而不适用于本政策，但我们也会努力审查该第三方的业务准入资质并努力要求该等服务商的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >4</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">、</span>
            <span style="font-family: 微软雅黑">在法定的情形下共享</span></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。例如，在您违反有关法律法规、本声明、您与兼职网平台签署相关声明时，向您的交易对手、兼职网平台及兼职网平台关联公司的合作伙伴等第三方提供，包括但不限于用于催收、诉讼等。又如，为建立信用体系，向中国人民银行个人信用信息基础数据库及信息服务机构或其他依法设立的征信机构发送您的信用信息和数据。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">（二）</span>
            <span style="font-family: 微软雅黑">信息的转让</span></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（三）公开披露</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >我们仅会在以下情况下，公开披露您的个人信息：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、获得您明确同意后；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，且不再另行通知您；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >3、基于其他平台用户或者任何第三人的申请、并提供初步证据的披露：当您和平台其他用户发生法律纠纷，或任何第三人认为您的行为侵犯其合法权益，意欲提起法律诉讼时，在申请人提供基本证据后，我们可能会公开披露您的个人信息，同时我们会保留申请人披露记录，以备您随时查询您个人信息的披露情况。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（四）共享、转让、披露个人信息时实现征得授权同意的例外</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、与个人信息控制者履行法律法规规定的义务相关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、与国家安全、国防安全直接相关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >3、与公共安全、公共卫生、重大公共利益直接相关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >4、与刑事侦查、起诉、审判和判决执行直接相关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >5、出于维护您或其他个人的生命、财产重大合法权益但又很难得到本人授权同意的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >6、您自行向社会公众公开的个人信息；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开渠道。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >如您不同意我们按照上述条款向第三方提供您的个人信息，请您立即停止使用我方平台服务。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >五、我们如何存储您的个人信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（一）信息存储的地点：</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1.法律法规有明确规定；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2.获得您的明确授权。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >针对以上情形，我们会确保依据本政策对您的个人信息提供足够的保护。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（二）信息存储期限：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。而我们判断前述期限的标准包括：</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、保证我们为您提供服务的安全和质量；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >3、您是否同意更长的留存期间；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >4、法律法规另有规定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；如《网络交易监督管理办法》对平台内经营者身份信息的保存时间自其退出平台之日起不少于三年。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >在您的个人信息超出保留期限后，我们会根据适用法律法规的要求删除您的个人信息，或使其匿名化处理。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（三）信息删除和匿名化处理</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >如本平台服务决定停止运营，我们将在相关服务停止运营后停止继续收集您的个人信息。我们将以公告、站内信、短信、邮件等方式将停止运营通知向您送达，并依法对已采集的个人信息进行删除或匿名化处理。涉及儿童个人信息的，我们会将停止运营的通知及时告知儿童监护人。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（四）数据存储服务提供方</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >您的信息存储于中国的服务器上，为了安全及备份的需要，兼职网平台可能将您的信息和资料储存到兼职网平台旗下具备相应安全措施的公司的服务器上。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >六、我们如何保护您的个人信息安全</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（一）技术安全防护</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >为保障您的信息安全，我们采取行业通行的各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于</span
          >SSL、信息加密存储、数据中心的访问控制、专用的网络通道及代理。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（二）安全体系保证</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，为提高您使用我们、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或我方平台相关协议规则的情况，我们对系统进行了等级保护。目前，我们的重要信息系统已经通过网络安全等级保护的三级以上测评。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（三）人员安全管理</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密声明，监控他们的操作情况等措施。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（四）个人信息安全管理机构</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们专门成立了信息安全委员会，并建立相应的安全管理制度、流程安全以保障信息的安全。如：严格限制访问信息的人员范围，签订关键岗位保密协议，定期进行审计等。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（五）信息安全事件管理</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >如不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、电话、推送通知、短消息的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >（</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >六）信息披露提示及密码泄露处理</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >尽管如此，请您注意互联网并非绝对安全的环境。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络我方客服，以便我方采取相应措施。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >七、您如何管理您的个人信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（一）您可以查询、更新、删除您的个人信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑"
            >平台为您提供个人信息自助查询、更新、删除信息的功能，部分个人信息的删除功能需要您通过注销账</span
          >
          <span style="font-family: 微软雅黑">号实现。同时您也可通过</span></span
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >兼职网</span
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >在线客服</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >要求提供相关信息的查询、更新、删除服务。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >除上述外，有下列情形之一的，我们将应当主动删除您的个人信息，您也有权请求我们删除：</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">（</span
          >1）处理目的已实现、无法实现或者为实现处理目的不再必要；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">（</span
          >2）我们已停止提供产品或者服务，或者保存期限已届满；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">（</span>3）您已明确撤回同意；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">（</span
          >4）如您有足够的理由认为我们违反法律、行政法规或者违反约定处理您的个人信息；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          ><span style="font-family: 微软雅黑">（</span
          >5）法律、行政法规规定的其他情形。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（三）您可以申请注销个人信息主体账户</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >如您注销账号，请按照兼职网平台相关规定妥善处理资金、发票及在途交易等事宜。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >在注销账户之后，我们将停止为您提供产品和服务，并对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >请您注意，当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            ><span style="font-family: 微软雅黑">（四）</span>
            <span style="font-family: 微软雅黑">约束信息系统自动决策</span></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >在某些业务场景中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式，但法律法规另有规定的除外。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（五）对账户信息的生前安排</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >根据相关法律要求，您可以提前就您存储于兼职网平台上的个人信息提前进行生前安排，您可以通过本政策公布的联系方式，通过书面方式告知我们您的安排；如无另行安排，您的近亲属可以基于其合法、正当利益，对您生后的相关个人信息依照相关法律规定和本政策的约定，行使查阅、复制、更正、删除等权利。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（六）响应您的上述请求</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、与国家安全、国防安全有关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、与公共安全、公共卫生、重大公共利益有关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >3、与犯罪侦查、起诉和审判等有关的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >4、有充分证据表明您存在主观恶意或滥用权利的；</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >八、我们如何保护未成年人的信息</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >我们重视未成年人的个人信息保护。</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >请您知悉，通常情况下，兼职网平台不面向未满十四周岁的未成年人提供产品、网站和服务。根据商业惯例，除非您提交的实人认证信息或者结合其他信息（如浏览、搜索记录等）可以识别出您为未成年人的情形外，在电子商务活动中，我们将推定您具有相应的民事行为能力。如您为已满十四周岁但未满十八周岁的未成年人，请您和您的父母或其他监护人共同仔细阅读本政策，并在您的父母或其他监护人监督的前提下使用我们的服务或向我们提供信息。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >根据《儿童个人信息网络保护规定》以及相关可适用的法律法规，如果我们识别出您是不满十四周岁的儿童用户的，我们将通知您的父母或者监护人并只有经其同意后才允许您继续使用兼职网产品或服务。当我们未和您的父母或者监护人取得联系或者其不同意我们关于儿童个人信息收集使用规则的，我们将尽快删除相关数据。对于经父母或其他监护人同意使用我们的产品或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span
          ></strong
        ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >若您是未成年人的父母或其他监护人，请您关注您监护的未成年人是否是在取得您的授权同意之后使用我们的服务的。如您对您所监护的未成年人的个人信息有疑问，请通过第十节中的联系方式与我们联系。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >九、本《兼职网平台个人信息保护政策》的更新</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          text-indent: 28px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >兼职网平台可能会对本政策进行变更，但未经您明确同意，我们不会削减您按照本隐私保护政策所应享有的权利。</span
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >如发生变更，兼职网平台将依据《兼职网平台服务协议》中有关通知事项的约定方式通知您，该变更将在公示期满后即时生效。若您在本政策变更后继续访问兼职网平台的网站，或使用兼职网平台提供的任一服务，则表示您已充分阅读、理解并接受修改后的本政策并受之约束</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >。</span
          ></strong
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >&nbsp;</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >十、如何联系我们</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >在线客服</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >与我们联系。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >2、一般情况下，我们将在</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >7</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</span
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >十一、法律效力与协议管辖</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >（一）您应认真阅读并充分理解本政策所有内容（特别是加粗字体内容）的意义以及由此产生的法律效力，自愿同意本政策。一旦您在线点击</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >“</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >同意</span
          ></strong
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 21px;
              color: rgb(38, 38, 38);
              font-size: 16px;
            "
            >”</span
          ></strong
        ><strong
          ><span style="font-family: 微软雅黑; line-height: 21px; font-size: 16px"
            >本政策即视为该等行为是您本人真实的意思表示，您同意承担由此带来的一切法律后果。</span
          ></strong
        >
      </p>
      <p
        style="
          margin: 10px 0;
          font-family: Calibri;
          font-size: 16px;
          line-height: 21px;
        "
      >
        <span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >（二）因您与兼职网平台基于本政策的相关服务而产生争议，双方一致同意提交</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >山东省济南市</span
        ><span
          style="
            font-family: 微软雅黑;
            line-height: 21px;
            color: rgb(38, 38, 38);
            font-size: 16px;
          "
          >仲裁委员会按照其仲裁规则进行裁决。</span
        >
      </p>
      <p>
        <br />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      currentHref:""
    }
  },
    created(){
    this.currentHref=window.location.href
    console.log(this.currentHref)
  },
}
</script>
<style scoped>
.content {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}
.content h4 {
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  margin: 30px auto;
}
.content p {
  line-height: 35px;
  color: #333;
  font-family: 微软雅黑;
}
</style>