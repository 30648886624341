<template>
  <!-- h5首页顶部区域 -->
<div class="hometop">
  <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item v-for="(item,index) in bannerList" :key="index">
      <div class="swiperItem" @click="swiperClick(item)">
        <img :src="item.url" alt="banner">
        <div v-show="item.videoUrl" class="mask_video">
          <span class="videoBtn"><i class="el-icon-caret-right"></i></span>
        </div>
      </div>
    </van-swipe-item>
  </van-swipe> -->
  <div class="jgqClass">
    <img v-for="(item,index) in bdlist" :key="item.id" @click="toList(index)" :src="item.icon" />
  </div>
</div>
</template>

<script>
import setSet from "@/assets/js/setSet.js"
export default {
  data() {
    return {
      bdlist:[],
      bannerList:[],
    }
  },
  created () {
    this.getBannerList()
    this.getJingangList();
  },
  methods: {
    swiperClick(item){
      if(item.videoUrl){
        this.$emit('videoClick',item.videoUrl)
      }else if(item.jumpUrl){
        let url = item.jumpUrl.split('?')
        let obj={}
        if(url[0].indexOf('/')==0){
          url[0]=url[0].slice(1)
        }
        if(url.length==2){
          url[1].split('&').forEach(el=>{
            let p = el.split('=')
            obj[p[0]]=p[1]
          })
        }
        this.$router.push({name:url[0],params:obj})
        // this.$router.push(item.jumpUrl)
      }
    },
    // 获取金刚区
    getJingangList() {
      this.$axios({
        url: "/jlApplets/essayRegion/list",
        params:{
          pageNum:1,
          pageSize:10,
          appId:setSet.appid
        }
      }).then((res) => {
        if (res.code == 200) {
          this.bdlist = res.rows
        }
      })
    },
    // 获取轮播图数据
    getBannerList() {
      this.$axios({
        url:"/jlApplets/banner/list",
        params: {
          pageNum:1,
          pageSize:10,
          appId:setSet.appid
        },
      })
        .then((res) => {
          if (res.code === 200) {
            this.bannerList = res.rows;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toList(item){
      // console.log(23423432,this.bdlist)
      // window.localStorage.setItem('distritDetail',item);
      // window.localStorage.setItem('essayRegionListlist',this.bdlist);
      this.$router.push({name:'kongKim-m',params:{index:item,essayRegionListlist:this.bdlist}})
      // this.$router.push({name:'kongKim-m'});
    },
  },
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.hometop{
  display: flex;
  justify-content: space-around;
  padding: 0 0.2rem;
}
.my-swipe{
  width:50.68%;
  height: 6.94rem;
  border-radius: 0.38rem;
}
.my-swipe .van-swipe-item {
  height: 6.94rem;
  color: #fff;
  font-size: 20px;
  line-height: 6.94rem;
  text-align: center;
  overflow: hidden;
}
.my-swipe /deep/ .van-swipe__indicators>i{
  width: 1.63rem;
  height: 0.13rem;
}
.jgqClass{
  width: 92%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.jgqClass>img{
  width: 24.41%;
  height: 3.38rem;
}
.swiperItem{
  width: 100%;
  height: 100%;
  position: relative;
}
.swiperItem>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mask_video{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.videoBtn{
  width: 1.62rem;
  height: 1.62rem;
  line-height: 1.62rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.62rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>