import about from '@/views/pc/about.vue'
import homePage from '@/views/pc/homePage.vue'
import informationHome from "@/views/pc/informationHome.vue";
import informationDetail from "@/views/pc/informationDetail.vue";
import enterprise from "@/views/pc/enterprise.vue"; //企业服务
import PartTJ from "@/views/pc/PartTJ.vue"//兼职
import positionDetail from "@/views/pc/positionDetail" //兼职详情 本地兼职
import enterpriseDetail from "@/views/pc/enterpriseDetail.vue"; //企业详情
import websiteMap from "@/views/pc/websiteMap.vue";//网站地图
import contact from "@/views/pc/contact.vue" //联系我们
import empty from "@/views/pc/empty.vue" //空白页
import thematicList from "@/views/pc/thematicList.vue"  //专题列表页面
import thematicDetails from "@/views/pc/thematicDetails.vue"//专题详情页面
import login from "@/views/pc/login.vue"//登录页面
import userxy from "@/views/pc/xieyi/userxy.vue";//用户协议
import userys from "@/views/pc/xieyi/userys.vue";//隐私协议
import hotCity from "@/views/pc/hotCity.vue" //热门城市
// import cooperation from "@/views/pc/cooperation.vue" //商务合作

const routerArray = [
    {
        path: '/about',
        name: 'about',
        component: about,
        meta:{
            metaInfo:{
                title:'关于我们',
                keywords:'pcabout',
                description:'pcabout',
            }
        },
    },
    {
        path: '/',
        name: 'homePage',
        component: homePage,
        meta:{
            metaInfo:{
                title:'首页',
                keywords:'pcHomePage',
                description:'pcHomePage',
            }
        },
    },
    {
        path: '/informationHome',
        name: 'informationHome',
        component: informationHome,
        meta:{
            metaInfo:{
                title:'资讯',
                keywords:'pcinformationHome',
                description:'pcinformationHome',
            }
        }
    },
    {
        path: '/informationDetail',
        name: 'informationDetail',
        component: informationDetail,
        meta:{
            metaInfo:{
                title:'pcinformationDetail',
                keywords:'pcinformationDetail',
                description:'pcinformationDetail',
            }
        }
    },
    {
        path:"/enterprise",
        name:"enterprise",
        component: enterprise,
        meta: {
            metaInfo:{
                title: "万企合作",
                keywords: "enterprise",
                description: "enterprise"
            }
        }
    },
    {
        path:"/PartTJ",
        name:"PartTJ",
        component: PartTJ,
        meta: {
            metaInfo:{
                title: "兼职",
                keywords: "pcPartTJ",
                description: "pcPartTJ"
            }
        }
    },
    {
        path:"/positionDetail",
        name:"positionDetail",
        component: positionDetail,
        meta:{
            metaInfo: {
                title: "positionDetail",
                keywords: "positionDetail",
                description: "positionDetail"
            }
        }
    },
    {
        path:"/enterpriseDetail",
        name:"enterpriseDetail",
        component: enterpriseDetail,
        meta:{
            metaInfo: {
                title: "enterpriseDetail",
                keywords: "enterpriseDetail",
                description: "enterpriseDetail"
            }
        }
    },
    {
        path:"/websiteMap",
        name:"websiteMap",
        component: websiteMap,
        meta:{
            metaInfo: {
                title: "网站地图",
                keywords: "websiteMap",
                description: "websiteMap"
            }
        }
    },
    {
        path:"/contact",
        name:"contact",
        component: contact,
        meta: {
            metaInfo:{
                title: "联系我们",
                keywords: "contact",
                description: "contact"
            }
        }
    },
    {
        path: '/empty',
        name: 'empty',
        component: empty,
        meta:{
            metaInfo:{
                title:'pcempty',
                keywords:'pcempty',
                description:'pcempty',
            }
        }
    },
    {
        path:"/thematicList",
        name:"thematicList",
        component: thematicList,
        meta: {
            metaInfo: {
                title: "资讯专题",
                keywords: "thematicList",
                description: "thematicList"
            }
        }
    },
    {
        path: "/thematicDetails",
        name: "thematicDetails",
        component: thematicDetails,
        meta: {
            metaInfo: {
                title: "专题详情",
                keywords: "thematicDetails",
                description: "thematicDetails"
            }
        }
    },
    {
        path:"/login",
        name: "login",
        component: login,
        meta: {
            metaInfo: {
                title: "登录",
                keywords: "login",
                description: "login"
            }
        }
    },
    {
        path: "/userxy",
        name: "userxy",
        component: userxy,
        meta: {
            metaInfo: {
                title: "userxy",
                keywords: "userxy",
                description: "userxy"
            }
        }
    },
    {
        path: "/userys",
        name:"userys",
        component: userys,
        meta: {
            metaInfo: {
                title: "userys",
                keywords: "userys",
                description: "userys"
            }
        }
    },
    {
        path:"/hotCity",
        name:"hotCity",
        component:hotCity,
        meta:{
            metaInfo:{
                title:"热门城市",
                keywords:"hotCity",
                description:"hotCity"
            }
        }
    },
    // {
    //     path:"/cooperation",
    //     name:"cooperation",
    //     component: cooperation,
    //     meta: {
    //         metaInfo: {
    //             title: "商务合作",
    //             keywords: "cooperation",
    //             description: "cooperation"
    //         }
    //     }
    // }



]

export default routerArray
