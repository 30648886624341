<template>
  <div>
      <div class="navtop">
          <div style="margin-left: 5px;cursor: pointer;" @click="goHome">
              首页 <i class="el-icon-arrow-right" style="margin-right: 5px"></i>
          </div>
          <span>
            兼职
          </span>
      </div>
      <!--大小分类-->
      <div class="classification">
          <div class="classificationBox"  @mouseleave = "sLevel = 0">
              <div class="cFLeft">
                  <div class="cFLeftList" @mouseover="sLevel = 1">
                      <div class="cFLeftListBox">
                          <div class="cFLeftListTitle" @click="chagenum(1)">
                              兼职职位
                          </div>
                          <div class="cFLeftLists" v-for="(item,index) in  rmnav.slice(0,5)"  :key="item.id" @click="click_tabS(item, index)">
                              {{item.name}}
                          </div>
                      </div>
                      <div class="iF">
                          <i class="el-icon-arrow-right" style="color: #999999;"></i>
                      </div>
                  </div>
                  <div class="cFLeftList" @mouseover="sLevel = 2" >
                      <div class="cFLeftListBox">
                        <div class="cFLeftListTitle" @click="getPositionCourseLists">
                          <img src="@/assets/images/pc/hot.png" class="hotIocn"/>
                          在家做
                        </div>

                        <div class="cFLeftLists" v-for="(item,index) in rmnav2.slice(0,7)" :key="item.id" @click="getPositionCourseList(item, index)">
                            {{item.name}}
                        </div>
                      </div>
                      <div class="iF">
                          <i class="el-icon-arrow-right" style="color: #999999;"></i>
                      </div>
                  </div>
                  <div class="cFLeftList" @mouseover="sLevel = 3" @click="getPositionLocallyList">
                      <div class="cFLeftListBox">
                          <div class="cFLeftListTitle">
                              本地兼职
                          </div>
                      </div>
                  </div>
                  <div class="cFLeftList" @mouseover="sLevel = 4" @click="getPositionGGList">
                      <div class="cFLeftListBox">
                          <div class="cFLeftListTitle">
                              全部岗位
                          </div>
                      </div>

                  </div>
              </div>

              <div class="cFRight">
                  <!--图片模块-->
                  <div class="cFRightBox">
                      <div class="cFRightImgLeft">
                          <div class="cFRightImgLeftO" @click="goDetail(bannerList[0])">
                              <img v-if="bannerList[1]" :src="bannerList[0].imgUrl">
                          </div>
                          <div class="cFRightImgLeftT" @click="goDetail(bannerList[1])">
                              <img v-if="bannerList[2]" :src="bannerList[1].imgUrl">
                          </div>
                      </div>

                      <div class="cFRightImgRight">
                          <div class="cFRightImgRightO" @click="goDetail(bannerList[2])">
                              <img v-if="bannerList[3]" :src="bannerList[2].imgUrl">
                          </div>
                          <div class="cFRightImgRightT" @click="goDetail(bannerList[3])">
                              <img v-if="bannerList[4]" :src="bannerList[3].imgUrl">
                          </div>
                          <div class="cFRightImgRightS" @click="goDetail(bannerList[4])">
                              <img v-if="bannerList[4]" :src="bannerList[4].imgUrl">

                          </div>
                      </div>
                  </div>


                  <!--分类模块-->
                  <div class="classMBox" v-show="sLevel == 1">
                      <div class="classMBoxTitle">兼职职位</div>
                      <div class="classMBoxList">
                          <div v-for="(item,index) in rmnav" :key="item.id" @click="click_tabS(item, index)">
                              {{item.name}}
                          </div>
                      </div>
                  </div>

                  <div class="classMBox" v-show="sLevel == 2">
                      <div class="classMBoxTitle">
<!--                          <img src="@/assets/images/pc/hot.png" class="hotIocn2"/>-->
                          在家做
                      </div>
                      <div class="classMBoxList">
                          <div v-for="(item,index) in rmnav2" :key="item.id" @click="getPositionCourseList(item, index)">
                              {{item.name}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


    <div class="wanqi_wd_bg">
      <div class="renwu_list wanqi_wd">
        <h2>
          <i>精选高薪兼职专场</i>
          <h6 style="color: #CCCCCC">名企直招、安全靠谱，热门职位一键报名</h6>
        </h2>
        <div class="r_list_nav" v-if="taskData.length>0">
          <!---广告位开始-->
          <div class="rlist_btl">
            <ul v-loading="fullscreenLoading" element-loading-text="拼命加载中">
              <el-row :gutter="20">
                <el-col
                    class="mt-50"
                    :md="8"
                    :xs="24"
                    v-for="(item, index) in taskData"
                    :key="index"
                >
                  <li class="grid-content bg-purple mycard rlist_btlT" :class="index == listindex?'':''"  @click="cys(item)">
                      <a onclick="return false" :href="item.id" >
                    <div class="wtitle-box">
                      <h4 :title="item.title">{{ item.positionTitle  }}</h4>
                    </div>
                    <div class="wtab">
                      <span v-if="item.pt_task_type">{{item.pt_task_type.type_name}}</span>
                      <span v-for="(item2,index) in item.tags">{{item2}}</span>
                    </div>
                    <div class="wtitle-box">
                      <h5  v-if="item.amountType == '1'">￥{{item.amount / 100}}/
                        <span v-if="item.settlementUnit == 0">其他</span>
                        <span v-if="item.settlementUnit == 1">小时</span>
                        <span v-if="item.settlementUnit == 2">天</span>
                        <span v-if="item.settlementUnit == 3">周</span>
                        <span v-if="item.settlementUnit == 4">月</span>
                        <span v-if="item.settlementUnit == 5">次</span>
                        <span v-if="item.settlementUnit == 6">单</span>
                      </h5>
                      <h5 v-else>￥{{item.amountBegin / 100}} ~ {{item.amountEnd / 100}}
                        /
                        <span v-if="item.settlementUnit == 0">其他</span>
                        <span v-if="item.settlementUnit == 1">小时</span>
                        <span v-if="item.settlementUnit == 2">天</span>
                        <span v-if="item.settlementUnit == 3">周</span>
                        <span v-if="item.settlementUnit == 4">月</span>
                        <span v-if="item.settlementUnit == 5">次</span>
                        <span v-if="item.settlementUnit == 6">单</span></h5>

                    </div>
                    <div class="wrlist_bt_b">

                      <div class="wrlist_bt_bb">
                        <div class="wrlist_bt_bbs">
                          <div class="publisher-box">
                            <div class="publisher-logo" v-if="item.enterprise">
                              <img :src="item.enterprise.enterpriseLogo" alt="兼职网"/>
                            </div>
                            <div class="publisher-mess">
                              <div class="publisher-mess-title" v-if="item.enterprise">{{ item.enterprise.enterpriseName }}</div>
                              <em class="fabuzhe" v-if="item.distance>1">距离{{item.distance}}km &nbsp; {{ item .detailedProcess }}</em>
                            </div>
                          </div>
                          <!-- <span>征稿人数:{{ item.filling_number }} 人</span> -->
                        </div>
                        <i v-if="item.state == 3" class="jzcy">立即报名</i>
                        <i v-else @click="cy(item)" class="jzcy">立即报名</i>
                      </div>
                    </div>
                      </a>
                  </li>
                </el-col>
              </el-row>
            </ul>
          </div>

<div class="page">
  <el-pagination
      @current-change="chagenum"
      :current-page.sync="pageNum"
      :page-size="page.pageSize"
      layout="prev, pager, next, jumper"
      :total="page.total">
  </el-pagination>
          </div>
        </div>




          <div class="rlist_btl" style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;" v-if="taskData == ''">
              <img src="@/assets/images/pc/noData.png" style="width: 541.67px;height: 507.17px;" />
          </div>
      </div>

    </div>



      <!---->
      <div class="news">
          <div class="news_detailed" v-if="informationList&&informationList.length>0" @click="goinformationDetail(informationList[0])">
              <a :href="informationList[0].id" onclick="return false">
                  <div>
                      <img class="news_detailed_img" :src="informationList[0].imgUrl" alt="兼职网">
                  </div>
                  <div class="news_detailed_content">

                      <div class="news_detailed_title_one">
                          {{ informationList[0].title }}
                      </div>
                      <div class="news_detailed_time">{{ informationList[0].timeFormatter}}</div>
                      <div class="news_detailed_desc">{{ getRichText(informationList[0].content) }}</div>

                  </div>
                  <div class="arrow">
                      <img class="noSelected" src="@/assets/images/pc/arrow.png" />
                      <img class="includeArrow" src="@/assets/images/pc/includeArrow.png" />
                  </div>
              </a>
          </div>
          <div class="news_detailed" v-if="informationList&&informationList.length>1" @click="goinformationDetail(informationList[1])">
              <a :href="informationList[1].id" onclick="return false">
                  <div>
                      <img class="news_detailed_img" :src="informationList[1].imgUrl" alt="兼职网">
                  </div>
                  <div class="news_detailed_content">
                      <div class="news_detailed_title_one">
                          {{ informationList[1].title }}
                      </div>
                      <div class="news_detailed_time">{{ informationList[1].timeFormatter }}</div>
                      <div class="news_detailed_desc">{{ getRichText(informationList[1].content) }}</div>
                  </div>
                  <div class="arrow">
                      <img class="noSelected" src="@/assets/images/pc/arrow.png" />
                      <img class="includeArrow" src="@/assets/images/pc/includeArrow.png" />
                  </div>
              </a>

          </div>
          <div class="news_detailed_list">
              <div class="news_detailed_list_tips">
                  <div>新闻</div>
                  <span style="cursor: pointer" @click="goInform">更多资讯</span>
              </div>

              <div class="news_detailed_list_content">
                  <a :href="item.id" onclick="return false" v-for="item in informationList">
                      <div class="news_detailed_item"  @click="goinformationDetail(item)">
                          <div class="news_detailed_item_title">
                              {{item.title}}
                          </div>
                          <div class="news_detailed_item_time">
                              <span>{{ item.timeFormatter }}</span>
                              <span style="cursor: pointer" >详情<i class="el-icon-arrow-right"></i></span>
                          </div>
                      </div>
                  </a>

              </div>

          </div>
      </div>
  </div>
</template>

<script>
import banner from '@/components/pc/banner';
export default {
  name: "homePage",
  data() {
    return {
        map :{},
      sLevel:0,
      url:"/official/enterprise/getPositionLevelList",
      levelType:1,
      levelId:"",  //二级分类id
      classifyType:1,//二级分类类型,
      pageNum:1,  //请求页数
      pageSize:9,  //请求条数
      longitude:"", //经度
      latitude:"",//维度
      listindex:0,  //去掉一会
      layout: "prev, pager, next",
      complete_enterprise_num: [],
      service_enterprise_num: [], //解决需求数
      fullscreenLoading: false, //加载
      //大导航
      navlist: [
        {
          title: "综合排序",
          type: true,
        },
        {
          title: "最新任务",
          type: false,
        },
      ],
      serviceList:[
        {
          icon:require('@/assets/images/pc/xshz-fill.png'),
          title:'线上合作',
          desc:'平台广告投放 产品CPA推广 平台资源置换、其它线上合作',
        },
        {
          icon:require('@/assets/images/pc/xxld.png'),
          title:'线下落地',
          desc:'平台活动策划营销 校园市场推广 活动执行落地 产品地推拓展',
        },
        {
          icon:require('@/assets/images/pc/mthz.png'),
          title:'媒体合作',
          desc:'品牌广告软文投放 品牌文案策划、撰写、公共媒体运营发稿 联合线上活动',
        },
        {
          icon:require('@/assets/images/pc/qydz.png'),
          title:'企业代招',
          desc:'企业全职/临时招聘 活动/项目兼职招聘 节假日兼职招聘 寒暑假工招聘',
        },
        {
          icon:require('@/assets/images/pc/ptnl.png'),
          title:'平台能力',
          desc:'一站式解决方案 搜索&浏览场景打通 智能算法解析 全链路数据支持',
        },
        {
          icon:require('@/assets/images/pc/fwbz.png'),
          title:'服务标准',
          desc:'专业的服务团队 标准的服务流程 高效的服务质量 海量的用工岗位',
        }
      ],
      icons: "el-icon-caret-bottom",
      activeTab: 0,
      activeTabS: 0,
      acindex: 0,
      laberKey: "0",
      //热门标签
      rmnav: [],  //兼职职位的二级列表
      rmnav2:[],  //课程兼职的二级
      bannerList:[],
      rwnav: [
        {
          title: "兼职职位",
          type: true,
        },{
          title:"课程兼职",
          type:false
        },
        {
          title:"本地兼职",
          type:false
        },
        {
          title:"全部岗位",
          type:false
        }
      ], //任务类型
      taskData: [],
      page: {
        total: 0, //总条目数
        pageSize: 9, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      pageCount: 9,
      show: true,
      kxlist: [],
      day: 15,
      dialogTableVisible:true,
      user:{},
      advertisingList:[],  //
      informationList:[],  //资讯列表
      guanggao:false,
      args:{
        occurrenceTime:"",
        user:{
          userId:"",
          phone:""
        },
        type:{},
        type2:"", //pc / h5的
        actionCode:"",
        message:{
          phone:""
        }
      },
      cityCode: '',
      positionTitle:"",
      positionClassType: '1',
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        loop: true,
      },
      firstOrder:"兼职职位",  //一级标题
      secondLevel:"全部兼职", //二级标题
    }
  },
  components: {
    banner
  },

  mounted() {
    this.randomNum();
    this.exposureOW();
    let ids = this.$route.params.id;
    let idss = this.$route.params.ids;
    let levelId = this.$route.params.levelId;
    console.log("this.$route.params=>",this.$route.params)
    console.log("idss=>",levelId);

    this.cityCode = this.$route.params.cityCode;
    console.log("title=>", this.$route.params.positionTitle)
    if(this.$route.params.positionTitle != undefined){
      this.positionTitle = this.$route.params.positionTitle
    }
      if(levelId != "" && levelId != undefined && levelId != null){
              if(idss== '1'){
                  // this.getSecondLevel0()
                  this.click_tabS({id:levelId});
              }else if(idss == '2'){
                  let data = {name:"",id:levelId}
                  this.getPositionCourseList(data,0);
                  // this.getPositionCourseLists();
              }else if(idss == '3'){
                  this.getPositionLocallyList();
              }else {
                  this.getPositionGGList();
              }
      }else {
          console.log("123",ids)
          if(ids != '' && ids != undefined && ids != null){
              if(ids== '1'){
                  this.getSecondLevel0()
              }else if(ids == '2'){
                  this.getPositionCourseLists();
              }else if(ids == '3'){
                  this.getPositionLocallyList();
              }else {
                  this.getPositionGGList();
              }

          } else if(this.cityCode){
              this.getHotCityList()
          }else if(this.positionTitle){
            this.positionList()
          } else {
            //入口页面在这里
            this.getPositionGGListT();
            this.positionListT();
              // this.getData();
              // this.getnavs();
          }
      }

    this.getInformationList()

    const that = this;
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token")) {
      // this.getuserlist();
    }

    return (() => {
      window.screenWidth = document.documentElement.clientWidth; //实时宽度
      window.screenHeight = document.documentElement.clientHeight; //实时高度
      if (window.screenWidth < 500) {
        //  setTimeout(function(){that.pageCount=3},100)
        that.layout = "pager";
      }
    })();
  },
    beforeDestroy() {
      this.$bus.$off("setList")
    },
    beforeMount(){
      this.setloca();
    this.getBanner();
    this.getSecondLevel();
    this.getSecondLevel1();
        this.$bus.$on('setList', (typeIndex)=>{
            console.log(typeIndex)
            if(typeIndex== '1'){
                this.getSecondLevel0()
            }else if(typeIndex == '2'){
                this.getPositionCourseLists();
            }else if(typeIndex == '3'){
                this.getPositionLocallyList();
            }else {
                this.getPositionGGList();
            }
        })

      this.$bus.$on('setPoList',(res)=>{

          this.taskData = res.rows;
          this.page.total = Number(res.total);
      })
  },
  created() {
    this.$bus.$on('tc_marke',e=>{
      console.log(e)
      this.user={agreement_audit:1}
    })
  },
  methods: {

      goDetail(e){
          console.log(e)
          let name = ''
          let params = {}
          if(e.jumpUrl.indexOf('?') > '-1'){
              name = e.jumpUrl.split('?')[0]
              let param = e.jumpUrl.split('?')[1]
              param.split('&').map(item=>{
                  params[item.split('=')[0]] = item.split('=')[1]
              })
          }else {
              name = e.jumpUrl
          }
          if (['positionDetail','PartTJ'].includes(this.$route.name)) {
              this.$router.push({
                  name: 'empty',
                  params: Object.assign({},{
                      id: e.jumpUrl,
                      name: name,
                      title: this.$route.meta.metaInfo.title,
                  },params)
              });
              return
          }
          console.log("name=>",name)
          this.$router.push({
              name: name,
              params: Object.assign({},{
                  id: e.jumpUrl,
                  name: this.$route.meta.metaInfo.title,
              },params),
          });
      },
      positionList(e){
        let cityType = "";
        let code = "";
        if(e != '' && e!= undefined && e!= null){
        }else{
          if(localStorage.getItem('dJ') != null){
            cityType = localStorage.getItem('dJ')
          }
          code = localStorage.getItem("adcode")
        }
        this.$axios({
              method: "POST",
              url:"/official/enterprise/getPositionAllList",
              data:{
                selectType:"12",
                cityType:cityType,
                // levelId:this.levelId,
                // classifyType:this.classifyType,
                code:code,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                longitude:this.longitude || '0', //经度
                latitude:this.latitude || '0',//维度
                positionTitle:this.positionTitle
              }
            }).then(res=>{
                console.log(res)
                if(res.code == 200 && res.rows!= []){
                  this.taskData = res.rows;
                  this.page.total = Number(res.total);
                  this.fullscreenLoading = false;
                }
            })
      },
      positionListT(){
        let cityType = "";
        let code = "";

        if(localStorage.getItem('dJ') != null){
          cityType = localStorage.getItem('dJ')
        }
        code = localStorage.getItem("adcode")
        this.$axios({
              method: "POST",
              url:"/official/enterprise/getPositionAllList",
              data:{
                selectType:"",
                cityType:cityType,
                // levelId:this.levelId,
                // classifyType:this.classifyType,
                code:code,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                longitude:this.longitude || '0', //经度
                latitude:this.latitude || '0',//维度
                positionTitle:this.positionTitle
              }
            }).then(res=>{
                if(res.code == 200 && res.rows!= []){
                  this.page.total = Number(res.total);
                }
            })
      },
      goInform(){
        this.$router.push({
            name: `informationHome`,
        })
      },
      goinformationDetail(e){
          this.$router.push({
              name: `informationDetail`,
              params: {
                  id: e.id,
                  name: "兼职"
              },
          });
      },
      /*
      * 获取经纬度
      * */
      setloca(){
          let location = localStorage.getItem("point")
          if(location != null && location != undefined && location != ''){
              let jsonL = JSON.parse(location)
              console.log("经纬度=>",jsonL)
              this.longitude = jsonL.lng //经度
              this.latitude = jsonL.lat //维度
          }

      },

      getHotCityList(e){
        let cityType = "";
        if(localStorage.getItem('dJ') != null){
          cityType = localStorage.getItem('dJ')
        }
          this.fullscreenLoading = true;
          this.$axios({
              method:"POST",
              url:"/official/enterprise/getHotCityList",
              data:{
                  cityType:cityType,
                  cityCode: this.cityCode,
                  longitude:this.longitude || "0",
                  latitude:this.latitude || "0",
                  pageNum: this.pageNum,
                  pageSize:"9"
              }
          }).then(res=>{
              this.taskData = res.rows;
              this.page.total = Number(res.total);
              this.fullscreenLoading = false;
              console.log("列表=>",res)
          })

      },
    navPD(e){  //跳转
      console.log("跳转:=>",e)
      this.$router.push({
        name: 'positionDetail',
        params: {
          id: e.id,
          name: this.$route.meta.metaInfo.title
        }
      })
    },
    getBanner(){
      this.$axios({
        url: "/official/enterprise/getBanner?locationType="+'10',
      }).then((res) => {
        if (res.code == 200) {
            this.bannerList = res.data;
        }
      });
    },
    moreNews(){

    },
    getRichText(richText) {
      // 提取文字
      const myDiv = document.createElement("div");
      myDiv.innerHTML = richText;
      return myDiv.innerText;
    },
    //生成随机id
    randomNum() {
      let num = "";
      for (let i = 0; i < 6; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let userid = new Date().getTime() + num;
      if(localStorage.getItem('userID') == '' || localStorage.getItem('userID') == undefined || localStorage.getItem('userID') == null){
        localStorage.setItem("userID",userid)
      }
    },

    getInformationList(){
      this.$axios({
        method: "get",
        url: "/official/enterprise/getInformationList",
        params: {
          pageNum: 1,
          pageSize: 4,
          websiteType:2
        },
      }).then((res) => {
            if (res.code === 200) {
              console.log(res.rows)
              this.informationList = res.rows;
              // this.page.total = res.total;
              // this.page.total = res.total;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
      goHome(){
          this.$router.push({
              name:"homePage"
          })
      },
    exposureOW(){
      let data = localStorage.getItem("userInfo")
      console.log(data);
      let phone = '';
      if(data != null){
        phone = JSON.parse(data).phone;
      }

      if(data != null && (phone != '' || phone != null || phone != undefined)){
        this.args.user.phone = phone;
        this.args.message.phone = phone;
          let user = JSON.parse(window.localStorage.getItem('userInfo'))
          let userId = user&&user.token?user.token:phone
          this.args.user.userId = userId;
      }else{
        this.randomNum();
          let user = JSON.parse(window.localStorage.getItem('userInfo'))
          let userId = user&&user.token?user.token:localStorage.getItem("userID")
        this.args.user.userId = userId
      }
      this.args.occurrenceTime = new Date().getTime(),
          //正是   https://jzuser-api.jianzhinet.cn
          this.args.actionCode = 'web_website_001';
      this.args.type = 'website';
      this.$axios({
        url: "/mqtt/actionIncentive/actionLogPc",
        data:{
          payload: JSON.stringify(this.args)
        },
        method:"POST",
      }).then((res)=>{
        console.log("曝光成功")
      })
    },
    getuserlist() {
      let _this = this;
      this.$axios({
        method: "POST",
        url: "center/my_info",
      }).then((res) => {
        if (res.code == 200) {
          this.user=res.data
        }
      });
    },

    change: function (content) {
      content = content.replace(/\n/g, "</br>");
      return content;
    },


    //热门任务
    hot() {
      this.show = !this.show;
      if (this.show) {
        this.icons = "el-icon-caret-bottom";
      } else {
        this.icons = "el-icon-caret-top";
      }
    },
    chagenum(num) {
          console.log("分页器=>",num)
        this.pageNum = num
      if(this.firstOrder == "兼职职位"){
        if(num<=2){
          this.getPositionGGListT();
          this.positionListT();
        }else{
          this.$axios({
          method: "POST",
          url: "/official/enterprise/getPositionLevelList",
          data: {
              levelId:this.levelId,
              classifyType:this.classifyType,
              pageNum:num-2,
              pageSize:this.pageSize,
              longitude:this.longitude|| '0', //经度
              latitude:this.latitude || '0',//维度
          },
      }).then((res) => {
          this.taskData = res.rows;
      }).catch((error) => {
          console.log(error);
      });
        }
      }else{
          console.log("进来这里123")
        if(this.positionClassType ==''){
        if(num < 3){
          // this.pageNum = num;
          this.getPositionGGList(num);
        } else if(this.cityCode){
            // this.pageNum = num;
            this.getHotCityList()
        } else {
          // this.pageNum = num;
          this.url = "/official/enterprise/getPositionAllList"
          this.levelType = ''
          this.positionList("1");
          // this.getData(this.pageNum - 2);
        }
      }else if(this.positionClassType =='2'){
          this.getPositionCourseList()
      }else if(this.positionClassType =='3'){

          // this.pageNum = num;
          this.getPositionLocallyList(num);
      }else{
          if(this.cityCode){
              this.getHotCityList()
          }if(this.positionTitle){
            this.positionList();
          }else {
              // this.pageNum = e;
              console.log(4)
              this.getData();
          }
      }
      }

    },
      getnavs(){
          this.$axios({
              method: "POST",
              url: "/official/enterprise/getPositionAllList",
              data: {
                  levelId:this.levelId,
                  classifyType:'',
                  pageNum:this.pageNum,
                  pageSize:this.pageSize,
                  longitude:this.longitude|| '0', //经度
                  latitude:this.latitude || '0',//维度
              },
          }).then((res) => {
              this.page.total = Number(res.total);
              this.fullscreenLoading = false;
          }).catch((error) => {
              console.log(error);
          });
      },
      // getPositionAllList
    // 任务详情
    cy(e) {
      this.$router.push({
        name: "positionDetail",
        params: {
            id: e.id,
            name: this.$route.meta.metaInfo.title,
            firstOrder:this.firstOrder,
            secondLevel:this.secondLevel
        },
      });

        localStorage.setItem("erWeima",1)
    },
    //职位详情
    cys(e){
        this.$router.push({
            name: "positionDetail",
            params: {
                id: e.id,
                firstOrder:this.firstOrder,
                secondLevel:this.secondLevel,
                name: this.$route.meta.metaInfo.title
            },
        });
    },
    qhtab(e,i) {
      this.activeTabS = 0;
      this.classifyType = 1;
      this.rmnav = [];
      this.acindex = e;
      this.pageNum = 1;
      console.log(e)
      if (e == 0) {
        this.levelType = 1;
        this.url = "/official/enterprise/getPositionLevelList"
        this.getnav();
      }else if (e == 1){
        this.levelType = 2;
        this.getnav();
        this.url = "/official/enterprise/getPositionCourseList"
      }else if(e == 2){
        this.levelId  = "";
        this.classifyType = "";
        this.url = "/official/enterprise/getPositionLocallyList"
        this.levelType = ''
        this.getData();
      }else {
        this.getPositionGGList();

      }
      // this.getData();
      // this.getadvertising(1,9);
    },
    //获取广告列表
    getPositionGGList(e){
        this.positionList("1");
        // this.$router.push({path: '/PartTJ/posts'})
        this.firstOrder = "全部岗位";  //一级标题
        this.secondLevel = "";
        this.positionClassType = ''
        if(e != '2'){
            this.page.pageNum =  1;
            this.pageNum = 1;
        }
        if(this.pageNum == 1){
            console.log(this.pageNum, 'this.pageNumthis.pageNumthis.pageNum')
            // this.getnavs();
        }
        this.pageSize = 9;
        this.cityCode = ""
      this.$axios({
        method: "POST",
        url:"/official/enterprise/getPositionGGList",
        data: {
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          longitude:this.longitude||'0', //经度
          latitude:this.latitude || '0',//维度
        },
      }).then(res=>{
        this.taskData = res.rows;
      })
    },

    //兼职职位 获取广告列表
    getPositionGGListT(){
      this.$axios({
        method: "POST",
        url:"/official/enterprise/getPositionGGList",
        data: {
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          longitude:this.longitude||'0', //经度
          latitude:this.latitude || '0',//维度
        },
      }).then(res=>{
        this.taskData = res.rows;
      })
    },
    // 获取任务列表

    getData(e) {
      this.fullscreenLoading = true;
      this.$axios({
        method: "POST",
        url: this.url,
        data: {
          selectType:"",
          levelId:this.levelId,
          classifyType:this.classifyType,
          pageNum:e || this.pageNum,
          pageSize:this.pageSize,
          longitude:this.longitude|| '0', //经度
          latitude:this.latitude || '0',//维度
        },
      }).then((res) => {
          this.taskData = res.rows;
          this.page.total = Number(res.total);
          this.fullscreenLoading = false;
      }).catch((error) => {
            console.log(error);
          });

    },
    getPositionLocallyList(e){
        // this.$router.push({path: '/PartTJ/local'})
        if(this.positionClassType != "3"){
            this.page.pageNum = 1
            this.pageNum = 1
        }
        let cityType = "";
        if(localStorage.getItem('dJ') != null){
          cityType = localStorage.getItem('dJ')
        }
        this.firstOrder = "本地兼职";  //一级标题
        this.secondLevel = "";
        this.positionClassType = "3";
        this.latitude = JSON.parse(localStorage.getItem("point")).lat;
        this.levelId = "";
        this.longitude =JSON.parse(localStorage.getItem("point")).lng;
        this.cityCode = ""
        this.fullscreenLoading = true;
        console.log('eeeee', e)

        this.pageSize = 9;
        this.$axios({
            method: "POST",
            url: "/official/enterprise/getPositionLocallyList",
            data: {
                levelId:this.levelId,
                classifyType:this.classifyType,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                longitude:this.longitude|| '0', //经度
                latitude:this.latitude || '0',//维度
                cityType: cityType,
                code:localStorage.getItem("adcode"),
            },
        }).then((res) => {
            this.taskData = res.rows;
            this.page.total = Number(res.total);
            this.fullscreenLoading = false;
        }).catch((error) => {
            console.log(error);
        });
    },
    onChange(){
      console.log('12')
    },
    //难易程度
    getnav() {
      this.$axios({
        method: "POST",
        params:{
          levelType:this.levelType,
        },
        url: "/official/enterprise/getPositionLevel",
      }).then((res) => {
        this.rmnav = res.data;
        this.levelId = this.rmnav[0].id
        this.rmnav.unshift({name:"全部兼职",id:'789456'});
        this.getData();
      }).catch((error) => {
        console.log(error);
      });
    },
    click_tab(item, index) {
      this.page.currentPage = 1;
      this.activeTab = index;
      this.laberKey = item.id;
      this.getData();
      // this.getadvertising(1,9);

    },
    click_tabS(item, index) {
        // this.$router.push({path: '/PartTJ/job'+ item.id.slice(0,2)})
        this.firstOrder = "兼职职位";  //一级标题
        this.secondLevel = item.name; //二级标题
        if(this.secondLevel == "全部兼职"){
          this.chagenum(1)
        }else{
          this.activeTabS = index;
      this.positionClassType = '1'
        this.cityCode = ""
      if(item.name == "全部岗位" || item.name == '全部兼职' || item.name == '全部职位'){
        this.classifyType = 1;//二级分类类型,
        this.levelId = '';
      }else {
        this.classifyType = 2;
        this.levelId = item.id;  //二级分类id
      }
      this.pageNum = 1;  //请求页数
      this.pageSize = 9;  //请求条数

      this.fullscreenLoading = true;
      this.$axios({
          method: "POST",
          url: "/official/enterprise/getPositionLevelList",
          data: {
              levelId:this.levelId,
              classifyType:this.classifyType,
              pageNum:this.pageNum,
              pageSize:this.pageSize,
              longitude:this.longitude|| '0', //经度
              latitude:this.latitude || '0',//维度
          },
      }).then((res) => {
          this.taskData = res.rows;
          this.page.total = Number(res.total);
          this.page.total = res.total;
          console.log("点的这个=>",this.page.total)

          this.fullscreenLoading = false;
      }).catch((error) => {
          console.log(error);
      });

        }


    },


    /*
    * 魏志远
    * 获取二级分类  levelType 对应 1234
    * */
    getSecondLevel(){
      this.$axios({
          method: "POST",
          params:{
              levelType:1,
          },
          url: "/official/enterprise/getPositionLevel",
      }).then((res) => {
          console.log("resData1=>",res)
          this.rmnav = res.data;
          this.rmnav.unshift({name:"全部兼职",id:'789456'});
      }).catch((error) => {
          console.log(error);
      });
    },
    getSecondLevel1(){
        this.$axios({
            method: "POST",
            params:{
                levelType:2,
            },
            url: "/official/enterprise/getPositionLevel",
        }).then((res) => {
            this.rmnav2 = res.data;
            this.rmnav2.unshift({name:"全部职位",id:'789456147'});
            console.log("resdata2=>",this.rmnav2)
        }).catch((error) => {
            console.log(error);
        });
    },
    getPositionCourseList(item,index){
        this.firstOrder = "在家做";  //一级标题
        this.cityCode = ""
        this.positionClassType = '2'
        if(item != undefined && item!= null){
          // this.$router.push({path: '/PartTJ/course'+ item.id.slice(0,2)})
        }
        if(item){
            this.activeTabS = index;
            this.secondLevel = item.name; //二级标题
            if(item.name == "全部职位"){
                this.classifyType = 1;//二级分类类型,
                this.levelId = '';
            }else {
                // this.classifyType = item.classifyType;
                this.classifyType = 2
                this.levelId = item.id;  //二级分类id
            }
            this.pageNum = 1;  //请求页数
            this.pageSize = 9;  //请求条数
        }


        this.fullscreenLoading = true;
        this.$axios({
            method: "POST",
            url: "/official/enterprise/getPositionCourseList",
            data: {
                levelId:this.levelId,
                classifyType:this.classifyType,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                longitude:this.longitude|| '0', //经度
                latitude:this.latitude || '0',//维度
            },
        }).then((res) => {
            this.taskData = res.rows;
            this.page.total = Number(res.total);
            this.fullscreenLoading = false;
        }).catch((error) => {
            console.log(error);
        });
    },
      getPositionCourseLists(){
          this.firstOrder = "在家做";  //一级标题
          this.secondLevel = "全部职位"; //二级标题
          this.page.pageNum = 1;  //请求页数
          this.pageNum = 1;
          this.cityCode = ""
          this.positionClassType = '2'
          this.fullscreenLoading = true;
          this.$axios({
              method: "POST",
              url: "/official/enterprise/getPositionCourseList",
              data: {
                  levelId:"",
                  classifyType:'1',
                  pageNum:this.pageNum,
                  pageSize:9,
                  longitude:this.longitude|| '0', //经度
                  latitude:this.latitude || '0',//维度
              },
          }).then((res) => {
              this.fullscreenLoading = false;
              this.taskData = res.rows;
              this.page.total = Number(res.total);
              this.fullscreenLoading = false;
          }).catch((error) => {
              console.log(error);
          });
      },
      getSecondLevel0(){
          this.firstOrder = "兼职职位";  //一级标题
          this.secondLevel = "全部兼职"; //二级标题
          this.positionClassType = 1;
          this.classifyType = 1
          this.latitude = this.latitude || '0';
          this.levelId = "";
          this.cityCode = ""
          this.longitude = this.longitude || '0';
          this.page.pageNum = 1;
          this.pageNum = 1;
          this.pageSize = 9;
          this.fullscreenLoading = true;
          this.$axios({
              method: "POST",
              url: "/official/enterprise/getPositionLevelList",
              data: {
                  levelId:this.levelId,
                  classifyType:this.classifyType,
                  pageNum:this.pageNum,
                  pageSize:this.pageSize,
                  longitude:this.longitude, //经度
                  latitude:this.latitude,//维度
              },
          }).then((res) => {
              this.taskData = res.rows;
              this.page.total = Number(res.total);
              this.fullscreenLoading = false;
              this.fullscreenLoading = false;
          }).catch((error) => {
              console.log(error);
          });
      }

  },
}
</script>

<style src="@/assets/css/pc/homePage.css" scoped></style>
<style src="@/assets/css/pc/PartTJ.css" scoped></style>
