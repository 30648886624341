<template>
  <div style="background: #f9f9f9;">
    <!--轮播图-->
    <banner :bannerList="bannerList"></banner>
    <div class="content">
      <div class="contentBox">
        <div class="navtop">
          <div style="margin-left: 5px;cursor: pointer;" @click="goHome">
            首页 <i class="el-icon-arrow-right" style="margin-right: 5px"></i>
          </div>
          <span style="margin: 0px 10px;color: #00A870;cursor: pointer">资讯</span>
        </div>

      </div>
    </div>
    <div style="width: 1200px;margin: 0 auto;">
      <div class="flex flex_item_between flex_end info_header">
        <div>
          <div class="info_en">Information</div>
          <div class="info_cn">资讯中心</div>
          <div class="info_line"></div>
        </div>
        <!--          <vue-scroll class="class_type" style="width: 700px;" :ops="ops">-->
        <div class="flex" style="flex: 1;flex-wrap: wrap; padding-left: 149px;">
          <div class="info_class_type" :class="item.dictValue == informationType ? 'active' : ''"
            @click="changeInformationClass(item)" v-for="item in informationClassList" :key="item.dictValue">{{
              item.dictLabel }}</div>
        </div>
        <!--          </vue-scroll>-->
      </div>
      <!--      <div class="flex flex_item_between" style="margin-top: 80px;" v-if="informationType">-->
      <!--        <el-carousel class="info_banner" indicator-position="none">-->
      <!--          <el-carousel-item v-for="item in informationList" :key="item.id">-->
      <!--            <div class="info_swiper" @click="toDetail(item)">-->
      <!--              <img :src="item.imgUrl" alt="兼职网">-->
      <!--              <div class="flex flex_column flex_item_between">-->
      <!--                <div style="width: 320px;">-->
      <!--                  <div class="info_swiper_title">{{ item.title }}</div>-->
      <!--                  <div class="info_swiper_desc">{{ getRichText(item) }}</div>-->
      <!--                  <div class="info_swiper_line"></div>-->
      <!--                </div>-->
      <!--                <div class="info_swiper_detail">-->
      <!--                  查看详情-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </el-carousel-item>-->
      <!--        </el-carousel>-->
      <!--        <div>-->
      <!--          <div class="flex info_sm_item" @click="toDetail(item)" v-for="item in informationList" :key="item.id">-->
      <!--            <img :src="item.imgUrl" alt="兼职网">-->
      <!--            <div class="info_sm_item_con flex flex_column flex_item_between">-->
      <!--              <div class="info_sm_item_title">{{item.title}}</div>-->
      <!--              <div class="flex flex_item_between">-->
      <!--                <div class="info_sm_item_time">{{formatterTime(item.createTime, 'YYYY-MM-DD')}}</div>-->
      <!--                <div class="info_sm_item_detail">查看详情 <img style="width: 10px; height: 10px;transform: rotate(-90deg);" src="@/assets/images/pc/arrow-d.png" alt="兼职网"></div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div v-else>-->
      <!--        <div class="flex flex_item_between flex_center info_content" v-for="item in informationList" :key="item.id" @click="toDetail(item)">-->
      <!--          <img :src="item.imgUrl" alt="兼职网">-->
      <!--          <div class="info_time flex flex_column flex_center">-->
      <!--            <div class="info_mmdd">{{ formatterTime(item.createTime, 'MM-DD') }}</div>-->
      <!--            <div class="info_year">{{ formatterTime(item.createTime, 'YYYY') }}</div>-->
      <!--          </div>-->
      <!--          <div class="info_text" >-->
      <!--            <div class="info_text_title ellipse">{{ item.title }}</div>-->
      <!--            <div class="info_text_con">{{ getRichText(item) }}</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->


      <div class="informationBox">
        <div class="informationLeft">
          <a :href="item.id" onclick="return false" v-for="item in informationList">

            <div class="informationList" @click="toDetail(item)">

              <div class="listImg">
                <img :src="item.imgUrl" />
              </div>

              <div class="listMess">
                <div class="messTitle">{{ item.title }}</div>
                <div class="messCount">{{ getRichText(item) }}</div>
                <div class="messBottom">
                  <div style="display: flex">
                    <div class="messTime">{{ formatterTime(item.createTime, 'YYYY') }}-</div>
                    <div class="messTime">{{ formatterTime(item.createTime, 'MM-DD') }}</div>
                  </div>
                  <!--                            <div class="messTime">2023-03-28</div>-->
                  <div class="messFun">查看详情 <i class="el-icon-right"></i></div>
                </div>
              </div>

            </div>
          </a>

          <div class="page">
            <el-pagination :layout="layout" :pager-count="pageCount" :page-size="page.pageSize"
              :current-page.sync="page.currentPage" :total="page.total" @size-change="chagenum"
              @current-change="chagenum">
            </el-pagination>
          </div>
        </div>


        <div class="informationRight">
          <div class="informationRightOne">
            <a v-for="(item, index) in tRIList" :href="item.id" onclick="return false">
              <div class="list" @click="toDetail(item)">
                <div class="tListImg">
                  <img :src="item.imgUrl" />
                </div>
                <div class="tMess">
                  <div class="tListTitle">{{ item.title }}</div>
                  <div class="tListBottom">
                    <div class="tTime">{{ item.createTime | setItem }}</div>
                    <div class="tFun">查看详情<i class="el-icon-d-arrow-right"></i> </div>
                  </div>
                </div>

              </div>
            </a>
          </div>

          <div class="specialTopic" :class="titleFixed ? 'isFixed' : ''" @click="goThematicList">
            <div class="SecondLevelMess">
              <div class="messImage">
                <div class="messImageTitle">专题</div>
                <img :src="topicList.imgUrl" />
              </div>
              <div class="messBox">
                <div class="messTitle">{{ topicList.topicName }}</div>
                <div class="messTitleT">{{ topicList.title }}</div>
                <div class="messExplain">
                  <div>
                    {{ topicList.introduction }}
                  </div>
                </div>

                <div class="messBottom">
                  <div class="messTime" style="line-height: 36px;font-size: 16.67px">
                    <i class="el-icon-time"></i>
                    <span>{{ topicList.createTime | setItem }}</span>
                  </div>

                  <div class="messFun">
                    <img class="messFunChoose" src="@/assets/images/pc/timeFun.png" />
                    <img class="messFunSelect" src="@/assets/images/pc/timeFunSelect.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import banner from '@/components/pc/banner';
export default {
  name: "informationHome",
  components: {
    banner
  },
  data() {
    return {
      layout: "prev, pager, next, jumper",
      page: {
        total: 0, //总条目数
        pageSize: 11, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      tRIList: [],
      informationTitle:"全部分类",
      titleFixed: false,
      pageCount: 5,
      topicList: {},
      bannerList: [],
      informationClassList: [],
      informationList: [],
      informationType: '',
      ops: {
        bar: {
          keepShow: true,
          background: "#C1C1C1"
        }
      }
    };
  },
  filters: {
    setItem(e) {
      if (e != undefined && e != null && e != '') {
        return e.split(" ")[0]
      } else {
        return "暂无时间"
      }
    }
  },
  created() {
    this.getBannerList();
    this.getInformationClassList();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollTop);
  },
  beforeMount() {
    this.$bus.$on('setChangeInformationClass', (item) => {
      this.changeInformationClass(item)
    })
  },
  mounted() {
    this.getInformationTICList();
    this.getInformationTRIList();
    window.addEventListener('scroll', this.handleScrollTop);
  },

  methods: {
    goThematicList() {
      this.$router.push({
        name: 'thematicList',
      });
    },
    goHome() {
      this.$router.push({
        name: "homePage"
      })
    },
    changeInformationClass(item) {
      console.log(item)
      this.informationType = item.dictValue;
      this.informationTitle = item.dictLabel;
      this.page.currentPage = 1
      this.getInformationList();
    },
    handleScrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let offsetTop = 800;
      console.log('scrollTop', this.informationList.length * 166)

      if (scrollTop > offsetTop && scrollTop < this.informationList.length * 196) {
        this.titleFixed = true;
      } else {
        this.titleFixed = false;
      }
    },
    getBannerList() {
      // 获取轮播图数据
      this.$axios({
        method: "get",
        url: "/official/enterprise/getBanner",
        params: {
          locationType: 1,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.bannerList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInformationTRIList() {
      this.$axios({
        url: "/official/enterprise/getInformationTRIList?pageNum=1&pageSize=3",
        data: {
          // pageNum: 1,
          // pageSize: 3,
          websiteType: 2
        },
        method: 'POST',
      }).then(res => {
        this.tRIList = res.rows;
        console.log('推荐资讯=>', res)
      })
    },
    toDetail(item) {
      this.$router.push({
        name: `informationDetail`,
        params: {
          id: item.id,
          name: this.informationTitle
        },
      });
    },
    getRichText(item) {
      // 提取文字
      const myDiv = document.createElement("div");
      myDiv.innerHTML = item.content;
      return myDiv.innerText;
    },
    getInformationClassList() {
      axios({
        method: "get",
        url: "/official/enterprise/getInformationClassList",
        params: {
          pageNum: 1,
          pageSize: 50,
        },
      }).then((res) => {
        if (res.code === 200) {
          res.rows.unshift({
            dictLabel: '全部',
            dictValue: ''
          })
          this.informationClassList = res.rows;
          this.informationType = this.$route.params.id || res.rows[0].dictValue;
          this.getInformationList();
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    formatterTime(time, type) {
      return moment(time).format(type)
    },
    getInformationList() {
      axios({
        method: "get",
        url: "/official/enterprise/getInformationList",
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
          type: this.informationType,
          websiteType:2
        },
      }).then((res) => {
        if (res.code === 200) {
          this.informationList = res.rows;
          this.page.total = res.total;
          // this.page.total = res.total;
        }
      })
        .catch((err) => {
          console.log(err);
        });
    },
    chagenum(e) {
      this.page.currentPage = e;
      this.getInformationList();
    },
    getInformationTICList() {
      this.$axios({
        url: "/official/enterprise/getInformationTRCList?pageNum=1&pageSize=1",
        method: "POST"
      }).then(res => {
        if (res.code == 200) {
          this.topicList = res.rows[0];
        }
      })
    }
  },
}
</script>

<style src="@/assets/css/pc/informationHome.css" scoped></style>
