var setSet = {
	// 兼职网聚量版
	// qcMa:'qrcode',
	// appid:'wxff1bf17bf9eb8233',
	
	// 兼职网聚合版
	// qcMa:'qrcode1',
	// appid:'wx158b4f7e212c26f8',
	
	// 兼职网聚星版
	// qcMa:'qrcode2',
	// appid:'wxaa750301a705bf24',
	
	// 兼职网聚汇版
	// qcMa:'qrcode3',
	// appid:'wxd6bb36afe29007de',
	
	// 兼职网聚光版
	// qcMa:'qrcode4',
	// appid:'wx03317b7ced113e97',
	
	// 兼职网聚集版
	// qcMa:'qrcode5',
	// appid:'wx4e5a2a7619397272',
	
	// 兼职网聚达版---客户用
	// qcMa:'qrcode6',
	// appid:'wx5f8658af7f68af27',
	
	// 兼职网聚恒版
	// qcMa:'qrcode7',
	// appid:'wx21a1bb73a904a878',
	
	// 兼职网聚速版
	// qcMa:'qrcode8',
	// appid:'wxaba3fe47223cad62',
	
	// 兼职网平台
	// qcMa:'qrcode9',
	// appid:'wxa0321a831da022cb',
	
	// 聚量信息宝
	// qcMa:'qrcode10',
	// appid:'wx0758e143f3ccf11d',
}
let kexiugai = 'wxff1bf17bf9eb8233';//可修改
var list = [
	{qcMa:'qrcode',appid:'wxff1bf17bf9eb8233',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚量版
	{qcMa:'qrcode1',appid:'wx158b4f7e212c26f8',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚合版
	{qcMa:'qrcode2',appid:'wxaa750301a705bf24',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚星版
	{qcMa:'qrcode3',appid:'wxd6bb36afe29007de',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚汇版
	{qcMa:'qrcode4',appid:'wx03317b7ced113e97',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚光版
	{qcMa:'qrcode5',appid:'wx4e5a2a7619397272',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚集版
	{qcMa:'qrcode6',appid:'wx5f8658af7f68af27',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚达版
	{qcMa:'qrcode7',appid:'wx21a1bb73a904a878',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚恒版
	{qcMa:'qrcode8',appid:'wxaba3fe47223cad62',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网聚速版
	{qcMa:'qrcode9',appid:'wxa0321a831da022cb',color:'#07be77',selectColor:'linear-gradient(90deg, #2fd586ff 0%, #2fd58600 100%)'},//兼职网平台
	{qcMa:'qrcode10',appid:'wx0758e143f3ccf11d',color:'#887646',selectColor:'linear-gradient(90deg, #887646ff 0%, #2fd58600 100%)'},//聚量信息宝
]
setSet = list.filter(res => {
	return res.appid==kexiugai;
})[0]
export default setSet;