import axios from '@/axios'
import OSS from "ali-oss";

let ossConfig = null
let ossConfigPromise = null
let ossClient = null
let ossClientPromise = null
// 查询OSS上传配置信息
function getOssConfig(flush = false) {
  if (flush){
    ossConfig = null
    ossConfigPromise = null
  }
  if(!ossConfigPromise){
    ossConfigPromise = new Promise((resolve)=>{
      if(ossConfig){
        resolve(ossConfig);
      }else{
        axios({
          url: '/common/ossconfig',
          baseURL:'http://58.56.114.246:9001/dev-api',
        }).then((res)=>{
          if(res.code==200){
            resolve(res.data)
          }else{
            throw new Error(res.msg)
          }
        })
      }
    })
  }
  return ossConfigPromise;
}

/**
 * 获取OSSclient
 * @param flush 是否强制刷新，重新通过接口生成一个 OssClient
 * @returns {null}
 */
function getOssClient(flush = false){
  if(flush){
    ossClientPromise = null
    ossClient = null
  }
  if(!ossClientPromise){
    ossClientPromise = new Promise((resolve)=>{
      if(ossClient){
        return resolve(ossClient);
      }
      getOssConfig(flush).then(config => {
        ossClient = new OSS({
          bucket: 'jianzhiwang-app',
          ...config
        });
        resolve(ossClient)
      })
    })
  }
  return ossClientPromise
}

/**
 * 上传文件到 OSS
 * @param file
 * @param fileName
 * @returns {*}
 */
export function uploadFileToOss(file,fileName = null){
  return getOssClient().then(function(client){
    if(!fileName){
      fileName = Date.now() + file.name
    }
    return client.put(fileName, file)
  }).catch(e=>{
    if(e.name == "InvalidAccessKeyIdError"){
      //KEY失效后，通过接口重新再获取一次配置信息上传
      return getOssClient(true).then(function(client){
        if(!fileName){
          fileName = Date.now() + file.name
        }
        return client.put(fileName, file)
      })
    }
    throw e
  });
}
