import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import index from '@/views/pc/home.vue'
import mhome from '@/views/h5/index.vue'
import NotFound from '@/views/NotFound.vue'
import h5 from './h5/index'
import pc from './pc/index'

// 特殊页面---企微认证
import qwAuthentication from '@/views/specialPages/qwAuthentication.vue'
import sendLink from '@/views/specialPages/sendLink.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [

  {
    path: '/',
    name: 'index',
    component: index,
    meta:{
      metaInfo:{
        title:'pcIndex',
        keywords:'pcIndex',
        description:'pcIndex',
      }
    },
    redirect: '/',
    children:[
      ...pc,
    ],
  },
  {
    path: '/home',
    name: 'mhome',
    component: mhome,
    meta:{
      mobile:true,
      metaInfo:{
        title:'h5home',
        keywords:'h5home',
        description:'h5home',
      }
    },
    redirect:'/homePage-m',
    children:[
      ...h5,
    ],
  },
  {
    path: '/qwAuthentication',
    name: 'qwAuthentication',
    component: qwAuthentication,
    meta:{
      special:true
    }
  },
  {
    path: '/sendLink',
    name: 'sendLink',
    component: sendLink,
    meta:{
      special:true
    }
  },
  {
    path: '*',
    component: NotFound,
  }
]
let i = location.href.indexOf('localhost') > -1
let router = new VueRouter({
  mode: i ? 'hash' :'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, form, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // if (to.meta.metaInfo) {
  //   // store.commit("CHANGE_META_INFO", to.meta.metaInfo)
  //   store.commit("CHANGE_META_INFO",{
  //     title: '兼职网-做兼职找工作就上兼职网',
  //     keywords: '兼职网,兼职网官网,兼职网站,网络兼职,山东兼职网,兼职,高薪兼职,副业,日结,灵活用工,兼职网信息技术,大学生兼职,网赚',
  //     description: '兼职网（济南）信息技术有限公司是国家级高新技术企业，兼职网围绕企业与个人需求，解决了大学生在校兼职、勤工俭学、灵活就业需求，为中国近千万大学生，提供更多有趣的兼职任务，满足兴趣驱动的职业追求，引领新时代。',
  //   })
  // }
  let hasParams = Object.keys(to.params).length > 0
  if(hasParams){
    let historyParams = JSON.parse(localStorage.getItem('routerParams'))
    if(historyParams) {
      let params = {}
      params[to.name] = to.params
      let newParams = Object.assign(historyParams, params)
      localStorage.setItem('routerParams', JSON.stringify(newParams))
      // console.log(newParams,'newParams')
    } else {
      let params = {}
      params[to.name] = to.params
      localStorage.setItem('routerParams', JSON.stringify(params))
    }
  }
  next()
})

router.getParams = function () {
  let historyParams = JSON.parse(localStorage.getItem('routerParams'))
  if(historyParams[router.currentRoute.name]) {
    return historyParams[router.currentRoute.name]
  } else {
    return {}
  }
}
 
// 生成sitemap.xml需要，勿删
// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     if(route.path==='/'){
//       route.path=''
//     }
//     const path = `${pre}${route.path}`;
 
//     if (route.path !== '*') {
//       array.push(path);
//     }
 
//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}`));
//     }
//  console.log(array,'哈哈哈')
//     return array;
//   }, []);
// }
// function getRoutesXML() {
//   console.log(router.options.routes,123)
//   const list = getRoutesList(router.options.routes, 'https://www.jianzhinet.cn')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n');
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }
// console.log(getRoutesXML())
export default router
