import axios from 'axios'
import router from '@/router'
import { Notification } from 'element-ui';
import { Toast } from 'vant';
import utils from '@/assets/utils/uitls'

const isMobile = utils.isMobile //是否是H5
let isDispose = true //全局处理code非200请求

axios.defaults.baseURL = process.env.VUE_APP_JZWB_URL2;
// axios.defaults.timeout = 5000;
axios.defaults.withCredentials  = true;

// 处理axios请求
axios.interceptors.request.use(function (config) {
    const uinfo = localStorage.getItem("userInfo")
    const userInfo = uinfo ? JSON.parse(uinfo) : null
    const userId = userInfo&&userInfo.id ? userInfo.id : null
    const token = localStorage.getItem('token') || null
    if(userId&&config.data){
        if(config.data.isUserId == "false"){
			delete config.data.isUserId
		}else{
			Object.assign(config.data,{userId})
		}
    }
    // 配置token和userid
    if(token && userId){
        config.headers['Authorization'] = 'Bearer ' + token
        config.headers['userId'] = userId
    }
    // 是否需要**自定义**处理code非200
    if(config.needPrivate){
        isDispose=false
        // delete config.needPrivate
    }else{
        isDispose=true
    }
    return config;
}, function (error) {
    if(isMobile()){
        Toast.fail('网络异常');
    }else{
        Notification.error("网络异常")
    }
    return Promise.reject(error);
});

// 处理axios响应
axios.interceptors.response.use(res => {
    if(res.data.code==200){
        return res.data;
    }else if(!res.config.needPrivate && res.data.code==401){// 认证失败，无法访问系统资源
        if(isMobile()){
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            router.push('/login-m')
        }
        return Promise.reject(new Error('宝儿，要先登录哦'))
        // router.push("/login")
    }else if(res.data.code==400){
		return res.data
			
	}else if(!res.config.needPrivate && res.data.code != 200) { //全局处理非200
        // Notification({
        //     title:"错误",
        //     message:res.data.msg || '系统错误',
        //     type:"error",
        //     duration:2000,
        // })
        // return Promise.reject(new Error('接口报错啦'))
        let url = '/mqtt/actionIncentive/actionLogPc' //过滤日志接口
        if(isMobile() && res.request.responseURL.indexOf(url)==-1){
            Toast.fail(res.data.msg || '系统错误');
        }
    }else if(res.config.needPrivate){//不需要全局处理
        console.log('res.da', res)
        return res.data
    }
},error => {
    let {message} = error;
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    if(isMobile()){
        // Toast.fail(message);
    }else{
        Notification.error(message)
    }
    Promise.reject(error)
});

export default axios
