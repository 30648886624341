<template>
  <!-- 求职保障 -->
<div class="needJob">
<!-- 顶部返回部分 -->
<div class="topBack">
  <div class="backIcon" @click="$router.go(-1)">
    <i class="el-icon-arrow-left"></i>
  </div>
  <span>求职保障</span>
</div>
<img src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681476078794qzbz.png" alt="求职保障">
</div>
</template>

<script>
export default {
}
</script>

<style scoped>
/* 顶部返回区域 */
.topBack{
  width: 100vw;
  height: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  background-color: #ffffff;
  color: #141419;
  font-size: 1.13rem;
  font-weight: 600;
  font-family: "PingFang SC";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.backIcon{
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4rem;
}
.needJob>img{
  width: 100%;
  height: auto;
}
</style>