<template>
  <!-- 支付弹框 -->
<div>
  <van-popup v-model="maskShow" round position="bottom" :safe-area-inset-bottom="true">
    <!-- 标题 -->
    <div class="maskTitle">请选择支付方式</div>
    <!-- 内容 -->
    <div class="maskCont">
      <div v-for="(item,index) in payType" :key="index" class="maskCont_item"
        :class="chooseIndex==index?'maskCont_item_check':''" @click="chooseIndex=index">
        <div class="maskCont_left">
          <img :src="require('@/assets/images/h5/detail/'+item.icon)" :alt="item.payName" />
          <span>{{ item.payName }}</span>
          <span class="maskCont_left_cont">{{ item.cont }}</span>
        </div>
        <img v-if="chooseIndex==index" src="@/assets/images/h5/detail/yes.png" alt="选中" />
        <img v-else src="@/assets/images/h5/detail/no.png" alt="未选中" />
      </div>
    </div>
    <!-- 按钮 -->
    <div class="maskBtn" @click="confirm">确认</div>
  </van-popup>
  <div id="pay" v-show="false"></div>
</div>
</template>

<script>
export default {
  props: {
    applyObj: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      maskShow:false,//弹框收起展开
      payType:[
        { payName:'微信支付',cont:'',icon:'vxzf.png',authCode:1 },
        { payName:'支付宝',cont:'支付花呗分期',icon:'zfb.png',authCode:2 },
      ],
      chooseIndex:0,
    }
  },
  methods: {
    // 弹框开关
    maskSwitch(bool) {
      this.maskShow=bool
    },
    // 确认事件
    confirm(){
      let authCode = this.payType[this.chooseIndex].authCode
      let params={
        positionId:this.applyObj.positionId,
        positionTitle:this.applyObj.positionTitle,
        payType:3,//默认传3
        payMoney:this.applyObj.payMoney,
        authCode
      }
      this.$common.showLoading()
      this.$axios({
        method:'POST',
        url:'/app/position/h5Pay',
        params,
        needPrivate:true,
      }).then(res=>{
        this.$common.hideLoading()
        console.log('支付回调结果',res.data.orderNo)
        this.maskShow=false
        if(res.code==200){
          if(authCode==1){//微信支付
            let h5wx = res.data&&res.data.h5Url ? res.data.h5Url.split('h5_url=') : []
            if(h5wx.length == 2){
              localStorage.setItem('orderId',res.data.orderNo)
              window.location.href = h5wx[1]
            }else{
              this.$toast('微信支付失败')
            }
          }else if(authCode==2){//支付宝支付
            localStorage.setItem('orderId',res.data.orderNo)
            // let str = 'https://openapi.alipay.com/gateway.do?'
            // window.open(str + res.data.aliPaySdk)
            document.getElementById('pay').innerHTML = res.data.aliPaySdk
            document.forms[0].submit()
          }
        }else{
          this.$emit('showError',JSON.stringify(res.data))
        }
      })
    },
  },
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.maskTitle{
  color: #333333;
  font-size: 1.13rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.35rem;
  padding: 1.5rem 1.31rem;
}
.maskCont{
  padding: 0 1.06rem;
}
.maskCont_item{
  height: 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.maskCont_item>img{
  width: 1.5rem;
  height: 1.5rem;
}
.maskCont_left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #414141;
  font-size: 1.13rem;
  font-weight: 500;
  font-family: "PingFang SC";
  line-height: 1.35rem;
}
.maskCont_left>img{
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.8rem;
}
.maskCont_left_cont{
  color: #818181;
  font-weight: 500;
  font-family: "PingFang SC";
  line-height: 1.35rem;
  font-size: 0.88rem;
  margin-left: 0.6rem;
}
.maskBtn{
  width: 21.94rem;
  height: 2.91rem;
  line-height: 2.91rem;
  border-radius: 0.63rem;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  margin: 3rem auto 2rem;
  color: #ffffff;
  font-size: 0.88rem;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: center;
  letter-spacing: 1px;
}
</style>