<template>
<div>
    <div class="mapHeader">
        <div class="header-img">
            <img src="@/assets/images/pc/headerImg.png" />
        </div>
        <div class="BreadCrumbs">
            <div class="BreadCrumbs-box">
                <span @click="goHome">首页</span>
                <span> <i class="el-icon-arrow-right"></i> </span>
                <span style="color: #00A870">网站地图</span>
            </div>
        </div>
    </div>

    <!--分类-->
    <div class="classificationBox">
        <div class="classificationTitle">首页</div>
        <div class="classificationList">
            <div @click="anchorElement('homePage', 'job')">海量兼职岗位</div>
            <div @click="anchorElement('homePage', 'service')">六大服务赋能企业急速招聘</div>
            <div @click="anchorElement('homePage', 'news')">新闻活动</div>
            <div @click="anchorElement('homePage', 'company')">全球合作商家</div>
            <div @click="anchorElement('homePage', 'contact')">联系我们</div>
            <div @click="anchorElement('homePage', 'hot-position')">热门职业</div>
        </div>

        <div class="classificationTitle">兼职</div>
        <div class="classificationList">
            <div @click="goPartTJ(1)">兼职职位</div>
            <div @click="goPartTJ(2)">课程兼职</div>
            <div @click="goPartTJ(3)">本地兼职</div>
            <div @click="goPartTJ(4)">全部岗位</div>
        </div>


        <div class="classificationTitle">企业服务</div>
        <div class="classificationList">
            <div @click="toOutSide">商家登录地址</div>
        </div>


        <div class="classificationTitle">资讯</div>
        <div class="classificationList">
            <div  @click="anchorElement('informationHome', '', item.dictValue)" v-for="item in informationClassList" :key="item.dictValue">{{ item.dictLabel }}</div>
        </div>


<!--        <div class="classificationTitle">万企合作</div>-->
<!--        <div class="classificationList">-->
<!--            <div v-for="(item,index) in enterpriseList" :key="item.id" v-if="index<=7" @click="anchorElement('enterprise', '', item.id)">{{ item.title }}</div>-->
<!--        </div>-->
<!--        <div class="classificationList" >-->
<!--            <div style="line-height: 30px;margin-bottom: 30px" v-for="(item,index) in enterpriseList" :key="item.id" v-if="index>7" @click="anchorElement('enterprise', '', item.id)">{{ item.title }}</div>-->
<!--        </div>-->


        <div class="classificationTitle">关于我们</div>
        <div class="classificationList">
            <div @click="anchorElement('contact', '')">联系我们</div>
            <div @click="setTypeIndex(1)">找兼职</div>
            <div @click="setTypeIndex(2)">我要招聘</div>
        </div>

    </div>

</div>
</template>

<script>
export default {
  name: "websiteMap",
  data() {
      return {
          informationClassList: [],
          enterpriseList:[
            {
              title:"名企直聘",
              id:"1"
            },
            {
              title: "蓝领普工",
              id:"2"

            },
            {
              title: "超市零售",
              id:'3'
            },
            {
              title: "餐饮酒店",
              id:'4'
            },
            {
              title: "电子商务",
              id:'5'
            },
            {
              title: "广告营销",
              id:'6'
            },
            {
              title: "生活服务",
              id:'7'
            },
            {
              title: "互联网",
              id:'8'
            },
            {
              title: "物流仓储",
              id:'9'
            },
            {
              title: "企业服务",
              id:'10'
            },
            {
              title: "教育培训",
              id:'11'
            },
            {
              title: "其他行业",
              id:'12'
            }
          ],
      }
  },
  created() {
    this.getInformationClassList()
  },
  methods:{
      goPartTJ(e){
          if(this.$route.fullPath == '/PartTJ'){
              window.scrollTo({
                  top:0,
                  left:0,
                  behavior: "smooth"
              });
              this.$bus.$emit('setList', e)
          }else {
              this.$router.push({
                  path:"/PartTJ"
              });
              setTimeout(()=>{
                  window.scrollTo({
                      top:0,
                      left:0,
                      behavior: "smooth"
                  });
                  this.$bus.$emit('setList', e)
              },300)

          }
      },
      goHome(){
        this.$router.push({path:'/homePage'})
      },
      anchorElement(name, element, id){
          this.$router.push({
            name: name,
            params:{
              element,
              id
            }
          })
      },
      toOutSide(){
        window.open('https://business.jianzhinet.cn/', "_blank")
      },
      getInformationClassList() {
        this.$axios({
          method: "get",
          url: "/official/enterprise/getInformationClassList",
          params: {
            pageNum: 1,
            pageSize: 50,
          },
        })
            .then((res) => {
              if (res.code === 200) {
                this.informationClassList = res.rows.slice(0, 7);

                this.informationClassList.push({
                  dictLabel: '更多资讯',
                  dictValue: ''
                })

              }
            })
            .catch((err) => {
              console.log(err);
            });
      },
      setTypeIndex(e){
        this.$bus.$emit('getStudentName', e)
      },
    }
}
</script>

<style scoped>
.mapHeader{
    width: 100%;
}

.header-img{
    width: 100%;
}
.header-img>img{
    width: 100%;
}
.BreadCrumbs{
    width: 100%;
    height: 50.83px;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.BreadCrumbs-box{
    width: 1200px;
    margin: auto;
    color: #666;
    font-size: 16.67px;
    cursor: pointer;
}
.classificationBox{
    width: 1200px;
    margin: 55px auto;
}
.classificationTitle{
    color: #000000ff;
    font-size: 20px;
    font-family: "PingFang SC";
}
.classificationList{
    display: flex;
    flex-wrap: wrap;
}
.classificationList>div{
    color: #7c7c7cff;
    font-size: 16.67px;
    font-family: "PingFang SC";
    margin-right: 34px;
    line-height: 76px;
    cursor: pointer;
}
</style>

