<template>
    <div>
        <div style="position: fixed;top: 0%;width: 100vw;height: 2.69rem;z-index: 999;">
            <van-nav-bar title="我的报名" left-arrow @click-left="onClickLeft" />
        </div>
        <div style="padding-top:3.69rem;background-color:#FFFFFF;position: fixed;width: 100vw;z-index: 99;">
            <ul style="display: flex;justify-content: space-between;margin-left: 1.2rem;margin-right: 1.2rem;">
                <li :class="active == index ? 'li' : 'li2'" v-for="(item, index) in list" @click="onClick(index)">
                    {{ item.title }}
                    <div :class="active == index ? 'li_bt' : ''"></div>
                </li>
            </ul>
            <div style="display: flex;background-color: #FFFFFF;position: relative;">
                <div :class="typeScreenItem.type == '全部' ? 'typesof' : 'typesof2'" @click="clicktypesof">{{
                    typeScreenItem.type
                    == '全部' ? typeScreenItem.type + '类型' :
                    typeScreenItem.type }}<i class="el-icon-caret-bottom"></i></div>
                <div class="typesofcontent" v-if="showtypesofcontent">
                    <div @click="clicktaskTypeList(item)"
                        :class="item.id == typeScreenItem.id ? 'taskTypeList' : 'taskTypeList2'"
                        v-for="(item, index) in taskTypeList" :key="index">{{ item.type }}<i
                            v-if="item.id == typeScreenItem.id" style="margin-left: 2.35rem ;" class="el-icon-check"></i>
                    </div>
                </div>
                <div :class="timeScreenItem.type == '全部' ? 'typesof' : 'typesof2'" @click="clickalltime">{{
                    timeScreenItem.type
                    == '全部' ? timeScreenItem.type + '时间' :
                    timeScreenItem.type }}<i class="el-icon-caret-bottom"></i></div>
                <div class="time" v-if="showtime">
                    <div @click="clicktimeList(item)" :class="item.id == timeScreenItem.id ? 'timeList' : 'timeList2'"
                        v-for="(item, index) in timeList" :key="index">{{
                            item.type }}<i v-if="item.id == timeScreenItem.id" style="margin-left: 2.35rem ;"
                            class="el-icon-check"></i></div>
                </div>
            </div>
        </div>
        <div class="van-tab">
            <van-list v-model="loading" :finished="finished" :finished-text="finishedtext" :immediate-check="false"
                offset="10" @load="onLoad">
                <div slot="default" style="display: flex;flex-direction: column;" class="box"
                    v-for="(item, index) in listArray" :key="index" @click="goRegistrationDetails(item)">
                    <div style="display: flex;justify-content: space-between;margin-top: 0.7rem;">
                        <div class="title">{{ item.positionTitle }}</div>
                        <div class="money">
                            <div v-if="item.amountType == 1"> {{ item.amount / 100 }}元/{{
                                typeList[item.settlementUnit]
                            }}
                            </div>
                            <div v-if="item.amountType == 2">{{ item.amountBegin / 100 }}-{{ item.amountEnd / 100
                            }}元/{{ typeList[item.settlementUnit] }}</div>
                        </div>
                    </div>
                    <div class="distance" v-if="item.isAtl == 2">距我 {{ item.positionDistance }}km<span
                            v-if="item.positionAddress"
                            style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">｜{{ item.positionAddress
                            }}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 0.56rem;">
                        <div class="state2" v-if="item.enrollmentStatus == 1"><img
                                style="width:0.88rem;margin-bottom: 0.31rem;margin-right: 0.38rem;"
                                src="@/assets/image/mine/dairuqu.png" alt="已录取">待录取</div>
                        <div class="state" v-if="item.enrollmentStatus == 2"><img
                                style="width:0.88rem;margin-bottom: 0.31rem;margin-right: 0.38rem;"
                                src="@/assets/image/mine/mine_yiruqu.png" alt="待录取">已录取</div>
                        <div class="state3" v-if="item.enrollmentStatus == 3"><img
                                style="width:0.88rem;margin-bottom: 0.31rem;margin-right: 0.38rem;"
                                src="@/assets/image/mine/yijieshu.png" alt="待录取">未录取</div>
                        <div class="Similar" v-if="item.positionState != 1 && item.enrollmentStatus == 3"
                            @click.stop="clickSimilar(item)">
                            <button>相似推荐</button>
                        </div>
                        <div v-else class="contact" @click.stop="clickcontact(item)">联系商家</div>
                    </div>
                </div>
            </van-list>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;" v-if="empty">
            <div style="margin-top: 6rem;margin-bottom: 2rem;"><img style="height: 10.3rem;"
                    src="@/assets/image/mine/zanwushuju.png" alt="空空如也"></div>
            <div style="font-size: 1.2rem;margin-bottom: 0.5rem;">空空如也</div>
            <div>什么都没有，抓紧报名吧</div>
        </div>
        <van-popup v-model="Contactmerchant" round position="bottom" :style="{ height: 13.5 + 'rem' }">
            <div style="display: flex;flex-direction: column;">
                <div class="Contact_sj">联系商家</div>
                <div class="vx">
                    <div><img style="height:1.88rem;margin-right: 0.31rem;" src="@/assets/image/mine/Slice 483.png"
                            alt="">微信：{{ wechatNum }}</div>
                    <div @click="copyWx"><img style="height: 2.19rem;" src="@/assets/image/mine/Slice 484.png" alt=""></div>
                </div>
                <div class="sure" @click="clicksure">确认报名</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: 'Myregistration-m',
    data() {
        return {
            active: 0,
            list: [{
                id: 1,
                title: '全部'
            },
            {
                id: 2,
                title: '待录取'
            },
            {
                id: 3,
                title: '已录取'
            },
            {
                id: 4,
                title: '已结束'
            },
            ],
            taskTypeList: [{
                type: '全部',
                id: ''
            }, {
                type: '在线课程',
                id: 1
            }, {
                type: '兼职职位',
                id: 2
            }],
            timeList: [{
                type: '全部',
                id: ''
            }, {
                type: '7天',
                id: 1
            }, {
                type: '30天',
                id: 2
            }],
            showtypesofcontent: false,//展示全部类型
            showtime: false,//展示全部时间
            typeScreenItem: {
                type: '全部',
                id: ''
            },
            timeScreenItem: {
                type: '全部',
                id: ''
            },
            pageNum: 1, //第一页
            listArray: [],//我的报名列表
            typeList: ['其他', '小时', '天', '周', '月', '次', '单'],
            loading: false,//触底刷新
            finished: false,//刷新
            finishedtext: '没有更多了',
            empty: false,
            Contactmerchant: false,//联系商家
            wechatNum: "",
        }
    },
    created() {
        this.active = this.$router.getParams().id
        this.getlist(1)
    },
    methods: {
        //相似推荐
        clickSimilar(item) {
            this.$router.push('/homePage-m')

            // //判断是什么方式报名
            // if (item.positionType == 1) {
            //     this.$router.push({ name: 'taskDetail-m', params: { id: item.positionId } })
            //     this.$router.push('taskDetail-m?id=' + item.positionId)
            // } else {
            //     this.$router.push({ name: 'courseDetail-m', params: { id: item.positionId } })
            //     this.$router.push('courseDetail-m?id=' + item.positionId)
            // }
        },
        //确认报名
        clicksure() {
            this.Contactmerchant = false
            // this.$JsCopy.iosCopy(this.wechatNum)
            this.$common.onCopy(this.wechatNum)
            this.$toast('微信号复制成功，请到微信上添加老师微信!')
        },
        //复制微信号
        copyWx() {
            // this.$JsCopy.iosCopy(this.wechatNum)
            this.$common.onCopy(this.wechatNum)
            this.$toast('微信号复制成功，请到微信上添加老师微信!')
        },
        //联系商家跳转
        clickcontact(item) {
            let userId = JSON.parse(localStorage.getItem('userInfo'))
            this.$common.showLoading()
            this.$axios({
                url: '/app/message/getAppWechatNum',
                params: {
                    id: item.positionId,
                    userId: userId.id
                },
            }).then(res => {
                this.$common.hideLoading()
                if (res.code == 200) {
                    this.wechatNum = res.data.wechatNum
                    this.Contactmerchant = true
                }
            })
        },
        onClickLeft() {
            this.$router.push('/PersonalCenter-m');
        },
        //跳转报名详情
        goRegistrationDetails(e) {
            this.$router.push({ name: 'RegistrationDetails-m', params: e })
            // this.$router.push('');
        },
        //切换标题
        onClick(name, title) {
            console.log(name, title, "nametitle");
            this.active = name
            this.listArray = []
            this.finished = false
            this.loading = true
            this.pageNum = 1
            this.getlist()
        },
        //全部类型
        clicktypesof() {
            this.showtypesofcontent = !this.showtypesofcontent
            this.showtime = false
        },
        //全部类型内容展示
        clicktaskTypeList(e) {

            this.typeScreenItem = e
            this.showtypesofcontent = false
            this.listArray = []
            this.getlist()
        },
        //全部时间
        clickalltime() {
            this.showtime = !this.showtime
            this.showtypesofcontent = false
        },
        //全部时间内容展示
        clicktimeList(e) {

            this.timeScreenItem = e
            this.showtime = false
            this.listArray = []
            this.getlist()
        },
        //我的报名列表
        getlist(e) {
            this.$common.showLoading()
            console.log(e);
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.$axios({
                method: 'post',
                url: '/app/collect/getPersonList',
                params: {
                    pageSize: 10,
                    pageNum: this.pageNum,
                },
                data: {
                    enrollmentStatus: this.active + 1 + '',
                    UserLongitude: localStorage.getItem('longitude') || 0,
                    UserLatitude: localStorage.getItem('latitude') || 0,
                    enrollType: this.typeScreenItem.id || '', // 职位类型 （ '' 全部，1 线上，2 线下）
                    enrollTimeType: this.timeScreenItem.id || '', // 时间类型（ '' 全部，1 7天，2 30天）
                    userId: userInfo.id
                }
            }).then((res) => {
                this.$common.hideLoading()
                console.log(res, "res33333");
                console.log(res);
                this.loading = false
                if (res.code == 200) {
                    this.finished = Boolean(res.data.length<7)
                    if(this.finished){
                        this.finishedtext = '没有更多了'
                    }
                    if (this.pageNum == 1) {
                        if (res.data.length == 0) {
                            this.finished = true
                            this.finishedtext = ''
                            this.listArray = []
                            this.empty=true
                        }else{
                            this.listArray = res.data
                            this.empty=false
                        }
                    } else {
                        this.listArray.push(...res.data)
                    }
                }
            })
        },
        //触底刷新
        onLoad() {
            this.pageNum++
            console.log('触底了');
            this.getlist(2)
        },
        scrolls(e) {
            console.log(e);
        }
    }
}
</script>

<style scoped lang="less">
.typesof {
    width: 4.88rem;
    height: 1.5rem;
    background: #F6F6F6;
    border-radius: 0.75rem;
    margin-left: 1.19rem;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.44rem;
}

.typesof2 {
    width: 4.88rem;
    height: 1.5rem;
    background: #E5FFF5;
    border-radius: 0.75rem;
    margin-left: 1.19rem;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.44rem;
    color: #07BE77;
}

.typesofcontent {
    position: absolute;
    width: 9.5rem;
    height: 7.13rem;
    background: #FFFFFF;
    box-shadow: 0 0.13rem 0.25rem 0 #3333334d;
    border-radius: 0.38rem;
    top: 2.94rem;
    left: 1.19rem;
    z-index: 99;

    .taskTypeList {
        margin-top: 0.75rem;
        margin-left: 1.25rem;
        color: #07BE77;
    }

    .taskTypeList2 {
        margin-top: 0.75rem;
        margin-left: 1.25rem;
        color: #000000;
    }
}

.time {
    position: absolute;
    width: 9.5rem;
    height: 7.13rem;
    background: #FFFFFF;
    box-shadow: 0 0.13rem 0.25rem 0 #3333334d;
    border-radius: 0.38rem;
    top: 2.94rem;
    left: 7.31rem;
    z-index: 99;

    .timeList {
        margin-top: 0.75rem;
        margin-left: 1.25rem;
        color: #07BE77;
    }

    .timeList2 {
        margin-top: 0.75rem;
        margin-left: 1.25rem;
        color: #000000;
    }
}

.box {
    // height: 6.9rem;/
    width: 21.94rem;
    margin-top: 0.63rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: #FFFFFF;
    border-radius: 0.75rem;
    padding-bottom: 0.3rem;

    .distance {
        width: 14rem;
        font-weight: 400;
        font-size: 0.75rem;
        color: #333333;
        margin-left: 0.7rem;
        margin-top: 0.56rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .title {
        width: 10.81rem;
        color: #000000ff;
        font-size: 1rem;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 0.75rem;
    }

    .money {
        // width: 9rem;
        color: #fe0000ff;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 0.75rem;
    }

    .state {
        width: 4.5rem;
        height: 1.5rem;
        background-color: #52DC87;
        border-radius: 0.75rem;
        text-align: center;
        line-height: 1.5rem;
        font-weight: 500;
        font-size: 0.75rem;
        color: #FFFFFF;
        margin: auto 0;
        margin-left: 0.75rem;
    }

    .state2 {
        width: 4.5rem;
        height: 1.5rem;
        background-color: #52bddc;
        border-radius: 0.75rem;
        text-align: center;
        line-height: 1.5rem;
        font-weight: 500;
        font-size: 0.75rem;
        color: #FFFFFF;
        margin: auto 0;
        margin-left: 0.75rem;
    }

    .state3 {
        width: 4.5rem;
        height: 1.5rem;
        background: #FEDFDA;
        border-radius: 0.75rem;
        text-align: center;
        line-height: 1.5rem;
        font-weight: 500;
        font-size: 0.75rem;
        color: #F4351A;
        margin: auto 0;
        margin-left: 0.75rem;
    }

    .Similar {
        width: 5.75rem;
        height: 2rem;
        background-image: linear-gradient(90deg, #07bb7e4d 0%, #06ad944d 100%);
        border-radius: 1rem;
        opacity: 1;
        text-align: center;
        line-height: 2rem;
        color: #FFFFFF;
        margin-top: 0.2rem;
        font-size: 0.75rem;
        margin-right: 0.75rem;
    }

    .contact {
        width: 5.75rem;
        height: 2rem;
        background-image: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
        border-radius: 1rem;
        opacity: 1;
        text-align: center;
        line-height: 2rem;
        color: #FFFFFF;
        margin-top: 0.2rem;
        font-size: 0.75rem;
        margin-right: 0.75rem;
    }
}

.li {
    width: 2.75rem;
    height: 2.3rem;
    font-weight: 700;
    font-size: 0.88rem;
    color: #07BE77;
    text-align: center;
}

.li2 {
    width: 2.75rem;
    height: 2.3rem;
    font-weight: 500;
    font-size: 0.88rem;
    color: #666666;
    text-align: center;
}

.li_bt {
    margin-top: 0.3rem;
    width: 2.75rem;
    height: 0.13rem;
    background: #07BE77;
}

.van-tab {
    padding-top: 8.88rem;
    height: 100%;
}

.Contact_sj {
    color: #333333ff;
    font-size: 1.25rem;
    font-weight: 600;
    margin: auto;
    margin-top: 1.5rem;
}

.vx {
    display: flex;
    justify-content: space-between;
    margin-left: 1.25rem;
    margin-right: 1.38rem;
    margin-top: 1.44rem;
    margin-bottom: 1.44rem;
}

.sure {
    width: 21.56rem;
    height: 3.13rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    color: #ffffffff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    line-height: 3.13rem;
    margin: auto;
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}

/deep/.van-nav-bar .van-icon {
    color: #000000;
    font-size: 1.3rem;
}

/deep/.van-tabs__line {
    background-color: #07BE77;
}

/deep/.van-tab--active {
    color: #07BE77;
}
</style>