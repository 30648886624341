<script src="../../axios/index.js"></script>
<template>
  <div class="box">
      <!--修饰的额-->
      <img class="embellish1" src="@/assets/images/pc/embellish1.png" />
      <img class="embellish2" src="@/assets/images/pc/embellish2.png" />
      <div class="loginBox">
          <div class="loginBoxLeft">
              <img src="@/assets/images/pc/loginBoxLeft.png" />
          </div>

          <div class="loginBoxRight">
              <div class="rightTitle">登录</div>
              <div class="gang"></div>
              <div class="hello">欢迎来到兼职网</div>

              <div class="inputBox">
                  <div class="inputMess">
                      <div class="inputTitle">手机号</div>

                      <div class="input">
                          <input placeholder="请输入手机号" v-model="phone">
                      </div>
                      <div class="ts">
                          <span v-if="phoneTs">{{phoneTs}}</span>
                      </div>
                  </div>

                  <div class="inputMess">
                      <div class="inputTitle">验证码</div>

                      <div class="input">
                          <input placeholder="请输入验证码" v-model="code" @keyup.enter="goIndex">

                          <div v-if="timeTrue" class="getCOde"  @click="getcode">获取验证码</div>
                          <div v-if="!timeTrue" class="time">再次发送({{time}})s</div>

                      </div>
                      <div class="ts">
                          <span v-if="codeTs">{{codeTs}}</span>
                      </div>
                  </div>

              </div>

              <div v-if="logoTrue" class="login">登录</div>
              <div v-if="!logoTrue" class="loginT" @click="goIndex">登录</div>

              <div class="agreement" @click="agree = !agree">
                  <i v-if="agree" class="el-icon-success" ></i>
                  <i v-if="!agree" style="color:#08BE79;" class="el-icon-success"></i>
                  我已阅读并同意<span @click="goyh">《用户协议》</span><span @click="goys">《隐私协议》</span>
              </div>

          </div>
      </div>
      <img class="embellish3" src="@/assets/images/pc/embellish3.png" />
  </div>
</template>

<script>

export default {
    name: "login",
    data(){
        return{
            radio: '1',
            agree:true,
            phoneTs:"",
            phone:"",
            time:60,
            timeTrue:true,
            codeTs:"",
            logoTrue:true,
            code:""
        }
    },
    watch:{
        'code':{
            handler(newval){
                console.log(newval)
                if (newval.length==6){
                    this.logoTrue = false
                }else {
                    this.logoTrue = true
                }
            },
            immediate:true,
        }
    },
    methods:{
        getcode(){
            var TEL_REGEXP = /^1[3|4|5|6|7|8|9]\d{9}$/;;
            if(!TEL_REGEXP.test(this.phone)){
                this.phoneTs = "手机号格式错误"
                setTimeout(()=>{
                    this.phoneTs = "";
                },3000)
            }else {
                this.timeTrue = false;
                let timers = setInterval(()=>{
                    this.time--;
                    if(this.time == 0){
                        clearInterval(timers);
                        this.timeTrue = true;
                        this.time = 60;

                    }
                },1000)
                this.$axios({
                    url: "/official/enterprise/getLoginCode?phone="+this.phone,
                    method:"POST"
                }).then((res)=>{
                    if(res.code==200){
                        this.$notify({
                            title: '成功',
                            message: '验证码发送成功',
                            type: 'success'
                        });
                    }
                    console.log(res)
                })
            }
        },
        goIndex(){
            var TEL_REGEXP = /^1[3|4|5|6|7|8|9]\d{9}$/;;
            if(!TEL_REGEXP.test(this.phone)){
                this.$notify({
                    title: '错误',
                    message: '手机号格式错误',
                    type: 'error'
                });
                return;
            }else if(this.agree){
                this.$notify({
                    title: '错误',
                    message: '请先勾选用户协议',
                    type: 'error'
                });
                return;
            }else {
                this.$axios({
                    url: "/official/enterprise/loginByCode",
                    data:{
                        code:this.code,
                        phone:this.phone
                    },
                    method:"POST",
                    needPrivate:true,
                }).then(res => {
                    console.log('res=>',res)
                    if(res.code == 200){
                        localStorage.setItem("infoAvars",res.data.imgUrl)
                        this.$bus.$emit("setImg",res.data.imgUrl)
                        this.$router.push({
                            name:"homePage"
                        })
                    }else if(res.code != 200) {
                        this.codeTs = res.msg
                        setTimeout(()=>{
                            this.codeTs = "";
                        },3000)
                    }
                })
            }

        },
        goyh(){
            this.$router.push({
                name:'userxy'
            })
        },
        goys(){
            this.$router.push({
                name:'userys'
            })
        }
    }
}
</script>

<style src="@/assets/css/pc/loginIn.css" scoped></style>