<template>
    <div class="page">
        <!-- <div class="top">
            <van-nav-bar title="新闻资讯" />
        </div> -->
        <div style="background-color: #F9F9F9;">
            <van-swipe :autoplay="3000" indicator-color="white" :show-indicators="false">
                <van-swipe-item v-for="item in image" :key="item.id" @click="swiperClick(item)">
                    <van-image class="imgDontClick" style="display:block;height: 11.94rem;" :src="item.imgUrl" />
                    <div v-show="item.videoUrl" class="mask_video">
                        <span class="videoBtn"><i class="el-icon-caret-right"></i></span>
                    </div>
                </van-swipe-item>
            </van-swipe>
            <!-- <swiper :options="mySwiperOption">
                <swiper-slide v-for=" (item, index) in image" :key="index">
                    <img style="width: 100%;height: 100%;" :src="item.imgUrl" alt="兼职网新闻资讯">
                </swiper-slide>
            </swiper> -->
            <!-- <el-carousel height="8.22rem" :interval="4000" type="card" indicator-position="none">
                <el-carousel-item v-for=" (item, index) in image" :key="index">
                    <img style="width: 100%;height: 100%;" :src="item.imgUrl" alt="兼职网新闻资讯">
                </el-carousel-item>
            </el-carousel> -->
        </div>
        <div>
            <van-tabs v-model="active" swipeable title-inactive-color="#616161" title-active-color="#000000" line-width="2rem" line-height="0.19rem"
                @click="changeInformationClass" offset-top="0" :sticky="true">
                <van-tab v-for="(item, index) in vantab" :key="index" :title="item.dictLabel" :name="item.dictValue">
                    <div v-for="(item1, index1) in informationList " :key="index1">
                        <div @click="toDetail(item1)">
                            <div style=" width:21.56rem;margin: auto;margin-top: 0.44rem;">
                                <img class="imgDontClick" style="width:100%;height:100%;" :src="item1.imgUrl" alt="兼职网新闻资讯">
                            </div>
                            <div :class="index1 % 2 == 0 ? 'bkground' : 'bkground2'">
                                <div
                                    style="display: flex;justify-content: space-between;width: 21.93rem;margin: auto;margin-bottom: 0.75rem;padding-top: 0.75rem;">
                                    <div :class="index1 % 2 == 0 ? 'intelligence' : 'intelligence2'">
                                        <p>{{ item1.title }}</p>
                                    </div>
                                    <div :class="index1 % 2 == 0 ? 'time' : 'time2'">
                                        {{ item1.createTime.trim().split(" ")[0] }}
                                    </div>
                                </div>
                                <div :class="index1 % 2 == 0 ? 'text' : 'text2'">
                                    <div class="newContText">{{ getPlainText(item1.content) }}</div>
                                    <!-- <div class="content" v-html="item1.content"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <div style="width: 21.56rem;margin: auto;text-align: center;margin-top: 1.2rem;margin-bottom: 1.53rem;padding: 2px;">
            <van-button type="default" @click="lookgengduo" v-if="lookgengduotitle">查看更多</van-button>
            <van-button type="default" @click="lookgengduo" v-else>暂无更多</van-button>
        </div>
        <div class="lsmen" style="">
            <div style="">
                <h3>联系我们</h3>
                <ul>
                    <!-- <li>邮箱：629666098@qq.com</li> -->
                    <li>电话：19906413192</li>
                </ul>
            </div>
            <div @click="$router.push('/aboutme-m')">
                <h3>关于我们</h3>
                <ul>
                    <li>关于我们</li>
                    <li>企业介绍</li>
                    <li>兼职网平台</li>
                </ul>
            </div>
            <div @click="$router.go(0)">
                <h3>媒体中心</h3>
                <ul>
                    <li>兼职新闻</li>
                </ul>
            </div>
        </div>
        <div class="jianzhiimg">
            <div> <img class="tg_icon" src="@/assets/image/mobile/jzwlogo.png" alt="jzwlogo"></div>
            <div>
                <div class="tg_icon2">
                    <img src="@/assets/image/mobile/erweima.png" alt="jzwdownload" />
                    <div>扫码下载APP</div>
                </div>
            </div>
            <div> <img class="jzwmini" src="@/assets/image/mobile/jzwmini.png" alt="jzwmini"></div>
        </div>
        <div class="footer" style="color: #333333; font-size: 0.63rem; font-weight: 500;padding: 0 1.6rem 1rem">
            <div style="display: flex;justify-content: center;">
                <div style="zoom: 0.9">鲁ICP备2022033828号-1</div>
                <div style="margin-left: 1.5rem;display: flex;align-items: center;">
                    <img style="width: 0.69rem;height: 0.69rem;" src="@/assets/image/mobile/beian.png" alt="鲁公网安">
                    <div style="zoom: 0.9">鲁公网安37010502001905号</div>
                </div>
            </div>
            <div style="zoom: 0.9;margin-top: 0.3rem;text-align: center;">Copyright © 2017-2023 兼职网(山东)信息技术有限公司</div>
        </div>
        <!-- 顶部轮播图视频 -->
        <van-overlay :show="showVideo" @click="showVideo = false" z-index="999">
            <div class="wrapper" @click.stop>
                <video v-if="showVideo" :src="videourl" controls autoplay="autoplay" ></video>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { CLOSING } from 'ws';

export default {
    name: 'information-m',
    data() {
        return {
            mySwiperOption: {
                loop: true,
                autoplay: true,
            },
            informationType: '',
            active: '',
            vantab: [
                '全部',
                '新闻资讯',
                '兼职信息',
                '校园生活',
                '职场故事',
                '赚钱方法',
                '党建风格',
            ],
            image: [],
            informationList: [],
            pageNum: 1,
            lookgengduotitle: true,
            time: '',
            showVideo:false,
        }
    },
    created() {
        this.getBannerList();
        this.getInformationClassList();
    },
    methods: {
        swiperClick(item) {
            if (item.videoUrl) {
                this.videourl = item.videoUrl
                this.$nextTick(() => {
                    this.showVideo = true
                })
            }else if(item.jumpUrl){
                let url = item.jumpUrl.split('?')
                let obj={}
                if(url[0].indexOf('/')==0){
                    url[0]=url[0].slice(1)
                }
                if(url.length==2){
                    url[1].split('&').forEach(el=>{
                        let p = el.split('=')
                        obj[p[0]]=p[1]
                    })
                }
                this.$router.push({name:url[0],params:obj})
            }
        },
        changeInformationClass(item) {
            this.lookgengduotitle = true
            this.pageNum = 1
            this.informationList = []
            // console.log(item)
            this.informationType = item;
            this.getInformationList();
        },
        //跳转详情
        toDetail(item) {
            this.$router.push({ name: 'consultationDetail-m', params: item })
        },
        getBannerList() {
            // 获取轮播图数据
            this.$axios({
                method: "get",
                url: "/official/enterprise/getBanner",
                params: {
                    locationType: 6,
                },
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.image = res.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getInformationClassList() {
            this.vantab = [{
                dictLabel: '全部',
                dictValue: '',
            }]
            this.$axios({
                method: "get",
                url: "/official/enterprise/getInformationClassList",
                params: {
                    pageNum: 1,
                    pageSize: 20,
                },
            })
                .then((res) => {
                    if (res.code === 200) {
                        // this.vantab = res.rows
                        this.vantab.push(...res.rows)
                        this.informationType = this.vantab[0].dictValue;
                        this.getInformationList();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getInformationList() {
            this.$common.showLoading()
            this.$axios({
                method: "get",
                url: "/official/enterprise/getInformationList",
                params: {
                    pageNum: this.pageNum,
                    pageSize: 2,
                    type: this.informationType,
                },
            })
                .then((res) => {
                    this.$common.hideLoading()
                    if (res.code === 200) {
                        if (res.rows.length != 0) {
                            this.informationList.push(...res.rows);
                        } else if (res.rows == 0) {
                            this.lookgengduotitle = false
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //转意符换成普通字符
        convertIdeogramToNormalCharacter(val){
            const arrEntities = {'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
            return val.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];});
        },
        // 获取富文本的纯文字内容
        getPlainText(richCont){
            const str = richCont;
            let value = richCont;
            if(richCont){
                value = value.replace(/(\n)/g, "");
                value = value.replace(/(\t)/g, "");
                value = value.replace(/(\r)/g, "");
                value = value.replace(/<\/?[^>]*>/g, "");
                value = value.replace(/\s*/g, "");
                value = this.convertIdeogramToNormalCharacter(value);
                return value;
            } else {
                return '--';
            }
        },
        //查看更多
        lookgengduo() {
            if (this.lookgengduotitle) {
                this.pageNum++
                this.getInformationList()
            }
        }
    }
}
</script>
<style scoped lang="less">
.newContText{
    width: 19.63rem;
    height: 5.2rem;
    line-height: 1.3rem;
    font-size: 0.89rem;
}
/deep/.content {
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

/deep/.content img {
    display: none;
}

/deep/.content p {
    width: 19.63rem;
    display: inline-block;
    /* color: #666666ff; */
    font-size: 0.89rem;
}
</style>

<style scoped lang="less">
.mask_video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.videoBtn {
    width: 2.81rem;
    height: 2.81rem;
    line-height: 2.81rem;
    text-align: center;
    color: #ffffff;
    font-size: 2.8rem;
    border-radius: 50%;
    background-color: #7d7d7d87;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.wrapper{
  width: 100vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.wrapper>video{
  width: 94%;
  height: auto;
  object-fit: cover;
}
div /deep/.el-carousel__mask {
    background: none;
}

.page {
    height: 100%;
    background-color: #F9F9F9;

    .bkground {
        background-color: #ffffffff;
        width: 21.56rem;
        height: 9.56rem;
        margin: 0rem auto auto;
    }

    .bkground2 {
        background-color: #ffffffff;
        width: 21.56rem;
        height: 9.56rem;
        margin: 0rem auto auto;
    }

    .intelligence {
        // width: 6.89rem;
        height: 1.56rem;
        line-height: 1.56rem;
        background-image: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681734777704lQLPJxJzw4QRVxJLzQFDsPVz4MFtrB11BDVGiEXAhQA_323_75.png');
        background-size: 100% 1.56rem;
        text-align: center;

        p {
            width: 9.89rem;
            height: 1.56rem;
            opacity: 1;
            color: #ffffffff;
            font-size: 0.88rem;
            font-weight: 600;
            font-family: "PingFang SC";
            // margin-left: -1rem;
            margin-right: 1.75rem;
            margin-left: 0.75rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .intelligence2 {
        // width: 6.89rem;
        height: 1.56rem;
        line-height: 1.56rem;
        //background-image: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681791676428lQLPJxejoCr5jXJLzQFBsE_ZLAKQ8FBBBDVLjT4AKgA_321_75.png');
        background-image: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681734777704lQLPJxJzw4QRVxJLzQFDsPVz4MFtrB11BDVGiEXAhQA_323_75.png');
        background-size: 100% 1.56rem;
        text-align: center;

        p {
            width: 9.89rem;
            height: 1.56rem;
            opacity: 1;
            color: #FFFFFF;
            font-size: 0.88rem;
            font-weight: 600;
            font-family: "PingFang SC";
            // margin-left: -1rem;
            margin-right: 1.75rem;
            margin-left: 0.75rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
    }

    .time {
        // width: 4rem;
        height: 1.5rem;
        opacity: 1;
        color: #a5a5a5ff;
        font-size: 0.75rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 1.5rem;
        margin-right: 0.78rem;
    }

    .time2 {
        // width: 4rem;
        height: 1.5rem;
        opacity: 1;
        color: #a5a5a5ff;
        font-size: 0.75rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 1.5rem;
        margin-right: 0.78rem;
    }

    .text {
        width: 19.63rem;
        height: 5.2rem;
        -ms-height:5.4rem;
        margin: auto;
        color: #666666;
        font-size: 0.69rem;
        font-weight: 500;
        margin-left: 0.78rem;
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
    }

    .text2 {
        width: 19.63rem;
        height: 5.2rem;
        margin: auto;
        color: #666666;
        font-size: 0.69rem;
        font-weight: 500;
        margin-left: 0.78rem;
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
    }

    .lsmen {
        display: flex;
        justify-content: space-evenly;
        margin-top: 3.6rem;

        h3 {
            color: #333333ff;
            font-size: 0.94rem;
            font-weight: 700;
        }

        li {
            // width: 6.25rem;
            // height: 0.81rem;
            opacity: 1;
            color: #333333ff;
            font-size: 0.75rem;
            font-weight: 500;
            font-family: "PingFang SC";
            text-align: left;
            letter-spacing: 0.02rem;
            line-height: 1.5rem;
        }
    }

    .jianzhiimg {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 2.09rem;
        margin-bottom: 2.34rem;

        .tg_icon {
            width: 5.84rem;
            height: 5.84rem;
            border-radius: 0.67rem;
        }
        .tg_icon2{
            width: 6rem;
            height: 7rem;
            border-radius: 0.67rem;
            text-align: center;
            font-size: 0.6rem;
            background-color: #ffffff;
            padding: 0.4rem;
            img{
                width: 5.2rem;
                height: 5.2rem;
                object-fit: cover;
            }
            div{
                margin-top: 0.2rem;
            }
        }
        .jzwmini{
            width: 6rem;
            height: 6.6rem;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
    }
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}

/deep/.van-tab {
    // width: 3.5rem;
    // margin: auto;
    margin-right: 0.3rem;
    margin-left: 0.4rem;
    padding: 0%;
}

/deep/.van-nav-bar .van-icon {
    color: #000000;
    font-size: 1.3rem;
}

/deep/.van-tab--active {
    padding: 0;
    // width: 100%;
    // height: 0.75rem;
    opacity: 1;
    color: #000000ff;
    font-size: 0.94rem;
    font-weight: 700;
    font-family: "PingFang SC";
    text-align: left;
    // line-height: 0.75rem;
    // letter-spacing: -0.01rem;
}

/deep/.van-tabs__line {
    width: 0.97rem;
    height: 0.19rem;
    border-radius: 0.13rem;
    opacity: 1;
    background: linear-gradient(270deg, #2fd586ff 0%, #2fd58600 100%);
}

/deep/.van-tabs__nav--line {
    padding-bottom: 0.35rem;
}

/deep/.van-button--default {
    width: 8.84rem;
    height: 2.09rem;
    background-color: #F9F9F9;
    color: #8d8d8dff;
    border: 0.01rem solid #8d8d8dff;
}
/deep/.van-swipe{
    // height: 8.19rem;
}
/deep/.van-tabs__wrap--scrollable .van-tab{
    padding: 0 0.6rem;
}
// /deep/ .van-tabs--line .van-tabs__wrap{
//     height: 3.5rem;
// }
</style>
