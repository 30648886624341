<template>
    <div :style="`min-height:${ mh>0 ? mh + 'px' : '100vh' };${active==0?'background-color:#fff;':''}`">
        <keep-alive include="homePage-m,information-m">
            <router-view :routeMsg="routeMsg" />
        </keep-alive>
        <van-tabbar class="vanTabbarClass" v-show="isShow" v-model="active" placeholder fixed @change="toLink">
            <van-tabbar-item>
                <span :style="`color:${active==0?'#07BE77':'#000000'};`">首页</span>
                <template #icon="props">
                    <img :src="props.active ? iconArray[0].active : iconArray[0].inactive" />
                </template>
            </van-tabbar-item>
            <!-- <van-tabbar-item icon="search">
                <span :style="`color:${active==1?'#07BE77':'#000000'};`">新闻资讯</span>
                <template #icon="props">
                    <img :src="props.active ? iconArray[1].active : iconArray[1].inactive" />
                </template>
            </van-tabbar-item> -->
            <van-tabbar-item icon="setting-o">
                <span :style="`color:${active==1?'#07BE77':'#000000'};`">关于我们</span>
                <template #icon="props">
                    <img :src="props.active ? iconArray[1].active : iconArray[1].inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item icon="setting-o">
                <span :style="`color:${active==2?'#07BE77':'#000000'};`">个人中心</span>
                <template #icon="props">
                    <img :src="props.active ? iconArray[2].active : iconArray[2].inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    name: 'mhome',
    data() {
        return {
            active: 0,
            iconArray:[{
                active:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048560878index.png',
                inactive:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048578615indexed.png',
                url:'/homePage-m',
            },
            // {
            //     active:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048601714refer.png',
            //     inactive:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048621310refered.png',
            //     url:'/information-m',
            // },
            {
                active:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048643651aboutme.png',
                inactive:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048662199aboutmeed.png',
                url:'/aboutme-m',
            },{
                active:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048680523mine.png',
                inactive:'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1682048698128mineed.png',
                url:'/PersonalCenter-m',
            }],
            isShow:true,
            mh:0,
            routeMsg:{
                new:null,
                old:null
            },
        };
    },
    created () {
        // this.$common.fixedPos()
        this.mh=this.$common.getHeight()
        this.$common.directDelParams('h5LoginParam')
        if(localStorage.getItem('orderId')){
            localStorage.removeItem('orderId')
        }
        this.$common.sendLogH5('exposure',{message:'官网H5曝光'})//埋点
    },
    methods: {
        toLink(e) {
            if(this.iconArray[e].url=='/PersonalCenter-m' && !localStorage.getItem('userInfo')){
                this.$router.push('/login-m')
            }else{
                this.$router.push(this.iconArray[e].url)
            }
        }
    },
    watch: {
        '$route':{
            handler(newval,oldval) {
                this.routeMsg={
                    new:newval?newval.path:null,
                    old:oldval?oldval.path:null,
                }
                let i = this.iconArray.findIndex(item=>item.url==newval.path)
                this.isShow = Boolean(i>-1)
                if(i>-1&&this.active!=i){
                    this.active=i
                }
            },
            immediate:true,
        }
    },
}
</script>

<style scoped>
.vanTabbarClass /deep/ .van-tabbar--fixed{
    z-index: 99;
}
div /deep/ .van-cell::after{
  border-bottom: none;
}
</style>