<template>
    <div>
        <div class="topBack">
            <div class="backIcon" @click="$router.go(-1)">
                <i class="el-icon-arrow-left"></i>
            </div>
            <span>隐私协议</span>
        </div>
        <iframe style="width: 100vw;height: 100vh;margin-top: 2.75rem;" src="https://platform.jianzhinet.cn/privacyAgreement.html"
            frameborder="0"></iframe>
    </div>
</template>

   
    
<script>
export default {
    data() {
        return {

        }
    }

}
</script>

<style scoped>
.topBack {
    width: 100vw;
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    background-color: #ffffff;
    color: #141419;
    font-size: 1.13rem;
    font-weight: 600;
    font-family: "PingFang SC";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.backIcon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
}
</style>