<template>
    <div class="page">
        <van-sticky>
            <div class="top">
                <div class="imgysright"><img src="@/assets/images/h5/login/righttop.png" alt=""></div>
                <van-nav-bar title="登录" left-text="" left-arrow @click-left="onClickLeft" @nav-bar-height="2.75" />
            </div>
        </van-sticky>

        <div class="Allrectangle">
            <div class="rectangle"></div>
            <div class="rectangle2"></div>
        </div>
        <div class="welcome">欢迎来到兼职网</div>
        <div class="center">
            <div class="input">
                <van-field class="tel" v-model="tel" type="tel" placeholder="请输入手机号" />
                <van-field class="sms" v-model="sms" maxlength="6" center clearable placeholder="请输入验证码">
                    <template #button>
                        <van-button style="border-radius: 0.3rem;" size="small" type="primary" @click="Code"
                            v-if="CodeTrue">获取验证码</van-button>
                        <van-button class="smsVbtn" color="#e3e5e6" size="small" type="primary" v-if="!CodeTrue">{{ count
                        }}s后重新发送</van-button>
                    </template>
                </van-field>
            </div>
            <div class="login">
                <van-button class="loginbut" type="primary" block @click="login">登录</van-button>
            </div>
            <div class="center_bottom">
                <div v-if="checked" class="notrue" @click="checked = false"></div>
                <div v-if="!checked" class="true" @click="checked = true">
                    <div style="height: 0.88rem;color:#FFFFFF ;line-height: 0.7rem;text-align: center;"><i
                            style="font-size: 0.6rem;" class="el-icon-check"></i></div>
                </div>
                <div class="agree"><span style="color:#C0C0C0;">我已阅读并同意</span><span
                        style="color:#04B08B;" @click="linkAgreement('/UserAgreement-m')">《用户协议》</span><span
                        style="color:#04B08B;" @click="linkAgreement('/PrivacyAgreement-m')">《隐私协议》</span></div>
                       
            </div>
            <div class="cengci"></div>
        </div>
        <div class="bottom">
            <div><img src="@/assets/images/h5/login/Slice 555.png" alt=""></div>
            <div><img src="@/assets/images/h5/login/rightbotton.png" alt=""></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'login',
    // props:['routeMsg'],
    data() {
        return {
            tel: '',//手机号
            sms: '',//验证码
            CodeTrue: true,//判断验证码是否获取
            count: 60,//获取验证码倒计时
            checked: true,
            screeheight: 0
        }
    },
    created() {
        // 查询缓存是否有data参数
        this.$common.getKeepParams('h5LoginParam',res=>{
            if(res){
                Object.assign(this.$data,res)
            }else{
                this.screeheight = this.$common.getHeight()
            }
        })
    },
    methods: {
        onClickLeft() {
            this.$router.push('/')
            // // this.$router.push("/home");
            // if(this.routeMsg.old){
            //     this.$router.push(this.routeMsg.old)
            // }else{
            //     this.$router.push('/')
            // }
        },
        //获取验证码
        Code() {
            let _this = this;
            let reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
            if (reg.test(this.tel)) {
                let set = setInterval(function () {
                    _this.CodeTrue = false
                    _this.count--;
                    if (_this.count == '0') {
                        _this.CodeTrue = true;
                        _this.count = 60;
                        clearInterval(set);
                    }
                }, 1000)
                this.$axios({
                    url: '/app/applications/getAppSms' + '/' + _this.tel,
                    needPrivate: true
                }).then(res => {
                    console.log(res, "res");
                    if (res.code == 200) {
                        console.log(_this.tel);
                        console.log("[验证码发送]", res);
                    }
                    else if (res.code == 400) {
                        console.log("[验证码发送]", res);
                        _this.$toast.fail(res.mag);
                    }
                })
            } else {
                this.$toast.fail('请检查您的手机号是否正确');
            }
        },
        login() {
            if (!this.checked && this.tel.length == 11 && this.sms.length == 6) {
                let data = {
                    phone: this.tel,
                    code: this.sms,
                    userType: 2,
                    cid: '',
                    tgId: '',
                    facilityInformation: ''
                };
                this.$axios({
                    method: 'post',
                    url: '/app/applications/verificationAppLogin',
                    data: data,
                    needPrivate: true
                }).then(res => {
                    if (res.code == 200) {
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('userInfo', JSON.stringify(res.data));
                        // this.$bus.$emit('refresh')
                        this.$router.replace("/homePage-m");
                        this.$common.sendLogH5('login',{message:res.data})//登录埋点
                    } else if (res.code == 400) {
                        this.$toast.fail(res.msg);
                    }

                })
            } else {
                if (this.checked) {
                    this.$toast.fail('请勾选同意协议');
                }else if (this.sms.length != 6){
                    this.$toast.fail('验证码输入不正确');
                }
            }
        },
        // 跳转协议
        linkAgreement(url){
            this.$common.setKeepParams('h5LoginParam',this.$data)
            this.$router.push(url)
        },
    },
}
</script>

<style scoped lang="less">
* {
    box-sizing: border-box;
}

.page {
    // height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.top {
    height: 7.94rem;

    .imgysright {
        img {
            z-index: 99;
            position: absolute;
            top: 0%;
            right: 0%;
            width: 7.63rem;
            height: 7.94rem;
            // background-color: aquamarine;
        }
    }
}

.welcome {
    margin-top: 1.88rem;
    margin-left: 0.75rem;
    // width: 10.5rem;
    height: 1.63rem;
    opacity: 1;
    color: #000000ff;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "PingFang SC";
    // text-align: center;
    text-align: left;
    letter-spacing: 2px;
    line-height: 1.63rem;
    position: relative;
}

.Allrectangle {
    display: flex;
    margin-left: 0.75rem;
    height: 0.38rem;

    .rectangle {
        width: 2.56rem;
        height: 0.38rem;
        border-radius: 0.19rem;
        opacity: 1;
        background: linear-gradient(180deg, #08c075ff 0%, #05b485ff 100%);
    }

    .rectangle2 {
        margin-left: 0.13rem;
        width: 0.38rem;
        height: 0.38rem;
        border-radius: 50%;
        opacity: 1;
        background: linear-gradient(180deg, #06b783ff 0%, #08c076ff 100%);
    }
}

.center {
    margin: auto;
    // margin-left: 0.75rem;
    margin-top: 3.44rem;
    padding-top: 1.94rem;
    width: 21.94rem;
    height: 18.21rem;
    border-radius: 0.38rem;
    opacity: 1;
    background: #ffffffff;
    box-shadow: 0.38rem 0.69rem 3.06rem 0 #4d4d4d21;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input {
        /deep/ .tel {
            background-color: #EFEFEF;
            width: 19.44rem;
            height: 2.63rem;
            border-radius: 0.25rem;
        }

        /deep/ .sms {
            width: 19.44rem;
            height: 2.63rem;
            border-radius: 0.25rem;
            margin-top: 0.63rem;
            background-color: #EFEFEF;
        }

        // margin-top: 1.94rem;
    }

    .login {
        margin-top: 2.75rem;

        /deep/ .loginbut {
            width: 19.44rem;
            height: 2.31rem;
            border-radius: 0.3rem;
            opacity: 1;
            background: linear-gradient(180deg, #08be79ff 0%, #04b08bff 100%);
        }
    }

    .center_bottom {
        display: flex;
        margin-top: 0.75rem;
        width: 18.81rem;
        height: 2.5rem;
        // align-items: center;

        .true {
            margin-top: 0.4rem;
            width: 0.88rem;
            height: 0.88rem;
            border-radius: 50%;
            opacity: 1;
            background: linear-gradient(180deg, #08bc7eff 0%, #06b489ff 100%);
        }

        .notrue {
            margin-top: 0.4rem;
            width: 0.88rem;
            height: 0.88rem;
            border-radius: 50%;
            opacity: 1;
            border: 1px solid #ccc;
        }

        .agree {
            width: 17.25rem;
            height: 2.5rem;
            margin-left: 0.69rem;

            span {
                font-size: 0.75rem;
            }
        }
    }
}

.bottom {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    margin-top: 8.25rem;

    div {
        img {
            width: 7.19rem;
            height: 9.06rem;
        }
    }
}

.cengci {
    top: 32.94rem;
    width: 20.81rem;
    height: 1rem;
    border-radius: 0.38rem;
    background: #ffffffc2;
    box-shadow: 0.38rem 0.69rem 3.06rem 0 #4d4d4d21;
    position: absolute;
    // bottom: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    // z-index: 1;
}

.cengci::after {
    content: '';
    width: 20.81rem;
    height: 1rem;
    border-radius: 0.38rem;
    opacity: 1;
    background: #ffffff1f;
    box-shadow: 0.38rem 0.69rem 3.06rem 0 #4d4d4d21;
    position: absolute;
    bottom: -0.75rem;
    left: 50%;
    transform: translateX(-50%);
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}

/deep/ .van-icon {
    // font-size: 1.3rem;
    color: black;
    // font-weight: 700 ;
}

/deep/.van-button--small {
    // width: 6.06rem;
    height: 1.63rem;
    opacity: 1;
    color: #ffffffff;
    font-size: 0.81rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    // line-height: 1.63rem;
}

/deep/.van-button--primary {
    background-color: #04B08B;
}
/deep/ .van-nav-bar .van-icon{
    color: #323233;
}
.smsVbtn{
    border-radius: 0.3rem;
}
.smsVbtn /deep/ .van-button__text{
    color: #575757;
}
</style>