import { render, staticRenderFns } from "./informationDetail.vue?vue&type=template&id=20e3dc61&scoped=true"
import script from "./informationDetail.vue?vue&type=script&lang=js"
export * from "./informationDetail.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/pc/informationDetail.css?vue&type=style&index=0&id=20e3dc61&prod&scoped=true&lang=css&external"
import style1 from "./informationDetail.vue?vue&type=style&index=1&id=20e3dc61&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e3dc61",
  null
  
)

export default component.exports