import dbm from './h5Route.js'
import about from '@/views/h5/about.vue'
import login from '@/views/h5/login.vue'
import information from '@/views/h5/NewsInformation/information.vue'
import aboutme from '@/views/h5/aboutme/aboutme.vue'
import consultationDetail from '@/views/h5/NewsInformation/consultationDetail.vue'
import needJob from '@/views/h5/detail/needJob.vue'
import company from '@/views/h5/detail/company.vue'
import PersonalCenter from '@/views/h5/PersonalCenter/PersonalCenter.vue'
import Myresume from '@/views/h5/PersonalCenter/Myresume.vue'
import Myregistration from '@/views/h5/PersonalCenter/Myregistration.vue'
import collect from '@/views/h5/PersonalCenter/collect.vue'
import RegistrationDetails from '@/views/h5/PersonalCenter/RegistrationDetails.vue'
import setup from '@/views/h5/PersonalCenter/setup.vue'
import Resuccessful from '@/views/h5/detail/Resuccessful.vue'
import CourseSuccess from '@/views/h5/detail/CourseSuccess.vue'
import results from '@/views/h5/detail/results.vue'
import PrivacyAgreement from '@/views/h5/Agreement/PrivacyAgreement.vue'
import UserAgreement from '@/views/h5/Agreement/UserAgreement.vue'
import Business from '@/views/h5/Business/Business.vue'

let routerArray = [
  ...dbm,
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: {
      mobile: true,
      metaInfo: {
        title: 'h5关于我们',
        keywords: 'about关键词',
        description: 'about阿斯顿',
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      mobile: true,
      metaInfo: {
        title: 'h5登录',
        keywords: 'login关键词',
        description: 'login阿斯顿',
      }
    },
  },
  {
    path: '/information',
    name: 'information',
    component: information,
    meta: {
      mobile: true,
      metaInfo: {
        title: '新闻资讯',
        keywords: '新闻资讯',
        description: '新闻资讯',
      }
    },
  },
  {
    path: '/aboutme',
    name: 'aboutme',
    component: aboutme,
    meta: {
      mobile: true,
      metaInfo: {
        title: '关于我们',
        keywords: '关于我们',
        description: '关于我们',
      }
    },
  },
  {
    path: '/consultationDetail',
    name: 'consultationDetail',
    component: consultationDetail,
    meta: {
      mobile: true,
      metaInfo: {
        title: '资讯详情',
        keywords: '资讯详情',
        description: '资讯详情',
      }
    },
  },
  {
    path: '/company',
    name: 'company',
    component: company,
    meta: {
      mobile: true,
      metaInfo: {
        title: '公司主页',
        keywords: '公司主页',
        description: '公司主页',
      }
    },
  },
  {
    path: '/needJob',
    name: 'needJob',
    component: needJob,
    meta: {
      mobile: true,
      metaInfo: {
        title: '求职保障',
        keywords: '求职保障',
        description: '求职保障',
      }
    },
  },
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: PersonalCenter,
    meta: {
      mobile: true,
      metaInfo: {
        title: '个人中心',
        keywords: '个人中心',
        description: '个人中心',
      }
    },
  },
  {
    path: '/Myresume',
    name: 'Myresume',
    component: Myresume,
    meta: {
      mobile: true,
      metaInfo: {
        title: '我的简历',
        keywords: '我的简历',
        description: '我的简历',
      }
    },
  },
  {
    path: '/setup',
    name: 'setup',
    component: setup,
    meta: {
      mobile: true,
      metaInfo: {
        title: '设置',
        keywords: '设置',
        description: '设置',
      }
    },
  },
  {
    path: '/collect',
    name: 'collect',
    component: collect,
    meta: {
      mobile: true,
      metaInfo: {
        title: '收藏',
        keywords: '收藏',
        description: '收藏',
      }
    },
  },
  {
    path: '/Business',
    name: 'Business',
    component: Business,
    meta: {
      mobile: true,
      metaInfo: {
        title: '商务合作',
        keywords: '商务合作',
        description: '商务合作',
      }
    },
  },
  {
    path: '/Myregistration',
    name: 'Myregistration',
    component: Myregistration,
    meta: {
      mobile: true,
      metaInfo: {
        title: '我的报名',
        keywords: '我的报名',
        description: '我的报名',
      }
    },
  },
  {
    path: '/RegistrationDetails',
    name: 'RegistrationDetails',
    component: RegistrationDetails,
    meta: {
      mobile: true,
      metaInfo: {
        title: '报名单详情',
        keywords: '报名单详情',
        description: '报名单详情',
      }
    },
  },
  {
    path: '/Resuccessful',
    name: 'Resuccessful',
    component: Resuccessful,
    meta: {
      mobile: true,
      metaInfo: {
        title: '抢名额报名成功',
        keywords: '抢名额报名成功',
        description: '抢名额报名成功',
      }
    },
  },
  {
    path: '/CourseSuccess',
    name: 'CourseSuccess',
    component: CourseSuccess,
    meta: {
      mobile: true,
      metaInfo: {
        title: '职位课程报名成功',
        keywords: '职位课程报名成功',
        description: '职位课程报名成功',
      }
    },
  },
  {
    path: '/results',
    name: 'results',
    component: results,
    meta: {
      mobile: true,
      metaInfo: {
        title: '报名结果',
        keywords: '报名结果',
        description: '报名结果',
      }
    },
  },
  {
    path: '/PrivacyAgreement',
    name: 'PrivacyAgreement',
    component: PrivacyAgreement,
    meta: {
      mobile: true,
      metaInfo: {
        title: '隐私协议',
        keywords: '隐私协议',
        description: '隐私协议',
      }
    },
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: UserAgreement,
    meta: {
      mobile: true,
      metaInfo: {
        title: '用户协议',
        keywords: '用户协议',
        description: '用户协议',
      }
    },
  },
]

routerArray.forEach(el => {
  el.path = el.path + '-m'
  el.name = el.name + '-m'
})
export default routerArray