import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/axios'
import utils from '@/assets/utils/uitls'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import "swiper/css/swiper.css";
import VueMeta from 'vue-meta'
import Swiper from "swiper";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
//引入 vue-awesome-swiper 样式
import 'swiper/css/swiper.css'
import JsCopy from '@/assets/utils/jsCopy'
Vue.prototype.$JsCopy = JsCopy

//     <link rel="stylesheet" href="https://unpkg.com/swiper@8/swiper-bundle.css">
//     <link rel="stylesheet" href="https://unpkg.com/swiper@8/swiper-bundle.min.css">
//     <script src="https://unpkg.com/swiper@8/swiper-bundle.js"> </script>
// <script src="https://unpkg.com/swiper@8/swiper-bundle.min.js"> </script>
// <script src="https://unpkg.com/swiper@8/swiper-bundle.min.js.map"> </script>

import swiper from "@/assets/js/swiper-bundle.css"

import VueAMap from 'vue-amap';
// Vue.use(swiper)
Vue.use(VueAMap);
// 初始化高德地图的 key 和插件
VueAMap.initAMapApiLoader({
  key: '8f2cabe1725281788ba9de184184942f',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor',"AMap.Geolocation" ],
  v: '1.4.15'
})
let AMap=VueAMap;
Vue.use(AMap);

Vue.use(vuescroll);
Vue.use(VueAwesomeSwiper)
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueMeta);
Vue.prototype.$axios=axios //axios请求
Vue.prototype.$common=utils //公共方法
Vue.prototype.cityAddress = '济南市' //城市地址
Vue.prototype.$bus = new Vue(); //全局事件车

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 全局组件注册
import jobItem from './components/h5/jobItem.vue'
import jobMask from './components/h5/jobMask.vue'
import jobPayMask from './components/h5/jobPayMask.vue'
// h5职位卡片组件
Vue.component('jobItem',jobItem)
Vue.component('jobMask',jobMask)
Vue.component('jobPayMask',jobPayMask)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  metaInfo() {
    return {
      title: store.state.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: store.state.metaInfo.keywords
        }, {
          name: "description",
          content: store.state.metaInfo.description
        }
      ]
    }
  },
  render: h => h(App)
}).$mount('#app')
