export default {
  arr1:[{
    src: require('@/assets/images/pc/company/kfc.png'),
    name: '兼职网合作商家-kfc'
  },{
    src: require('@/assets/images/pc/company/zgrs.png'),
    name: '兼职网合作商家-中国人寿'
  },{
    src: require('@/assets/images/pc/company/dfrb.png'),
    name: '兼职网合作商家-东方阮白'
  },{
    src: require('@/assets/images/pc/company/pizza.png'),
    name: '兼职网合作商家-pizza'
  },{
    src: require('@/assets/images/pc/company/adidas.png'),
    name: '兼职网合作商家-adidas'
  },{
    src: require('@/assets/images/pc/company/nike.png'),
    name: '兼职网合作商家-nike'
  },{
    src: require('@/assets/images/pc/company/dd.png'),
    name: '兼职网合作商家-滴滴'
  },{
    src: require('@/assets/images/pc/company/hdl.png'),
    name: '兼职网合作商家-hdl'
  },{
    src: require('@/assets/images/pc/company/jjy.png'),
    name: '兼职网合作商家-jjy'
  }],
  arr2:[{
    src: require('@/assets/images/pc/company/yd.png'),
    name: '兼职网合作商家-韵达'
  },{
    src: require('@/assets/images/pc/company/mtwm.png'),
    name: '兼职网合作商家-美团外卖'
  },{
    src: require('@/assets/images/pc/company/jdwl.png'),
    name: '兼职网合作商家-京东物流'
  },{
    src: require('@/assets/images/pc/company/mdl.png'),
    name: '兼职网合作商家-麦当劳'
  },{
    src: require('@/assets/images/pc/company/qcs.png'),
    name: '兼职网合作商家-屈臣氏'
  },{
    src: require('@/assets/images/pc/company/payh.png'),
    name: '兼职网合作商家-payh'
  },{
    src: require('@/assets/images/pc/company/u.png'),
    name: '兼职网合作商家-u'
  },{
    src: require('@/assets/images/pc/company/uniqlo.png'),
    name: '兼职网合作商家-uniqlo'
  },{
    src: require('@/assets/images/pc/company/UR.png'),
    name: '兼职网合作商家-UR'
  }],
  arr3:[{
    src: require('@/assets/images/pc/company/coffee.png'),
    name: '兼职网合作商家-coffee'
  },{
    src: require('@/assets/images/pc/company/drf.png'),
    name: '兼职网合作商家-大润发'
  },{
    src: require('@/assets/images/pc/company/ikea.png'),
    name: '兼职网合作商家-滴滴'
  },{
    src: require('@/assets/images/pc/company/dkn.png'),
    name: '兼职网合作商家-迪卡侬'
  },{
    src: require('@/assets/images/pc/company/jdwl.png'),
    name: '兼职网合作商家-jdwl'
  },{
    src: require('@/assets/images/pc/company/xbk.png'),
    name: '兼职网合作商家-xbk'
  },{
    src: require('@/assets/images/pc/company/st.png'),
    name: '兼职网合作商家-st'
  },{
    src: require('@/assets/images/pc/company/uupt.png'),
    name: '兼职网合作商家-uupt'
  },{
    src: require('@/assets/images/pc/company/zaba.png'),
    name: '兼职网合作商家-zaba'
  }]
}