<template>
  <div :class="'footers'">

    <div class="wanqitong">
      <div class="wanqitong-box">
          <!--左边的模块-->
          <div class="box-left">
              <img src="@/assets/images/pc/footer-bottom.png" alt="兼职网"/>
          </div>
          <!--中间模块--->
          <div class="box-center">
              <div class="center-mess">
                  <div class="mess-title">热门兼职</div>
                  <div class="mess-list" @click="goPartTJ(1)">兼职职位</div>
                  <div class="mess-list" @click="goPartTJ(2)">在家做</div>
                  <div class="mess-list" @click="goPartTJ(3)">本地兼职</div>
                  <div class="mess-list" @click="goPartTJ(4)">全部岗位</div>
              </div>

<!--              <div class="center-mess">-->
<!--                  <div class="mess-title">热门企业</div>-->
<!--                  <div class="mess-list" v-for="(item,index) in enList" :key="item.id" @click="goEnterPrise(item)">{{item.title}}</div>-->
<!--              </div>-->
              <div class="center-mess">
                  <div class="mess-title">资讯分类</div>
                  <div class="mess-list" v-for="(item,index) in informationClassList.slice(0,6)" :key="index" @click="goInformationHome(item)">
                      {{item.dictLabel}}
                  </div>
              </div>
              <div class="center-mess">
                  <div class="mess-title">关于我们</div>
                  <div class="mess-list" @click="goContact">联系我们</div>
                  <div class="mess-list" @click="setTypeIndex(1)">找兼职</div>
                  <div class="mess-list" @click="setTypeIndex(2)">我要招聘</div>
                  <div class="mess-list" @click="goShangwu">商务合作</div>
              </div>
          </div>

          <!--右边模块-->
          <div class="box-rght">
              <div class="right-bottom">
                  <div>
                      <img src="@/assets/images/pc/zaixianKf.png" alt="兼职网客服二维码"/>
                      <span>兼职网在线客服</span>
                  </div>

                  <div>
                      <img src="@/assets/images/pc/xiaochengxu.png" alt="兼职网小程序二维码"/>
                      <span>兼职网小程序</span>
                  </div>
              </div>
              <div class="phone"><span>客户电话:</span> 0531 8896 2990</div>
          </div>
      </div>
      <!--底部-->
        <div class="wanqitong-bottom">
            <div>Copyright © 2017-2023 兼职网（济南）信息技术有限公司</div>
            <div>
                <span style="cursor: pointer" @click="goicp">鲁ICP备2022036741号-1 </span>
                <div class="dang-box" style="cursor: pointer" @click="goDang">
                    <img src="@/assets/images/pc/dang.png" />
                    鲁公网安备 37010502001905号
                </div>
                <span style="cursor: pointer" @click="goMap">网站地图</span>
            </div>

        </div>

    </div>




 <div class="bt_rightT">
        <template v-if="zk_li">
<!--          <el-popover placement="left-start" width="370" trigger="hover" popper-class="kf-pv">-->
<!--            <div class="kf kf2" slot="reference">-->
<!--              <img style="width:1.2rem;" src="@/assets/images/pc/jdyq_right.png" alt="" srcset="">-->
<!--               <p class="kefuText">在线客服</p>-->
<!--              <div class="box-jdyqT">-->
<!--                <img style="width='1.2rem'"  src="@/assets/images/pc/jdyq.png">-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-popover>-->
          <el-popover placement="left-start" width="207" trigger="hover" popper-class="kf-pv">
            <img class="ku_pic" src="@/assets/images/pc/qrcode.png" alt="兼职网在线客服二维码" srcset="">
            <p style="text-align: center;padding-top:5px;">在线客服-微信扫一扫</p>
            <div class="kf kf2" slot="reference">
              <img style="width:28px;" src="@/assets/images/pc/kfT.png" alt="" srcset="">
              <div class="box-jdyqT">
                <img width="28px" src="@/assets/images/pc/xz_kfT.png">
              </div>
            </div>
          </el-popover>
          <el-popover placement="left-start"  width="217" trigger="hover" popper-class="kf-pv">
<!--            <div style="padding: 10px 14px;" class="jiantou">-->
              <p style=" width: 198px;height: 36px;color: #333333;font-size: 24px;font-weight: 600;font-size: 20px;">0531-8896-2990</p>
              <p class="">官方电话</p>
<!--            </div>-->
            <div class="kf kf3" slot="reference">
              <img src="@/assets/images/pc/phoneT.png" style="width: 28px;height: 33px;" alt="">
              <div class="box-jdyqT">
                <img src="@/assets/images/pc/xz_phoneT.png" style="width: 28px;height: 33px;" alt="">
              </div>
            </div>
          </el-popover>

          <el-popover placement="left-start" width="207" trigger="hover" popper-class="kf-pv">
            <img class="ku_pic" src="@/assets/images/pc/App.png" alt="兼职网APP二维码" srcset="">
            <p style="text-align: center;padding-top:5px;">下载兼职网APP</p>
            <div class="kf kf2" slot="reference">
              <img style="width:28px;" src="@/assets/images/pc/download.png" alt="" srcset="">
              <div class="box-jdyqT">
                <img width="28px" src="@/assets/images/pc/download2.png">
              </div>
            </div>
          </el-popover>
        </template>
      </div>



    <div class="zk" @click="zk_li = true" v-show="!zk_li">
      <i class="el-icon-arrow-left"></i>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "foot",
  data() {
    return {
      kf: true,
      kt: false,
      zk_li: true,
      currentHref: "",
      isMobile : "",
      about:false,
      meiti:true,
      lianxi:true,
      jdyqT:true, //控制接单是否展示
      kefu:true,  //客服是否展示
      phone:true,  //电话是否显示
      informationClassList:[],
        enList:[
            {
                title:"名企直聘",
                id:"1"
            },
            {
                title: "蓝领普工",
                id:"2"

            },
            {
                title: "超市零售",
                id:'3'
            },
            {
                title: "餐饮酒店",
                id:'4'
            },
            {
                title: "电子商务",
                id:'5'
            },
            {
                title: "广告营销",
                id:'6'
            },
        ],
    }
  },
  created() {
    this.currentHref = window.location.href
    console.log(this.currentHref)
    this.isMobile = this._isMobile();
  },
  beforeMount() {
    this.getInformationClassList();
  },
  methods: {
      goShangwu(){
          window.open(location.origin + '/cooperation/index.html', "_blank")
      },
      goicp(){
          window.open('https://beian.miit.gov.cn/#/Integrated/index', "_blank")
      },
      goDang(){
          window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010502001905', "_blank")
      },
      getInformationClassList() {
          axios({
              method: "get",
              url:"/official/enterprise/getInformationClassList",
              params: {
                  pageNum: 1,
                  pageSize: 50,
              },
          }).then((res) => {
              if (res.code === 200) {
                  this.informationClassList = res.rows;
                  console.log(this.informationClassList)
              }
          }).catch((err) => {
              console.log(err);
          });
      },
      goEnterPrise(e){
          if(this.$route.fullPath == '/enterprise'){
              window.scrollTo({
                  top:620,
                  left:0,
                  behavior: "smooth"
              });
              this.$bus.$emit('getEnList', e)
          }else {
              this.$router.push({
                  path:"/enterprise"
              });
              setTimeout(()=>{
                  window.scrollTo({
                      top:620,
                      left:0,
                      behavior: "smooth"
                  });
                  this.$bus.$emit('getEnList', e)
              },100)

          }
      },
    goPartTJ(e){
        if(this.$route.fullPath == '/PartTJ'){
            window.scrollTo({
                top:0,
                left:0,
                behavior: "smooth"
            });
            this.$bus.$emit('setList', e)
        }else {
            this.$router.push({
                path:"/PartTJ"
            });
            setTimeout(()=>{
                window.scrollTo({
                    top:0,
                    left:0,
                    behavior: "smooth"
                });
                this.$bus.$emit('setList', e)
            },100)

        }
    },
    goInformationHome(e){
        if(this.$route.fullPath == '/informationHome'){
            window.scrollTo({
                top:600,
                left:0,
                behavior: "smooth"
            });
            this.$bus.$emit('setChangeInformationClass', e)
        }else {
            this.$router.push({
                name:"informationHome"
            });
            setTimeout(()=>{
                window.scrollTo({
                    top:600,
                    left:0,
                    behavior: "smooth"
                });
                this.$bus.$emit('setChangeInformationClass', e)
            },100)

        }
    },
    setTypeIndex(e){
        this.$bus.$emit('getStudentName', e)
    },
    mouseOver(){
      this.kefu = !this.kefu
    },
    mouseOverT(){
      console.log("123")
      this.phone = !this.phone
    },
    mouseLeave(){
      this.kefu = !this.kefu
    },
    mouseLeaveT(){
      this.phone = !this.phone
    },
    setLianxi(){
      this.lianxi = !this.lianxi
    },
    setMeiti(){
      this.meiti = !this.meiti
    },
    setAbout(){
      this.about = !this.about
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    goMap(){
      this.$router.push({
          path:'/websiteMap'
      })
    },
    goContact(){
      this.$router.push({
          path:"/contact"
      })
    },
    open2() {

      this.jdyqT = false;
      let star = "亲爱的用户您好，请将本人简历、作品及申请内容，传至邮箱jianzhiwang2022@163.com，审核通过即可开通接任务权限！";

      let _this = this
      if (this.kt == false) {
        this.kt = true
        this.$notify({
          title: '温馨提示',
          customClass: "wxts_email",
          message: star,
          duration: 0,
          onClose() {
            _this.kt = false;
            _this.jdyqT = true;
          }
        });

      } else {
        this.$notify.closeAll()
        this.kt = false
      }


    }
  }
};
</script>

<style scoped lang="less">
.f_top b {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  margin-left: 15px;
  margin-right: 10px;
}

.ku_pic {
  width: 100%;
}

.footers {
  width: 100%;
  // height: 260px;
  background-image: url("https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/3e703fdc46464523b823b8da5e9e88fa.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  color: white;
}
.footer{
  background: #1e2021;
  height: 250px;
}


.footer .f_c {
  width: 765px;
  margin: 0 auto;
  text-align: center;
  padding-top: 55px;
}

.footer .f_c i {
  font-weight: bold;
  color: #fff;
  font-size: 22px;
  font-style: italic;
  transform: translateY(4px);
  display: inline-block;

}

.footer .f_c span {
  margin: 15px 0;
  display: inline-block;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: #00E598;
}

.footer .f_c p {
  color: #999999;
}

.footer .f_c img {
  width: 130px;
  vertical-align: middle;
}

.bt_right {
  position: fixed;
  bottom: 300px;
  width: 81px;
  right: 15px;
  border-radius: 8px;
  box-shadow: #1e202114 0px 5px 10px 3px;
  overflow: hidden;
  padding: 0 10px;
  background: #fff;
  z-index: 10;
}
.bt_rightT{
  position: fixed;
  bottom: 50px;
  width: 0.7rem;
  right: 15px;
  border-radius: 72px;
  box-shadow: #1e202114 0px 5px 10px 3px;
  overflow: hidden;
  padding: 0 10px;
  background: #fff;
  z-index: 10;
}

.kf {
  text-align: center;
  height: 71px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
  z-index: 5;
  border-top: 1px solid #e8e8e8;
  font-size: 14px;
}
.kf:first-child{
  border-top: none;
}

.zk,
.rt_jt {
  display: none;
}

.jiedan {
  margin-top: 10px;
}

.kefuText {
  margin-top: 7px;
}

.fangshi {
  margin-top: 7px;

}

@media only screen and (max-width: 500px) {
  .rt_jt {
    display: block;
    position: absolute;
    left: 0px;
    top: 125px;
  }

  .jiedan {
    margin-top: 3px;
  }

  .kefuText {
    margin-top: 2px;
  }

  .fangshi {
    margin-top: 2px;

  }

  .zk {
    display: block;
    position: fixed;
    right: 10px;
    height: 150px;
    background: #fff;
    bottom: 320px;
    line-height: 150px;
    border-radius: 15px;
    box-shadow: #ccc -3px 0px 3px;
    z-index: 10;
  }

  .bt_right {
    padding-left: 20px;
    width: 90px;
  }
  .bt_rightT{
    padding-left: 20px;
    width: 90px;
  }

  .kf {
    width: 65px;
    height: 88px;
    font-size: 14px;
    right: 10px;
  }

  .footer {
    height: auto;
    // padding: 30px 15px;
  }

  .footer .f_c {
    width: 100%;
    padding: 0;
  }

  .footer .f_c i {
    font-size: 16px;
  }

  .footer .f_bt {
    font-size: 15px;
  }
}
</style>

<style>
.kf-pv.el-popover {
  border-radius: 17px;
  padding: 20px;
  padding-bottom: 10px;
  transform: translateX(-10px);
}

.popper-phone {
  border-radius: 26px !important;
  margin-right: 20px !important;
  width: 217px;
    /*position: relative;*/
  /*top: 758px !important;*/
  /*left: 1604px;*/
  /*transform-origin: right center;*/
  z-index: 2033;
}

.popper-phone .popper__arrow {
  display: none !important;
}

.kf-pv.el-popper .popper__arrow::after {
  border-width: 11px;
}

.kf-pv.el-popper[x-placement^=left] .popper__arrow::after {
  right: -3px;
  bottom: -10px;
}

.wxts_email {
  width: 335px !important;
}

.Bottombeijing {
  width: 100%;
  /*height: 260px;*/
  background-color: rgba(0, 0, 0, 0.2);
  /* padding: 20px 230px; */
}
.Bottombeijing-box{
  width: 1200px;
  margin: auto;
}

.Bottom_Logo {
  width: 120px;
  height: 120px;
}

.Bottom_Left {
  display: flex;
  width: 100%;
  border-bottom: 1px solid white;
  height: 171px;
}

.Bottom_lanmu {
  margin: 0 20px;
}

.Bottom_lanmu_title {
  font-size: 16px !important;

  color: #FFFFFF;
  font-weight: bold;
}

.Bottom_lanmu_red {
  width: 25px;
  height: 3px;

  background: #F4351A;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 5px;
}

.Bottom_lanmu_name {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #FFFFFF;
  font-weight: 300 !important;
  font-size: 13px;
}

.call {
  border: 1px solid white;
  padding: 9px 17px;
  margin-top: 25px;
}

.Bottom_lanmu_name>a {
  color: white;
}

.Bottom_lanmu_call {
  font-size: 16px;
  margin-top: 5px;
  line-height: 16px;
  color: #F4351A;
  font-weight: 400 !important;
}

.Bottom_Right {
  width: 30%;
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;
}

.Bottom_erwei {
  width: 100px;
  height: 100px;
  /*background-color: #DDDDDD;*/
  border-radius: 5px;
  padding: 1px;
  /*border: 2px solid #DDDDDD;*/
}

.Bottom_name {
  font-size: 13.5px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: white;
  line-height: 11px;
  margin-top: 12px;
}

.Bottom_Right_div {
  /*background: white;*/
  padding: 9px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 40px;
  height: 140px;
}

.footerTop {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 11px;

}

.footer_Title {
    font-size: 14px;
    font-weight: bold;
}

.footer_name {
    margin-bottom: 8px;
}

.footer_phone {
    color: #F4351A;
    margin-bottom: 8px;
}

.footer_Bottom {
    display: flex;
    margin-top: 25px;
    margin-bottom: 18px;
    justify-content: space-between;
}

.footer_Logo {
    width: 98px;
    height: 105px;
}
.download{
    display: flex;
    justify-content: space-between;
}
.download>div:nth-child(2){
    margin-left: 10px;
}
.footer_right {
    width: 98px;
    height: 105px;
    border-radius: 10px;
    font-size: 9px;
    color: #3D3D3D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
}

.footer_right_Logo {
    width: 61px;
    height: 61px;
    margin-bottom: 5px;
}

.putOnReco {
    color: #fff;
}
.putOnRecoOne{
    font-size: 12px;
}
.putOnRecoOne>div{
    text-align: center;
}
.putOnRecoOne>div>img{
    width: 18px;
}
.putOnRecoOne>div:nth-child(2){
    align-items: center;
}
.putOnReco>div:nth-child(2){
    font-size: 13px;
    text-align: center;
}
.bottom-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    font-size: 16px;
}
.bottome-box {
    width: 100%;
}
.list-fun {
    width: 20px;
}
.list-fun>img{
  width: 100%;
}
.routerTwo{
  transform: rotate(180deg);
}
.routerTwo>img{
  width: 20px;
}
.bottom-list-mess>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #CCCCCC;
    margin-top: 7px;
    font-size: 12px;
}
.wanqitong{
  background-image: url(@/assets/images/pc/wanqitongBg.png);
  background-size: cover;
  width: 100%;
}
.wanqitong-box{
    width: 1480px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3ddddff;
    margin-top: 30px;
    padding-bottom: 37.5px;
}
.logo1{
  width: 140px;
  height: 42px;
  display: block;
}
.logo2{
  width: 288px;
  height: 26px;
  margin-top: 16px;
}
.beian{
  color: #ccccccff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 9px;
}
.gwab{
  display: flex;
  align-items: center;
  margin-top: 9px;
}
.gwab > span{
  color: #ccccccff;
  font-size: 16px;
  letter-spacing: 1px;
  margin-left: 10px;
}
.dang{
  width: 26px;
  height: 26px;
}
.bq{
  color: #ccccccff;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 14px;
}
.wanqitong-left{
  width: 700px;
  border-right: 1px solid #ccc;
  position: relative;
}
.wanqitong-info{
  position: absolute;
  right: 40px;
  top: 0px;
}
.info-title{
  color: #ffffffff;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
}
.info-list{
  color: #ccccccff;
  font-size: 16px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  letter-spacing: 1px;
  margin-top: 12px;
}
.wanqitong-right{
  width: 500px;
  display: flex;
  justify-content: space-around;
}
.module{
  width: 124px;
}
.module>img{
  width: 100%;
}
.module>div{
    color: #ffffffff;
    font-size: 12px;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: center;
    margin-top: 20px;
}
.box-jdyqT{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000000d;
}
.jiantou{
  position: relative;
}
.jiantou::before{
  content: '';
    width: 0;
    height: 0;
    border: 15px solid #fff;
    border-bottom-color: transparent;
    border-left-color: #fff;
    border-right-color: transparent;
    border-top-color: transparent;
    position: absolute;
    right: -42px;
    top: 19px;
}

.kf2>.box-jdyqT{
  display: none;
}
.kf2:hover>img{
  display: none;
}
.kf2:hover>.box-jdyqT{
  display: flex !important;
}
.kf2>img{
  display: inline-block;
}

.kf3>.box-jdyqT{
  display: none;
}
.kf3:hover>img{
  display: none;
}
.kf3:hover>.box-jdyqT{
  display: flex !important;
}
.kf3>img{
  display: inline-block;
}
.box-left{
    width: 439.17px;
    height: 74px;
    margin-bottom: 95px;
}
.box-left>img{
    width: 100%;
    height: 100%;
}
.box-center{
    display: flex;
    width: 526.67px;
    justify-content: space-between;
}
.mess-title{
    color: #ffffffff;
    font-size: 16.67px;
    font-weight: 900;
    font-family: "PingFang SC";
    margin-bottom: 21px;
}
.mess-list{
    color: #ffffffff;
    font-size: 13.33px;
    font-weight: 400;
    font-family: "PingFang SC";
    letter-spacing: 0.53px;
    margin-bottom: 13.33px;
    cursor: pointer;
}
.right-bottom{
    display: flex;
    justify-content: space-between;
}
.right-bottom>div{
    width: 119.9px;
    height: 141.9px;
    margin-bottom: 25px;
}
.right-bottom>div>img{
    width: 119.9px;
    height: 119.9px;
}
.right-bottom>div>span{
    color: #ffffffff;
    font-size: 14.47px;
    font-family: "PingFang SC";
    text-align: center;
    width: 100%;
    display: inline-block;
    margin-top: 12px;
}
.phone{
    width: 276.72px;
    height: 41.21px;
    border: 0.98px solid #ffffffff;
    text-align: center;
    line-height: 41.21px;
}
.phone>span{
    font-size: 19.63px;
    font-weight: 900;
    font-family: "PingFang SC";
    text-align: left;
    letter-spacing: 0.79px;
}
.wanqitong-bottom{
    width: 1480px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 33px;
}
.wanqitong-bottom>div{
    width: 100%;
    color: #ffffffff;
    font-size: 11.67px;
    font-family: "PingFang SC";
    letter-spacing: 0.47px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wanqitong-bottom>div:nth-child(2){
    margin-top: 10px;
    margin-bottom: 50px;
}
.dang-box{
    border-left: 1px solid #ccc;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}
.dang-box>img{
    width: 15.83px;
    height: 15px;
    margin-right: 10px;
    margin-left: 10px;
}


</style>
