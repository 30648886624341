<template>

</template>

<script>
import {assign} from "vue-amap/src/lib/utils/polyfill";

export default {
  name: "empty",
  data(){
    return{

    }
  },
  created() {
      console.log('123=>',this.$route.params)
    this.$router.replace({
      name: this.$route.params.name,
      params: Object.assign({},this.$route.params,{
          id: this.$route.params.id,
          name: this.$route.params.title,
          firstOrder:this.$route.params.firstOrder,
          secondLevel:this.$route.params.secondLevel,
          company:this.$route.params.company,
          cityCode:this.$route.params.cityCode,
          item:this.$route.params.item,
          titleT:this.$route.params.titleT,
          routeNameT:this.$route.params.routeNameT,
          Second:this.$route.params.Second
      }),
    })
  },
}

</script>

<style scoped>

</style>
