<template>
  <!-- <div class="detailClass"
    :style="{ height: mh && mh != 0 ? mh + 'px' : '100vh', paddingBottom: isPay ? '6.74rem' : '4.5rem' }"> -->
  <div>
    <!-- 顶部返回部分 -->
    <div class="topBack">
      <div class="backIcon" @click="backItem">
        <i class="el-icon-arrow-left"></i>
      </div>
      <span>岗位详情</span>
    </div>
    <div style="width: 1rem;height: 2.75rem;"></div>
    <div style="width: 1rem;height: 120px;"></div>
    <!-- 内容 -->
    <div class="container">
      <div class="detail_top">
        <div>
          <div class="title">
            {{ details.title }}
          </div>
          <div class="time">
            {{ details.createTime }}
          </div>
        </div>
      </div>
      <div class="content">
        <div v-html="details.content"></div>
      </div>
      <div class="" style="height:80px;width: 1px;"></div>
      <div class="fixBotm">
        <div class="subBtn" @click="submit">
          立即报名
        </div>
      </div>

      <van-popup v-model="baoasdasdShow" position="center" safe-area-inset-bottom z-index="999">
        <div style="display: flex;align-items: center;flex-direction: column;width: 250px;height: 300px;background-color: #fff;border-radius: 20px;">
          <div style="font-size: 18px;font-weight: bold;padding: 10px 0 10px;">报名成功</div>
          <div style="font-size: 14px;padding: 0rem 0 10px;">长按二维码保存到相册到wx添加商家</div>
          <!-- <div style="font-size: 14px;padding: 0rem 0 10px;">保存图片到wx识别二维码添加商家</div> -->
          <!-- <image @click="previewImage(details['qrcode'])" v-if="details['qrcode']" @longpress="longpress"
            :src="details['qrcode']" style="width: 300rpx;height: 300rpx;" :show-menu-by-longpress="true"></image> -->
          <img :src="details[setSet.qcMa]" style="width: 200px;height: 200px;">
          <!-- <div class="subaBtn" @click="baocuntu(details[setSet.qcMa])">保存图片</div> -->
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import setSet from "@/assets/js/setSet.js"
export default {
  name: 'courseDetail-m',
  data() {
    return {
      setSet:setSet,
      zksqBtn: false,
      contentNum: 0,
      zksqMl: false,
      maskState: false,
      popupNum: 3,
      popupNumObj: {},
      showError: false,
      courseDetail: {},
      loadDetail: false,
      resumeInfo: {},
      userInfo: {},
      avatarList: [],
      avatarSize: 0,
      mh: '',
      isCollect: false,
      positionId: '',
      chooseTj: 1,
      taskArray: [], //任务列表
      taskLoad: false,
      //任务列表查询
      taskInfo: {
        pageNum: 1, //查询第几页
        pageSize: 10,
        //默认根据算法查
        queryTypes: ['3:1'], //[0: '1'、1: '0'、2: '0'、3: '0'、4: '0'、5: '0']算法0、曝光1、时间2、分类3、模糊4、榜单5
        positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
        positionType: 1, //1兼职职位，2课程职位
        longitude: localStorage.getItem('longitude') ? localStorage.getItem('longitude') : '0',
        latitude: localStorage.getItem('latitude') ? localStorage.getItem('latitude') : '0',
      },
      mydata: null,
      applyObj: {},
      isPay: false,//是否是付费课程
      offline: false,//是否下架
      timers: null,//定时器
      loadPay: false,

      details: {
        title: null,
        qrcode: null
      },
      id: '',//
      baoasdasdShow: false,
    }
  },
  created() {
    let qy = this.$router.getParams()
    // console.log(qy)
    this.getWxManage();
    if (qy && qy.id) {
      this.positionId = qy.id
      this.mh = this.$common.getHeight()
      this.getDetails(qy.id)
    } else {
      this.$toast('职位id无效')
      this.$router.go(-1)
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeChange);
    if (this.$common.isIos()) {
      window.onpageshow = () => {
        this.handlerPopstate()
      }
    } else if (window.history && window.history.pushState) {
      window.addEventListener('popstate', this.handlerPopstate);
    }
    document.addEventListener('visibilitychange', this.visibilitychange)
  },
  methods: {
    baocuntu(Url){
        console.log(324324,Url)
				// Url = '' //图片路径，也可以传值进来
				// var triggerEvent = "touchstart"; //指定下载方式
        // var blob=new Blob([''], {type:'application/octet-stream'}); //二进制大型对象blob
        // var url = URL.createObjectURL(blob); //创建一个字符串路径空位
        // var a = document.createElement('a'); //创建一个 a 标签
        // a.href = Url;  //把路径赋到a标签的href上
        // //正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本
        // a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0]; 
        // /* var e = document.createEvent('MouseEvents');  //创建事件（MouseEvents鼠标事件）
        // e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); //初始化鼠标事件（initMouseEvent已弃用）*/
        
        // //代替方法。创建鼠标事件并初始化（后面这些参数我也不清楚，参考文档吧 https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent）
				// var e = new MouseEvent('click', ( true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null));  
				// //派遣后，它将不再执行任何操作。执行保存到本地
				// 	a.dispatchEvent(e); 
				// 	//释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
				// 	URL.revokeObjectURL(url);  
				// 新版
        var oA = document.createElement("a"); // 创建一个a标签
        // 正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本 ,
        // a的download 谷歌浏览器必须同源才能强制下载，否则跳转到图片地址
        oA.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0]; ; // 设置下载的文件名，默认是'下载'
        oA.href = Url;
        document.body.appendChild(oA);
        oA.click();
        oA.remove(); // 下载之后把创建的元素删除
			},
      
    backItem() {
      this.$common.returnEvent()
    },
    resizeChange() {
      this.mh = this.$common.getHeight()
    },
    visibilitychange() {
      // this.$toast('可监听')
      if (document.visibilityState == 'visible') {
        //页面展示
        let orderId = localStorage.getItem('orderId')
        // this.$toast('页面展示' + orderId)
        if (this.$route.path.indexOf('courseDetail-m') > -1 && orderId) {
          this.debounce(orderId)
        }
      } else {
        //页面隐藏
        // this.$toast('页面隐藏')
      }
    },
    handlerPopstate() {
      let orderId = localStorage.getItem('orderId')
      if (this.$route.path.indexOf('courseDetail-m') > -1 && orderId) {
      }
    },
    debounce(orderId) {
      this.loadPay = true
      this.timers && clearTimeout(this.timers); //清除上次计时
      this.timers = setTimeout(() => {
        this.timers = null
        this.loadPay = false
      }, 2000);
    },

    submit() {
      if(this.wxManageInfo&&this.wxManageInfo.htmlLink) {
        window.location.href = this.wxManageInfo.htmlLink;
      } else {
        this.baoasdasdShow = true;
      }
    },
    getWxManage() {
      let this_ = this
      this.$axios({
        method: 'get',
        url: '/platform/wxManage/list',
        params:{appId:setSet.appid},
      }).then((res) => {
        if (res&&res.code == 200&&res.rows&&res.rows.length>0) {
          this.wxManageInfo = res.rows[0];
        }
      })
    },
    // 获取详情
    getDetails(id) {
      let this_ = this
      this.$axios({
        method: 'get',
        url: '/jlApplets/essay/' + id
      }).then((res) => {
        if (res.code == 200) {
          res.data.content = this.repairRichText(res.data.content)
          this.details = res.data
        }
      })
    },
    // 图片自适应
    repairRichText(html) {
      // 去掉<img>中style /width / height属性
      let newContent = html.replace(/<img[^>]*>/gi, (match) => {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '')
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '')
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '')
        match = match.replace(/style\s*=\s*(["'])(?:(?!\1).)*\1/gi, '') // 去除 style=""这样的属性
        return match
      })
      // img标签添加style属性：max-width:100%;height:auto
      newContent = newContent.replace(/\<img/gi,
        '<img style="max-width:100%;height:auto;"'
      )
      return newContent;
    },

    longpress(e) {
      let args = {
        actionCode: 'app_jlApplets_001',
        message: this.details.id
      }
      // console.log('长按');
      this.mqttactionLog(args)
    },
    // 提交万简历信息进行报名
    submitJlMb() {
      this.getUserResumeInfo(true, res => {
        if (res) {
          this.applyChange()
        }
      })
    },
    mqttactionLog(args) {
      setOpenId(openId => {
        args['occurrenceTime'] = new Date().getTime(),
          args['type'] = "applets"
        args['channel'] = 'wxff1bf17bf9eb8233';//
        if (!args.message) {
          args.message = {}
        }
        this.$axios({
          method: 'POST',
          url: '/mqtt/actionIncentive/actionLog'
        }).then((res) => {
          if (res.code == 200) {
            res.data.content = this.repairRichText(res.data.content)
            this.details = res.data
          }
        })
      })
    },
    setOpenId (callback) {
      const openId = uni.getStorageSync('jlOpenId');
      if (openId) {
        callback(openId)
      } else {
        uni.login({
          provider: 'weixin',
          success: (loginRes) => {
            uni.request({
              url: weburl + "/api/jlApplets/getOpenId",
              data: {
                code: loginRes.code,
                appletAppId: setSet.appid
              },
              success: (res) => {
                if (res.data && res.data.code == 200 && res.data.data && res.data.data.openid) {
                  uni.setStorageSync('jlOpenId', res.data.data.openid)
                  callback(res.data.data.openid);
                  // uni.setStorageSync('jlOpenId','34324234234324asdasdsa3')
                  // callback('34324234234324asdasdsa3');
                }
              }
            })
          }
        });
      }
    },
    confirmBm() { },


    // 跳转成功页面
    linkSuccess() {
      this.$router.push({ name: 'CourseSuccess-m', params: { id: this.positionId, ptype: 2 } })
    },
    // 跳转抢名额成功页面
    linkSuccess2() {
      this.$router.push({ name: 'Resuccessful-m', params: { id: this.positionId } })
    },
    // 推荐&大家都在看
    checkOut(i) {
      if (this.chooseTj == i) {
        return false;
      }
      this.chooseTj = i
      this.taskInfo.pageNum = 1 //初始化每页条数
      this.taskArray = [] //初始化
      if (this.chooseTj == 0) {
        this.taskInfo.queryTypes[0] = '1'
        this.taskInfo.queryTypes[1] = '0'
      } else {
        this.taskInfo.queryTypes[0] = '0'
        this.taskInfo.queryTypes[1] = '1'
      }
    },
    // 获取已报名的头像和人数
    getApplyList() {
      this.$axios({
        method: 'POST',
        url: '/app/home/getApplyList?positionId=' + this.positionId
      }).then((res) => {
        if (res.code == 200) {
          this.avatarList = res.data.list
          this.avatarSize = res.data.size
        } else {
          this.avatarList = []
          this.avatarSize = 0
        }
      })
    },

    // 是否有没填简历
    isResumeInfo() {
      let data = this.courseDetail
      let info = this.resumeInfo
      let obj = {
        education: false,
        usex: false,
        uold: false,
      }
      //学历判断 true为显示弹窗
      if (data.education > -1 && !info.educationBackground) {
        obj.education = true
      }
      //性别
      if ((data.userSex == 1 || data.userSex == 0) && !info.gender) {
        obj.usex = true
      }
      //年龄
      let dUold = JSON.parse(data.userOld);
      if (dUold && dUold.length > 0 && !info.age) {
        obj.uold = true
      }
      return obj
    },
    // 收藏
    hasCollect() {
      if (this.isCollect) {//已收藏准备要取消收藏
        this.$axios({
          method: 'POST',
          url: '/app/collect/deList',
          params: {
            ids: this.positionId,
            collectStatus: '1',
          }
        }).then(res => {
          if (res.code == 200) {
            this.isCollect = false
          }
        })
      } else {//未收藏准备要收藏
        this.$axios({
          method: 'POST',
          url: '/app/collect/addList',
          params: {
            positionId: this.positionId,
            positionTitle: this.courseDetail.positionTitle,
          }
        }).then(res => {
          if (res.code == 200) {
            this.isCollect = true
          }
        })
      }
    },
    // 跳转企业详情
    toLink() {
      let et = this.courseDetail.enterpriseVo
      let obj = {
        id: this.courseDetail.enterpriseId,
        name: et ? et.enterpriseName : '',
        logo: et ? et.enterpriseLogo : ''
      }
      this.$router.push({ name: 'company-m', params: obj })
    },
    // 跳转求职保障
    toLink2() {
      this.$router.push('/needJob-m')
    },
    // 跳转支付相关协议
    linkXy() {
      this.$router.push('/UserAgreement-m')
    },
  },
  filters: {
    setage(e) {
      if (!e) {
        return '--';
      }
      let dUold = JSON.parse(e);
      let str = `${dUold[0]}岁~${dUold[1] < 99 ? dUold[1] + '岁' : '不限'}`
      return str
    },
    setedu(e) {
      let str = '不限'
      if (e === 0) {
        str = '其他'
      } else if (e == 3) {
        str = '高中'
      } else if (e == 4) {
        str = '大专'
      } else if (e == 5) {
        str = '本科及以上'
      }
      return str
    },
    formatterAmounts(num) {
      if (!num) {
        return '0.00'
      }
      return num.toFixed(2)
    }
  },
  watch: {
    '$route.params': {
      handler(newval, oldval) {
        console.log(newval, '路由监听')
        // if(newval.path == '/taskDetail-m' || newval.path == '/courseDetail-m'){
        //   if(newval.query.id&&newval.query.id!=oldval.query.id){
        //     this.$nextTick(()=>{
        //       this.$router.go(0)
        //     })
        //   }
        // }
      },
      deep: true,
    },
    '$router': {
      handler(newval, oldval) {
        console.log(newval, 'router监听', oldval)
      },
    }
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlerPopstate)
    window.removeEventListener('resize', this.resizeChange)
    document.removeEventListener('visibilitychange', this.visibilitychange)
  },
}
</script>
  
<style scoped>
* {
  box-sizing: border-box;
}

.detailClass {
  padding: 2.75rem 0 4.5rem;
  position: relative;
  background-color: #FFFFFF;

  overflow: hidden;
}

/* 顶部返回区域 */
.topBack {
  width: 100vw;
  height: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  background-color: #ffffff;
  color: #141419;
  font-size: 1.13rem;
  font-weight: 600;
  font-family: "PingFang SC";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.backIcon {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4rem;
}

/* 底部按钮区域 */
.myBtns {
  width: 100vw;
  height: 4.5rem;
  background-color: #ffffff;
  border-radius: 0.75rem 0.75rem 0 0;
  box-shadow: 0 -0.31rem 1.06rem 0 #0000000d;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem 0 1.13rem;
}

.myBtn {
  width: 14.75rem;
  height: 3.25rem;
  line-height: 3.25rem;
  letter-spacing: 0.2rem;
  border-radius: 0.75rem;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  text-align: center;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "PingFang SC";
  display: inline-block;
}

/* 付费课程下方按钮 */
.myBtns2 {
  width: 100vw;
  height: 6.74rem;
  background-color: #ffffff;
  border-radius: 0.75rem 0.75rem 0 0;
  box-shadow: 0 -0.31rem 1.06rem 0 #0000000d;
  position: fixed;
  padding: 0 1.38rem 0 0.81rem;
}

.myBtns2_top {
  height: 2.16rem;
  line-height: calc(2.16rem - 1px);
  color: #9d9d9d;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  border-bottom: 1px solid #e8e8e8;
}

.myBtns2_bottom {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myBtns2_bottom_l {
  min-width: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myBtns2_bottom_l_money {
  color: #f4351a;
  font-weight: 500;
  font-family: "PingFang SC";
  line-height: 1.5rem;
  font-size: 1.25rem;
}

.myBtns2_bottom_btn {
  width: 7.5rem;
  height: 3.06rem;
  line-height: 3.06rem;
  text-align: center;
  border-radius: 0.75rem;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  font-family: "PingFang SC";
}

.baoZhang {
  color: #999999;
  font-size: 0.69rem;
  font-weight: 400;
  font-family: "PingFang SC";
  line-height: 1.25rem;
  margin: 0 0.63rem 0.38rem;
}

.baoZhang>img {
  width: 7.17rem;
  height: 2rem;
  vertical-align: middle;
}

.baoZhang>span {
  vertical-align: middle;
  margin-left: 0.4rem;
}

.detail_title {
  color: #333333;
  font-size: 1.13rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.63rem;
  padding: 0 0.63rem;
}

.courseContent {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.fuliList {
  display: flex;
  flex-wrap: nowrap;

}

.fuliList>div {
  width: 8.63rem;
  height: 2.75rem;
  position: relative;
  margin-right: 0.38rem;
  background: url(http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680600147332aaaaa.png) no-repeat center/cover;
  display: flex;
  padding: 0.24rem 0 0.24rem 2rem;
}

.fuliData_item_cont {
  color: #666666;
  font-size: 0.63rem;
  font-weight: 500;
  font-family: "PingFang SC";
  display: inline-block;
  padding: 0.2rem 0;
}

.fuliData_item_cont>div:first-child {
  color: #333333;
  font-size: 0.69rem;
  font-weight: 600;
  font-family: "PingFang SC";
}

.fuliData_item_cont>div {
  width: 6.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fuli_icon {
  width: 1.38rem;
  height: 1.38rem;
  position: absolute;
  left: 0.31rem;
  top: 50%;
  transform: translateY(-50%);
}

.avatorArr>img {
  width: 1.38rem;
  height: 1.38rem;
  border-radius: 50%;
}

.baomingDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.93rem 0 0.63rem;
  margin-top: 0.2rem;
}

.baomingNum {
  color: #999999;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
}

.baomingNum>span {
  color: #F4351A;
}


/* 模块标题 */
.moduleTitle {
  height: 1.41rem;
  line-height: 1.41rem;
  display: inline-block;
  color: #333333;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  letter-spacing: -0.02rem;
  position: relative;
  margin: 0 0 0.59rem 0.81rem;
}

.moduleTitle>span {
  display: inline-block;
}

.moduleTitle_fst {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  letter-spacing: 0.05rem;
}

.moduleTitle_sed {
  width: 100%;
  height: 0.38rem;
  background: linear-gradient(-90deg, #06ad941a 0%, #07bb7eff 83%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.gangwei {
  width: 22.14rem;
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 0.63rem;
  word-break: break-all;
  position: relative;
  margin: 0 auto 0.63rem;
}

.gangwei_sq {
  overflow: hidden;
  max-height: 19.38rem;
}

.zkSq {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 6.31rem;
  background: linear-gradient(0deg, #ffffff 0%, #ffffff00 100%);
  line-height: 9rem;
  text-align: center;
  color: #07be77;
  font-size: 0.88rem;
  font-weight: bold;
  font-family: "PingFang SC";
  letter-spacing: 0.05rem;
}

.zkSq2 {
  background: linear-gradient(0deg, #ffffff 0%, #ffffff 40%, #ffffff00 100%);
}

.zkSq3 {
  line-height: 10.4rem;
}

.zkSq_up {
  text-align: center;
  line-height: 2.4rem;
  color: #07be77;
  font-size: 0.88rem;
  font-weight: bold;
  font-family: "PingFang SC";
  letter-spacing: 0.05rem;
}

.zhunbei {
  width: 22.14rem;
  /* height: 13.25rem; */
  border-radius: 0.5rem;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 0.63rem;
  letter-spacing: 0.05rem;
  margin-bottom: 0.63rem;
}

.zhunbei_item>div>img {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
  margin-right: 0.24rem;
}

.zhunbei_item>div>span {
  color: #999999;
  font-size: 0.69rem;
  font-weight: 500;
  font-family: "PingFang SC";
  vertical-align: middle;
}

.zhunbei_item>div:last-child {
  padding-left: 0.99rem;
  color: #333333;
  font-size: 0.81rem;
  font-weight: 500;
  font-family: "PingFang SC";
}

.fuyeClass_outer {
  width: 100vw;
  padding: 0 0.63rem;
  overflow: scroll;
  margin-bottom: 0.63rem;
}

.fuyeClass {
  display: flex;
  justify-content: space-between;
}

.fuyeClass>img {
  width: 18.75rem;
  height: 6.38rem;
  margin-right: 0.63rem;
}

.muluClass {
  width: 22.14rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 0.63rem;
  margin: 0 auto 1rem;
  position: relative;
}

.muluClass_item {
  height: 2.44rem;
  line-height: 2.34rem;
}

.muluClass_item:not(.fst_class) {
  border-top: 1px solid #f3f3f3;
}

.muluClass_item>span:first-child {
  margin-right: 1.63rem;
}

.teacherClass {
  width: 22.14rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 0.63rem;
  margin: 0 auto 0.63rem;
}

.teacherClass_top {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.63rem;
  padding-bottom: 0.63rem;
  border-bottom: 1px solid #F3F3F3;
}

.teacherClass_top>img {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.teacherClass_top>div>img {
  width: 4rem;
  height: 1.13rem;
}

.teacherClass_bm {
  margin-top: 0.63rem;
  color: #999999;
  font-size: 0.69rem;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 1.18rem;
}

.googsi {
  width: 22.14rem;
  height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #ffffff;
  margin: 0 auto 0.63rem;
  padding: 0.63rem;
  position: relative;
}

.googsi>img {
  width: 2.76rem;
  height: 2.76rem;
  border-radius: 50%;
  margin-right: 0.55rem;
}

.googsi_r_cont {
  width: 14.38rem;
  height: 1.25rem;
  color: #999999;
  font-size: 0.63rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.googsi_r_cont_renzheng {
  color: #999999;
  font-size: 0.63rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.25rem;
}

.googsi_r_cont_renzheng>img {
  vertical-align: middle;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
}

.googsi_r_cont_renzheng>span {
  vertical-align: middle;
}

.tuijianClass {
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  padding-left: 0.63rem;
  margin-bottom: 1rem;
}

.tuijianClass>div {
  display: inline-block;
  padding-bottom: 0.3rem;
  margin-right: 1.03rem;
}

.tuijianClass_spanCheck {
  color: #07BB7E;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  position: relative;
}

.tuijianClass_spanCheck>span:last-child {
  width: 100%;
  height: 0.25rem;
  border-radius: 0.13rem;
  opacity: 1;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.collectBtn {
  width: 2.6rem;
  text-align: center;
  display: inline-block;
  color: #000000;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  vertical-align: middle;
}

.collectBtn>img {
  width: 1.56rem;
  height: 1.56rem;
}

.elLoadingdiv {
  height: 3rem;
  line-height: 2rem;
  text-align: center;
  color: #999999;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "PingFang SC";
}

.vRrrorPopup {
  background-color: transparent !important;
}

.errorMaskClass {
  width: 20.13rem;
  height: 14.69rem;
  position: relative;
}

.errorMaskClass>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.errorMaskClass_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.errorMaskClass_div>div:first-child {
  height: 11.66rem;
  width: 100%;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: 0.06rem;
  text-align: center;
  padding-top: 6.4rem;
}

.errorMaskClass_div>div:first-child>.error_cont {
  font-size: 1.25rem;
}

.errorMaskClass_div>div:last-child {
  width: 100%;
  height: 3.03rem;
  line-height: 3.03rem;
  text-align: center;
  color: #07BE77;
  font-size: 1.38rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: 1px;
}

.noMoreClass {
  height: 2rem;
  text-align: center;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}

.loadPay {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  z-index: 9999;
}

.loadPay>div {
  width: calc(100vw * 0.32);
  height: calc(100vw * 0.32);
  border-radius: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadPay>div>div {
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  margin-top: 0.4rem;
}

.detail_top {
  width: 100vw;
  height: 120px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 2.75rem;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.time {
  font-size: 12px;
  color: #999;
  margin-top: 3px;
}

.container {
  /* padding: 100px 0 60px; */
}

.content {
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px 15px 0;
}

.subBtn {
  width: 345px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  background-color: #07be77;
  color: #fff;
  border-radius: 5px;
  letter-spacing: 2px;
}
.subaBtn {
  width: 150px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  background-color: #07be77;
  color: #fff;
  border-radius: 5px;
  letter-spacing: 2px;
  margin-top: 10px;
}
.fixBotm {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>