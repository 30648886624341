<template>
  <div>
    <van-nav-bar fixed placeholder title="资讯详情" left-arrow @click-left="onClickLeft" />
    <van-sticky :offset-top="46" @change="stickyChange">
      <div class="topTitle" :style="stickyState?'':'margin-top:1rem;'">
        <div>{{ consultationDetail.title }}</div>
        <div v-if="consultationDetail.createTime">{{ consultationDetail.createTime }}</div>
      </div>
    </van-sticky>
    <div class="vhtml" v-html="consultationDetail.content"></div>
    <!-- <div class="neirong">
      <div class="title">{{ consultationDetail.title }}</div>
      <div class="recommendTime" v-if="consultationDetail.createTime">{{ consultationDetail.createTime.trim().split(" ")[0] }}</div>
      <hr style="width: 21.13rem;margin-top: 0.51rem; ">
      <div class="detailscontent" v-html="consultationDetail.content"></div>
    </div> -->
    <div class="lsmen" style="">
      <div style="">
        <h3>联系我们</h3>
        <ul>
          <!-- <li>邮箱：629666098@qq.com</li> -->
          <li>电话：19906413192</li>
        </ul>
      </div>
      <div>
        <h3>关于我们</h3>
        <ul>
          <li>关于我们</li>
          <li>企业介绍</li>
          <li>兼职网平台</li>
        </ul>
      </div>
      <div>
        <h3>媒体中心</h3>
        <ul>
          <li>兼职新闻</li>
        </ul>
      </div>
    </div>
    <div class="jianzhiimg">
      <div> <img class="tg_icon" src="@/assets/image/mobile/jzwlogo.png" alt="兼职网logo"></div>
      <div>
        <div class="tg_icon2">
          <img src="@/assets/image/mobile/erweima.png" alt="兼职网">
          <div>扫码下载APP</div>
        </div>
      </div>
      <div> <img class="jzwmini" src="@/assets/image/mobile/jzwmini.png" alt="兼职网"></div>
    </div>
    <div class="footer" style="color: #333333; font-size: 0.63rem; font-weight: 500;padding: 0 1.6rem 1rem">
      <div style="display: flex;justify-content: center;">
        <div style="zoom: 0.9">鲁ICP备2022033828号-1</div>
        <div style="margin-left: 1.5rem;display: flex;align-items: center;">
          <img style="width: 0.69rem;height: 0.69rem;" src="@/assets/image/mobile/beian.png" alt="兼职网">
          <div style="zoom: 0.9">鲁公网安37010502001905号</div>
        </div>
      </div>
      <div style="zoom: 0.9;margin-top: 0.3rem;text-align: center;">Copyright © 2017-2023 兼职网(山东)信息技术有限公司</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "consultationDetail-m",
  props:['routeMsg'],
  data() {
    return {
      consultationDetail: {},
      stickyState:false,
    }
  },
  created() {
    this.getConsultationDetail();
  },
  methods: {
    stickyChange(e){
      this.stickyState=e
    },
    onClickLeft() {
      if(this.routeMsg.old){
        this.$router.push(this.routeMsg.old)
      }else{
        this.$router.push('/')
      }
    },
    getConsultationDetail() {
      this.$axios({
        method: "get",
        url: "/official/enterprise/getInformation",
        params: {
          id: this.$router.getParams().id
        },
      })
        .then((res) => {
          if (res.code === 200) {
            this.consultationDetail = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
}
</script>
<style scoped lang="less">
.neirong {
  background-color: #FFFFFF;
  margin-top: 0.69rem;
  width: 100vh;
  padding-left: 1.13rem;
  padding-right: 1.13rem;

  .title {
    width: 21.56rem;
    // height: 1.31rem;
    opacity: 1;
    color: #000000ff;
    font-size: 0.94rem;
    font-weight: 600;
    font-family: "PingFang SC";
    text-align: left;
    letter-spacing: 0.04rem;
    padding-top: 0.75rem;
    // margin-bottom: 1rem;
  }

  .recommendTime {

    // width: 4.81rem;
    height: 1.06rem;
    opacity: 1;
    color: #a2a2a2ff;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: left;
    letter-spacing: 0.03rem;

  }

}

.lsmen {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3.6rem;

  h3 {
    color: #333333ff;
    font-size: 0.94rem;
    font-weight: 700;
  }

  li {
    // width: 6.25rem;
    // height: 0.81rem;
    opacity: 1;
    color: #333333ff;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: left;
    letter-spacing: 0.02rem;
    line-height: 1.5rem;
  }
}

.jianzhiimg {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2.09rem;
  margin-bottom: 2.34rem;

  .tg_icon {
    width: 5.84rem;
    height: 5.84rem;
    border-radius: 0.67rem;
  }
  .tg_icon2{
    width: 6rem;
    height: 7rem;
    border-radius: 0.67rem;
    text-align: center;
    font-size: 0.6rem;
    background-color: #ffffff;
    padding: 0.4rem;
    img{
      width: 5.2rem;
      height: 5.2rem;
      object-fit: cover;
    }
    div{
      margin-top: 0.2rem;
    }
  }
  .jzwmini{
    width: 6rem;
    height: 6.6rem;
  }
}

.footer {
  display: flex;
  flex-direction: column;
}

/deep/ .van-nav-bar {
  background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
  font-size: 1.13rem !important;
  font-weight: 700 !important;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
  font-size: 1.3rem;
}
.topTitle>div:first-child{
  color: #000000;
  font-size: 0.94rem;
  font-weight: 600;
  font-family: "PingFang SC";
  letter-spacing: 0.04rem;
  margin-bottom: 0.69rem;
}
.topTitle>div:last-child{
  color: #a2a2a2ff;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: 0.03rem;
}
.topTitle{
  padding: 1rem 1.13rem;
  border-bottom: 1px solid #ededed;
  background-color: #ffffff;
}
.vhtml{
  width: 100vw;
  overflow-x: hidden;
  padding: 1rem 1rem 2rem;
  background-color: #ffffff;
}
.vhtml /deep/ img{
  max-width: calc(100vw - 2rem) !important;
  height: auto;
}
</style>
