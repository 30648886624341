<template>
  <div>
    <div class="renwu_top">
        <swiper :options="mySwiperOption" v-if="bannerList.length>0">
            <swiper-slide v-for=" (item,index) in bannerList" :key="item.id" >
                <div v-if="item.videoUrl == '' || item.videoUrl == null" style="height: 100%;position: relative" :data-no="item.id">
                    <img :data-set="index" style="width: 100%;height: 100%;" :src="item.imgUrl" alt="兼职网"/>

                </div>
                <div v-else  style="height: 100%;position: relative;" :data-no="item.id">
                    <img :data-set="index" style="width: 100%;height: 100%;" :src="item.imgUrl" alt="兼职网" />
                    <!---播放按钮-->
                    <div class="play-box" >
                        <img :data-set="index" src="@/assets/images/pc/videoPlay.png"  alt="兼职网"/>
                    </div>
                    <!-- <video controls  width='100%' height='100%'><source  :src="item.videoUrl"  type='video/mp4'></video> -->
                </div>
            </swiper-slide>
        </swiper>

      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>

    <div class="content">
        <div class="contentBox">
            <div class="navtop">
                <div style="margin-left: 5px;cursor: pointer;" @click="goHome">
                    首页 <i class="el-icon-arrow-right" style="margin-right: 5px"></i>
                </div>
                <span style="margin: 0px 10px;color: #00A870;cursor: pointer">关于我们</span>
            </div>

        </div>
    </div>

    <!---公司简介开始-->
    <div class="comPanyBI">
      <div class="comPanyBI-box">
        <div class="comPanyBI-left">
          <img src=""  class="comPanyBI-left-quan" alt="兼职网">
          <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687416848800Slice%20658%402x.png" class="comPanyBI-left-img" alt="兼职网"/>
        </div>


        <div class="comPanyBI-right">
          <div class="comPanyBI-title">公司简介</div>
          <div class="comPanyBI-mess">
            <span>
              兼职网（济南）信息技术有限公司成立于2017年，是国家级高新技术企业、山东省文化厅批准设立的经营性互联网文化单位、地方政府重点关注扶持企业，被国家评定为科技型企业，山东省软件协会理事单位，是数字经济浪潮下专为中国大学生提供兼职、实习、就业指导的服务型互联网平台，也是一家面向全国企业，专业从事人力资源与劳务派遣服务的综合性平台。
            </span>
          </div>

          <div class="comPanyBI-bottom">
            <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687418586394Mask%20group%402x.png" alt="兼职网"/>
            <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687418633383Slice%20660%402x.png" alt="兼职网"/>
          </div>
        </div>
      </div>
    </div>
    <!---公司简介结束-->

    <!---实例品牌展示开始-->
    <div class="exhibition">
      <div class="exhibition-box">
        <div class="exhibition-title">
          实力品牌资质展示
        </div>

        <div class="exhibition-f-title">
          企业发展多年以来，荣获国家级知识产权60余项，涵盖注册商标9项、发明及外观专利3项、软件著作权50余项、等诸多资质
        </div>


        <!--第一个轮播开始-->
        <div >
          <carousel-3d :autoplay="true" space="300" height="500" :autoplay-timeout="3000" :display="5" controlsVisible>
            <slide v-for="(item,index) in swiper1List" :index="index" :key="item.id">
              <template slot-scope="{index,isCurrent,leftIndex,rightIndex}" >
                <img class="spin_img" :src="item" :data-index="index"
                     :class="{current: isCurrent, onLeft:(leftIndex>=0), onRight:(rightIndex>=0)}" alt="兼职网">
              </template>
            </slide>
          </carousel-3d>


        </div>
        <!--第一个轮播结束-->

        <!---第二个轮播开始-->
        <div style="margin-top:230px">
          <carousel-3d inverseScaling="1" space="400" :autoplay="true" :autoplay-timeout="3000" :display="3" controlsVisible>
            <slide v-for="(item,index) in swiper2List" :index="index" :key="item.id" >
              <template slot-scope="{index,isCurrent,leftIndex,rightIndex}" >
                <img class="spin_img2" :src="item" :data-index="index"
                     :class="{current: isCurrent, onLeft:(leftIndex>=0), onRight:(rightIndex>=0)}"alt="兼职网">
              </template>
            </slide>
          </carousel-3d>
        </div>

        <!---第二个轮播结束-->
      </div>
    </div>
    <!---实例品牌展示结束-->

    <!---兼职网灵活用工平台-->
    <div class="comPanyBIs">
      <div class="comPanyBI-box">
        <div class="comPanyBIS-left">
          <div class="comPanyBI-left-title">兼职网灵活用工平台</div>

          <div class="comPanyBI-left-mess">
            由央视主办，以响应“质量强国建设，甄选并培育更多精品品牌”为口号，中国优质品牌融媒体互动访谈节目《品质中国》在北京隆重开幕，兼职网作为品质卓越、特色鲜明的灵活就业领军品牌受邀参加，开启中国品牌走向全国各地城市的新目标、新征程！
          </div>
        </div>

        <div class="comPanyBI-rights" @click="videoShowT = true">
          <img src="@/assets/images/pc/QualityChina.png" alt="兼职网" >
        </div>
      </div>
    </div>
    <!----兼职网灵活用工平台结束-->

      <!--中国共产党兼职网支部委员会成立开始-->
      <div class="council" style="margin-top: 80px;">
          <div class="councilLeft">
              <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687419678012Slice%20819%402x.png">
          </div>

          <div class="councilRight">

              <div class="councilNav">
                  <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687419414027Slice%20700%402x.png" />

                  <div>中国共产党兼职网支部委员会成立</div>
              </div>

              <div class="councilRightMess">
                  兼职网（济南）信息技术有限公司顺利召开中国共产党兼职网（济南）信息技术有限公司支部委员会成立大会。会议承载了国家党建对兼职网党建工作的大力支持，同时发扬了员工深刻的爱国情怀淋漓尽致的体现了党员的先进性：“平时工作看得出来，关键时候站得出来，党和群众需要时候能挺身出来”从大家宣誓的誓言里听出大家的心声：势必保持党员先进性，这是时代发展的要求，也是党的事业发展的要求，更是共产党员永葆政治本性，勇作先锋的要求。
              </div>

          </div>
      </div>
      <!--中国共产党兼职网支部委员会成立结束-->

    <!---信息开始-->
    <div class="explain">
      <div class="explain-box">
        <!---左边-->
        <div class="explain-left">
          <div class="explain-left-bottom">
            <div class="explain-left-info">
              <div class="explain-left-info-title">
                用心服务<span style="margin-left: 10px;"></span>追求卓越品质
                <br/>
                以服务为核心，为客户持续创造价值
              </div>

              <div class="explain-left-info-mess">
                <img src="@/assets/images/pc/left-info.png">
                <!--描述-->
                <span>
                   基于精准的用户画像及庞大的人才资源库，平台为大学生与用人单位搭建桥梁，赋能大学生勤工俭学，涵盖电商运营、短视频剪辑、配音、直播、平面设计、软件开发等线上兼职，与海量线下灵活用工兼职机会
                </span>
              </div>
            </div>
          </div>

<!--          下面的图片-->
<!--          <div class="explain-left-top">-->
<!--            <img src="@/assets/images/pc/left-top.png" alt="百万种子用户 赋能企业用工"/>-->
<!--          </div>-->
        </div>


        <!--右边-->
        <div class="explain-right">
          <div class="explain-right-bottom">
            <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687420319608Slice%20820%402x.png" alt=" 用心服务 追求卓越品质 以服务为核心，为客户持续创造价值"/>
          </div>

<!--          <div class="explain-right-top">-->
<!--            <div class="explain-right-box-box">-->
<!--              <div class="explain-right-top-title">种子用户数量庞大  <span style="margin-left: 10px;"></span> 赋能企业用工</div>-->

<!--              <div class="explain-right-top-mess">-->
<!--                <img src="@/assets/images/pc/right-info.png" alt="兼职网"/>-->
<!--                <span>平台累积注册用户率创新高，完成了众多兼职任务。解决了大学生在校兼职、勤工俭学、灵活就业需求，为中国广大优秀应届大学生，提供更多更有趣的兼职任务。</span>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
        </div>
      </div>
    </div>
    <!---信息结束-->


      <div class="council" style="margin-top: 20px;">
          <div class="councilLeft">
              <img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687427997035Slice%20820%402x.png">
          </div>

          <div class="councilRight">

              <div class="comPanyBI-left-title">
                  企业发展理念
              </div>

              <div class="councilRightMess">
                  兼职网致力于通过移动软件开发，整合校企渠道资源， 逐步形成强大的品牌、人才与技术优势。公司成立以来，坚持稳中求进，推动人才高质量发展，向全面建设社会主义现代化国家新征程迈出坚实步伐，积极响应国家政策，助力灵工经济。以厚积薄发的精神带动企业经营稳步提升。
              </div>

          </div>
      </div>

    <!---视频播放框-->
    <div class="viode-box" v-if="videoShow" @click="videoShow = !videoShow">
      <div class="video-mess">
        <video id="startvideo" controls  width='100%' height='100%' autoplay="autoplay"><source  :src="playVideo"  type='video/mp4' ></video>
      </div>
    </div>


    <!---视频二 播放控件-->
    <div class="video-box" v-if="videoShowT" @click="videoShowT = !videoShowT">
      <div class="video-mess">
        <video id="startvideo" controls  width='100%' height='100%' autoplay="autoplay"><source  src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1704513998690%E5%93%81%E8%B4%A8%E4%B8%AD%E5%9B%BD%20APP%E6%9B%BF%E6%8D%A2.mp4"  type='video/mp4' ></video>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {Carousel3d, Slide} from 'vue-carousel-3d'
export default {
  name:"nAboutMe",
  components: {
    Carousel3d,
    Slide,
  },
  data(){
    return{
      videoShow :"",
      videoShowT:false,
      playVideo :"",
      bannerList:[],
      swiper1List : [
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1684143740959541e0e1c22a363192e4f555b8bf10af.jpg",
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1684143773369d58d3da49506ca37393eaab745d96a5.jpg",
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/16841438241883e3d1b9b49f148ed509ec69e6bf29a3.jpg",
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/168414385068599b2a7bba3b17351dd437c1a22af480.png",
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1684143874979f058bd4245cbbd9bd6bc15a3cbfaf63.png",
      ],
      swiper2List: [
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680919902427lQLPJxPoqAcXc07NASjNAaCwP3ZjYD1lRPwEKUI9eUDMAA_416_296.png",
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680919945709lQLPJxFsAqWhVk7NASjNAaCwcl5guTydYGYEKUJAXAAvAA_416_296.png",
        "https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680919973485lQLPJxTeODXvZc7NAYDNAhyw9jT1WMtAsiIEKUJC0EAEAA_540_384.png",
      ],
      mySwiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click:(e)=> {
              let index = e.target.dataset.set
              let item  = this.bannerList[index]
            console.log(item);
              if(item.jumpUrl != "" && item.jumpUrl != undefined && item.jumpUrl != null){
                  let name = ''
                  let params = {}
                  if(item.jumpUrl.indexOf('?') > '-1'){
                      name = item.jumpUrl.split('?')[0];
                      console.log("name=>",name)
                      let param = item.jumpUrl.split('?')[1]
                      param.split('&').map(item=>{
                          params[item.split('=')[0]] = item.split('=')[1]
                      })
                  }
                  name = item.jumpUrl.split('?')[0];
                  this.$router.push({
                      name: name,
                      params: Object.assign({},{
                          id: item.jumpUrl,
                          name: this.$route.meta.metaInfo.title,
                      },params),
                  });
              }
            if(item.videoUrl != null && item.videoUrl != ''){
              this.videoShow = true;
              this.playVideo = item.videoUrl
              var video = document.getElementById("startvideo");
              if(video != null){
                video.play();
              }
            }

          }
        }
      }
    }
  },
  created(){
    console.log("123")
  },
  beforeMount(){
    // var mySwiper2 = new Swiper(this.$refs.swiper1, {
    //   effect: 'coverflow',
    //   initialSlide:3,
    //   slidesPerView: 3,
    //   centeredSlides: true,
    //   prevButton:'.swiper-button-prev',
    //   nextButton:'.swiper-button-next',
    //   loop:true,
    //   loopAdditionalSlides: 10,
    //   coverflow: {
    //     rotate: 45,
    //     stretch: 0,
    //     depth: 0,
    //     // modifier: 2,
    //     slideShadows: false
    //   }
    // });
      this.getBanner();
  },
    // beforeMount(){
    //
    // },
  watch: {
    // bannerList: {
    //   //为什么即使监听到数据变化了，还需要放入$nextTick？因为v-for渲染需要时间，在没有渲染完成之前，不能初始化swiper
    //   immediate: true,
    //   handler() {
    //     //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
    //     this.$nextTick(function() {
    //       var mySwiper = new Swiper(this.$refs.cur, {
    //         autoplay: {
    //           delay: 3000,
    //           stopOnLastSlide: false,
    //           disableOnInteraction: false,
    //         },
    //         loop: true,
    //         observer: true,       //修改swiper自己或子元素时，自动初始化swiper
    //         observeParents: true, //修改swiper的父元素时，自动初始化swiper
    //         navigation: {
    //           nextEl: ".swiper-button-next",
    //           prevEl: ".swiper-button-prev",
    //         },
    //         onSlideChangeEnd:function (swiper) {
    //           console.log("当前页数=>",swiper);
    //         }
    //       });
    //       mySwiper.on("slideChangeTransitionEnd",function(swiper){
    //         console.log("当前页数=>",swiper);
    //
    //       })
    //     });
    //   },
    // },
  },

  methods:{
    goHome(){
          this.$router.push({
              name:"homePage"
          })
      },
    setvideo(e){
      console.log(e)
      this.videoShow = true;
      this.playVideo = e.videoUrl
      var video = document.getElementById("startvideo");
      if(video !=null){
        video.play();
      }else{
        console.log("没有元素")
      }
    },
    swiperleft() {
    },
    swiperright() {
    },
    //获取banner 图
    getBanner(){
      this.$axios({
        method: "GET",
        baseUrl:process.env.VUE_APP_JZWB_URL2,
        url:"/official/enterprise/getBanner?locationType="+'2',
        data:{
          locationType:2
        }
      }).then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data
          console.log("res=>",res)

        }
      });
    },
  }
}
</script>
<style scoped>
#el-carousel /deep/ .el-carousel__container{
  height: 10.5rem !important;
}
.renwu_top {
  position: relative;
  width: 100%;
  /* height: 540px; */
  /* height: 440px; */
}

.content{
    background-size: 100% auto;
}
.contentBox{
    width: 1200px;
    margin: auto;
}
.navtop{
    /*width: 1200px;*/
    margin: auto;
    display: flex;
    align-items: center;
    padding-top: 28px;
}
.navtop div{
    color: #ccc;
}
.navtop div:hover{
    color: #00A870;
}
.navtop span{
    color: #bcbfca;
}


.comPanyBI{
  margin-top: 105px;
  margin-bottom: 146px;
}
.comPanyBI-box{
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.comPanyBI-left{
  width: 547px;
  height: 410px;
  position: relative;
}
.comPanyBI-left-img{
  width: 547px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.comPanyBI-left::before{
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 11px solid #9CDFC9;
  position: absolute;
  left: -49px;
  top: 0px;
}
.comPanyBI-right{
    position: absolute;
    left: 45%;
    background-color: #F7F7F7;
    padding:0 55px;
    width: 616.67px;
    height: 550px;
    opacity: 1;
    background: #f7f7f7ff;
}
.comPanyBI-rights{

}
.comPanyBI-title{
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 24px;
  position: relative;
}
.comPanyBI-title::before{
  content: "";
  position: absolute;
  width: 74px;
  height: 5px;
  background: #07ba7dff;
  top: 42px;
    left: 8%;
}
.comPanyBI-mess{
  margin-top: 51px;
}
.comPanyBI-mess>span{
  display: block;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  font-family: "PingFang SC";
}
.comPanyBI-bottom{
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}
.comPanyBI-bottom>img{
    width: 234.17px;
    height: 155px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.exhibition{
  background-image: url("https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687234438139exhibitionBg.png");
  padding: 55px;
  width: 100%;
  height: 1300px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.exhibition-box{
  width: 1200px;
  margin: auto;
}
.exhibition-title{
  height: 80px;
  opacity: 1;
  color: #f7eb84ff;
  font-size: 40px;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: center;
  line-height: 80px;
  letter-spacing: 20px;
  position: relative;
}
.exhibition-title::before{
  content: "";
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  position: absolute;
  background-color: #f7eb84ff;
  position: absolute;
  left: 304px;
  top: 35px;
}
.exhibition-title::after{
  content: "";
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  position: absolute;
  background-color: #f7eb84ff;
  position: absolute;
  right: 304px;
  top: 35px;
}
.exhibition-f-title{
  color: #ffffffff;
  font-size: 20px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: center;
}
.explain{
  padding: 71px 0px 60px 0px;
}
.explain-left{
  width: 710px;
}
.explain-left-bottom{
  display: flex;
  justify-content: left;
}
.explain-left-info{
  /*width: 450px;*/
}
.explain-left-info-title{
  height: 90px;
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 45px;
  position: relative;
}
.explain-left-info-title::before{
  content: "";
  width: 45px;
  height: 5px;
  background: #07be78ff;
  position: absolute;
  bottom: -22px;
}
.explain-left-info-mess{
  position: relative;
  margin-top: 55px;
}
.explain-left-info-mess>img{
  width: 375px;
  position: absolute;
  top: 0;
  left: 0;
}
.explain-left-info-mess>span{
  display: block;
  width: 470px;
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 34px;
  margin-left: 18px;
}
.explain-left-top{
  width: 710px;
  margin-top: 99px;
}
.explain-left-top>img{
  width: 530px;
}
.explain-box{
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: auto;
}
.explain-right{
  width: 530x;
  position: relative;
}
.explain-right-bottom{
  width: 530px;
}
.explain-right-bottom>img{
  width: 100%;
}
.explain-right-top-title{
  color: #000000ff;
  font-size: 25px;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 45px;
  position: relative;
}
.explain-right-top-title::before{
  position: absolute;
  content: "";
  width: 45px;
  height: 5px;
  background: #07be78ff;
  bottom: -20px;
}
.explain-right-top-mess{
  position: relative;
}
.explain-right-top-mess>img{
  width: 375px;
  position: relative;
  right: -242px;
}
.explain-right-top{
  position: absolute;
  bottom: 188px;
  left: -115px;
}
.explain-right-box-box{
  width: 624px;
  position: absolute;
  bottom: -164px;
}
.explain-right-top-mess>span{
  display: block;
  position: absolute;
  top: 26px;
  width: 548px;
  color: #333333ff;
  font-size: 16.5px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 34px;
}


.swiper-container{
  height: 100%;
}
.viode-box{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5);
  /* padding-top: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-mess{
  width: 1200px;
  margin: auto;
  height: 600px;
}
.play-box{
  position: absolute;
  right: 150px;
  bottom: 60px;
  z-index: 99;
}
.play-box>img{
  width: 100px;
  height: 100px;
}


.spin_img {
  width: 100%;
  height: 100%;
}

.carousel-3d-container:nth-child(1){
  min-height: 1px;
  width: 100%;
  /* height: 16rem !important; */
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 20px auto;
  box-sizing: border-box;
}

.carousel-3d-container:nth-child(1)>.carousel-3d-slider{
  height: 100% !important;
}
.carousel-3d-slide{
  background-color: rgba(0, 0, 0, 0) !important;
}
/deep/.next>span{
  font-size: 100px;
  color: #7e7e7e;
}
/deep/.prev>span{
  font-size: 100px;
  color: #7e7e7e;
}
.comPanyBIS-left{
  width: 569px;
  height: 250px;
  opacity: 1;
  color: #585858ff;
  font-size: 24px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 50px;
}
.comPanyBI-right>img{
  width:550px;
}
.comPanyBI-rights>img{
    width: 550px;
}
.comPanyBIs{
  margin-top: 67px;
  /*margin-bottom: 120px;*/
  background-image: url("@/assets/images/pc/QualityChinaBg.png");
  height: 500px;
  background-size: cover;
}
.comPanyBIS-left{
  width: 569px;
  color: #585858ff;
  font-size: 24px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 50px;
}
.comPanyBI-left-title{
  width: 360px;
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  position: relative;
    line-height: 41px;
}
.comPanyBI-left-title::before{
  content: "";
  width: 56px;
  height: 4px;
  background-color: #07BA7D;
  position: absolute;
  left: 0px;
  top: 56px;
}
.comPanyBI-left-mess{
  color: #333;
  font-size: 20px;
  font-weight: 500;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 35px;
    margin-top: 24px;
}
.video-box{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.8);
}

/deep/ .swiper-button-prev, .swiper-button-next {
  color: #fff;
}
/deep/.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 32px;
}

.council{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

}
.councilLeft{
    width: 552.5px;
}
.councilLeft>img{
    width: 525.5px;
}
.councilRight{
    margin-left: 82.5px;
}
.councilNav{
    display: flex;
}
.councilNav>img{
    width: 28.33px;
    height: 30.83px;
}
.councilNav>div{
    color: #ff0606;
    font-size: 30px;
    font-weight: 700;
    font-family: "PingFang SC";
    position: relative;
    margin-left: 10px;
}
.councilNav>div:before{
    content: "";
    width: 44.17px;
    height: 4.17px;
    background: #ff0606;
    position: absolute;
    left: 0;
    top: 50px;
}
.councilRightMess{
      width: 553.33px;
      height: 325.83px;
      color: #333333;
      font-size: 20px;
    margin-top: 60px;
    background-image: url(https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687421425932Vector%20828.png);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-position: 150px 52px;
}
</style>
