<template>
  <div>
    <!--面包屑-->
    <div>
      <div class="navtop-box" >
        <div class="navtop">
          <div style="font-weight: 600;" @click="$router.back()">
            <span>首页</span> <i class="el-icon-arrow-right"></i>
          </div>

            <div style="font-weight: 600;" @click="$router.back()">
                <span>职位详情</span> <i class="el-icon-arrow-right"></i>
            </div>


          <span style="color: #00A870;">{{enterpriseDetail.name}}</span>
        </div>
      </div>


      <!--岗位详情-->
      <div class="post-mess-box">
        <div class="post-mess-box-left">
          <!--公司信息-->
          <div class="company">
            <img :src="enterpriseDetail.enterpriseLogo" alt="兼职网" />
            <div>{{enterpriseDetail.name}}</div>
          </div>

          <!--企业基本信息-->
          <div class="module-box">
            <div class="module-title"><span>企业基本信息</span></div>
            <div class="enterprise-mess">
              <div class="enterprise-list">
                <div class="enterprise-let">
                  <div class="enterprise-title">企业类型</div>
                  <div class="enterprise-info">{{enterpriseDetail.companyOrgType}}</div>
                </div>

                <div class="enterprise-right">
                  <div class="enterprise-title">经营状态</div>
                  <div class="enterprise-info">{{enterpriseDetail.regStatus}}</div>
                </div>
              </div>

              <div class="enterprise-list">
                <div class="enterprise-let">
                  <div class="enterprise-title">成立时间</div>
                  <div class="enterprise-info">{{enterpriseDetail.fromTime}}</div>
                </div>

                <div class="enterprise-right">
                  <div class="enterprise-title">注册地址</div>
                  <div class="enterprise-info">{{enterpriseDetail.regLocation}}</div>
                </div>
              </div>

              <div class="enterprise-list">
                <div class="enterprise-let">
                  <div class="enterprise-title">统一信用代码</div>
                  <div class="enterprise-info">{{enterpriseDetail.creditCode}}</div>
                </div>

                <div class="enterprise-right">
                  <div class="enterprise-title">组织结构代码</div>
                  <div class="enterprise-info">{{enterpriseDetail.orgNumber}}</div>
                </div>
              </div>

              <div class="enterprise-list">
                <div class="enterprise-let">
                  {{enterpriseDetail.businessScope}}
                </div>
              </div>
              <div class="yuanzi">
                以上信息由<img src="@/assets/images/pc/tianyancha.png" alt="兼职网" /> <span>天眼查</span>提供
              </div>
            </div>
          </div>

          <!--同类型商家还在招的岗位-->
          <div class="module-box">
            <div class="module-title">
                <span>{{enterpriseDetail.name}}在招的其他岗位</span></div>

            <div class="module-list-box">
              <div v-for="(item,index) in PositionList" :key="index" class="quarters-list" @click="getErweimaT(item)">
                <!--第一标题-->
<!--                <div class="quarters-title">-->
<!--                  <img src="" alt="兼职网"/>-->
<!--                  <span>任务d</span>-->
<!--                </div>-->
                <!--第二标题-->
                <div class="quarters-title-two">
                  <span>{{item.positionTitle}}</span>
                  <span v-if="item.amountType == 2">{{item.amountBegin /100}}~{{item.amountEnd /100}}元/{{timeTypeList[item.settlementUnit]}}</span>
                  <span v-else>{{item.amount /100}} / {{timeTypeList[item.settlementUnit]}}</span>
                </div>

                <!--tabs-list-->
                <div class="quarters-tabs">
                  <div v-for="(item2,index2) in item.tags" :key="index2">{{item2}}}</div>
                </div>


                <!--底部-->
                <div class="quarters-bottom">

                  <div class="quarters-left">
                    <div class="quarters-left-portrait">
                      <img v-if="item.enterprise" :src="item.enterprise.enterpriseLogo" alt="兼职网"/>
                    </div>

                    <div class="info">
                      <div v-if="item.enterprise">{{item.enterprise.enterpriseName}}</div>
                      <div v-if="item.positionType!= '2'">距您4.88km 济南市 历下区（限制字符20个）</div>
                    </div>
                  </div>
                  <div class="sumbit-fun" @click="getErweima(item)">
                    立即报名
                  </div>

                </div>

              </div>
            </div>

          </div>

          <!--推荐公司-->
<!--          <div class="module-box">-->
<!--            <div class="type-title">推荐公司</div>-->
<!--            <div class="type-list" style="width: 591.67px;justify-content: space-between">-->
<!--              <div v-for="(item,index) in 4" :key="index" class="company-list">-->
<!--                <div class="company-portrait">-->
<!--                  <img src="" alt="兼职网"/>-->
<!--                </div>-->
<!--                <div class="company-info">-->
<!--                  <div class="company-info-title">钱江鱼味(大润发店）</div>-->
<!--                  <div class="company-info-mess">在招岗位 2 个</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->


          <!--更多兼职类型-->
<!--          <div class="module-box">-->
<!--            <div class="type-title">更多兼职类型</div>-->

<!--            <div class="type-list">-->
<!--              <div class="tyope-list-mess" v-for="(item,index) in typeList" :key="index">-->
<!--                {{item}}<i class="el-icon-right" style="margin-left: 5px"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <!--热门城市-->
<!--          <div class="module-box">-->
<!--            <div class="type-title">热门城市</div>-->

<!--            <div class="type-list">-->
<!--              <div class="tyope-list-messT" v-for="(item,index) in cityList" :key="index">-->
<!--                {{item}}<i class="el-icon-right" style="margin-left: 5px"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <!--专题-->
<!--          <div class="module-box" style="display: flex;flex-wrap: wrap;align-items: center">-->
<!--            <span class="title">专题:</span>-->
<!--            <div class="tags" v-for="(item,index) in 10" :key="index">苏州兼职日常</div>-->
<!--          </div>-->
        </div>
        <div class="post-mess-box-right">
<!--          <div class="box-title">热门推荐</div>-->
<!--          <div class="position-box" v-for="item in 2">-->
<!--            <img src="" alt="">-->
<!--            <div class="position-title">招语音聊天主播</div>-->
<!--            <div class="position-price">300元/天</div>-->
<!--          </div>-->

          <div class="box-title">推荐兼职</div>
          <div class="recommend-position" v-for="item in ExposureList" @click="goDetail(item)">
            <div class="flex flex_center flex_item_between">
              <div class="recommend-position-title">{{item.positionTitle}}</div>
                <div class="recommend-position-price" v-if="item.amountType == 1">{{ item.amount /100}}元/{{timeTypeList[item.settlementUnit]}}</div>
                <div class="recommend-position-price" v-else>{{ item.amountBegin /100}}-{{item.amountEnd /100}}元/{{timeTypeList[item.settlementUnit]}}</div>
            </div>
              <div class="recommend-position-time" v-if="item.workingPeriod != 0 || (item.workDateBegin != '' || item.workDateEnd)">工作时间：{{ item.workDateBegin}} -- {{item.workDateEnd}}</div>
              <div class="recommend-position-time" v-else>工作时间：不限</div>
              <div class="recommend-position-address">工作地点：{{ item.detailedProcess || '不限' }}</div>
          </div>
          <div class="qrcode">
            <div class="qrcode-bg">
              <img src="@/assets/images/pc/gzh_qrcode.png" alt="公众号">
            </div>
          </div>
          <div class="qrcode-tip">关注兼职网微信公众号，海量兼职岗位在线等</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enterpriseDetail",
  data(){
    return {
      title:"",
      typeList:[
        '全国教育培训完工结算兼职',
        '全国咨询服务完工结算兼职',
        '全国生活服务兼职',
        '全国客服话务月结兼职',
        '全国互联网岗位兼职'
      ],
      cityList:[
        '马鞍山兼职',
        '攀枝花兼职',
        '鄂州兼职',
        '马鞍山兼职',
        '攀枝花兼职'
      ],
      enterpriseDetail:{},
      PositionList:[],
          timeTypeList: ['其他', '小时', '天', '周', '月', '次', '单'],
        ExposureList:[]
    }
  },
  beforeMount() {
    this.getEnterpriseDetail();
    this.getPositionList();
    this.getRecommendList();
    this.title = this.$router.getParams().name
  },

  methods:{
      goDetail(e){
          this.$router.push({
              name: "positionDetail",
              params: {
                  id: e.id,
                  company:"企业详情"
              },
          });
      },
      getRecommendList() {
          this.$axios({
              url:`/official/enterprise/getRecommendList?pageNum=1&pageSize=2`,
              method: 'POST',
              // data:{
              //     latitude: this.latitude || 0,
              //     longitude: this.longitude || 0,
              // }
          }).then(res => {
              // if(this.recommendType == 1){
              //     this.ExposureList = res.data
              // }
              this.ExposureList = res.rows

          })
      },
    // getErweima(e){
    //   this.$router.push({
    //     name: "positionDetail",
    //     params: {
    //         id: e.id,
    //         company:this.enterpriseDetail.name
    //     },
    //   });
    //   this.$router.go(0)
    // },
      getErweima(e){
          localStorage.setItem("erWeima",1)
          this.$router.push({
              name: "positionDetail",
              params: {
                  id: e.id,
                  name: this.$route.name,
                  company:"企业详情"
              },
          });
      },

      getErweimaT(e){
          this.$router.push({
              name: "positionDetail",
              params: {
                  id: e.id,
                  name: this.$route.name,
                  company:"企业详情"
              },
          });
      },
    getEnterpriseDetail(){
      this.$axios({
        url:'/official/enterprise/getEnterprise',
        method:'POST',
        params:{
          enterpriseId:this.$router.getParams().id
        }
      }).then(res=>{
        if(res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.enterpriseDetail = res.data
        this.$route.meta.metaInfo.title = this.enterpriseDetail.name
        console.log("enterpriseDetail=>",this.enterpriseDetail)
      })
    },

    getPositionList() {
      this.$axios({
        url: `/official/enterprise/getPositionList?pageNum=${this.pageNum}&pageSize=2`,
        method: 'POST',
        data: {
          pageNum: this.pageNum,
          pageSize: 2,
          enterpriseId: this.$router.getParams().id,
          latitude: this.latitude || '0',
          longitude: this.longitude || '0',
        }
      }).then(res => {
        console.log(res)
        this.PositionList = res.data
      })
    },
  }
}
</script>

<style src="@/assets/css/pc/enterpriseDetail.css" scoped></style>
