<template>
    <div>

      <Header v-show="isShow"></Header>
      <router-view/>
        <hot v-show="isShow "></hot>
      <Footer v-show="isShow"></Footer>
    </div>
</template>

<script>
  import Header from '@/components/pc/header.vue'
  import Footer from '@/components/pc/footer.vue'
  import hot from "@/components/pc/hot.vue"
    export default {
      data(){
        return {
            isShow:true,
            isShowT:true
        }
      },
      components: {
        Header,
        Footer,
          hot
      },
      mounted() {
          this.$common.sendLogH5('exposure', {message: '官网曝光埋点'})
          console.log("home mounted", this.$route.params);
      },
      watch: {
          '$route':{
              handler(newval) {
                  console.log("路由=》",newval)
                  if(newval.path == "/login" ){
                      this.isShow = false;
                  }else {
                      this.isShow = true;
                  }
              },
              immediate:true,
          }
      },
      methods:{}
    }
</script>

<style scoped>

</style>
