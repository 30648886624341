<template>
  <div style="background: #f9f9f9;">
    <div class="content">
        <div class="contentBox">
            <div class="navtop">
                <div style="margin-left: 5px;cursor: pointer;" @click="goHome">
                    首页 <i class="el-icon-arrow-right" style="margin-right: 5px"></i>

                </div>
                <div style="margin-left: 5px;cursor: pointer;" @click="$router.back()" ><!----v-if="routeNameT"-->
                    <!-- {{ routeNameT }}-->
                    资讯
                         <i class="el-icon-arrow-right" style="margin-right: 5px"></i> 
                </div>
                <div style="margin-left: 5px;cursor: pointer;" @click="$router.back()" v-if="routeName"> <!-- -->
                    <!-- 资讯类别 -->
                    {{ routeName }} 
                    <i class="el-icon-arrow-right" style="margin-right: 5px"></i>
                </div>
                <span style="margin: 0px 10px;color: #00A870;cursor: pointer">{{ informationDetail.title }}</span>
            </div>
            <div class="title" >
                {{ informationDetail.title }}
            </div>

            <div class="time">
                {{informationDetail.createTime | setTime}}
            </div>
        </div>


    </div>


      <div class="messBox">
          <div class="messLeft">
              <div class="rich_text fwb" v-html="informationDetail.content"></div>
          </div>

          <div class="messRight">
              <!--热门推荐-->
              <div class="hotBox">
                  <div class="hotBoxTitle">
                      <img src="@/assets/images/pc/HotIcon.png" />
                      <span>热门推荐</span>
                  </div>

                  <a :href="item.id" v-for="item in hotList" onclick="return false">
                      <div class="hotList"  @click="toDetail(item)">
                          <div class="hotListLeft">
                              <img :src="item.imgUrl" />
                          </div>
                          <div class="hotListCount">
                              <div class="hotListCountTitle">{{item.title}}</div>

                              <div class="hotListBottom">
                                  <div class="hotListTime">
                                      <i class="el-icon-time" style="margin-right: 5px"></i>{{item.createTime | setTime}}
                                  </div>
                                  <div class="hotListFun">
                                      查看详情 <i class="el-icon-arrow-right"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </a>
              </div>


              <div class="messBoxRightT">
                  <div class="hotBoxTitle">
                      <img src="@/assets/images/pc/Recommendedtopic.png" />
                      <span>推荐专题</span>
                  </div>

                  <div class="topicBoxx">
                      <a :href="item.id" v-for="item in topicList" onclick="return false">
                          <div class="topicList" >
                              <!--底部图片-->
                              <img class="topicListImg" :src="item.imgUrl" />
                              <!--文字-->
                              <div class="descriptionBox">
                                  <div class="descriptionTitle">{{item.topicName}}</div>
                                  <div class="descriptionTitleT">{{item.title}}</div>
                                  <div class="descriptionFun" @click="goThematicDetails(item)">查看专题</div>
                              </div>
                          </div>
                      </a>
                  </div>

                  <div class="topicFun" @click="goTopic">
                      <img src="@/assets/images/pc/topicFun.png" />
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "informationDetail",
  data() {
    return {
      informationDetail: {},
      minHeight: 807,
      routeName: '',
        routeNameT: '',
      hotList:[],
      topicList:[]
    };
  },
    filters:{
        setTime(e){
            return e.split(" ")[0]
        }
    },
  created() {
    this.getInformationDetail();
    this.getInformationTIIList();
    this.getInformationTRCList();
    if(this.$router.getParams().name != '首页'){
        this.routeName = this.$router.getParams().name
        this.routeNameT = this.$router.getParams().routeNameT;
    }
  },
  methods: {
      toDetail(item) {
          this.$router.push({
              name: 'empty',
              params: {
                  id: item.id,
                  name: this.$route.name
              }
          });
      },
      goThematicDetails(item){
          this.$router.push({
              name: `thematicDetails`,
              params: {
                  name: '资讯详情',
                  Second:"资讯",
                  item:item
              },
          });
      },
      goHome(){
          this.$router.push({
              name:"homePage"
          })
      },
      /*跳转全部专题*/
      goTopic(){
          this.$router.push({
              name:"thematicList"
          })
      },
    getInformationDetail() {
      this.$axios({
        method: "get",
        url:"/official/enterprise/getInformation",
        params: {
          id: this.$router.getParams().id,
        },
      })
        .then((res) => {
          if (res.code === 200) {
            this.informationDetail = res.data;
            this.$route.meta.metaInfo.title = this.informationDetail.title
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
      getInformationTIIList(){
          this.$axios({
              url:"/official/enterprise/getInformationTRIList?pageNum=1&pageSize=3",
              method:"POST"
          }).then(res=>{
              console.log('热门推荐=>',res)
              if(res.code == 200){
                  this.hotList = res.rows
              }
          })
      },

      getInformationTRCList(){
          this.$axios({
              url:"/official/enterprise/getInformationTRCList?pageNum=1&pageSize=6&id=''",
              method:"POST"
          }).then(res=>{
              console.log('推荐专题=>',res)
              if(res.code == 200){
                  this.topicList = res.rows
              }
          })
      }
  },
}
</script>

<style src="@/assets/css/pc/informationDetail.css" scoped>


</style>
<style >
.fwb .ql-editor{
    white-space: pre-wrap;
}
.fwb .ql-container>div{
    min-height: 300px;
    max-height: 550px;
}
.fwb h1,.fwb h2,.fwb h3,.fwb h4,.fwb h5,.fwb h6{
    font-weight:bold;
}
.fwb h1{
    font-size: 26px !important;
}
.fwb h2{
    font-size: 22px !important;
}
.fwb h3{
    font-size: 20px !important;
}
.fwb h4{
    font-size: 18px !important;
}
.fwb h5{
    font-size: 16px !important;
}
.fwb h6{
    font-size: 16px !important;
}
.fwb p{
    font-size: 16px;
}
.fwb p > img{
    width: 100%;
}
.ql-align-center {
    text-align: center;
}
.ql-align-center>span>img{
    width: 100%;
}
.ql-align-center>img{
    width: 100%;
}
.ql-align-right {
    text-align: right;
}
.ql-align-left {
    text-align: left;
}
</style>