<template>
    <div style="height:100vh">
        <van-sticky>
            <van-nav-bar title="我的简历" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <div class="jianli">
            <div class="hang" style="height: 4.59rem;line-height: 4.59rem;">
                <p>头像</p>
                <input @input="uploadoss" accept='image/*' id="sssss" ref="ipt" type="file" v-show="false">
                <div @click="Obtainavatar" style="line-height: 2rem;margin-top: 0.8rem;"><img class="avatarImg"
                        :src="fileList" alt="头像" srcset="" /></div>
            </div>
            <div class="hang">
                <p style="height: calc(2.75rem - 1px);line-height: calc(2.75rem - 1px);">昵称</p>
                <div><van-field placeholder="请输入名称" v-model="userlist.nickname" /></div>
            </div>
            <div class="hang">
                <p style="height: calc(2.75rem - 1px);line-height: calc(2.75rem - 1px);">姓名</p>
                <div><van-field placeholder="请填写姓名" v-model="userlist.name" /></div>
            </div>
            <div class="hang" style="height: 4.59rem;line-height: 4.59rem;">
                <p>性别</p>
                <div v-for="item in sexOption" :key="item.value"
                    :class="item.value == userlist.gender ? 'active' : 'default'" @click="setSex(item)">
                    {{ item.label }}
                </div>
            </div>
            <div class="hang" style="line-height: 3.31rem;">
                <p style="margin-right: 2.13rem;">出生日期</p>
                <div>
                    <div v-if="userlist.age" style="line-height:1.5rem;margin-top: 0.95rem;display: flex;"><span
                            style="color: #999999ff;font-size: 0.88rem;" @click="clickshowVanPop">{{ userlist &&
                                userlist.age.split('-')[0] }}年 -
                            {{ userlist && userlist.age.split('-')[1] }}月</span>
                        <div class="dateRemark" style="margin-left:1rem;margin-top: 0.2rem;">( 一个月只能修改一次 )</div>
                    </div>
                    <div v-else style="padding-bottom: 0.5rem;">
                        <div class="form_item_date" @click="clickshowVanPop">
                            <div style="margin-left: 0.2rem;"><span>请选择出生日期</span></div>
                            <div><img style="width: 1.5rem;" src="@/assets/image/mine/dateicon.png" alt="日期"></div>
                        </div>
                        <div class="dateRemark">( 一个月只能修改一次 )</div>
                    </div>
                </div>
            </div>
            <div class="hang" style="height: 5.88rem;line-height: 3.88rem;border: 0;">
                <p style="width: 4rem;margin-right: 2.13rem;">学历</p>
                <div style="display: flex;flex-wrap: wrap;margin-top: 0.15rem;">
                    <div v-for="item in education" :key="item.value"
                        :class="item.value == userlist.educationBackground ? 'active' : 'default'"
                        @click="educationclick(item)">
                        {{ item.label }}
                    </div>
                </div>

            </div>
        </div>
        <div style="display: flex;margin-top: 8.06rem;">
            <div class="forget"><button @click="$router.push('/PersonalCenter-m')">放弃填写</button></div>
            <div class="save" @click="saveUserResInfo"><button>保存</button></div>
        </div>
        <!-- 出生年月 -->
        <van-popup v-model="showVanPop" round position="bottom" safe-area-inset-bottom>
            <van-datetime-picker @cancel="showVanPop = false" @confirm="dateConfirm" v-model="currentDate" type="year-month"
                title="选择年月" :min-date="minDate" :max-date="maxDate"></van-datetime-picker>
        </van-popup>
    </div>
</template>

<script>
import {uploadFileToOss} from "@/assets/utils/common.js";
export default {
    data() {
        return {
            fileList: '',
            nickname: '',
            userName: '',
            sexOption: [
                {
                    label: "男",
                    value: 0
                },
                {
                    label: "女",
                    value: 1
                },
                {
                    label: "未知",
                    value: 2
                },
            ],
            education: [
                {
                    label: "高中",
                    value: 3
                },
                {
                    label: "大专",
                    value: 4
                },
                {
                    label: "本科及以上",
                    value: 5
                },
                {
                    label: "其他",
                    value: 0
                },
            ],
            showVanPop: false,
            userlist: {},
            minDate: new Date(1945, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(2003, 0, 1),
        }
    },
    created() {
        this.getuser()
    },
    methods: {
        //获取头像
        Obtainavatar() {
            this.$refs.ipt.dispatchEvent(new MouseEvent('click'))
        },
        // 上传oss
        uploadoss() {
            uploadFileToOss(this.$refs.ipt.files[0]).then(res => {
                this.fileList = res.url
                this.userlist.userImg= res.url
            })
        },
         onClickLeft() {
            this.$router.push('/PersonalCenter-m');
        },
        //拉起日历
        clickshowVanPop() {
            if (this.userlist.age && this.userlist.stype == 1) {
                this.showVanPop = false
                this.$toast.fail('本月已修改过生日，不能再次修改');
            } else {
                this.showVanPop = true
            }
        },
        //日历确定按钮
        dateConfirm(value) {
            let date = new Date(value)
            let getFullYear = date.getFullYear()
            let getMonth = date.getMonth() + 1
            this.userlist.age = getFullYear + '-' + getMonth
            this.showVanPop = false
        },
        //获取个人信息
        getuser() {
            this.$axios({
                method: "POST",
                url: "/app/userResume/getUserResInfo",
            }).then((res) => {
                this.fileList = res.data.userImg;
                this.currentDate = new Date(res.data.age)
                console.log(this.currentDate, "this.currentDate");
                this.userlist = res.data;
                localStorage.setItem("resume", JSON.stringify(res.data));
            }).catch((error) => { });
        },
        // 修改用户信息
        saveUserResInfo() {
            this.$axios({
                method: 'post',
                url: '/app/userResume/saveUserResInfo',
                data: this.userlist,
                needPrivate: true
            }).then((res => {
                if (res.code == 200) {
                    this.$router.push('/PersonalCenter-m');
                    this.$toast.success('保存成功')
                } else if (res.code == 500) {
                    // this.$toast.fail('本月已修改过生日，不能再次修改');
                }
            }))
        },
        //修改性别
        setSex(e) {
            this.userlist.gender = e.value
        },
        //修改学历
        educationclick(e) {
            this.userlist.educationBackground = e.value
        },
    },
}
</script>
<style scoped>
*{
    box-sizing: border-box;
}
</style>
<style scoped lang="less">
.jianli {
    margin-top: 1.31rem;
    height: 25.44rem;
    opacity: 1;
    background: #ffffffff;
    display: flex;
    flex-direction: column;

    .hang {
        width: 100%;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        border-bottom: 1px solid #f2f2f2ff;
        display: flex;

        .avatarImg {
            width: 3.13rem;
            height: 3.13rem;
            border-radius: 0.44rem;
        }

        p {
            color: #333333ff;
            font-size: 1rem;
            font-weight: 600;
            margin-right: 4.13rem;
            font-family: "PingFang SC";
        }

        .active {
            width: 4.25rem;
            height: 2rem;
            border-radius: 0.16rem;
            background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
            color: #ffffffff;
            font-size: 0.81rem;
            font-weight: 500;
            text-align: center;
            line-height: 2rem;
            margin: auto 0;
            margin-right: 0.5rem;
            // margin-top: 0.75rem;
        }

        .default {
            width: 4.25rem;
            height: 2rem;
            border-radius: 0.16rem;
            background: #f2f2f2ff;
            color: #CCCCCC;
            font-size: 0.81rem;
            font-weight: 500;
            text-align: center;
            line-height: 2rem;
            margin: auto 0;
            margin-right: 0.5rem;
            // margin-top: 0.75rem;
        }

        .form_item_date {
            width: 14.69rem;
            height: 2rem;
            border-radius: 0.25rem;
            opacity: 1;
            border: 0.06rem solid #07be77ff;
            display: flex;
            text-align: center;
            justify-content: space-between;
            line-height: 1.8rem;
            margin-top: 0.4rem;

            span {
                font-size: 0.88rem;
                font-weight: 400;
                color: #07BB7E;
            }

            img {
                // margin-top: 0.16rem;
                margin-bottom: 0.2rem;
                margin-right: 0.3rem;
            }
        }

        .dateRemark {
            height: 0.94rem;
            line-height: 0.94rem;
            color: #07BB7E;
            font-size: 0.69rem;
            font-weight: 500;
            font-family: "PingFang SC";
            margin-top: 0.38rem;
        }

    }


}

.forget {
    width: 7.13rem;
    height: 3.25rem;
    border-radius: 0.75rem;
    opacity: 1;
    background-color: #FFFFFF;
    color: #07BB7E;
    border: 1px solid #07BB7E;
    text-align: center;
    line-height: 3.25rem;
    margin-left: 0.75rem;
}

.save {
    width: 14.06rem;
    height: 3.25rem;
    border-radius: 0.75rem;
    opacity: 1;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    color: #FFFFFF;
    text-align: center;
    line-height: 3.25rem;
    margin-left: 0.75rem;
}

/deep/ .van-cell {
    padding-left: 0%;
    padding-right: 0%;
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/.van-nav-bar .van-icon {
    color: #000000;
    font-size: 1.3rem;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}

/deep/ .van-picker__confirm {
    color: #07BB7E;
}

/deep/.el-upload {
    line-height: 3.2rem;
}
</style>