<template>
<div class="loginBox_outer" @click="close">
  <div class="loginBox" @click.stop="">
    <img class="loginBox_logo" src="@/assets/images/pc/loginMask/jzblogin.png" alt="兼职网">
    <img class="loginBox_hj" src="@/assets/images/pc/loginMask/huojian.png" alt="兼职网">
    <div class="loginBox_content">
      <i class="el-icon-close loginBox_content_close" @click="close"></i>
      <div class="loginBox_content_title">
        <img src="@/assets/images/pc/loginMask/title.png" alt="兼职网" />
      </div>
      <div class="loginBox_content_title2">
        <span>多重岗位审核</span>
        <span>·</span>
        <span>千万薪资保障</span>
      </div>
      <div class="loginBox_content_icon">
        <span></span>
        <span></span>
      </div>
      <div class="myIpt">
        <div>
          <el-input style="width:233.33px;height: 28px;" v-model="phone" placeholder="请输入手机号"></el-input>
          <div class="myIpt_error" :style="{opacity:phoneTs?1:0}">{{phoneTs?phoneTs:'err信息'}}</div>
        </div>
        <div style="position: relative;" class="yzm_ipt">
          <el-input style="width:233.33px;height: 28px;" v-model="code" placeholder="请输入验证码" @keyup.enter="goIndex"></el-input>
          <div v-if="timeTrue" class="yzmclass getCOde" @click="getcode">获取验证码</div>
          <div v-if="!timeTrue" class="yzmclass time">再次发送({{time}})s</div>
          <div class="myIpt_error" :style="{opacity:codeTs?1:0}">{{codeTs?codeTs:'err信息'}}</div>
        </div>
        <div class="loginBtn" @click="goIndex">登录</div>
        <div class="agreement" @click="agree = !agree">
          <i v-if="agree" class="el-icon-success" ></i>
          <i v-if="!agree" style="color:#08BE79;" class="el-icon-success"></i>
          我已阅读并同意<span @click="goyh">《用户协议》</span><span @click="goys">《隐私协议》</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        agree:true,
        timeTrue:true,
        time:60,
        phoneTs:"",
        codeTs:"",
        phone:"",
        code:"",
        logoTrue:true,
      }
    },
    watch:{
        'code':{
            handler(newval){
                console.log(newval)
                if (newval.length==6){
                    this.logoTrue = false
                }else {
                    this.logoTrue = true
                }
            },
            immediate:true,
        }
    },
    methods: {
      getcode(){
        var TEL_REGEXP = /^1[3|4|5|6|7|8|9]\d{9}$/;;
        if(!TEL_REGEXP.test(this.phone)){
          this.phoneTs = "手机号格式错误"
          setTimeout(()=>{
              this.phoneTs = "";
          },3000)
        }else {
          this.timeTrue = false;
          let timers = setInterval(()=>{
            this.time--;
            if(this.time == 0){
              clearInterval(timers);
              this.timeTrue = true;
              this.time = 60;
            }
          },1000)
          this.$axios({
            url: "/official/enterprise/getLoginCode?phone="+this.phone,
            method:"POST"
          }).then((res)=>{
            if(res.code==200){
              this.$notify({
                title: '成功',
                message: '验证码发送成功',
                type: 'success'
              });
            }
          })
        }
      },
      goIndex(){
            var TEL_REGEXP = /^1[3|4|5|6|7|8|9]\d{9}$/;;
            if(!TEL_REGEXP.test(this.phone)){
                this.$notify({
                    title: '错误',
                    message: '手机号格式错误',
                    type: 'error'
                });
                return;
            }else if(this.agree){
                this.$notify({
                    title: '错误',
                    message: '请先勾选用户协议',
                    type: 'error'
                });
                return;
            }else {
                this.$axios({
                    url: "/official/enterprise/loginByCode",
                    data:{
                        code:this.code,
                        phone:this.phone
                    },
                    method:"POST",
                    needPrivate:true,
                }).then(res => {
                    if(res.code == 200){
                        localStorage.setItem("infoAvars",res.data.imgUrl)
                        this.$bus.$emit("setImg",res.data.imgUrl)
                        this.close()
                        // this.$router.push({
                        //     name:"homePage"
                        // })
                    }else if(res.code != 200) {
                        this.codeTs = res.msg
                        setTimeout(()=>{
                            this.codeTs = "";
                        },3000)
                    }
                })
            }

        },
      // 关闭按钮事件
      close() {
        this.$emit('close')
      },
      goyh(){
        this.close()
            this.$router.push({
                name:'userxy'
            })
        },
        goys(){
          this.close()
            this.$router.push({
                name:'userys'
            })
        }
    },
  }
</script>

<style scoped>
.loginBox_outer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.loginBox{
  width: 750px;
  height: 375px;
  background-color: #51d1a1;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  transform: scale(1.2);
}
.loginBox_logo{
  width: 71.67px;
  height: 21.72px;
  position: absolute;
  top: 21.67px;
  left: 26.67px;
}
.loginBox_hj{
  width: 369.17px;
  height: 303.33px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.loginBox_content{
  width: 450px;
  height: 375px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 57.5px;
  box-sizing: border-box;
}
.loginBox_content_close{
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.loginBox_content_title,.loginBox_content_title>img{
  width: 214.51px;
  height: 20.83px;
  margin: 0 auto;
}
.loginBox_content_title2{
  width: 214.51px;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #1c453a;
  margin: 10px auto;
  padding: 0 10px;
}
.loginBox_content_icon{
  height: 4px;
  line-height: 4px;
  text-align: center;
}
.loginBox_content_icon>span{
  width: 32px;
  height: 4px;
  background-color: #0ac078;
  border-radius: 2px;
  display: inline-block;
  margin-right: 2px;
}
.loginBox_content_icon>span:last-child{
  width: 4px;
}
.myIpt{
  width: 246px;
  margin: 24px auto 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.myIpt /deep/ .el-input__inner:hover {
  border-color: #f9f9f9;
}
.myIpt /deep/ .el-input__inner{
  border: 1px solid #f9f9f9;
  background-color: #f9f9f9;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
.myIpt /deep/ .el-input__inner:focus{
  border-color: #f9f9f9;
}
.myIpt_error{
  color: #ff0000;
  font-size: 12px;
  font-family: "PingFang SC";
  text-align: left;
  padding-left: 15px;
}
.loginBtn{
  width: 233.33px;
  height: 28.33px;
  line-height: 28.33px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  background-color: #14ca84;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 6px auto;
  cursor: pointer;
}
.agreement{
  font-size: 12px;
  color: #C0C0C0;
  margin-top: 11.67px;
  align-items: baseline;
  cursor: pointer;
  user-select: none;
}
.agreement>span{
    color: #08BD7C;
}
.yzmclass{
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 6px;
  top: 0;
  font-size: 12px;
  color: #08BD7C;
  cursor: pointer;
  user-select: none;
}
.yzm_ipt /deep/ .el-input__inner{
  padding: 0 80px 0 15px;
}
</style>