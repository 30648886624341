<template>
    <div style="height: 100vh;background-color: #FFFFFF;">
        <van-nav-bar title="设置" left-arrow @click-left="onClickLeft" />
        <div class="box_div" style=""  @click="$router.push('/PrivacyAgreement-m')">
            <div class="name">隐私协议</div>
            <div class="icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="box_div" style=""  @click="$router.push('/UserAgreement-m')">
            <div class="name">用户协议</div>
            <div class="icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="box_div" style="" @click="ClearCache">
            <div class="name">清除缓存</div>
            <div class="icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="tclogin" @click="ClearCache"><button>退出登录</button></div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        onClickLeft() {
            this.$router.push('/PersonalCenter-m');
        },
        ClearCache() {
            //清除所有
            localStorage.clear();
            // this.$bus.$emit('refresh')
            this.$router.push('/homePage-m');
        }
    }

}
</script>

<style scoped lang="less">
.box_div {
    height: 2.8rem;
    display: flex;
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-bottom: 0.05rem solid #ccc;
    line-height: 2.8rem;

    .name {
        font-size: 0.8rem;
    }

    .icon {
        font-size: 1.2rem;
    }
}

.tclogin {
    width: 14.75rem;
    height: 3.25rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    color: #ffffffff;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    line-height: 3.25rem;
    margin: auto;
    margin-top: 0.5rem;
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}
/deep/.van-nav-bar .van-icon {
    color: #000000;
    font-size: 1.3rem;
}
</style>