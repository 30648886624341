<template>
	<div class="aboutme">
		<van-swipe :autoplay="3000" indicator-color="white" :show-indicators="false">
			<van-swipe-item v-for="item in bannerList" :key="item.id" @click="swiperClick(item)">
				<van-image class="imgDontClick" style="display:block;" :src="item.imgUrl" />
				<div v-show="item.videoUrl" class="mask_video">
					<span class="videoBtn"><i class="el-icon-caret-right"></i></span>
				</div>
			</van-swipe-item>
		</van-swipe>
		<div class="cont1">
			<div class="moduleTitle">
				<div>关于兼职网简介</div>
				<div class="line" style="bottom: -0.6rem;width: 1.6rem;top: 1.8rem;"></div>
			</div>
			<div class="new_cont1_content">
				<img @click="setzhanshi('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1700710433873131.png')"
					src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1700710433873131.png" alt="兼职网12" />
				{{ data1 }}
				<!--				<span>兼职网（山东）信息技术有限公司成立于2017年，是高新技术企业、山东省文化厅批准设立的经营性互联网文化单位</span>-->
				<!--				<span style="display: block;margin-top: 22px">地方政府重点关注扶持企业，被国家评定为科技型企业山东省软件协会理事单位，是数字经济浪潮下专为中国大学生提供兼职、实习、就业指导的服务型互联网平台，也是一家面向全国企业，专业从事人力资源与劳务派遣服务的综合性平台。</span>-->
				<!--				&lt;!&ndash; <div>{{ data1 }}</div>
        <div>
          <span>专业决定品质，实力铸就成功！</span>
          <img style="height: 11.6rem;top: -11.6rem;" class="imgDontClick"
            src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1683254900733dalou.png" alt="兼职网">
        </div> -->
			</div>
		</div>
		<div class="cont2" style="margin-top: 0.6rem;">
			<div class="cont2_title">实力品牌 资质展示</div>
			<div class="cont2_content">{{ data2 }}</div>
			<div style="position: relative;">
				<el-carousel :interval="3000" type="card" style="height: 9.19rem; margin-top: 1rem;overflow-y: hidden;"
					indicator-position="none" ref="swiper1">
					<el-carousel-item v-for="(item, index) in swiper1List" :key="item">
						<img @click="onChangeswiper1List(index)" style="width: 6.75rem; height: 9.19rem" :src="item"
							alt="" />
					</el-carousel-item>
				</el-carousel>
				<div class="swiperL" @click="$refs.swiper1.prev()">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="swiperR" @click="$refs.swiper1.next()">
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
			<div style="position: relative;">
				<el-carousel :interval="3000" type="card" style="height: 6.5rem; margin-top: 1.6rem;overflow-y: hidden;"
					indicator-position="none" ref="swiper2">
					<el-carousel-item style="height:142px;" v-for="(item, index) in swiper2List" :key="item">
						<img @click="onChange(index)" style="width: 9.13rem; height: 6.5rem" :src="item" alt="" />
					</el-carousel-item>
				</el-carousel>
				<div class="swiperL" @click="$refs.swiper2.prev()">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="swiperR" @click="$refs.swiper2.next()">
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</div>
		<div class="cont2d5">
			<div class="cont2d5_title">兼职网灵活用工平台</div>
			<div class="cont2d5_cont">
				由央视主办，以响应“质量强国建设，甄选并培育更多精品品牌”为口号，中国优质品牌融媒体互动访谈节目《品质中国》在北京隆重开幕，兼职网作为品质卓越、特色鲜明的灵活就业领军品牌受邀参加，开启中国品牌走向全国各地城市的新目标、新征程！
			</div>
			<div class="cont2d5_img" @click="showVideo2 = true">
				<img class="imageThree" src="@/assets/images/h5/aboutme/pzzgBj.png" alt="品质中国背景2" />
				<img src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681785919459baccTu.png" alt="兼职网"
					class="imgDontClick imageOne" />
				<img class="imageTwo" src="@/assets/images/h5/aboutme/pzzgBj.png" alt="品质中国背景" />
			</div>
		</div>

		<div class="council">
			<div class="councilNav">
				<span>
					<img src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687414908479Slice%20700%402x.png" />
				</span>

				<div>
					中国共产党兼职网支部委员会成立
				</div>
			</div>

			<div class="councilMess">
				<div class="councilMessLeft">
					<span style="font-size: 13px;color: #6d6d6d;font-family: 'PingFang SC';font-weight: 600;">
						兼职网（山东）信息技术有限公司顺利召开中国共产党兼职网（山东）信息技术有限公司支部委员会成立大会。会议承载了国家党建对兼职网党建工作的大力支持，同时发扬了员工深刻的爱国情怀，淋漓尽致的体现了党员的先进性：“平时工作看得出来，关键时候站得出来，党和群众需要时候能挺身出来”从大家宣誓的誓言里听出大家的心声：势必保持党员先进性，这是时代发展的要求，也是党的事业发展的要求，更是共产党员永葆政治本性，勇作先锋的要求
					</span>
				</div>


				<div class="councilMessRight">
					<img @click="setzhanshi('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687415326275Slice%20699%402x.png')"
						src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687415326275Slice%20699%402x.png" />
					<img @click="setzhanshi('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687415354954Slice%20698%402x.png')"
						src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687415354954Slice%20698%402x.png" />
				</div>
			</div>
		</div>


		<div class="cont3">
			<div class="cont2d5_title" style="width: 19.06rem;margin-left: 0.97rem;">
				用心服务 追求卓越品质<br />以服务为核心，为客户持续创造价值
			</div>
			<div class="cont3_content">
				<img @click="setzhanshi('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687440587555Slice%20702%402x.png')"
					src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687440587555Slice%20702%402x.png" />
				<div>
					<span style="font-size: 13px;color: #6d6d6d;font-family: 'PingFang SC';font-weight: 600;">
						{{ data3 }}
					</span>
				</div>
			</div>
		</div>


		<div class="council">
			<div class="cont2d5_title" style="width: 19.06rem;margin-left: 0.97rem;">
				企业发展理念
			</div>

			<div class="councilMess">
				<div class="councilMessLefts">
					<span style="font-size: 13px;color: #6d6d6d;font-family: 'PingFang SC';    font-weight: 600;">
						兼职网致力于通过移动软件开发，整合校企渠道资源，
						逐步形成强大的品牌、人才与技术优势。公司成立以来，坚持稳中求进，推动人才高质量发展，向全面建设社会主义现代化国家新征程迈出坚实步伐，积极响应国家政策，助力灵工经济。以厚积薄发的精神带动企业经营稳步提升。
					</span>
				</div>


				<div class="councilMessRight">
					<img @click="setzhanshi('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687427712162Slice%20702%402x.png')"
						src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1687427712162Slice%20702%402x.png" />
				</div>
			</div>
		</div>


		<div class="cont4" v-if="false">
			<div class="moduleTitle" style="margin: 2.88rem auto 1.53rem">
				<div style="width: 19.06rem; margin: 0 auto">
					种子用户数量庞大 赋能企业用工
				</div>
				<div class="line" style="bottom: -0.6rem"></div>
			</div>
			<div class="cont4_content">
				<div>
					<span>
						{{ data4 }}
					</span>
				</div>
				<img src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1683255002162aboutT2.png"
					class="imgDontClick" />
			</div>
		</div>

		<!--预览框开始-->
		<div class="yulan" v-show="yulanshou" @click="setYulan">
			<img :src="yulanImg">
		</div>
		<!--预览框结束-->
		<div class="cont4d5" style="position: relative;height: 14.34rem;overflow: hidden;" v-if="false">
			<div class="cont4d5_title">全球合作商家</div>
			<div class="globalImgs_outer">
				<vue-seamless-scroll :data="companyList" style="width: 100vw;height: 2.3rem;overflow: hidden;"
					:class-option="optionLeft">
					<div class="globalImgs">
						<img v-for="(item, index) in companyList" :key="index" :src="item.src" :alt="item.name">
					</div>
				</vue-seamless-scroll>
				<vue-seamless-scroll :data="companyList2"
					style="width: 100vw;height: 2.3rem;overflow: hidden;margin: 0.8rem 0;" :class-option="optionLeft2">
					<div class="globalImgs">
						<img v-for="(item, index) in companyList2" :key="index" :src="item.src" :alt="item.name">
					</div>
				</vue-seamless-scroll>
				<vue-seamless-scroll :data="companyList3" style="width: 100vw;height: 2.3rem;overflow: hidden;"
					:class-option="optionLeft">
					<div class="globalImgs">
						<img v-for="(item, index) in companyList3" :key="index" :src="item.src" :alt="item.name">
					</div>
				</vue-seamless-scroll>
			</div>
			<div class="quanqiu" v-if="false">
				<div class="" style="display: flex;flex-wrap: wrap;justify-content: center">
					<div>
						<img style="width: 4.25rem;height: 1.52rem;margin-right:1.6rem;margin-bottom: 0.91rem;"
							:src="require(`@/assets/images/h5/aboutme/about/logo${item}.png`)" alt="兼职网" v-for="item in 8"
							:key="item">
					</div>
					<div>
						<img style="width: 4.25rem;height: 1.52rem;margin-right:1.6rem;margin-bottom: 0.91rem;"
							:src="require(`@/assets/images/h5/aboutme/about/logo${item + 8}.png`)" alt="兼职网"
							v-for="item in 8" :key="item">
					</div>
					<div>
						<img style="width: 4.25rem;height: 1.52rem;margin-right:1.6rem;margin-bottom: 0.91rem;"
							:src="require(`@/assets/images/h5/aboutme/about/logo${item + 3}.png`)" alt="兼职网"
							v-for="item in 8" :key="item">
					</div>
					<div>
						<img style="width: 4.25rem;height: 1.52rem;margin-right:1.6rem;margin-bottom: 0.91rem;"
							:src="require(`@/assets/images/h5/aboutme/about/logo${item + 5}.png`)" alt="兼职网"
							v-for="item in 8" :key="item">
					</div>
				</div>
			</div>
		</div>
		<div class="cont5" v-if="false">
			<div class="moduleTitle" style="margin: 0.88rem auto 1.53rem">
				<div style="width: 19.06rem; margin: 0 auto">
					我们拥有众多优秀的合作企业
				</div>
				<div class="line" style="bottom: -0.6rem"></div>
			</div>
			<div class="flex flex_wrap flex_item_between" style="padding: 0 0.67rem">
				<div class="flex company" v-for="item in companiesList" :key="item.id">
					<img class="company_logo" :src="item.enterpriseLogo" alt="公司logo" />
					<div class="company_content">
						<div class="company_name">
							{{ item.enterpriseName }}
						</div>
						<div class="company_center">
							<span class="company_type">{{ item.enterpriseTypeName }}|{{ item.employeeNumber }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="flex flex_item_around" style="padding: 0 5rem;margin-top: 0.69rem;padding-bottom: 0.95rem;">
				<div class="page_btn" :class="pages == 1 ? 'active' : ''" @click="prevPage">
					上一页
				</div>
				<div class="page_btn" :class="pages == 2 ? 'active' : ''" @click="nextPage">
					下一页
				</div>
			</div>
		</div>

		<div>
			<van-tabs v-model="active" swipeable title-inactive-color="#616161" title-active-color="#000000"
				line-width="2rem" line-height="0.19rem" @click="changeInformationClass" offset-top="0" :sticky="true">
				<van-tab v-for="(item, index) in vantab" :key="index" :title="item.dictLabel" :name="item.dictValue">
					<div v-for="(item1, index1) in informationList " :key="index1">
						<div @click="toDetail(item1)">
							<div style=" width:21.56rem;margin: auto;margin-top: 0.44rem;">
								<img class="imgDontClick" style="width:100%;height:100%;" :src="item1.imgUrl" alt="兼职网新闻资讯">
							</div>
							<div :class="index1 % 2 == 0 ? 'bkground' : 'bkground2'" style="margin: 2px;">
								<div
									style="display: flex;justify-content: space-between;width: 21.93rem;margin: auto;margin-bottom: 0.75rem;padding-top: 0.75rem;">
									<div :class="index1 % 2 == 0 ? 'intelligence' : 'intelligence2'">
										<p>{{ item1.title }}</p>
									</div>
									<div :class="index1 % 2 == 0 ? 'time' : 'time2'">
										{{ item1.createTime.trim().split(" ")[0] }}
									</div>
								</div>
								<div :class="index1 % 2 == 0 ? 'text' : 'text2'">
									<div class="newContText">{{ getPlainText(item1.content) }}</div>
									<!-- <div class="content" v-html="item1.content"></div> -->
								</div>
							</div>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<div
			style="width: 21.56rem;margin: auto;text-align: center;margin-top: 1.2rem;margin-bottom: 1.53rem;padding: 2px;">
			<van-button type="default" @click="lookgengduo" v-if="lookgengduotitle">查看更多</van-button>
			<van-button type="default" @click="lookgengduo" v-else>暂无更多</van-button>
		</div>


		<div class="lsmen" style="">
			<div style="">
				<h3>联系我们</h3>
				<ul>
					<!-- <li>邮箱：629666098@qq.com</li> -->
					<li>电话：19906413192</li>
				</ul>
			</div>
			<div @click="$router.push('/aboutme-m')">
				<h3>关于我们</h3>
				<ul>
					<li>关于我们</li>
					<li>企业介绍</li>
					<li>兼职网平台</li>
				</ul>
			</div>
			<div @click="$router.go(0)">
				<h3>媒体中心</h3>
				<ul>
					<li>兼职新闻</li>
				</ul>
			</div>
		</div>
		<div class="jianzhiimg">
			<div> <img class="tg_icon" src="@/assets/image/mobile/jzwlogo.png" alt="jzwlogo"></div>
			<div>
				<div class="tg_icon2">
					<img src="@/assets/image/mobile/erweima.png" alt="jzwdownload" />
					<div>扫码下载APP</div>
				</div>
			</div>
			<div> <img class="jzwmini" src="@/assets/image/mobile/jzwmini.png" alt="jzwmini"></div>
		</div>
		<div class="footer" style="color: #333333; font-size: 0.63rem; font-weight: 500;padding: 0 1.6rem 1rem">
			<div style="display: flex;justify-content: center;">
				<div style="zoom: 0.9">鲁ICP备2022033828号-1</div>
				<div style="margin-left: 1.5rem;display: flex;align-items: center;">
					<img style="width: 0.69rem;height: 0.69rem;" src="@/assets/image/mobile/beian.png" alt="鲁公网安">
					<div style="zoom: 0.9">鲁公网安37010502001905号</div>
				</div>
			</div>
			<div style="zoom: 0.9;margin-top: 0.3rem;text-align: center;">Copyright © 2017-2023 兼职网(山东)信息技术有限公司</div>
		</div>
		<!-- 顶部轮播图视频 -->
		<van-overlay :show="showVideo" @click="showVideo = false" z-index="999">
			<div class="wrapper" @click.stop>
				<video v-if="showVideo" :src="videourl" controls autoplay="autoplay"></video>
			</div>
		</van-overlay>
		<!-- 品质中国 -->
		<van-overlay :show="showVideo2" @click="showVideo2 = false" z-index="999">
			<div class="wrapper" @click.stop>
				<video v-if="showVideo2" :src="videoUrl2" controls autoplay="autoplay"></video>
			</div>
		</van-overlay>
		<!-- 图片预览 -->
		<van-image-preview v-model="show1" :images="swiper2List" @change="onChange" :startPosition="startPosition1">
			<template v-slot:index1>第{{ index1 }}页</template>
		</van-image-preview>
		<van-image-preview v-model="show2" :images="swiper1List" @change="onChangeswiper1List"
			:startPosition="startPosition2">
			<template v-slot:index2>第{{ index2 }}页</template>
		</van-image-preview>


		<div class="business1" @click="report()">
			<div style="position: relative;">
				<div class="business_text">
					投诉职位
				</div>
			</div>
		</div>

		<!-- 商务合作入口 -->
		<div class="business" @click="business()">
			<div style="position: relative;">
				<div class="business_img" style="margin: 0 auto;">
					<!-- <van-image width="60px" :src="businessImg" /> -->
				</div>
				<div class="business_text">
					商务合作
				</div>
			</div>
		</div>

		<el-dialog width="90%" @close="cancel()" center="true" :modal="false" title="投诉举报"
			:visible.sync="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="投诉原因" :label-width="formLabelWidth">
					<el-input v-model="form.complaintType" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="具体描述" :label-width="formLabelWidth">
					<el-input type="textarea" v-model="form.complaintContent"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel()">取 消</el-button>
				<el-button type="primary" @click="confirmData()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { Toast } from 'vant';
import alObj from './aboutList.js'
export default {
	name: 'aboutme-m',
	data() {
		return {
			data1: "兼职网（山东）信息技术有限公司成立于2017年，山东省文化厅批准设立的经营性互联网文化单位、是高新技术企业、地方政府重点关注扶持企业，被国家评定为科技型企业，山东省软件协会理事单位，是数字经济浪潮下专为中国大学生提供兼职、实习、就业指导的服务型互联网平台，也是一家面向全国企业，专业从事人力资源与劳务派遣服务的综合性平台。",
			data2: "企业发展多年以来，荣获知识产权60余项，涵盖注册商标9项、发明及外观专利3项、软件著作权50余项、等诸多资质",
			data3: "基于精准的用户画像及庞大的人才资源库，平台为大学生与用人单位搭建桥梁，赋能大学生勤工俭学，涵盖电商运营、短视频剪辑、配音、直播、平面设计、软件开发等线上兼职，与海量线下灵活用工兼职机会。",
			data4: "平台累积注册用户率创新高，完成了众多兼职任务。解决了大学生在校兼职、勤工俭学、灵活就业需求，为中国广大优秀应届大学生，提供更多更有趣的兼职任务。",
			data5: "",
			list: [{
				title: "山东领英信息科技有限有公司"
			}],
			swiper1List: [
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680767169995mmexport1645154155676.jpg",
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680767181616%E8%BD%AF%E4%BB%B6%E8%91%97%E4%BD%9C%E6%9D%83%20(2).jpg",
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680767194637%E4%BD%BF%E7%94%A8%E6%96%B0%E5%9E%8B-%E8%BF%9C%E7%A8%8B%E6%95%99%E8%82%B215556111.jpg",
				// 'https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680767238294%E4%B8%93%E5%88%A92.jpg',
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680873146453%E6%96%87%E7%BD%91%E6%96%87%E6%96%87%E4%BB%B6.png",
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680873171299%E4%B8%93%E5%88%A92.jpg",
			],
			swiper2List: [
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680919902427lQLPJxPoqAcXc07NASjNAaCwP3ZjYD1lRPwEKUI9eUDMAA_416_296.png",
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680919945709lQLPJxFsAqWhVk7NASjNAaCwcl5guTydYGYEKUJAXAAvAA_416_296.png",
				"https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680919973485lQLPJxTeODXvZc7NAYDNAhyw9jT1WMtAsiIEKUJC0EAEAA_540_384.png",
			],
			page: {
				currentPage: 1,
				pageSize: 6,
				total: 0,
				loading: false,
			},
			companiesList: [],
			controls: false,
			bannerList: [],
			showVideo: false,
			videourl: '',
			showVideo2: false,
			videoUrl2: 'https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1704513998690%E5%93%81%E8%B4%A8%E4%B8%AD%E5%9B%BD%20APP%E6%9B%BF%E6%8D%A2.mp4',
			pages: 1, //下一页 上一页判断
			show1: false, //图片预览
			show2: false,
			index1: 0,
			index2: 0,
			startPosition1: 0, //图片索引
			startPosition2: 0,
			dictList: [], //企业类型字典
			companyList: alObj.arr1,
			companyList2: alObj.arr2,
			companyList3: alObj.arr3,
			yulanImg: "",
			yulanshou: false,
			businessImg: 'http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686621972933lQDPJwUrKcMR5x7NBADNBACwN9h5aRGZftEEfj5PJQCeAA_1024_1024.jpg',
			dialogFormVisible: false,
			form: {
				complaintContent: "",
				complaintType: "",
				positionId: "we",
				enterpriseId: "we",
			},

			lookgengduotitle: true,
			pageNum: 1,
			informationList: [],
			informationType: '',
			vantab: [
				'全部',
				'新闻资讯',
				'兼职信息',
				'校园生活',
				'职场故事',
				'赚钱方法',
				'党建风格',
			],
			active: '',
		};
	},
	created() {
		this.getBannerList()
		this.getDict()
		this.getInformationClassList();
	},
	computed: {
		hasNextPage() {
			return (
				this.page.currentPage < Math.ceil(this.page.total / this.page.pageSize)
			);
		},
		optionLeft() {
			return {
				direction: 2,
				limitMoveNum: 3,
				step: 0.8,
			}
		},
		optionLeft2() {
			return {
				direction: 2,
				limitMoveNum: 3,
				step: 0.4,
			}
		},
	},
	methods: {
		//转意符换成普通字符
		convertIdeogramToNormalCharacter(val) {
			const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
			return val.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t]; });
		},
		// 获取富文本的纯文字内容
		getPlainText(richCont) {
			const str = richCont;
			let value = richCont;
			if (richCont) {
				value = value.replace(/(\n)/g, "");
				value = value.replace(/(\t)/g, "");
				value = value.replace(/(\r)/g, "");
				value = value.replace(/<\/?[^>]*>/g, "");
				value = value.replace(/\s*/g, "");
				value = this.convertIdeogramToNormalCharacter(value);
				return value;
			} else {
				return '--';
			}
		},
		getInformationClassList() {
			this.vantab = [{
				dictLabel: '全部',
				dictValue: '',
			}]
			this.$axios({
				method: "get",
				url: "/official/enterprise/getInformationClassList",
				params: {
					pageNum: 1,
					pageSize: 20,
				},
			})
				.then((res) => {
					if (res.code === 200) {
						// this.vantab = res.rows
						this.vantab.push(...res.rows)
						this.informationType = this.vantab[0].dictValue;
						this.getInformationList();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		//查看更多
		lookgengduo() {
			if (this.lookgengduotitle) {
				this.pageNum++
				this.getInformationList()
			}
		},
		getInformationList() {
			this.$common.showLoading()
			this.$axios({
				method: "get",
				url: "/official/enterprise/getInformationList",
				params: {
					pageNum: this.pageNum,
					pageSize: 2,
					type: this.informationType,
				},
			})
				.then((res) => {
					this.$common.hideLoading()
					if (res.code === 200) {
						if (res.rows.length != 0) {
							this.informationList.push(...res.rows);
						} else if (res.rows == 0) {
							this.lookgengduotitle = false
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		//跳转详情
		toDetail(item) {
			this.$router.push({ name: 'consultationDetail-m', params: item })
		},
		changeInformationClass(item) {
			this.lookgengduotitle = true
			this.pageNum = 1
			this.informationList = []
			// console.log(item)
			this.informationType = item;
			this.getInformationList();
		},
		confirmData() {
			if (!this.form.complaintContent) {
				this.$toast.fail('请输入描述');
				return
			}
			if (!this.form.complaintType) {
				this.$toast.fail('请输入投诉原因');
				return
			}
			this.$axios({
				method: 'POST',
				url: '/app/user/complaintGw',
				data: {
					...this.form
				}
			}).then(res => {
				if (res.code == 200) {
					this.form = {}
					this.$toast.success('举报成功');
					this.dialogFormVisible = false
				}
			})
		},
		cancel() {
			this.form = {}
			this.dialogFormVisible = false
		},
		report() {
			this.dialogFormVisible = true;
		},
		setYulan() {
			this.yulanshou = false;
		},
		setzhanshi(e) {
			this.yulanshou = true;
			this.yulanImg = e;
			console.log(this.yulanshou, this.yulanImg)
		},
		// 企业类型字典
		getDict() {
			this.$axios({
				url: '/system/dict/data/list',
				params: {
					pageNum: 1,
					pageSize: 100,
					dictType: 'enterprise_type'
				}
			}).then(res => {
				if (res.code == 200) {
					this.dictList = res.rows
					this.getCompaniesList()
				}
			})
		},
		//图片预览事件
		onChange(index) {
			this.show1 = true
			this.index1 = index;
			this.startPosition1 = index
		},
		onChangeswiper1List(index) {
			this.show2 = true
			this.index2 = index;
			this.startPosition2 = index
		},
		swiperClick(item) {
			if (item.videoUrl) {
				this.videourl = item.videoUrl
				this.$nextTick(() => {
					this.showVideo = true
				})
			} else if (item.jumpUrl) {
				let url = item.jumpUrl.split('?')
				let obj = {}
				if (url[0].indexOf('/') == 0) {
					url[0] = url[0].slice(1)
				}
				if (url.length == 2) {
					url[1].split('&').forEach(el => {
						let p = el.split('=')
						obj[p[0]] = p[1]
					})
				}
				this.$router.push({
					name: url[0],
					params: obj
				})
			}
		},
		getCompaniesList() {
			this.page.loading = true;
			this.$common.showLoading()
			let lat = localStorage.getItem('latitude')
			let lgt = localStorage.getItem('longitude')
			this.$axios({
				method: "get",
				url: "/app/fake/enterprise/aboutList",
				params: {
					itudeType: 2,
					longitude: lgt ? lgt : 0,
					latitude: lat ? lat : 0,
					distance: 100000000,
					distanceUnit: 1,
					pageNum: this.page.currentPage,
					pageSize: this.page.pageSize,
				},
			})
				.then((res) => {
					this.$common.hideLoading()
					res.rows.forEach(el => {
						el.enterpriseTypeName = this.handlerDict(el.enterpriseType)
					})
					this.companiesList = res.rows;
					this.page.total = res.total;
					this.page.loading = false;
				})
				.catch((error) => {
					this.page.loading = false;
				});
		},
		// 处理字典
		handlerDict(ept) {
			let needItem = this.dictList.find(item => item.dictValue == ept)
			if (!needItem) {
				return '商业服务'
			} else {
				return needItem.dictLabel
			}
		},
		nextPage() {
			this.pages = 2
			if (this.page.loading) return;
			if (!this.hasNextPage) return;
			this.page.currentPage++;
			this.getCompaniesList();
		},
		prevPage() {
			this.pages = 1
			if (this.page.loading) return;
			if (this.page.currentPage > 1) {
				this.page.currentPage--;
				this.getCompaniesList();
			}
		},
		getBannerList() {
			// 获取轮播图数据
			this.$axios({
				url: "/official/enterprise/getBanner",
				params: {
					locationType: 7,
				},
			})
				.then((res) => {
					if (res.code === 200) {
						this.bannerList = res.data;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		business() {
			this.$router.push({ name: 'Business-m' })
		}
	},
};
</script>
<style scoped lang="less">
.mask_video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.videoBtn {
	width: 2.81rem;
	height: 2.81rem;
	line-height: 2.81rem;
	text-align: center;
	color: #ffffff;
	font-size: 2.8rem;
	border-radius: 50%;
	background-color: #7d7d7d87;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

div /deep/.el-carousel__mask {
	background: none;
}

.aboutme {
	width: 100vw;
	min-height: 100vh;
	overflow-y: scroll;
}

.lsmen {
	display: flex;
	justify-content: space-evenly;
	margin-top: 3.6rem;

	h3 {
		color: #333333ff;
		font-size: 0.94rem;
		font-weight: 700;
	}

	li {
		// width: 6.25rem;
		// height: 0.81rem;
		opacity: 1;
		color: #333333ff;
		font-size: 0.75rem;
		font-weight: 500;
		font-family: "PingFang SC";
		text-align: left;
		letter-spacing: 0.02rem;
		line-height: 1.5rem;
	}
}

.jianzhiimg {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 2.09rem;
	margin-bottom: 2.34rem;

	.tg_icon {
		width: 5.84rem;
		height: 5.84rem;
		border-radius: 0.67rem;
	}

	.tg_icon2 {
		width: 6rem;
		height: 7rem;
		border-radius: 0.67rem;
		text-align: center;
		font-size: 0.6rem;
		background-color: #ffffff;
		padding: 0.4rem;

		img {
			width: 5.2rem;
			height: 5.2rem;
			object-fit: cover;
		}

		div {
			margin-top: 0.2rem;
		}
	}

	.jzwmini {
		width: 6rem;
		height: 6.6rem;
	}
}

.footer {
	display: flex;
	flex-direction: column;
}

.aboutme_top {
	width: 100vw;
	/*height: 12.5rem;*/
	/*background-color: aqua;*/
	position: relative;
}

.moduleTitle {
	color: #333333;
	font-size: 1.09rem;
	font-weight: 600;
	font-family: "PingFang SC" !important;
	text-align: center;
	letter-spacing: 0.02rem;
	line-height: 1;
	position: relative;
	text-align: center;
}

.moduleTitle>div {
	font-family: "PingFang SC" !important;
}

.moduleTitle>.line {
	width: 2.19rem;
	height: 0.13rem;
	background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
	position: absolute;
	left: 50%;
	bottom: 0.4rem;
	transform: translateX(-50%);
}

.cont1 {
	width: 100vw;
	padding: 1.25rem 0.69rem;
	/* background-image: url("@/assets/images/h5/aboutme/jianzhiIconBg.png"); */
	background-size: 30%;
	background-repeat: no-repeat;
	background-position: right bottom;
	/* margin-bottom: 1rem; */
}

.cont1_content {
	margin: 0.81rem auto 0rem;
	color: #333333ff;
	font-size: 0.44rem;
	line-height: 0.88rem;
	display: flex;
	justify-content: space-between;
}

.cont1_content>div {
	word-break: break-all;
	zoom: 0.8;
	margin: 0.2rem 0 0.2rem 0.75rem;
}

.cont1_content>img {
	width: 9.94rem;
	height: 6.81rem;
}

.cont2 {
	text-align: center;
	height: 26.84rem;
	background-image: url("@/assets/images/h5/aboutme/qualification.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding: 0.91rem 0 0;
}

.cont2_title {
	color: #dbc66dff;
	font-size: 1.13rem;
	font-weight: 500;
	font-family: "PingFang SC";
	line-height: 2.25rem;
}

.cont2_content {
	color: #ffffffff;
	font-size: 0.75rem;
	font-weight: 500;
	font-family: "PingFang SC";
	text-align: center;
	line-height: 1.38rem;
	padding: 0 1.31rem;
}

.cont3 {}

.cont3_content {
	margin: 2rem 0.97rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	color: #585858;
	font-size: 0.81rem;
	font-weight: 500;
	font-family: "PingFang SC";
	text-align: left;
	line-height: 1.2rem;
	letter-spacing: 1px;
	background-color: #F2F2F2;
}

.cont3_content>div {
	word-break: break-all;
	padding: 0.63rem 0.63rem 0.63rem 1.09rem;
	margin-left: -0.46rem;
	/* margin-top: 0.38rem; */
	width: 13.91rem;
	/* height: 6.84rem; */
	border-radius: 0.25rem;
	background-color: #ffffff;
	background-image: url("@/assets/images/h5/aboutme/textbg2.png");
	background-size: 100% 50%;
	background-repeat: no-repeat;
	background-position: right bottom;
}

/* .cont3_content>div>span {
  zoom: 0.8;
} */

.cont3_content>img {
	width: 9.94rem;
	height: 6.81rem;
	z-index: 1;
	/* margin-top: 0.63rem; */
}

.cont4 {}

.cont4_content {
	margin: 0.81rem 0.97rem 0;
	display: flex;
	justify-content: space-between;
	position: relative;
	color: #585858;
	font-size: 0.81rem;
	font-weight: 500;
	font-family: "PingFang SC";
	text-align: left;
	line-height: 0.96rem;
	line-height: 1.2rem;
}

.cont4_content>div {
	word-break: break-all;
	padding: 0.63rem 1.09rem 0.63rem 0.63rem;
	margin-right: -0.46rem;
	/* margin-top: 0.4rem; */
	width: 12.91rem;
	/* height: 6.84rem; */
	border-radius: 0.25rem;
	background-color: #ffffff;
	background-image: url("@/assets/images/h5/aboutme/textbg.png");
	background-size: 100% 50%;
	background-repeat: no-repeat;
	background-position: right bottom;
}

/* .cont4_content>div>span {
  zoom: 0.8;
} */

.cont4_content>img {
	width: 9.94rem;
	height: 6.81rem;
	z-index: 1;
	margin-top: 0.63rem;
}

.cont5 {
	background-size: 100% 100%;
	background-image: url("@/assets/images/h5/aboutme/wanqibg.png");
	background-repeat: no-repeat;
	padding-top: 0.90rem;
}

.company {
	width: 10.47rem;
	height: 3.34rem;
	border-radius: 0.38rem;
	padding: 0.3rem;
	margin-bottom: 0.5rem;
	/* background: linear-gradient(140.7deg, #ffbf0bff 0%, #ffffff00 30%); */
	background-color: #ffffff;
}

.company_logo {
	width: 2.66rem;
	height: 2.66rem;
	border-radius: 0.25rem;
}

.company_content {
	padding-left: 0.3rem;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.3rem 0 0.4rem 0.3rem
}

.company_center {
	width: 6.91rem;
	color: #666666;
	font-size: 0.6rem;
	overflow: hidden;
	text-overflow: ellipsis;
	/* 弹性伸缩盒子模型显示 */
	display: -webkit-box;
	/* 限制在一个块元素显示的文本的行数 */
	-webkit-line-clamp: 1;
	/* 设置或检索伸缩盒对象的子元素的排列方式 */
	-webkit-box-orient: vertical;
}

.company_type {
	/* zoom: 0.8; */
	line-height: 0.5rem;
}

.company_name {
	width: 6.94rem;
	height: 1.3rem;
	color: #333333;
	font-size: 0.7rem;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.page_btn {
	width: 3rem;
	height: 1.44rem;
	line-height: 1.36rem;
	box-sizing: border-box;
	text-align: center;
	border-radius: 0.15rem;
	border: 0.04rem solid #06b87e;
	color: #06b87e;
	font-size: 0.75rem;
	font-weight: 500;
}

.page_btn.active {
	color: #ffffff;
	background: #06b87eff;
}

.info_title {
	color: #333333;
	font-size: 0.94rem;
	font-weight: 500;
	margin-bottom: 0.69rem;
}

.info_desc {
	position: relative;
	padding-left: 0.5rem;
	color: #333;
	zoom: 0.8;
	font-size: 0.56rem;
	font-weight: 500;
}

.info_desc::after {
	content: "";
	display: block;
	clear: both;
	position: absolute;
	width: 0.19rem;
	height: 0.19rem;
	background: #797979ff;
	border-radius: 50%;
	left: 0;
	top: 50%;
	/*transform: translateY(-50%);*/
}

.tg_icon {
	width: 5.44rem;
	height: 5.44rem;
	border-radius: 0.67rem;
}

.line-b {
	margin-top: 1.63rem;
	border-top: 0.01rem solid #0000001a;
	margin-bottom: 0.69rem;
}

.new_cont1_content {
	margin: 1.6rem 0 0;
	color: #6d6d6d;
	font-size: 13px;
	font-weight: 500;
	font-family: "PingFang SC";
	line-height: 1.56rem;
	font-weight: 600;
}

.new_cont1_content>img {
	width: 9.25rem;
	height: 7.13rem;
	border-radius: 0.4rem;
	float: left;
	clear: both;
	margin-right: 0.8rem;
}

.new_cont1_content>div:first-child {
	width: 13rem;
	color: #474747;
	font-size: 0.69rem;
	font-weight: 500;
	font-family: "PingFang SC";
	line-height: 1.17rem;
	letter-spacing: 0.06rem;
	/* text-indent: 2em; */
	margin-bottom: 0.2rem;
}

.new_cont1_content>div:last-child {
	height: 2.03rem;
	background-color: #42D097;
	color: #ffffffff;
	font-size: 0.75rem;
	font-weight: 500;
	font-family: "PingFang SC";
	line-height: 2.03rem;
	padding-left: 0.6rem;
	position: relative;
}

.new_cont1_content>div:last-child>img {
	width: 7.28rem;
	height: 10.5rem;
	position: absolute;
	top: -9.5rem;
	right: 0;
}

.cont3>.moduleTitle>.cont3_title {
	width: 19.06rem;
	line-height: 1.41rem;
	text-align: left !important;
}

.mask_video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.videoBtn {
	width: 2.81rem;
	height: 2.81rem;
	line-height: 2.81rem;
	text-align: center;
	color: #ffffff;
	font-size: 2.8rem;
	border-radius: 50%;
	background-color: #7d7d7d87;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.wrapper {
	width: 100vw;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
}

.wrapper>video {
	width: 94%;
	height: auto;
	object-fit: cover;
}

.cont2d5 {
	width: 100vw;
	padding: 0 0.69rem 1.19rem;
}

.cont2d5_title {
	color: #333333;
	font-size: 1.09rem;
	font-weight: 600;
	font-family: "PingFang SC" !important;
	text-align: center;
	letter-spacing: 0.02rem;
	line-height: 1.5;
	position: relative;
	text-align: left;
	margin: 1.72rem 0 1.09rem;
}

.cont2d5_title::after {
	content: '';
	width: 1.66rem;
	height: 0.16rem;
	background-color: #07be78;
	position: absolute;
	bottom: -0.32rem;
	left: 0;
}

.cont2d5_cont {
	color: #6d6d6d;
	font-size: 13px;
	font-weight: 600;
	font-family: "PingFang SC";
	line-height: 1.56rem;
}

.cont2d5_img {
	width: 100%;
	height: 14.28rem;
	text-align: right;
	margin-top: 0.94rem;
	position: relative;
}

.cont2d5_img>.imageOne {
	width: 19.97rem;
	height: 14.28rem;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 5;
}

.cont2d5_img>.imageTwo {
	width: 11.88rem;
	height: 11.88rem;
	position: absolute;
	bottom: -0.56rem;
	left: -0.69rem;
}

.cont2d5_img>.imageThree {
	width: 10.84rem;
	height: 10.84rem;
	position: absolute;
	right: -0.69rem;
	bottom: 0;
	transform: rotate(180deg);
}

.cont4d5_cont {
	width: 23.1rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 1.61rem;
	grid-row-gap: 0.91rem;
	margin: 0 auto;
}

.cont4d5 {
	margin-bottom: 1rem;
}

.cont4d5_cont>img {
	width: 4.25rem;
	height: 1.52rem;
	border-radius: 0.18rem;
	opacity: 1;
	box-shadow: 0 0.06rem 0.15rem 0 #00000014;
}

.cont4d5_title {
	color: #333333;
	font-size: 1.19rem;
	font-weight: 600;
	font-family: "PingFang SC" !important;
	text-align: center;
	letter-spacing: 0.02rem;
	line-height: 1.5;
	position: relative;
	margin: 1.72rem 0 1.49rem;
}

.cont4d5_title::after {
	content: '';
	width: 1.66rem;
	height: 0.16rem;
	background-color: #07be78;
	position: absolute;
	bottom: -0.32rem;
	left: 50%;
	transform: translateX(-50%);
}

.swiperL,
.swiperR {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #7d7d7d;
	font-size: 1.6rem;
	font-weight: bold;
	z-index: 99;
}

.swiperL {
	left: 0;
}

.swiperR {
	right: 0;
}

.quanqiu {
	top: 4.9rem;
	position: absolute;
	margin: 0px;
	left: 0px;
	bottom: 0px;
	width: 210%;
	/* height: 300rpx; */
	right: auto;
	-webkit-animation: 6s move infinite linear;
}

@-webkit-keyframes move {
	0% {
		left: 0;
	}

	100% {
		left: -100%;
	}
}

@keyframes move {
	0% {
		left: 0;
	}

	100% {
		left: -100%;
	}
}

.globalImgs_outer {
	width: 100vw;
	margin: 0 auto;
	padding: 0.6rem 0;
	position: relative;
}

.globalImgs_outer::after {
	content: '';
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
}

.globalImgs {
	width: 48.6rem;
	height: 2.3rem;
}

.globalImgs>img {
	width: 4.6rem;
	height: 2.3rem;
	margin-right: 0.8rem;
	border-radius: 6px;
}

.business {
	/* width: 65px; */
	height: 81px;
	/* background-color: #06b87e; */
	position: fixed;
	right: 16px;
	bottom: 95px;
	z-index: 9999;
}

.business_img {
	width: 60px;
	height: 60px;
	border-radius: 30px;
	overflow: hidden;
	background-image: url('http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1686621972933lQDPJwUrKcMR5x7NBADNBACwN9h5aRGZftEEfj5PJQCeAA_1024_1024.jpg');
	background-size: 100%;
	background-repeat: no-repeat;
	border: 1px solid #06b87e;
	box-sizing: border-box;

}

.business_text {
	/* width: 100%; */
	padding: 2px 4px;
	border-radius: 2.5px;
	background: #06b87e;
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	font-family: "PingFang SC";
	text-align: center;
	margin-top: -5px;
	/* line-height: 25px; */
}

.council {
	padding: 14px 10px 13px 10px;
	background-color: #F2F2F2;
}

.councilNav {
	display: flex;
	align-items: center;
	justify-content: center;
}

.councilNav>span {
	width: 27px;
	height: 29px;
	overflow: hidden;
	position: relative;
}

.councilNav>span>img {
	width: 27px;
	height: 29px;
	position: relative;
}

.councilNav>span:before {
	content: '';
	display: block;
	width: 4px;
	height: 20px;
	position: absolute;
	background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6));
	box-shadow: rgba(255, 255, 255, 0.6);
	top: 16%;
	left: 0%;
	background: #fff;
	transform: skewX(157deg);
	animation: sh02 1.2s 0s linear infinite;
	z-index: 9;
	/*animation-iteration-count: infinite;*/
}

@keyframes sh02 {
	from {
		opacity: 0.6;
		left: 0%;
	}

	50% {
		opacity: 0.6;
	}

	to {
		opacity: 0.6;
		left: 100%;
	}
}

.councilNav>div {
	color: #ff0606;
	font-size: 1.09rem;
	font-weight: 600;
	font-family: "PingFang SC";
	margin-left: 8px;
	position: relative;
}

.councilNav>div:before {
	content: "";
	position: absolute;
	width: 42px;
	height: 3.14px;
	background: #ff0606;
	left: 50%;
	transform: translateX(-50%);
	top: 32px;
}

.councilMess {
	position: relative;
	margin-top: 32px;
}

.councilMessLeft {
	width: 203px;
	border-radius: 6.5px;
	background: #ffffff;
	padding: 20px;
	color: #6d6d6d;
	font-size: 13px;
	background-image: url("@/assets/images/h5/aboutme/textbg2.png");
	background-size: 88% 11%;
	background-repeat: no-repeat;
	background-position: right bottom;
	background-position: 50px 333px;
	font-family: "PingFang SC";
}

.councilMessLefts {
	width: 203px;
	border-radius: 6.5px;
	background: #ffffff;
	padding: 20px;
	color: #6d6d6d;
	font-size: 13px;
	background-image: url("@/assets/images/h5/aboutme/textbg2.png");
	background-size: 88% 11%;
	background-repeat: no-repeat;
	background-position: right bottom;
	background-position: 50px 218px;
	font-family: "PingFang SC";
}

.councilMessRight {
	position: absolute;
	top: 48%;
	transform: translateY(-50%);
	display: flex;
	flex-wrap: wrap;
	width: 144px;
	left: 54%;
}

.councilMessRight>img {
	width: 10rem;
	/*height: 139px;*/
	border-radius: 3px;
}

.yulan {
	width: 100%;
	height: 100%;
	background-color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 9999;
	top: 0;
}

.yulan>img {
	width: 85%;
}

.business1 {
	/* width: 65px; */
	/* background-color: #06b87e; */
	position: fixed;
	right: 16px;
	bottom: 70px;
	z-index: 9999;
}

.customClass {
	border-radius: 15px;
}

.business {
	/* width: 65px; */
	height: 81px;
	/* background-color: #06b87e; */
	position: fixed;
	right: 16px;
	bottom: 105px;
	z-index: 9999;
}

.bkground {
	background-color: #ffffffff;
	margin: 1rem 2px 2px;
}

.bkground2 {
	background-color: #ffffffff;
	margin: 1rem 2px 2px;
}

.intelligence {
        // width: 6.89rem;
        height: 1.56rem;
        line-height: 1.56rem;
        background-image: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681734777704lQLPJxJzw4QRVxJLzQFDsPVz4MFtrB11BDVGiEXAhQA_323_75.png');
        background-size: 100% 1.56rem;
        text-align: center;

        p {
            width: 9.89rem;
            height: 1.56rem;
            opacity: 1;
            color: #ffffffff;
            font-size: 0.88rem;
            font-weight: 600;
            font-family: "PingFang SC";
            // margin-left: -1rem;
            margin-right: 1.75rem;
            margin-left: 0.75rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .intelligence2 {
        // width: 6.89rem;
        height: 1.56rem;
        line-height: 1.56rem;
        //background-image: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681791676428lQLPJxejoCr5jXJLzQFBsE_ZLAKQ8FBBBDVLjT4AKgA_321_75.png');
        background-image: url('https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681734777704lQLPJxJzw4QRVxJLzQFDsPVz4MFtrB11BDVGiEXAhQA_323_75.png');
        background-size: 100% 1.56rem;
        text-align: center;

        p {
            width: 9.89rem;
            height: 1.56rem;
            opacity: 1;
            color: #FFFFFF;
            font-size: 0.88rem;
            font-weight: 600;
            font-family: "PingFang SC";
            // margin-left: -1rem;
            margin-right: 1.75rem;
            margin-left: 0.75rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
    }

	.time {
        // width: 4rem;
        height: 1.5rem;
        opacity: 1;
        color: #a5a5a5ff;
        font-size: 0.75rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 1.5rem;
        margin-right: 0.78rem;
    }

    .time2 {
        // width: 4rem;
        height: 1.5rem;
        opacity: 1;
        color: #a5a5a5ff;
        font-size: 0.75rem;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 1.5rem;
        margin-right: 0.78rem;
    }

    .text {
        width: 19.63rem;
        height: 5.2rem;
        -ms-height:5.4rem;
        margin: auto;
        color: #666666;
        font-size: 0.69rem;
        font-weight: 500;
        margin-left: 0.78rem;
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
    }

    .text2 {
        width: 19.63rem;
        height: 5.2rem;
        margin: auto;
        color: #666666;
        font-size: 0.69rem;
        font-weight: 500;
        margin-left: 0.78rem;
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
