import { render, staticRenderFns } from "./positionDetail.vue?vue&type=template&id=7aa8c88c&scoped=true"
import script from "./positionDetail.vue?vue&type=script&lang=js"
export * from "./positionDetail.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/pc/positionDetail.css?vue&type=style&index=0&id=7aa8c88c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa8c88c",
  null
  
)

export default component.exports