<template>
    <div class="userxy">
        <div class="content">
       <h4>兼职网用户协议</h4>
<p>
    感谢您选择兼职网服务。兼职网用户协议（以下简称&quot;本协议&quot;）由兼职网（济南）信息技术有限公司（以下简称&quot;兼职网&quot;或&quot;我们&quot;）和您签订。本协议就兼职网平台提供的兼职服务相关权利义务进行规范，本服务条款对您及兼职网均具有法律效力。
</p>
<p>
    【特别提示】
</p>
<p>
    1、在使用兼职网服务之前，请您务必仔细阅读本协议内容，尤其是字体加粗部分。请您务必审慎阅读、充分理解各条款内容，特别是隐私政策、用户协议、免责以及争议管辖与法律适用的条款。 除非您已阅读并接受本协议所有条款，否则您无权使用&quot;兼职网&quot;提供的服务。
</p>
<p>
    2、&quot;兼职网&quot;的服务仅向有完全民事行为能力的自然人、法人或其他组织提供。您使用兼职网服务时应确认为具备相应民事行为能力的自然人、法人或其他组织。未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意，若您不具备签署与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果，且&quot;兼职网&quot;有权直接注销您的账户。若您在使用&quot;兼职网&quot;服务过程中造成我们损失的，我们有权向您及您的监护人索偿。
</p>
<p>
    3、如您对本协议内容或页面提示信息有疑问，请立即停止当前操作，退出页面，勿进行下一步操作。您可通过联系我们在线客服进行咨询，以便我们为您解释和说明。您在注册页面完成信息填写、勾选阅读并同意本协议并完成全部注册程序后确认即表示您愿意签署本协议并受本协议约束;您的注册、登录、使用等行为将视为对本协议的接受，并视为您同意接受本协议各项条款的约束，本协议将构成您和&quot;兼职网&quot;之间直接有约束力的法律文件。
</p>
<p>
    4、如我们对本协议进行修改，我们将以网站公告、用户后台或通过其他联系方式向您通知/送达的相关规则、政策、通知，前述修订或相关规则，自通知/送达之日起正式生效。如您不同意相关变更内容或我们通知之任何内容，请停止使用&quot;兼职网&quot;服务。如果您继续使用&quot;兼职网&quot;服务，则视为您已认可并无条件接受本协议变更及我们向您通知之全部内容。
</p>
<p>
    一、定义
</p>
<p>
    1、兼职网平台，是指兼职网（济南）信息技术有限公司运营的兼职网网站。
</p>
<p>
    2、用户，包含注册用户和非注册用户。注册用户是指通过兼职网平台完成全部注册程序后，使用兼职网服务的用户。非注册用户指虽未进行注册，但以直接登录兼职网平台方式，或通过其他网站/客户端直接或间接方式使用兼职网平台服务的用户。
</p>
<p>
    二、 服务内容及服务的变更、中断、终止
</p>
<p>
    1、&quot;兼职网&quot;的具体服务内容以&quot;兼职网&quot;平台实际情况提供之内容为准，包括但不限于上传功能、报名功能、发布职位、留言分享等。&quot;兼职网&quot;仅提供相关服务，除此之外与本服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担。
</p>
<p>
    2、您同意并理解，&quot;兼职网&quot;仅为网络服务提供者，&quot;兼职网&quot;对提供的服务效果不作任何保证，前述效果包括但不限于信息发布后的浏览数量、产生某种效果或达成某种目的/目标等。
</p>
<p>
    3、&quot;兼职网&quot;保留根据实际情况随时调整&quot;兼职网&quot;平台提供的服务种类、形式的权利。&quot;兼职网&quot;不承担因业务调整给您造成的损失。
</p>
<p>
    4、&quot;兼职网&quot;因业务调整或平台自身需要进行改版的，包括但不限于产品取消、服务内容发生增加或减少、登载页面变更、发布城市变更、版面布局及页面设计变更等，&quot;兼职网&quot;将以网站公告等方式向您告知，调整在告知后正式生效，此类情况不视为&quot;兼职网&quot;违约。
</p>
<p>
    5、您理解网络服务具有特殊性（包括但不限于服务期稳定性问题、恶意的网络攻击等行为的存在及其他&quot;兼职网&quot;无法控制的情况），若发生服务中断或终止部分/全部服务的情况，&quot;兼职网&quot;将尽可能及时通过网站公告、系统通知、短信或其他合理方式向您告知。&quot;兼职网&quot;保留单方变更、中断或终止部分网络服务的权利。
</p>
<p>
    三、账户注册、安全及使用
</p>
<p>
    （一）注册
</p>
<p>
    1、您完成注册程序或以其他&quot;兼职网&quot;认可的渠道实际使用&quot;兼职网&quot;服务后，兼职网会向您提供唯一编号的兼职网账户，并由您自行设置密码。兼职网识别特定您的方式是且仅是您使用特定账户，并输入与之匹配的密码进行登录。故您应保证账户和密码的安全，并对您注册的账户通过登录密码校验后实施的一切行为负责。对于他人使用任何手段获取您账户及其密码登录兼职网并实施任何的行为，兼职网都视为您本人的行为。
</p>
<p>
    2、您在注册账号或使用服务过程中，应向&quot;兼职网&quot;提供合法、真实、准确的个人信息，若您填写的个人信息有变动，您应及时进行更新，以免影响服务体验。若您故意提供虚假的身份信息进行注册，发布虚假信息的，视为严重违反本协议，&quot;兼职网&quot;有权立即暂停或终止您的账号并停止提供服务。虚假注册、发布虚假信息给&quot;兼职网&quot;造成经济、名誉损失的，&quot;兼职网&quot;有权向您全额索赔。
</p>
<p>
    3、如您为企业用户，您应及时提供的&quot;单位名称、主体身份证件、被授权人职务或岗位信息、企业邮箱&quot;等相关授权信息及材料，并确保前述授权的真实性;您应保证其职务行为已获得有效授权，包括但不限于上传/发布信息、确认需求/服务信息等。
</p>
<p>
    4、用户名由您自行设置，请注意文明用语;不得以党和国家领导人或其他社会名人真实姓名、字号、艺名、笔名注册; 不得以国家机构或其他机构名称注册、 不得注册不文明、不健康的名称，及包含歧视、侮辱、猥亵类词语的名称;不得注册易产生歧义、引起他人误解或其他不符合法律规定的账号。若&quot;兼职网&quot;发现您的用户名不符合前述规定或含有其他不恰当文字的，&quot;兼职网&quot;有权单方注销您的账号，因此造成的一切后果概由您自行承担。
</p>
<p>
    5、您理解并同意，一经注册用户账号，即视为您同意兼职网及/或其关联公司可以通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务广告信息、促销优惠等营销信息;您如果不同意接收相关信息，您可以通过客服或相关提示进行退订。
</p>
<p>
    （二）账号安全
</p>
<p>
    1、您有义务保证密码及帐号的安全，并对利用该密码及帐号所进行的一切活动负全部责任，包括任何经由兼职网上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。
</p>
<p>
    2、除非有法律规定且征得兼职网的同意，否则，您账户和密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）、不得将账号提供给任何第三方进行使用。如因此造成用户隐私泄露或&quot;兼职网&quot;平台损失的（包括但不限于经济损失、名誉损失等），您均应当承担相应的责任。
</p>
<p>
    3、&quot;兼职网&quot;承诺非经法定原因、本协议的约定或您的事先许可，&quot;兼职网&quot;不会向任何第三方透露您的注册账号、手机号码等非公开信息。如您发现任何人不当使用您的账户或者有任何其他可能危及您的账户安全的情形时，应该立即修改帐号密码并妥善保管，您还可立即通知&quot;兼职网&quot;平台，我们在收到您的通知后会在合理时间内处理您的账号问题。因黑客行为或您的保管疏忽导致账号非法使用，兼职网平台不承担任何责任，你应通过司法、行政等救济途径向侵权行为人追偿。
</p>
<p>
    4、&quot;兼职网&quot;有权根据自身的判定，在怀疑账号被不当使用时，拒绝或限制账号使用，或者直接注销该账号，同时终止本协议。
</p>
<p>
    （三）账号使用规范
</p>
<p>
    1、您同意，遵守法律及本协议规定，善意使用&quot;兼职网&quot;平台，且保证不会利用&quot;兼职网&quot;进行任何违法、违背社会公共利益或公共道德、损害他人的合法权益等不正当活动。
</p>
<p>
    2、您不得在&quot;兼职网&quot;平台发布或以其它方式传送、传播含有下列内容之一的信息∶
</p>
<p>
    （1）违反国家法律法规禁止性规定的;
</p>
<p>
    （2）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的;
</p>
<p>
    （3）欺诈、虚假、不准确或存在误导性的;
</p>
<p>
    （4）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；（5）侮辱或者诽谤、侵害他人隐私或其他合法权利的;
</p>
<p>
    （6）粗俗、猥亵或其它道德上令人反感的内容的;
</p>
<p>
    （7）存在可能破坏、篡改、删除、影响&quot;兼职网&quot;任何系统正常运行或未经授权秘密获得&quot;兼职网&quot;及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的;
</p>
<p>
    （8）其他违背社会公共利益或公共道德或经&quot;兼职网&quot;评估不适合在&quot;兼职网&quot;平台上发布的。
</p>
<p>
    3、您在使用&quot;兼职网&quot;平台的过程中，您应保证∶
</p>
<p>
    （1）您在&quot;兼职网&quot;实施的所有行为均遵守国家法律法规等规范性文件、遵守&quot;兼职网&quot;平台各项规则的规定和要求。
</p>
<p>
    （2）未经&quot;兼职网&quot;同意，不擅自对&quot;兼职网&quot;平台上任何数据进行商业性使用，包括但不限于复制、传播&quot;兼职网&quot;平台上展示的资料、数据、文章、链接等。
</p>
<p>
    （3）不使用任何可能破坏、篡改、删除、影响&quot;兼职网&quot;任何系统正常运行的装置、病毒、木马、爬虫等软件、程序代码。
</p>
<p>
    （4）不得采用作弊、欺诈或利用系统漏洞等不正当手段，获取不正当利益。
</p>
<p>
    4、如因您发布不当信息、违反法律法规、本协议规定或&quot;兼职网&quot;平台各项规则，导致的任何纠纷、争议、损失、赔偿等一切责任均由您自行承担，&quot;兼职网&quot;不承担任何责任及赔偿，如您因此造成兼职网被第三人索赔的，造成兼职网损失的，您应承担全部责任，包括但不限于各种赔偿费、诉讼代理费及&quot;兼职网&quot;为此支出的其它合理费用。
</p>
<p>
    5、您同意并理解，对于您在兼职网上的行为，兼职网有权单方认定您行为是否构成对本协议及相关规则的违反，并据此采取相应处理措施。若您违反本协议约定及承诺，兼职网&quot;有权依据法律法规及本协议的约定，对您的账号作出相应处理且无须征得您的同意或提前向您告知，包括但不限于屏蔽或删除您发布的信息、暂停您的账号权限、终止您的账号服务等。若因此造成&quot;兼职网&quot;损失或发生相应费用的，您应当全额赔偿。
</p>
<p>
    6、当您在使用&quot;兼职网&quot;平台过程中，向&quot;兼职网&quot;提供除个人信息之外的一切信息，包括但不限于上传之图片、文章、职位等，您认可自提供之日起即授予&quot;兼职网&quot;就该等信息永久性的、全球的、不可撤销的、免使用费的、可再次授权给他人的使用权。
</p>
<p>
    四、隐私条款
</p>
<p>
    1、在您使用&quot;兼职网&quot;平台过程中，我们有可能需要您填写能识别用户身份的个人信息，以便可以在必要时联系您或为您提供更好的服务。
</p>
<p>
    2、我们重视对您信息的保护，您向&quot;兼职网&quot;提供的个人信息，我们将依《兼职网隐私政策》进行保护与规范，详情请参阅《兼职网隐私政策》。
</p>
<p>
    3、如果您是企业用户，我们将依法保护您的商业秘密，非经您同意不会对外提供，但在法律、行政法规要求下，基于民事或刑事调查、监管需要等，应有管辖权的法院、仲裁机构、政府机关、司法机关或监管机构的要求进行的披露除外。
</p>
<p>
    五、服务费用
</p>
<p>
    1、&quot;兼职网&quot;是个对大多数用户免费的分类信息网站，但兼职网&quot;同时提供部分收费服务。若您所使用的服务需支付费用，您有权决定是否使用并接受该收费服务。
</p>
<p>
    2、&quot;兼职网&quot;上的收费服务以人民币计价，定价上可能随时调整。我们将以网站公告的方式，来通知您收费政策的变更。&quot;兼职网&quot;也可选择在促销或新服务推出时，暂时调整我们的服务收费，该调整于我们公布促销或新服务时开始生效。
</p>
<p>
    3、&quot;兼职网&quot;只有在系统接收到您支付的款项后，才开始为您提供您订购的付费服务。
</p>
<p>
    4、您理解并认可∶订购之付费服务功能一旦开通，您不得以任何理由要求取消、终止服务或退款，若因个人行为（如∶自行删除）或发布信息内容违反本协议规定、兼职网公布之各项规则，而导致您订购的服务无法继续使用的，&quot;兼职网&quot;将不予退款。
</p>
<p>
    5、&quot;兼职网&quot;因网站自身需要进行改版的，若涉及付费产品的实质性变化，包括但不限于产品取消、服务内容发生增加或减少、登载页面变更、发布城市变更的，&quot;兼职网&#39;可提前终止服务并将您已付款但未履行服务部分款项退还给您，此类情况不视为兼职网违约。
</p>
<p>
    六、账户余额
</p>
<p>
    &quot;兼职网&quot;账户余额是&quot;兼职网&quot;内部使用的现金账户，方便您进行付费产品的购买，或者享受更多的优惠。您可以使用充值的方式增加&quot;兼职网&quot;账户的余额，同时&quot;兼职网&quot;也会通过活动的方式奖励一定的现金到您的&quot;兼职网&quot;账户中。所有账户余额中的部分都仅限于在&quot;兼职网&quot;内部进行使用，不能提现或退款。
</p>
<p>
    七、侵权通知
</p>
<p>
    1、如您发现&quot;兼职网&quot;平台上的任何内容不符合法律法规规定，不符合本用户协议规定，或不符合&quot;兼职网&quot;公布的各项规则规定时，您有权利和义务通过点击&quot;举报&quot;链接向我们申述。
</p>
<p>
    2、当您发现自己的个人信息被盗用，或者您的版权或者其他权利被侵害，请及时将此情况报告给&quot;兼职网&quot;。我们接受在线提交举报，请同时提供以下信息：
</p>
<p>
    ① 侵犯用户权利的信息的网址，编号或其他可以找到该信息的细节
</p>
<p>
    ② 用户提供所述的版权或个人信息的合法拥有者的声明
</p>
<p>
    ③ 用户提供初步能证明侵权的证据；
</p>
<p>
    ④ 用户的姓名，地址，电话号码和电子邮件等各类有效联系方式
</p>
<p>
    ⑤ 用户的签名。
</p>
<p>
    “兼职网”会在核实后，根据相应法律法规及本协议约定予以配合处理。若因您提供的证据不充分或填写的联系方式有误等不可归咎于“兼职网”的原因，使“兼职网”无法对相关情况进行核实的，我们保留暂停处理侵权通知的权利。
</p>
<p>
    八、知识产权
</p>
<p>
    1、“兼职网”平台上展示的所有内容，包括但不限于版面设计、图标、文字及其组合、产品、技术、程序等，知识产权均受著作权法、商标法、专利法或其他法律法规保护。未经“兼职网”书面授权许可，您不得擅自使用、复制、转载、修改、售卖前述内容。否则，“兼职网”有权追究您的法律责任。
</p>
<p>
    2、除另有声明外，&quot;兼职网&quot;提供服务时所依托软件的著作权、专利权及其他知识产权均归&quot;兼职网&quot;所有。未经明确授权，任何用户不得更改、演绎、拆分、反解&quot;兼职网&quot;的技术和程序或以其它任何方式进行可能损害&quot;兼职网&quot;专有权利的行为。
</p>
<p>
    3、您在使用&quot;兼职网&quot;平台过程中发布的任何内容，均授予&quot;兼职网&quot;免费且不可撤销的非独家使用许可，&quot;兼职网&quot;有权将改内容用于&quot;兼职网&quot;平台的各项产品及服务上。您对&quot;兼职网&quot;的前述授权并不改变您发布内容的所有权及知识产权归属。
</p>
<p>
    九、免责声明
</p>
<p>
    1、您同意因网络原因、&quot;兼职网&quot;进行业务调整、正常的系统维护升级及第三方原因或不可抗力因素等造成的&quot;兼职网&quot;平台无法正常访问或&quot;兼职网&quot;服务无法正常提供的，&quot;兼职网&quot;不承担任何责任。
</p>
<p>
    2、您通过&quot;兼职网&quot;平台发布的信息或内容，并不代表&quot;兼职网&quot;之意见及观点，也不意味着&quot;兼职网&quot;赞同其观点或证实其内容的真实性。您通过&quot;兼职网&quot;平台发布的信息、文字、图片等资料均由您提供，其真实性、准确性和合法性由信息发布人或提供者负责。&quot;兼职网&quot;不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，&quot;兼职网&quot;对此不承担任何法律责任及赔偿责任。
</p>
<p>
    3、&quot;兼职网&quot;仅为网络平台提供者，不对您与其他用户或任何第三方之间的任何纠纷（包括但不限于债权债务纠纷、劳资纠纷、人身损害纠纷、意外伤害纠纷等）承担任何法律责任及赔偿责任;且&quot;兼职网&quot;不与任何您建立任何劳动、劳务、雇佣关系，您通过&quot;兼职网&quot;平台得到资讯和信息后，与用工方建立的用工关系或实际用工关系均系您与用工方双方自愿之行为，与&quot;兼职网&quot;无关，&quot;兼职网&quot;不承担任何法律责任。对于可证实的纠纷，&quot;兼职网&quot;可以提供相关资料帮助您进行协调。
</p>
<p>
    4、除&quot;兼职网&quot;注明的平台服务内容及条款外，其他一切因使用&quot;兼职网&quot;平台所造成的损失，&quot;兼职网&quot;概不负责，亦不承担任何法律责任及赔偿责任。任何您使用&quot;兼职网&quot;平台服务并不意味与&quot;兼职网&quot;及其运营公司产生任何代理、合伙、合资、雇佣、劳动、劳务及劳务派遣之关系。
</p>
<p>
    5、在您使用的过程中，若我们通过广告或其他方式向您提供链接，使您可以接入第三方服务或网站，且您实际使用该等第三方的服务时，须受该第三方的服务条款及隐私条款约束，您需要仔细阅读其条款。您应了解，本协议仅适用于&quot;兼职网&quot;提供的服务，并不适用于任何第三方提供的服务或第三方的信息使用规则，&quot;兼职网&quot;无法对第三方网站进行控制。因此，&quot;兼职网&quot;对任何第三方提供的服务或第三方使用由您提供的信息不承担任何责任。
</p>
<p>
    6、 您清楚&quot;兼职网&quot;平台内容大多数内容来自用户，&quot;兼职网&quot;不保证平台上展示之信息的准确性和有效性、以及所提供内容质量的安全性或合法性。您如因为浏览&quot;兼职网&quot;的内容或第三方发布和上传的内容而因此遭受到任何损失（包括但不限于金钱、商誉、名誉的损失），或与其他用户发生争议，就上述损失或争议或任何方面产生有关的索赔、要求、诉讼、损失和损害，我们可为您提供相关资料处理争议事宜。您同意不就前述争议事件追究&quot;兼职网&quot;的责任，同意免除&quot;兼职网&quot;平台运营方广州九尾信息科技有限公司及其关联公司的责任。
</p>
<p>
    十、违约责任
</p>
<p>
    1、如因您违反有关法律、法规或本协议项下的任何条款给&quot;兼职网&quot;或任何第三方造成损失，&quot;兼职网&quot;有权随时采取停止服务、删除或修改信息、解除本协议等措施，并保留向您追索赔偿一切损失之权利。同时&quot;兼职网&quot;已向您收取的服务费（如有）将不予退还，您应自行承担由此造成的一切法律责任。
</p>
<p>
    2、如因&quot;兼职网&quot;违反有关法律、法规或本协议项下的任何条款而给您造成损失的，相应的损害赔偿责任由&quot;兼职网&quot;承担。
</p>
<p>
    十一、通知
</p>
<p>
    1、&quot;兼职网&quot;提供服务有关的用户协议和服务条款的修改、服务的变更、收费政策的变更或其它重要事件的通告都会以网站发布、用户后台或通过其他联系方式向您通知/送达的相关规则、政策、通知，前述修订或相关规则，自通知/送达之日起正式生效。
</p>
<p>
    2、您在使用&quot;兼职网&quot;服务时，应向&quot;兼职网&quot;提供真实有效的联系方式（包括但不限于联系电话等），若您的联系方式发生变更的，您应该及时更新有关信息，便于&quot;兼职网&quot;及时向您发送各类通知。
</p>
<p>
    十二、争议的解决及其他
</p>
<p>
    1、本协议的所有内容和条款均受中华人民共和国法律管辖。因您与兼职网平台基于本政策的相关服务而产生争议，双方一致同意提交山东省济南市仲裁委员会按照其仲裁规则进行裁决。
</p>
<p>
    2、如本协议的任何条款被裁定为无效或不可执行，该条款应视为可分的，且不影响本协议其余条款的有效性及可执行性。
</p>
<p>
    3、在发生并购时，本协议和所有纳入的协议所确定的权利可由&quot;兼职网&quot;&quot;自行酌情决定向第三方转让。&quot;兼职网&quot;未就用户或其他方的违约行为采取行动并不等于&quot;兼职网&quot;放弃就随后或类似的违约行为采取行动的权利。
</p>
<p>
    4、&quot;兼职网&quot;不定期发布针对用户的相关协议、公告、规则等，并可将前述文件作为本协议的补充或修改而将其内容作为本协议的一部分。
</p>
<p>
    <br/>
</p>
</div>
    </div>
</template>
<script>
export default {
    data(){
        return{
             currentHref:"" 
        }
    },
    created(){
    this.currentHref=window.location.href
    console.log(this.currentHref)
  },
}
</script>
<style scoped>
 .content{
     max-width: 1200px;
     width: 90%;
     margin:0 auto;
 }
 .content h4{font-weight: bold;text-align: center;font-size:25px;margin: 30px auto;}
  .content p{line-height: 35px;color: #333;font-family: 微软雅黑;}
</style>