import { render, staticRenderFns } from "./taskDetail.vue?vue&type=template&id=d779b7f2&scoped=true"
import script from "./taskDetail.vue?vue&type=script&lang=js"
export * from "./taskDetail.vue?vue&type=script&lang=js"
import style0 from "./taskDetail.vue?vue&type=style&index=0&id=d779b7f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d779b7f2",
  null
  
)

export default component.exports