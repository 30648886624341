<template>
  <!-- 金刚区 -->
<div style="position: relative;padding-top: 17.94rem;">
  <div class="fixedModule">
    <div class="jgImage">
      <img v-if="bdlist[checkIndex].background" :src="bdlist[checkIndex].background" alt="金刚区背景图">
      <div @click="$common.returnEvent()" class="backIcon">
        <i class="el-icon-arrow-left"></i>
      </div>
    </div>
    <div class="bdClass">
      <div v-for="(item,index) in bdlist" :key="index"
        :class="{'type_check':checkIndex==index}"
        :style="checkIndex==index?`background-image:url(${item.recordState});`:`background-image:url(${item.imRecordState});`"
        @click="checkBd(index)">
        <div>{{ item.title }}</div>
        <div>{{ item.description }}</div>
      </div>
    </div>
  </div>
  <!-- 列表区域 -->
  <van-list
    style="margin-top: 1rem;"
    v-model="taskLoad"
    :finished="noMore"
    finished-text="- 没有更多内容了哦 -"
    @load="loadNextPage"
  >
    <van-cell v-for="(item,index) in taskArray" :key="item.id">
      <jobItem :itemObj="item" :keyd="index" :type="type"/>
    </van-cell>
    <van-cell v-for="(item,index) in homeJuLianglist" :key="item.id">
      <jobItemQuanJian :itemObj="item" :keyd="index" :type="type"/>
    </van-cell>

    <!-- <jobItemQuanJian :itemObj="item" :keyd="index" :type="type" v-if="bdlist[checkIndex].listType!=3" />
      <jobItem :itemObj="item" :keyd="index" :type="type" v-else/> -->
  </van-list>
</div>
</template>

<script>
import setSet from "@/assets/js/setSet.js"
import jobItemQuanJian from "@/components/h5/jobItemQuanJian.vue"
export default {
  components:{
    jobItemQuanJian
  },
  name:'kongKim-m',
  data() {
    return {
      selectName :"",
      type:"",//展示不同表的职位时的标识
      //任务列表查询
      taskData: {
        pageNum: 1, //查询第几页
        pageSize: 20,
      },
      taskLoad:false,
      noMore:true,
      taskArray:[],
      homeJuLianglist:[],
      bdlist:[],
      checkIndex:0,//榜单选择
      errorCode:false,//服务器是否错误
    }
  },
  created () {
    let qy = this.$router.getParams();
    this.bdlist = qy.essayRegionListlist;
    this.checkIndex = qy.index;
    this.getTaskList();
  },
  methods: {
    getaPositionExpand() {
      this.taskLoad=true;
      let obj = {
        positionType:this.bdlist[this.checkIndex].listType,
        ...this.taskData,
      }
      this.$axios({
        method:'GET',
        url:'/position/positionExpand/list',
        params:obj,
      }).then(res=>{
        this.errorCode=false
        this.taskLoad=false
        if (res&&res.rows && res.rows.length > 0&&obj.positionType==this.bdlist[this.checkIndex].listType) {
          this.noMore=res.rows&&Boolean(res.rows.length<this.taskData.pageSize)
          if (obj.pageNum == 1) {
            this.homeJuLianglist = res.rows
          } else {
            this.homeJuLianglist.push(...res.rows)
          }
        } else {
          if(obj.positionType==this.bdlist[this.checkIndex].listType) {
            if (obj.pageNum == 1) {
              this.homeJuLianglist = []
            }
            this.noMore=true;
          }
        }
        // if(res.code==200 && ddd==this.taskData.queryTypes[5]){
        //   this.noMore=res.rows&&Boolean(res.rows.length<this.taskData.pageSize)
        //   if(this.taskData.pageNum==1){
        //     this.taskArray=res.rows
        //   }else{
        //     this.taskArray.push(...res.rows)
        //   }
        //   this.selectName = ""
        // }
      }).catch(err=>{
        this.errorCode=true
      })
    },
    // 查询任务列表
    getTaskList(){
      if(this.bdlist[this.checkIndex].listType!=3) {
        this.noMore = true;
        this.getaPositionExpand();
        return false;
      }
      this.taskLoad=true;
      let obj = {
        appletAppId:setSet.appid,
        randomSort:1,//0：正常排序 ，1：随机排序(默认)
        displayStatus:1, //0: 全部,1:正常展示的文章,2:暂停的文章
        essayRegionId:this.bdlist[this.checkIndex].regionTypeId,
        ...this.taskData,
      }
      this.$axios({
        method:'GET',
        url:'/jlApplets/essay/list',
        params:obj,
      }).then(res=>{
        this.errorCode=false
        this.taskLoad=false
        if (res&&res.rows && res.rows.length > 0&&obj.essayRegionId==this.bdlist[this.checkIndex].regionTypeId) {
          this.noMore=res.rows&&Boolean(res.rows.length<this.taskData.pageSize)
          if (obj.pageNum == 1) {
            this.taskArray = res.rows
          } else {
            this.taskArray.push(...res.rows)
          }
        } else {
          if(obj.essayRegionId==this.bdlist[this.checkIndex].regionTypeId) {
            if (obj.pageNum == 1) {
              this.taskArray = []
            }
            this.noMore=true;
          }
        }
        // if(res.code==200 && ddd==this.taskData.queryTypes[5]){
        //   this.noMore=res.rows&&Boolean(res.rows.length<this.taskData.pageSize)
        //   if(this.taskData.pageNum==1){
        //     this.taskArray=res.rows
        //   }else{
        //     this.taskArray.push(...res.rows)
        //   }
        //   this.selectName = ""
        // }
      }).catch(err=>{
        this.errorCode=true
      })
		},
    // 切换榜单
    checkBd(index){
      if(this.checkIndex == index) return false;
      this.checkIndex = index;
      this.taskData.pageNum=1;
      this.taskArray = [] //榜单列表
      this.homeJuLianglist = [];
      this.noMore=false;
      this.getTaskList();
    },
    // 加载下一页
    loadNextPage(){
      if(this.errorCode){
        return false;
      }
      if(!this.noMore){
        this.taskData.pageNum++
        this.getTaskList()
      }
    },
  },
}
</script>

<style scoped>
.jgImage{
  width: 100vw;
  height: 13.44rem;
  background-color: #ffffff;
  position: relative;
}
.jgImage>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bdClass{
  width: 100vw;
  height: 4.5rem;
  background-color: #ffffff;
}
.bdClass{
  width: 100vw;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0 0.75rem;
}
.bdClass>div{
  width: 5.25rem;
  height: 3.2rem;
  /* background-color: #f5f7fb; */
  font-family: "PingFang SC";
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.3rem;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.bdClassDiv_bacc1{
  background-size: 5.25rem 3.19rem;
}
.bdClassDiv_bacc2{
  background-size: 5.25rem 2.8rem;
}
.bdClass>div>div:first-child{
  color: #666666;
  font-size: 0.75rem;
  font-weight: 700;
}
.bdClass>div>div:last-child{
  color: #999999;
  font-size: 0.63rem;
  font-weight: 500;
}
.bdClass>.type_check>div{
  color: #ffffff !important;
}
.backIcon{
  width: 2rem;
  height: 2.75rem;
  text-align: center;
  line-height: 2.75rem;
  color: #ffffff;
  position: absolute;
  top: 2rem;
  left: 0.6rem;
  font-size: 1.6rem;
}
.fixedModule{
  width: 100vw;
  height: 17.94rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
div /deep/ .van-cell{
  background-color: transparent;
  padding: 0;
}
</style>
