<template>
  <div>
    <router-view/>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  watch: {
    '$route':{
      handler(newval){
        let mobile=this.$common.isMobile()
        if(newval.meta.special == true){ //特殊页面，绕过判断
          console.log('special:' + newval.meta.special);
        }else if(mobile&&!newval.meta.mobile){
          this.$router.replace('/home')
        }else if(!mobile&&newval.meta.mobile){
          this.$router.replace('/')
        }
      },
      immediate:true
    }
  },
  created() {
    // const _ResizeObserver = window.ResizeObserver;
    // window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    //   constructor(callback) {
    //     callback = debounce(callback, 16);
    //     super(callback);
    //   }
    // }
  },
  beforeMount() {

  },
  methods:{

  }
}
</script>
<style>
@import url("assets/css/index.css");
</style>
