import { Toast } from 'vant';
import moment from "moment";
import Vue from "vue";
import axios from "@/axios/index.js";
import router from '@/router'

// 当前是否是h5
function isMobile(){
    let flag = !!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
// 当前是否是ios
function isIos(){
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
}
// 开启加载
function showLoading(msg){
    Toast.loading({
        type:'loading',
        message: msg ? msg : '加载中',
        duration:0,
        forbidClick: true,
    });
}
// 关闭加载
function hideLoading(){
    Toast.clear()
}
// h5获取浏览器可视区域高度
function getHeight() {
    let h = window.innerHeight || document.documentElement.innerHeight || document.body.clientHeight;
    // console.log(h,'h5获取浏览器可视区域高度')
    return h
}

function formatterTime(time, format = 'YYYY-MM-DD HH:mm:ss'){
    return moment(time).format(format)
}


/**
 * 高德地图定位
 * @type {{}}
 */
function fixedPos() {
    return new Promise((resolve, reject)=>{
        let point = getPoint()
        if(point.lat){
            // console.log("获取定位成功=>",point)
            Vue.prototype.cityAddress= point.city;
            Vue.prototype.latitude= point.lat;
            Vue.prototype.longitude= point.lng;
            resolve()
            return
        }
        const geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(function(r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                let myGeo = new BMap.Geocoder()
                myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result) {
                    if (result) {
                        // console.log("获取定位成功=>",result)
                        Vue.prototype.cityAddress= result.addressComponents.city;
                        Vue.prototype.latitude= result.point.lat;
                        Vue.prototype.longitude= result.point.lng;
                        localStorage.setItem('latitude',result.point.lat)
                        localStorage.setItem('longitude',result.point.lng)
                        setPoint(result.point.lat,result.point.lng,result.addressComponents.city)
                        resolve()

                    }
                })
            } else {
                reject()
                // _this.$message.error('failed' + this.getStatus())
            }
        })
    })
}
function setPoint(lat,lng,city) {
    let point = {
        lat:lat,
        lng:lng,
        city:city
    }
    // console.log(point,'point')

    localStorage.setItem("point",JSON.stringify(point))
}
function getPoint() {
    let point = localStorage.getItem("point")
    if(point){
        point = JSON.parse(point)
    }
    return point || {}
}
// 获取唯一ID（毫秒数+6位随机数）
const getOnlyId=function(){
    let onlyid=window.localStorage.getItem('onlyid')
    if(onlyid){
        return onlyid
    }else{
        let times=new Date().getTime()
        let sixnum=parseInt(Math.random()*1000000) + ''
        let oid=times+sixnum
        window.localStorage.setItem('onlyid',oid)
        return oid
    }
}
// h5行为日志
const sendLogH5=function(i, message){
    let codeArr = {
        login:'web_website_006',/*官网登录，4*/
        detail:'web_website_007',/*官网详情，5*/
        apply:'web_website_008',/*官网报名，6*/
        register:'web_website_009',/*官网注册，7*/
        exposure:"web_website_002",/*官网首页曝光 8 */
        position:'web_website_011' /*位置授权*/
    }
    if(!codeArr[i]){
        console.error('日志code错误')
    }else{
        let uf = window.localStorage.getItem('userInfo')
        let user = uf&&uf!='undefined' ? JSON.parse(uf) : null
        let userId = user&&user.token?user.token:getOnlyId()
        let phone=user&&user.phone?user.phone:null
        let args={
            occurrenceTime:new Date().getTime(),
            actionCode:codeArr[i],
            type:isMobile()?'h5':'pc',
            user:{
                userId,
                phone
            },
            message:message
        }
        axios({
            method:"POST",
            url:"/mqtt/actionIncentive/actionLogPc",
            data:{
                payload: JSON.stringify(args)
            },
            needPrivate:true,
        }).then((res)=>{
        })
    }
}
// 复制
function onCopy(val) {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(val).then(text => {
            // Toast.success("复制成功");
            console.log('复制成功');
        }).catch(err => {
            onExecCommand(val);
        });
    } else {
        onExecCommand(val);
    }
}
function onExecCommand(val) {
    let input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', val);
    input.style.position = "absolute";
    input.style.opacity = 0;
    input.style.left = "-999999px";
    input.style.top = "-999999px";
    document.body.appendChild(input);
    input.focus();
    input.select();
    input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        Toast.success("复制成功");
    }
    document.body.removeChild(input);
}
// 当前页面参数保活
const setKeepParams = function(localName,data){
    sessionStorage.setItem(localName,JSON.stringify(data))
}
// 获取当前页面保活参数
const getKeepParams = function(localName,callback){
    let local = sessionStorage.getItem(localName)
    if(!local){
        callback(false)
    }else{
        sessionStorage.removeItem(localName)
        callback(JSON.parse(local))
    }
}
// 直接清除缓存参数
const directDelParams = function(local){
    let pm = sessionStorage.getItem(local)
    if(pm){
        sessionStorage.removeItem(local)
    }
}
const returnEvent = function(){
    if (window.history.length <= 1) {
        router.push({path:'/'})
        return false
    } else {
        router.go(-1)
    }
}
export default {
    isMobile,
    isIos,
    onCopy,
    showLoading,
    hideLoading,
    getHeight,
    fixedPos,
    formatterTime,
    setPoint,
    getPoint,
    setKeepParams,
    getKeepParams,
    directDelParams,
    sendLogH5,
    returnEvent,
}
