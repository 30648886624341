<template>
    <!-- 失败页面 -->
    <div>
        <van-nav-bar title="报名结果" left-arrow @click-left="onClickLeft" />
        <div style="display: flex;flex-direction: column;background: #ffffff80;margin-bottom: 1.19rem;">
            <div style="display: flex;margin-top: 1.75rem;margin-left: 0.94rem;margin-bottom: 0.75rem;">
                <div style="width: 1.13rem;"><i class="el-icon-circle-close"></i></div>
                <div style="color: #333333ff;font-size: 1rem;font-weight: 500;">报名未成功</div>
            </div>
            <div class="errorPage">
                <img src="@/assets/images/h5/detail/bmError.png" alt="报名未成功">
            </div>
            <div class="errorReason">
                <div>失败原因：</div>
                <div>
                    <div v-for="(item,index) in errorList" :key="index">
                        {{ item.content }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 推荐 -->
        <div class="tuijianClass">
            <div :class="chooseTj == 1 ? 'tuijianClass_spanCheck' : ''" @click="checkOut(1)">
                <span>相关推荐</span>
                <span></span>
            </div>
            <div :class="chooseTj == 2 ? 'tuijianClass_spanCheck' : ''" @click="checkOut(2)">
                <span>大家都在看</span>
                <span></span>
            </div>
        </div>
        <div class="elLoadingdiv" v-if="taskLoad">
            <i class="el-icon-loading"></i> 加载中
        </div>
        <template v-else>
            <div v-for="(item, index) in taskArray" :key="index">
                <jobItem :keyd="index" :itemObj="item" />
            </div>
            <div class="noMoreClass">- 没有更多内容了哦 -</div>
        </template>
    </div>
</template>

<script>
export default {
    name:'results-m',
    data() {
        return {
            errorList:[],
            taskList: [],
            taskLoad: false,
            taskArray: [], //任务列表
            chooseTj:1,//相关推荐下划线
            //任务列表查询
            taskInfo: {
                pageNum: 1, //查询第几页
                pageSize: 10,
                //默认根据算法查
                queryTypes: ['3:1'], //[0: '1'、1: '0'、2: '0'、3: '0'、4: '0'、5: '0']算法0、曝光1、时间2、分类3、模糊4、榜单5
                positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
                positionType: 1, //1兼职职位，2课程职位
                longitude: localStorage.getItem('longitude') ? localStorage.getItem('longitude') : '0',
                latitude: localStorage.getItem('latitude') ? localStorage.getItem('latitude') : '0',
            },
        }
    },
    created(){
        this.getTaskList()
        let qy = this.$router.getParams()
        if(qy&&qy.mydata){
            this.getError(qy.mydata)
        }else{
            this.$router.go(-1)
        }
    },
    methods: {
        onClickLeft() {
            this.$common.returnEvent()
        },
        getError(errorData){
            let list = []
            let array = JSON.parse(errorData)
            if(!Array.isArray(array) && typeof array === 'object'){
                list.push({
                    title: '失败原因',
                    content: array.content
                })
            }else{
                if(!Array.isArray(array)){
                    array = array.split(',')
                }
                array.forEach(item => {
                    if(typeof item === 'object'){
                        list.push({
                            title: '失败原因',
                            content: item.content
                        })
                    }else{
                        list.push({
                            title: '失败原因',
                            content: item
                        })
                    }
                })
            }
            // this.errorList = [
            //  {title:"失败原因",content:"性别不符"},
            //  {title:"失败原因",content:"年龄不符"},
            //  {title:"失败原因",content:"学历不符"},
            // ]
           this.$nextTick(()=>{
            let asd=JSON.stringify(list)
            this.errorList=JSON.parse(asd)
            console.log(this.errorList,1231231)
            this.$forceUpdate()
           })
        },
        // 推荐&大家都在看
        checkOut(i) {
            if (this.chooseTj == i) {
                return false;
            }
            this.chooseTj = i
            this.taskInfo.pageNum = 1 //初始化每页条数
            this.taskArray = [] //初始化
            if (this.chooseTj == 0) {
                this.taskInfo.queryTypes[0] = '1'
                this.taskInfo.queryTypes[1] = '0'
            } else {
                this.taskInfo.queryTypes[0] = '0'
                this.taskInfo.queryTypes[1] = '1'
            }
            this.getTaskList()
        },
        // 查询任务列表
        getTaskList() {
            this.taskLoad = true
            this.$axios({
                method: 'POST',
                url: '/app/home/getLessonRecommendList',
                params: {
                    pageNum: this.taskInfo.pageNum,
                },
                data: this.taskInfo,
            }).then(res => {
                this.taskLoad = false
                if (res.code == 200) {
                    if (this.taskInfo.pageNum == 1) {
                        this.taskArray = res.rows
                    } else {
                        this.taskArray.push(...res.rows)
                    }
                }
            })
        },
    }

}
</script>

<style scoped lang="less">
.tuijianClass {
    color: #666666;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    letter-spacing: -0.02rem;
    padding-left: 0.63rem;
    margin-bottom: 1rem;
}

.tuijianClass>div {
    display: inline-block;
    padding-bottom: 0.3rem;
    margin-right: 1.03rem;
}

.tuijianClass_spanCheck {
    color: #07BB7E;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    position: relative;
}

.tuijianClass_spanCheck>span:last-child {
    width: 100%;
    height: 0.25rem;
    border-radius: 0.13rem;
    opacity: 1;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.elLoadingdiv {
    height: 3rem;
    line-height: 2rem;
    text-align: center;
    color: #999999;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "PingFang SC";
}
.errorPage{
    width: 16.5rem;
    height: 13.88rem;
    margin: 0 auto;
}
.errorPage>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.errorReason{
    margin: 0 auto;
    color: #333333;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: flex;
}
.noMoreClass{
  height: 2rem;
  text-align: center;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}
</style>