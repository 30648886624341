<template>
    <div>
        <van-sticky>
            <van-nav-bar title="收藏" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <van-list v-model="loading" :finished="finished" :finished-text="finishedtext" :immediate-check="false" offset="10"
            @load="onLoad">
            <div style="display: flex;flex-direction: column;" :class="item.positionState == 3 ? 'box2' : 'box'"
                v-for="(item, index) in listArray" :key="index" @click="godetails(item)">
                <div style="display: flex;justify-content: space-between;margin-top: 0.7rem;">
                    <div class="title">{{ item.positionTitle }}</div>
                    <div class="money">
                        <div v-if="item.amountType == 1"> {{ item.amount / 100 }}元/{{
                            typeList[item.settlementUnit] }}</div>
                        <div v-if="item.amountType == 2">{{ item.amountBegin / 100 }}-{{ item.amountEnd /
                            100 }}元/{{
        typeList[item.settlementUnit] }}</div>
                    </div>
                </div>
                <div
                    style="display: flex;justify-content: space-between;;margin-top: 0.56rem; margin-left: 0.75rem;margin-right: 0.75rem;">
                    <div style="display: flex;align-items: center;">
                        <div style="margin-right: 0.5rem;">
                            <img style="width:2.38rem;border-radius: 50%;" :src="item.enterpriseLogo" alt="公司logo">
                        </div>
                        <div style="height:2.38rem;line-height: 2.38rem;" v-if="item.isAtl == 1">
                            <p class="p_1">{{ item.enterpriseName }}</p>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-content: center;" v-if="item.distance != -1 && item.isAtl == 2">
                            <p class="p_1">{{ item.enterpriseName }}</p>
                            <p class="p_2">
                                距您<span style="color: #06AD94;">{{ item.distance }}</span>km
                                {{ item.detailedProcess }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div v-if="item.positionState == 3" style="color: #f4351aff;font-size: 0.63rem;font-weight: 500;">
                            职位已失效</div>
                        <div class="remove ">
                            <button @click.stop="clickremove(item.id, index)">移出</button>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <div style="display: flex;flex-direction: column;align-items: center;" v-if="listArray.length == 0">
            <div style="margin-top: 6rem;margin-bottom: 2rem;"><img style="height: 10.3rem;"
                    src="@/assets/image/mine/zanwushuju.png" alt="空空如也"></div>
            <div style="font-size: 1.2rem;margin-bottom: 0.5rem;">空空如也</div>
            <div>什么都没有，抓紧收藏吧</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageNum: 1,
            listArray: [],//收藏列表
            typeList: ['其他', '小时', '天', '周', '月', '次', '单'],
            id: '',//职位id
            loading: false,//触底刷新
            finished: false,//刷新
            finishedtext: '没有更多了'
        }
    },
    created() {
        this.getlist()
    },
    methods: {
        onClickLeft() {
            this.$router.push('/PersonalCenter-m');
        },
        clickremove(id, index) {
            this.id = id
            this.$dialog.confirm({
                title: '温馨提示',
                message: '确定移除该收藏吗',
                confirmButtonColor: '#07BB7E'
            }).then(() => {
                console.log('确定了');
                this.$axios({
                    method: 'post',
                    url: '/app/collect/deList',
                    params: {
                        ids: this.id,
                        collectStatus: 2,
                    }
                }).then((res => {
                    console.log(res, "res1111");
                    if (res.code == 200) {
                        this.listArray.splice(index, 1)
                        if (this.listArray.length == 0) {
                            console.log('没有了');
                            // this.isLoading = true
                        } else {
                            // this.isLoading = false
                        }
                    }
                }))
            }).catch(() => {
                console.log('取消了');
            });
        },
        //收藏列表
        getlist() {
            this.$common.showLoading()
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.$axios({
                method: 'post',
                url: '/app/collect/getList',
                params: {
                    pageNum: this.pageNum,
                    pageSize: 10,
                    userLatitude: localStorage.getItem('latitude') || 0,
                    userLongitude: localStorage.getItem('longitude') || 0,
                    userId: userInfo.id
                }
            }).then((res) => {this.$common.hideLoading()
                console.log(res);
                this.loading = false
                if (res.code == 200) {
                    this.finished = Boolean(res.data.length<10)
                    if(this.finished){
                        this.finishedtext = '没有更多了'
                    }
                    if (this.pageNum == 1) {
                        if (res.data.length == 0) {
                            this.finished = true
                            this.finishedtext = ''
                            this.listArray = []
                        }else{
                            this.listArray = res.data
                        }
                    } else {
                        this.listArray.push(...res.data)
                    }
                }
            })
        },
        //收藏跳转详情
        godetails(e) {
            console.log(e, "eeeee");
            if (e.positionState != 3) {
                //判断是什么方式报名
                if (e.positionType == 1) {
                    this.$router.push({ name: 'taskDetail-m', params: { id: e.positionId } })
                    // this.$router.push('taskDetail-m?id=' + e.positionId)
                } else {
                    this.$router.push({ name: 'courseDetail-m', params: { id: e.positionId } })
                    // this.$router.push('courseDetail-m?id=' + e.positionId)
                }
            } else {
                this.$toast.fail('该职位已经下架');
            }

        },
        //触底刷新
        onLoad() {
            console.log('触发函数',this.pageNum)
            this.pageNum++
            this.getlist()
        }
    }

}
</script>

<style scoped lang="less">
.box {
    // height: 5.58rem;
    margin-top: 0.63rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: #FFFFFF;
    border-radius: 0.75rem;
    padding-bottom: 0.4rem;

    .title {
        width: 9.81rem;
        color: #000000ff;
        font-size: 1rem;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 0.75rem;
    }

    .money {
        // width: 9rem;
        color: #fe0000ff;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 0.75rem;

        .money_2 {
            width: 9rem;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .remove {
        width: 3rem;
        height: 1.75rem;
        border-radius: 0.25rem;
        opacity: 1;
        background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
        text-align: center;
        line-height: 1.75rem;
        color: #FFFFFF;
        margin: auto;
        margin-top: 0.2rem;
        font-size: 0.75rem;
    }

    .p_1 {
        width: 13rem;
        color: #333333ff;
        font-size: 0.63rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .p_2 {
        width: 13rem;
        color: #818897ff;
        font-size: 0.63rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.box2 {
    margin-top: 0.63rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: #FAFAFA;
    border-radius: 0.75rem;
    padding-bottom: 0.4rem;

    .title {
        width: 10.81rem;
        color: #999999;
        font-size: 1rem;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 0.75rem;
    }

    .money {
        // width: 9rem;
        color: #FDA5A5;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 0.75rem;

        .money_2 {
            width: 5rem;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .remove {
        width: 3rem;
        height: 1.75rem;
        border-radius: 0.25rem;
        // opacity: 0.10000000149011612;
        background: rgba(7, 187, 126, 0.2);
        text-align: center;
        line-height: 1.75rem;
        color: #07BE77;
        margin: auto;
        margin-top: 0.2rem;
        font-size: 0.75rem;
        button{
            color: #07BE77;
            opacity:1;
        }
    }

    .p_1 {
        width: 13rem;
        color: #999999;
        font-size: 0.63rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .p_2 {
        width: 13rem;
        color: #999999;
        font-size: 0.63rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.noclick {
    pointer-events: none;
    /* 上层加上这句样式可以实现点击穿透 */
}

/deep/ .van-nav-bar {
    background-color: #FFFFFF;
}

/deep/ .van-nav-bar__title {
    font-size: 1.13rem !important;
    font-weight: 700 !important;
}

/deep/.van-nav-bar .van-icon {
    color: #000000;
    font-size: 1.3rem;
}
</style>