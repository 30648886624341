import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    metaInfo: {
      title: "兼职网-做兼职找工作就上兼职网",
      keywords: "",
      description: ""
    },
    infoAvars:""
  },
  getters: {
  },
  mutations: {
    CHANGE_META_INFO(state, metaInfo) {
      state.metaInfo = metaInfo;
    },
    SET_USERINFO(state,info){
      state.infoAvars = info
    }
  },
  actions: {
  },
  modules: {
  }
})
