<template>
    <div>


  <div class="hot-box">
      <div class="hot-title-list">
          <div v-for="(item,index) in hotTitlList" :key="index" class="Title" :class="hotTitleIndex == index?'titles':''" @click="setindex(index)">
              {{item.name}}
          </div>
      </div>

      <div class="hot-mess-list" v-if="hotTitleIndex == 0">
          <a v-for="(item,index) in messList"  :key="index" :href="item.jumpUrl" v-if="item.jumpUrl != undefined" onclick="return false;">
              <div class="mess"  @click="goDetail(item)">
                  {{item.positionTitle}}
              </div>
          </a>

      </div>
      <div class="hot-mess-list" v-if="hotTitleIndex == 1">
          <div class="mess" v-for="(item,index) in arr" :key="index" @click="getPosition(item)">
                  {{item}}兼职
          </div>
      </div>


      <div class="hot-mess-list" v-if="hotTitleIndex == 2">
          <a :href="item.id" onclick="return false" v-for="(item,index) in consultationList" :key="index">

          <div class="mess"  @click="goInfo(item)">
                  {{item.title}}
          </div>
          </a>

      </div>
  </div>

    </div>
</template>

<script>
export default {
    name: "hot",
    data(){
        return {
          typeIndex:"1",
          hotTitlList:[
             {name:"热门职位"},
             {name:"热门城市"},
             {name:"热门资讯"}
          ],
          messList:[],
          consultationList:[

          ],
          cityList:[
              '济南',
              '北京',
              '上海',
              '广州',
              '深圳',
              '杭州',
              '天津',
              '西安',
              '苏州',
              '武汉',
              '厦门',
              '长沙',
              '成都',
              '郑州',
              '重庆',
              '佛山',
              '合肥',
              '青岛',
              '南京',
              '东莞',
              '昆明',
              '南昌',
              '石家庄',
              '宁波',
              '福州',
              '太原',
              '乌鲁木齐',
              '包头',
              '温州',
              '无锡',
              '大连',
              '大同',
              '哈尔滨',
              '珠海',
              '秦皇岛',
              '洛阳',
              '常州',
              '九江',
              '通辽',
              '保定',
              '安阳',
              '扬州',
              '烟台',
              '大理',
              '六安',
              '丽水',
          ],
          citylistTwo:[],
          arr:[],
          hotTitleIndex:0,
        }
    },
    beforeMount() {
        this.getHotPositionList();
        this.getHotInformationList();
        this.setCityList();
    },
    methods:{
        goDetail(e){
            let name = ''
            let params = {}
            if(e.jumpUrl.indexOf('?') > '-1'){
                name = e.jumpUrl.split('?')[0]
                let param = e.jumpUrl.split('?')[1]
                param.split('&').map(item=>{
                    params[item.split('=')[0]] = item.split('=')[1]
                })
            }
            if ('positionDetail' === this.$route.name) {
                this.$router.push({
                    name: 'empty',
                    params: Object.assign({},{
                        id: e.jumpUrl,
                        name: name,
                        title: this.$route.meta.metaInfo.title,
                    },params)
                });
                return
            }
            this.$router.push({
                name: name,
                params: Object.assign({},{
                    id: e.jumpUrl,
                    name: this.$route.meta.metaInfo.title,
                },params),
            });
        },
        setCityList(){
          this.arr = Array.from(new Set([...this.citylistTwo,...this.cityList]));
        },
        setindex(e){
            this.hotTitleIndex = e;
        },
        goInfo(e){
            if ('informationDetail' === this.$route.name) {
                this.$router.push({
                    name: 'empty',
                    params: {
                        id: e.id,
                        name: 'informationDetail',
                    }
                });
                return
            }
            this.$router.push({
                name: 'informationDetail',
                params: {
                    id: e.id,
                },
            });

          // this.$router.push({
          //     name:"informationDetail",
          //     params:{
          //         id:e.id,
          //         name: this.$route.meta.metaInfo.title
          //     }
          // })
        },

        getHotPositionList(){
            this.$axios({
                method: "POST",
                url:"/official/enterprise/getHotPositionList?pageNum=1&pageSize=50",
            }).then(res=>{
                this.messList = res.rows
            })
        },
        getHotInformationList(){
            this.$axios({
                method: "POST",
                url:"/official/enterprise/getHotInformationList?pageNum=1&pageSize=50",
            }).then(res=>{
                this.consultationList = res.rows
                console.log("res123=>",res)
            })
        },

        //根据城市名称 获取城市code
        getPosition(e){
            let _this = this;
            console.log(e)
            AMap.plugin('AMap.Geocoder', function() {
                var geocoder = new AMap.Geocoder({
                    city: '全国' // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                })
                geocoder.getLocation( e, function (status, result) {
                    if (status === "complete" && result.info === "OK") {

                        console.log('result', result.geocodes[0])
                        let adcode = result.geocodes[0].adcode
                        let city = result.geocodes[0].formattedAddress
                        // result为对应的地理位置详细信息
                        // this.place为返回的具体地理位置信息，里面无法使用return回来！
                        _this.getHotCityList(adcode)
                    }
                })
                // 使用geocoder做地理/逆地理编码
            })
        },
        getHotCityList(e){
            // this.$axios({
            //     method:"POST",
            //     url:"/official/enterprise/getHotCityList",
            //     data:{
            //         cityCode:e,
            //         longitude:this.longitude || "0",
            //         latitude:this.latitude || "0",
            //         pageNum:"1",
            //         pageSize:"9"
            //     }
            // }).then(res=>{
            //     // this.$bus.me
            //     if(res.code == 200){
            //         if(this.$route.fullPath == '/PartTJ'){
            //             this.$bus.$emit('setPoList', res)
            //         }else {
            //             this.$router.push({
            //                 name:'PartTJ',
            //                 cityCode:e
            //             })
            //             setTimeout(()=>{
            //                 window.scrollTo({
            //                     top:480,
            //                     left:0,
            //                     behavior: "smooth"
            //                 });
            //             },100)
            //             this.$bus.$emit('setPoList', res)
            //         }
            //     }else {
            //     }
            //     console.log("列表=>",res)
            // })
            if ('PartTJ' === this.$route.name) {
                this.$router.push({
                    name: 'empty',
                    params: {
                        id: e.jumpUrl,
                        name: 'PartTJ',
                        cityCode: e,
                    }
                });
                return
            }
            this.$router.push({
                name: 'PartTJ',
                params: {
                    id: e.jumpUrl,
                    cityCode: e,
                },
            });
        }
    }
}
</script>

<style scoped>
.hot-box{
    width: 1200px;
    margin: 40px auto;
    border-radius: 15px;
    background: #ffffffff;
    box-shadow: 0 4px 46px 0 #f0f0f0ff;
    padding: 46.67px 68px;
    margin-bottom: 80px;
}
.hot-title-list{
    display: flex;
}
.Title{
    color: #333333ff;
    font-size: 20px;
    font-family: "PingFang SC";
    margin-right: 50px;
    cursor: pointer;
}
.titles{
    color: #07BB7E;
    font-size: 20.67px;
    font-weight: 600;
    font-family: "PingFang SC";
    position: relative;
}
.titles:before{
    content: "";
    width: 30px;
    height: 6px;
    border-radius: 15px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #06AD94;
}
.hot-mess-list{
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 19.34px;
    display: flex;
    flex-wrap: wrap;
    max-height: 165px;
    overflow: hidden;
}
.mess{
    color: #333333ff;
    font-size: 13.33px;
    font-family: "PingFang SC";
    margin-right: 33px;
    margin-top: 18.33px;
    cursor: pointer;
}
.mess:hover{
    color: #07BB7E;
}
</style>
