<template>
<div>
<!--面包屑-->
  <div>
<div class="" >
    <div class="navTopBox">
        <div class="navtop">
            <div @click="goHome">
                首页 <span style="margin: 0px 5px;">
                        <i class="el-icon-arrow-right"></i>
                    </span>
            </div>
            <div style="margin-left: 5px;"  @click="$router.back()" v-if="company">
                {{company}}<span style="margin: 0px 5px;"><i class="el-icon-arrow-right"></i></span>
            </div>
            <div style="margin-left: 5px;"  @click="$router.back()" v-if="routeName">
                {{ routeName }}  <span style="margin: 0px 5px;">
                                        <i class="el-icon-arrow-right"></i>
                                </span>
            </div>
            <div style="margin-left: 5px;"  @click="$router.back()" v-if="firstOrder">
                {{ firstOrder }}  <span style="margin: 0px 5px;">
                                            <i class="el-icon-arrow-right"></i>
                                    </span>
            </div>
            <div style="margin-left: 5px;"  @click="$router.back()"  v-if="secondLevel">
                {{ secondLevel }}
                <span style="margin: 0px 5px;">
                    <i class="el-icon-arrow-right"></i>
                </span>
            </div>

            <span>{{ positionDetail.positionTitle }}</span>
        </div>

    </div>

    <!--立即报名-->
    <div ref="pride_tab_fixed" class="row1" v-if="positionDetail.positionTitle">

        <div class="row1-box">
        <div class="row_type">招聘中</div>
        <div class="flex just_content_sp row_margin">
          <div class="flex">
            <div class="row_name">{{ positionDetail.positionTitle }}</div>
            <div class="other">其他</div>
            <div class="row_money" v-if="positionDetail.amountType == '1'">{{ positionDetail.amount /100}}元/{{timeTypeList[positionDetail.settlementUnit]}}</div>
            <div class="row_money" v-else>{{ positionDetail.amountBegin /100}} ~ {{positionDetail.amountEnd /100}}元/{{timeTypeList[positionDetail.settlementUnit]}}</div>
          </div>
          <div class="row_btn"  @click="submint">
            <div id="enroll" style="cursor: pointer">立即报名</div>
          </div>
          <div class="row_btn1" style="display: none">
            <div>已结束</div>
          </div>
        </div>
        <div class="flex row_time">
          <div>发布时间:</div>
          <div class="row_time_time">&nbsp;{{ $common.formatterTime(positionDetail.publishTime) }}&nbsp;</div>
        </div>
      </div>
    </div>
</div>
    <!--岗位详情-->
    <div class="post-mess-box">
      <div class="post-mess-box-left">
        <div >
            <div class="moduleBox" v-if="positionDetail.positionType == 2">
                <div class="module-title">
                    <span>岗位详情</span>
                </div>
                <div class="module-mess" v-html="positionDetail.positionDescription">
                    <!--              {{ positionDetail.positionDescription }}-->
                </div>
            </div>
          <div class="position-card" v-if="positionDetail.positionType == 1">
            <div class="position-card-title">{{ positionDetail.positionTitle }}</div>
            <div class="position-card-price">
              <span v-if="positionDetail.amountType == '1'">{{ positionDetail.amount /100}}</span>
                <span v-else>{{positionDetail.amountBegin /100}} ~ {{positionDetail.amountEnd /100}}</span>
              <span>元/{{timeTypeList[positionDetail.settlementUnit]}}</span>
            </div>
            <div class="flex">
              <div class="position-card-label" v-for="item in positionDetail.tags">{{ item }}</div>
            </div>
            <div class="position-card-apply flex flex_jus" style="align-items: end" v-if="applyList.length>5">
                <div style="max-width: 10px" >
                    <div class="position-card-apply-list" >
                        <img v-for="item in applyList" :key="item.id" :src="item" alt="报名人员">
                    </div>
                </div>

              <div class="position-card-apply-desc" v-if="applyNum>0">
                已经有<span>{{applyNum}}</span>人报名
              </div>
            </div>
          </div>

          <div class="work-box" v-if="positionDetail.isAtl != '1'">
              <div class="module-title" v-if="positionDetail.isAtl != '1'">
                  <span>工作地点</span>
              </div >
            <div style="margin-top: 20px;" class="flex flex_item_between">
              <div class="address-pc">{{ areaName || '' }}</div>
              <div class="distance" v-if="positionDetail.distance>0">距您&nbsp;<span>{{ positionDetail.distance || 0 }}</span> km</div>
            </div>
            <div class="address-detail">详细地址：{{positionDetail.detailedProcess || '--'}}</div>
            <div class="work-map">
              <img src="@/assets/images/pc/map.png" alt="工作地点">
            </div>
            <div class="module-list" v-if="positionDetail.positionType == 2">
              <div class="list">
                <div class="list-title">
                  <img src="@/assets/images/pc/clock.png" alt="兼职网"/>
                  上课时间+周期
                </div>
                <div class="list-mess">
                  {{ positionDetail.courseAudition || '--' }}
                </div>
              </div>
              <div class="list">
                <div class="list-title">
                  <img src="@/assets/images/pc/age.png" alt="兼职网"/>
                  年龄性别学历要求
                </div>
                <div class="list-mess">
                  年龄：{{ positionDetail.userOld | setName}}
                </div>
                <div class="list-mess">
                  性别：{{ positionDetail.userSexName || "不限" }}
                </div>
                <div class="list-mess">
                  学历：{{ positionDetail.educationName || "不限" }}
                </div>
              </div>

              <div class="list">
                <div class="list-title">
                  <img src="@/assets/images/pc/equipment.png" alt="兼职网"/>
                  设备要求
                </div>
                <div class="list-mess">
                  {{ positionDetail.auditionDevice || '无' }}
                </div>
              </div>

              <div class="list">
                <div class="list-title">
                  <img src="@/assets/images/pc/rests.png" alt="兼职网"/>
                  其他要求
                </div>
                <div class="list-mess">
                  {{ positionDetail.otherRequirements  || '无'}}
                </div>
              </div>
            </div>
          </div>
        </div>

          <div class="module-lists" v-if="positionDetail.positionType == 1">
              <div class="list">
                  <div class="list-title">
                      <img src="@/assets/images/pc/clock.png" alt="兼职网"/>
                      工作时间
                  </div>
                  <div class="list-mess">
                      {{ positionDetail.courseAudition || '任意日期' }}
                  </div>
              </div>
              <div class="list">
                  <div class="list-title">
                      <img src="@/assets/images/pc/age.png" alt="兼职网"/>
                      工作时段
                  </div>
                  <div class="list-mess">
                      {{ positionDetail.auditionDevice || '不限' }}
                  </div>
              </div>

              <div class="list">
                  <div class="list-title">
                      <img src="@/assets/images/pc/equipment.png" alt="兼职网"/>

                      岗位要求
                  </div>
                  <div class="list-mess">
                      学历：{{ positionDetail.educationName || "不限" }}
                  </div>
                  <div class="list-mess">
                      年龄：{{ positionDetail.userOld | setName}}
                  </div>
                  <div class="list-mess">
                      性别：{{ positionDetail.userSexName || "--" }}
                  </div>
              </div>


          </div>


        <div class="module-box"  v-if="positionDetail.positionType == 2">
          <div class="module-title">
              <span>学前准备</span></div>

          <div class="module-list">
            <div class="list">
              <div class="list-title">
                <img src="@/assets/images/pc/clock.png" alt="兼职网"/>
                上课时间+周期
              </div>
              <div class="list-mess">
                {{ positionDetail.courseAudition || '无'}}
              </div>
            </div>
            <div class="list">
              <div class="list-title">
                <img src="@/assets/images/pc/age.png" alt="兼职网"/>
                年龄性别学历要求
              </div>
              <div class="list-mess">
                年龄：{{ positionDetail.userOld | setName }}
              </div>
              <div class="list-mess">
                性别：{{ positionDetail.userSexName || "--" }}
              </div>
              <div class="list-mess">
                学历：{{ positionDetail.educationName || "不限" }}
              </div>
            </div>

            <div class="list">
              <div class="list-title">
                <img src="@/assets/images/pc/equipment.png" alt="兼职网"/>
                设备要求
              </div>
              <div class="list-mess">
                {{ positionDetail.auditionDevice || '无' }}
              </div>
            </div>

            <div class="list">
              <div class="list-title">
                <img src="@/assets/images/pc/rests.png" alt="兼职网"/>
                其他要求
              </div>
              <div class="list-mess">
                {{ positionDetail.otherRequirements  || '无'}}
              </div>
            </div>
          </div>
        </div>


        <!--毕业兼职副业-->
        <div class="module-box"  v-if="positionDetail.positionType == 2 && showSideline">
          <div class="module-title"><span>毕业兼职副业</span></div>

          <div class="module-list">
            <div class="fuyelist" v-for="(item,index) in positionDetail.sideline"  v-if="item.checked" :key="index">
<!--              <div class="fuyelist-left">-->
<!--                <div class="fuyelist-title">-->
<!--                  <span>设计视觉</span><span>(3000元-5000元)</span>-->
<!--                </div>-->
<!--                <div class="fuye-mess">-->
<!--                  根据业务需求和交互设计稿，设计高保真视觉效果图-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="fuyelist-right">-->
                <img :src="item.imageUrl" />
<!--              </div>-->
            </div>
          </div>

        </div>

        <!--课程目录-->
        <div class="module-box"  v-if="positionDetail.positionType == 2">
          <div class="module-title"><span>课程目录</span></div>

          <div class="module-list">
            <div class="course-list-box">
              <div class="course-list" v-for="(item,index) in positionDetail.courses.slice(0,length)" :key="index">
                <span>{{index+1}}.</span>
                <span>{{ item.courseName }}</span>
              </div>
            </div>
            <div v-if="positionDetail.courses.length>3 && length == 3" class="course-fun" @click="expansion(positionDetail.courses)">展开<img src="@/assets/images/pc/arrow-bottom.png" /></div>
            <div class="course-fun"  v-if="length != 3"  @click="length = 3">收起<img style="transform:rotate(180deg)" src="@/assets/images/pc/arrow-bottom.png" /></div>
          </div>
        </div>


        <!--讲师介绍-->
        <div class="module-box" v-if="positionDetail.positionType == 2 && positionDetail.teacher">
          <div class="module-title"><span>讲师介绍</span></div>
          <div class="lecturer-mess">
            <div class="lecturer-info">
              <div class="lecture-info-portrait">
                <img :src="positionDetail.teacher.teacherAvatar" :alt="positionDetail.teacher.teacherName" alt="兼职网"/>
              </div>

              <div class="lecturer-info-name">
                <div>{{positionDetail.teacher.teacherName}}</div>
                <div>金牌讲师</div>
              </div>
            </div>

            <div class="lecturer-jieshi">
              {{positionDetail.teacher.teacherDesc}}
            </div>
          </div>
        </div>
          <div class="moduleBox" v-if="positionDetail.positionType == 1 ">
              <div class="module-title">
                  <span>岗位详情</span>
              </div>
              <div class="module-mess" v-html="positionDetail.positionDescription">
                  <!--              {{ positionDetail.positionDescription }}-->
              </div>
          </div>
        <!--授课认证--->
        <div class="module-box">
          <div class="module-title">
              <span>{{  positionDetail.positionType == 2 ? "授课认证" : "发布企业" }}</span>
          </div>
          <div class="gongs">
            <div class="gongsi-info">
              <div class="gongsi-info-portrait">
                <img v-if="positionDetail.enterprise" :src="positionDetail.enterprise.enterpriseLogo" :alt="positionDetail.enterprise&&positionDetail.enterprise.enterpriseName"/>
              </div>

                <div>
                  <div>{{ positionDetail.enterprise&&positionDetail.enterprise.enterpriseName }}</div>
                  <div v-if="positionDetail.enterprise && positionDetail.enterprise.remark != 'null' &&  positionDetail.enterprise.remark != undefined" class="introduce">{{positionDetail.enterprise.remark}}</div>
                    <div v-else class="renzheng">
                        <img src="@/assets/images/pc/v.png">
                        已通过企业认证
                    </div>
              </div>

            </div>
            <div class="zhuye" @click="goenter" v-if="positionDetail.enterprise != null && positionDetail.enterprise.isTrue == '1'">进入企业主页</div>
          </div>
        </div>

        <!--同类型商家还在招的岗位-->
        <div class="module-box" v-if="otherList.length > 0 && false">
          <div class="module-title">
              <span>同类型商家还在招的岗位</span></div>

          <div class="module-list-box">
            <div v-for="item in otherList" :key="item.id" class="quarters-list"  @click="getErweima(item)">
              <!--第一标题-->
              <div class="quarters-title">
                <span>{{item.operationDescriptionIcon}}</span>
                <span>{{ item.operationDescription }}</span>
              </div>
              <!--第二标题-->
              <div class="quarters-title-two">
                <span>{{ item.positionTitle }}</span>
                <span v-if="item.amount">{{ item.amount /100}}元/小时</span>
                <span v-else>{{ item.amountBegin /100}}-{{ item.amountEnd /100}}元/{{timeTypeList[item.settlementUnit]}}</span>
              </div>

              <!--tabs-list-->
              <div class="quarters-tabs">
                <div v-for="(item,index) in item.tags" :key="index">{{ item }}</div>
              </div>


              <!--底部-->
              <div class="quarters-bottom">

                <div class="quarters-left">
                  <div class="quarters-left-portrait" v-if="item.enterprise">
                    <img :src="item.enterprise.enterpriseLogo" alt="兼职网"/>
                  </div>

                  <div class="info" v-if="item.enterprise">
                    <div>{{ item.enterprise.enterpriseName }}</div>
                    <div>
                      <span v-if="item.distance&&item.distance != '-1'">距您{{ item.distance }}km&nbsp;</span>
                      <span v-if="item.detailedProcess">{{ item.detailedProcess || '' }}</span>
                    </div>
                  </div>
                </div>
                <div class="sumbit-fun" @click="getErweimas(item)">
                  立即报名
                </div>

              </div>

            </div>
          </div>

        </div>

        <!--都在看大家相关推荐 -->
        <div class="module-list">
          <div class="recommend-box">
            <div class="recommend-title" :class="recommendType == 1 ? 'recommend' : ''" @click="changeType(1)">相关推荐</div>
            <div class="recommend-title" :class="recommendType == 2 ? 'recommend' : ''" @click="changeType(2)">大家都在看</div>
          </div>

          <div class="module-list-box">
            <div v-for="item in uniRecommendList.slice(0,2)" :key="item.id" class="quarters-list"   @click="getErweima(item)">
              <!--第一标题-->
              <div class="quarters-title">
                <span>{{item.operationDescriptionIcon}}</span>
                <span>{{ item.operationDescription }}</span>
              </div>
              <!--第二标题-->
              <div class="quarters-title-two">
                <span>{{ item.positionTitle }}</span>
                <span v-if="item.amount">{{ item.amount /100}}元/{{timeTypeList[item.settlementUnit]}}</span>
                <span v-else>{{ item.amountBegin /100}}-{{ item.amountEnd /100}}元/{{timeTypeList[item.settlementUnit]}}</span>
              </div>

              <!--tabs-list-->
              <div class="quarters-tabs">
                <div v-for="(item,index) in item.tags">{{ item }}</div>
              </div>


              <!--底部-->
              <div class="quarters-bottom">

                <div class="quarters-left">
                  <div class="quarters-left-portrait" v-if="item.enterprise">
                    <img :src="item.enterprise.enterpriseLogo" alt="兼职网"/>
                  </div>

                  <div class="info" v-if="item.enterprise">
                    <div>{{ item.enterprise.enterpriseName }}</div>
                    <div class="info-two">
                      <span v-if="item.distance&&item.distance != '-1'">距您{{ item.distance }}km&nbsp;</span>
                      <span v-if="item.detailedProcess">{{ item.detailedProcess || '' }}</span>
                    </div>
                  </div>
                </div>
                <div class="sumbit-fun" @click="getErweimas(item)">
                  立即报名
                </div>

              </div>

            </div>
          </div>

          <div class="batch-box">
            <div class="batch" @click="getMore">换一批</div>
          </div>
        </div>

        <!--更多兼职类型-->
<!--        <div class="module-box">-->
<!--          <div class="type-title">更多兼职类型</div>-->

<!--          <div class="type-list">-->
<!--            <div class="tyope-list-mess" v-for="(item,index) in typeList" :key="index">-->
<!--              {{item}}<i class="el-icon-right" style="margin-left: 5px"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!--热门城市-->
<!--        <div class="module-box">-->
<!--          <div class="type-title">热门城市</div>-->

<!--          <div class="type-list">-->
<!--            <div class="tyope-list-messT" v-for="(item,index) in cityList" :key="index">-->
<!--              {{item}}<i class="el-icon-right" style="margin-left: 5px"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!--专题-->
<!--        <div class="module-box" style="display: flex;flex-wrap: wrap;align-items: center">-->
<!--          <span class="title">专题:</span>-->
<!--          <div class="tags" v-for="(item,index) in 10" :key="index">苏州兼职日常</div>-->
<!--        </div>-->
      </div>
      <div class="post-mess-box-right">

        <div class="post-mess-box-right-box">
            <div class="box-title"><img src="@/assets/images/pc/HotIcon.png" /> 热门推荐</div>
          <div class="position">
              <div class="position-box" v-for="(item,index) in recommendList" @click="getErweima(item)">
                  <!--            <img v-if="item.courseCephalogram" :src="item.courseCephalogram" :alt="item.positionTitle">-->
                  <img v-if="index == 1" src="@/assets/images/pc/hot1.png" />
                  <img v-else src="@/assets/images/pc/hot2.png" />
                  <div class="position-title">{{ item.positionTitle }}</div>
                  <div class="position-price" v-if="item.amount">{{ item.amount /100}}元/{{timeTypeList[item.settlementUnit]}}</div>
                  <div class="position-price" v-else>{{ item.amountBegin /100}}-{{item.amountEnd /100 }}元/{{timeTypeList[item.settlementUnit]}}</div>
              </div>
          </div>

        </div>
          <div class="post-mess-box-right-box">
          <div class="box-title"><img src="@/assets/images/pc/HotIcon.png" /> 推荐兼职</div>
          <div class="recommend-position" v-for="item in ExposureList" :key="item.id"  @click="getErweima(item)">
            <div class="flex flex_center flex_item_between">
              <div class="recommend-position-title">{{ item.positionTitle }}</div>
              <div class="recommend-position-price" v-if="item.amountType == 1">{{ item.amount /100}}元/{{timeTypeList[item.settlementUnit]}}</div>
              <div class="recommend-position-price" v-else>{{ item.amountBegin /100}}-{{item.amountEnd /100}}元/{{timeTypeList[item.settlementUnit]}}</div>
            </div>
            <div class="recommend-position-time" v-if="item.workingPeriod != 0 || (item.workDateBegin != '' || item.workDateEnd)">工作时间：{{ item.workDateBegin}} -- {{item.workDateEnd}}</div>
              <div class="recommend-position-time" v-else>工作时间：不限</div>
            <div class="recommend-position-address">工作地点：{{ item.detailedProcess || '不限' }}</div>
          </div>
<!--          <div class="qrcode">-->
<!--            <div class="qrcode-bg">-->
<!--              <img src="@/assets/images/pc/gzh_qrcode.png" alt="公众号">-->
<!--            </div>-->
<!--          </div>-->
<!--            <div class="qrcode-tip-box">-->
<!--                <div class="qrcode-tip">关注兼职网微信公众号，海量兼职岗位在线等</div>-->
<!--            </div>-->
        </div>
      </div>

    </div>

    <!--二维码弹框 -->
    <div class="erweima-box" v-if="erweimaimgShow == 1">
      <div class="erweima-bg">
        <img src="@/assets/images/pc/wxqrcode.png" />
        <img  src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1701659335171517e9559-8e76-48d6-83c4-2a8e7f41291a.gif"  alt="报名二维码" />
        <img class="close" src="@/assets/images/pc/close.png" alt="关闭按钮"  @click="setEr" />
      </div>

    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "positionDetail",
  data(){
    return {
      typeList: [
        '全国教育培训完工结算兼职',
        '全国咨询服务完工结算兼职',
        '全国生活服务兼职',
        '全国客服话务月结兼职',
        '全国互联网岗位兼职'
      ],
      cityList: [
        '马鞍山兼职',
        '攀枝花兼职',
        '鄂州兼职',
        '马鞍山兼职',
        '攀枝花兼职'
      ],
      positionDetail: {},
      firstOrder:"",  //一级标题
      secondLevel:"", //二级标题
      company:"", //公司名称
      applyList: [],
      applyNum: 0,
      timeTypeList: ['其他', '小时', '天', '周', '月', '次', '单'],
      uniRecommendList: [],
      recommendList: [],
      ExposureList: [],
      otherList: [],
      pageNum: 1,
      recommendType: 1,
      showSideline: false,
      areaName: '',
      erweimaimg:"https://jzuser-api.jianzhinet.cn/official/enterprise/getAppletErCodeB?positionId=ed72235d5eac41c4a8b439ae967a150b",
      length:3,
        titleFixed: false,
      erweimaimgShow:localStorage.getItem("erWeima"), //控制二维码是否显示
      routeName: '',
        args:{
            occurrenceTime:"",
            user:{
                userId:"",
                phone:""
            },
            type:{},
            type2:"", //pc / h5的
            actionCode:"",
            message:{
                phone:""
            }
        }
    }
  },
    filters:{
        setName(e){
            let age = eval('(' + e + ')')
            console.log('age=>',age)
            if(age[0]  && age[1]){
                return age[0] + '-' + age[1] + '岁'
            }else {
                return '不限'
            }
        }
    },
  mounted() {
      console.log("获取=>",this.$router.getParams())
      window.addEventListener('scroll', this.handleScrollTop);
      let breadCru = this.$route.params
      this.firstOrder = breadCru.firstOrder;
      // firstOrder:"",  //一级标题
      if(breadCru.secondLevel != null && breadCru.secondLevel != undefined && breadCru.secondLevel != ''){
         this.secondLevel = breadCru.secondLevel //二级标题
      }else {
          console.log("走这里=>",breadCru.name)
          // this.routeName = breadCru.name;
      }


      if(breadCru.company != "" && breadCru.company != undefined && breadCru.company != null){
          this.company = breadCru.company
      }
      if(this.$router.getParams().type == 'fullTime'){
          this.getFullTimePositionDetail()
      }else{
          this.getPositionDetail()
      }
      this.getApplyList()
      this.getRecommendPosition()
      this.getRecommendList()
      this.getExposurePosition()
      this.getAppletsErCode();

      this.$bus.$on('getCode',(e)=>{
          console.log("接受=>",e)
          this.submint();
      })
  },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScrollTop);
        this.$bus.$off('getCode')
    },
    watch: {
    recommendType(val){
      if(val == 1){
        this.getRecommendPosition()
      }else{
        this.getExposurePosition()
      }
    },
    $route: { // 监听路由的参数变化
      handler () { // 数据变化更新数据
        console.log(this.$route.params,'this.$route.paramsthis.$route.params')
      },
      immediate: true // 立即执行
    }
  },
  methods:{
      setEr(){
        localStorage.setItem("erWeima",2)
          this.erweimaimgShow = localStorage.getItem("erWeima")
      },
      goHome(){
          this.$router.push({
              name:"homePage"
          })
      },
      handleScrollTop(){
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          // console.log('scrollTop', scrollTop)
          let offsetTop = 80;
          // this.titleFixed = scrollTop > offsetTop;
      },
      getOffset(obj){
          let offsetT = 0;
          if( obj!== window.document.body && obj !== null ){
              console.log('obj', obj.offsetTop)
              if(obj.offsetTop){
                  offsetT += obj.offsetTop;
              }
          } else {
              return offsetT;
          }
      },

      expansion(e){
        this.length = e.length;
      },
    getErweima(e){
      this.$router.push({
        name: "empty",
        params: {
          id: e.id,
          name: this.$route.name,
          title: this.positionDetail.positionTitle,
          firstOrder:this.firstOrder,
          secondLevel:this.secondLevel,
          sLname:"兼职",
          company:this.company
        },
      });
    },
      getErweimas(e){
          localStorage.setItem("erWeima",1)
          this.$router.push({
              name: "empty",
              params: {
                  id: e.id,
                  name: this.$route.name,
                  title: this.positionDetail.positionTitle,
                  firstOrder:this.firstOrder,
                  secondLevel:this.secondLevel,
                  sLname:"兼职",
                  company:this.company
              },
          });
      },
    goenter(){
      this.$router.push({
        name:"enterpriseDetail",
        params:{
          id: this.positionDetail.enterpriseId,
          name:this.positionDetail.positionTitle
        }
      })
    },
    getAppletsErCode(){
       this.$axios({
        baseURL:"https://jzuser-api.jianzhinet.cn",
        url:"/official/enterprise/getAppletErCodeB",
        method:"POST",
        responseType:"blob",
        params:{
          positionId: this.$router.getParams().id
        },
        needPrivate: true
      }).then( res=> {

          // let binaryData = [];
          // binaryData.push(res);
          // this.erweimaimg = window.URL.createObjectURL(new Blob(binaryData));
          // this.erweimaimg = window.URL.createObjectURL(res)
          console.log(res)

          let blob = new Blob( [res], {type: 'image/gif'})
        //   console.log('blob', blob)
        // this.erweimaimg =  window.URL.createObjectURL(blob)
          let fileReader= new FileReader();
          fileReader.onloadend = ( e ) => {

              this.erweimaimg  = e.target.result;
              console.log(this.erweimaimg)
          };
          fileReader.readAsDataURL( res );

      })
    },
    getPositionDetail(){
      let positionId = this.$route.params.id || this.$router.getParams().id
      this.$axios({
        url: '/official/enterprise/getPositionDetail',
        method: 'POST',
        params: {
          positionId: positionId, // '003bf484d85e4288847b88a557170008'
          latitude: this.latitude || '0',
          longitude: this.longitude || '0',
        }
      }).then(res => {
        this.positionDetail = res.rows[0];
        console.log("positionDetail=>",this.positionDetail)
        this.$common.sendLogH5('detail', this.positionDetail)
        this.positionDetail.positionDescription = this.positionDetail.positionDescription.replace(/\n/g,'<br>')
        this.$route.meta.metaInfo.title = this.positionDetail.positionTitle
        if(this.positionDetail.sideline){
            for (let item in this.positionDetail.sideline) {
              if (this.positionDetail.sideline[item].checked) {
                this.showSideline = true
              }
            }
        }
        // if(this.positionDetail.courses){
        //   this.positionDetail.courses = JSON.parse(this.positionDetail.courses)
        // }
        this.getOtherPosition()
        if(this.positionDetail.positionType == "1"){
          this.mapregeo()
        }
      })
    },
      getFullTimePositionDetail(){
          let positionId = this.$route.params.id || this.$router.getParams().id
          this.$axios({
              url: '/official/enterprise/getCooperationListRPD',
              method: 'POST',
              params: {
                  id: positionId, // '003bf484d85e4288847b88a557170008'
                  latitude: this.latitude || '0',
                  longitude: this.longitude || '0',
              }
          }).then(res => {
              if(res.code == 200){
                  this.positionDetail = res.data;
                  console.log("this.positionDetail=>",this.positionDetail)
                  this.positionDetail['positionType'] = 1
                  this.$common.sendLogH5('detail', this.positionDetail)
                  this.positionDetail.positionDescription = this.positionDetail.positionDescription.replace(/\n/g,'<br>')
                  this.$route.meta.metaInfo.title = this.positionDetail.positionTitle
                  if(this.positionDetail.sideline){
                      for (let item in this.positionDetail.sideline) {
                          if (this.positionDetail.sideline[item].checked) {
                              this.showSideline = true
                          }
                      }
                  }
                  // if(this.positionDetail.courses){
                  //   this.positionDetail.courses = JSON.parse(this.positionDetail.courses)
                  // }
                  this.getOtherPosition()
                  if(this.positionDetail.positionType == "1"){
                      this.mapregeo()
                  }
              }
          })
      },
    getApplyList(){
      this.$axios({
        url: '/official/enterprise/getApplyList',
        method: 'POST',
        params: {
          positionId: this.$router.getParams().id
        }
      }).then(res => {
        if(res.code == 200){
          this.applyList = res.data.list
          this.applyNum = res.data.size
        }
      })
    },
    getRecommendPosition(){
      this.$axios({
        url: '/official/enterprise/getTaskListByRSA',
        method: 'POST',
        data: {
          pageNum: this.pageNum,
          pageSize: 2,
          latitude: this.latitude || 0,
          longitude: this.longitude || 0,
        }
      }).then(res => {
        if(this.recommendType == 1){
          this.uniRecommendList = res.rows
        }
      })
    },
    getOtherPosition() {
      this.$axios({
        url: `/official/enterprise/getPositionList?pageNum=${this.pageNum}&pageSize=2`,
        method: 'POST',
        data: {
          pageNum: this.pageNum,
          pageSize: 2,
          enterpriseId: this.positionDetail.enterpriseId,
          latitude: this.latitude || 0,
          longitude: this.longitude || 0,
        }
      }).then(res => {
        this.otherList = res.data
      })
    },
      getExposurePosition() {
          this.$axios({
              url:`/official/enterprise/getExposurePosition`,
              method: 'POST',
              data:{
                  pageNum:this.pageNum,
                  pageSize:'4',
                  latitude: this.latitude || 0,
                  longitude: this.longitude || 0,
              }
          }).then(res => {
              // if(this.recommendType == 1){
              //     this.ExposureList = res.data
              // }
              this.recommendList = res.data
              if(this.recommendType == 2){
                  this.uniRecommendList = res.data
              }
          })
      },
      getRecommendList() {
          this.$axios({
              url:`/official/enterprise/getRecommendList?pageNum=${this.pageNum}&pageSize=3`,
              method: 'POST',
              // data:{
              //     latitude: this.latitude || 0,
              //     longitude: this.longitude || 0,
              // }
          }).then(res => {
              // if(this.recommendType == 1){
              //     this.ExposureList = res.data
              // }
              this.ExposureList = res.rows
              console.log("ExposureLis=>",res)

          })
      },

    changeType(type){
          if(type == 2){
              this.pageNum = 1;
          }
      this.recommendType = type
    },
    getMore(){
      this.pageNum++
      if(this.recommendType == 1){
        this.getRecommendPosition()
      }else{
        this.getExposurePosition()
      }
    },
    mapregeo() {
      let longitude = this.positionDetail.longitude || 0
      let latitude = this.positionDetail.latitude ||0

      this.$axios({
        url: '/common/map/regeo' + '?longitude=' + longitude + '&latitude=' + latitude,
        method: 'get'
      })
          .then(res => {
            if (res.code == 200) {
              this.areaName = res.data.addressComponent.province + res.data.addressComponent.city
              //区县
              let dis = this.positionDetail.detailedProcess.length
              let areaName = this.areaName.length

              let str = this.positionDetail.detailedProcess

              let strs = str.substring(areaName, dis)

              this.positionDetail.detailedProcess = strs
            } else {
              this.areaName = ''
            }
          })
    },
      submint(){
        this.erweimaimgShow = true
          this.$common.sendLogH5('apply', this.positionDetail)
          console.log("调用",this.erweimaimgShow)

      },
//报名曝光
      exposureOW2(){
          let data = localStorage.getItem("userinfo")
          let phone = '';
          if(data != null){
              phone = JSON.parse(data).phone;
          }

          if(data != null && (phone != '' || phone != null || phone != undefined)){
              this.args.user.phone = phone;
              let user = JSON.parse(window.localStorage.getItem('userInfo'))
              let userId = user&&user.token?user.token:phone
              this.args.user.userId = userId;
              this.args.message.phone = userId;
          }else{
              this.randomNum();
              let user = JSON.parse(window.localStorage.getItem('userInfo'))
              let userId = user&&user.token?user.token:getOnlyId()
              this.args.user.userId = userId
          }
          this.args.message.message = this.taskdetail;
          this.args.occurrenceTime = new Date().getTime(),
              //正是   https://jzuser-api.jianzhinet.cn
              this.args.actionCode = 'web_website_005';
          this.args.type = 'website';
          this.$axios({
              url:"/mqtt/actionIncentive/actionLogPc",
              data:{
                  payload: JSON.stringify(this.args)
              },
              method:"POST",
          }).then((res)=>{

          })
      },

//详情曝光
      exposureOW(){
          let data = localStorage.getItem("userinfo")
          let phone = '';
          if(data != null){
              phone = JSON.parse(data).phone;
          }
          if(data != null && (phone != '' || phone != null || phone != undefined)){
              let user = JSON.parse(window.localStorage.getItem('userInfo'))
              let userId = user&&user.token?user.token:phone
              this.args.user.userId = userId;
              this.args.user.phone = phone;
              this.args.message.phone = phone;
          }else{
              this.randomNum();
              let user = JSON.parse(window.localStorage.getItem('userInfo'))
              let userId = user&&user.token?user.token:getOnlyId()
              this.args.user.userId = userId
          }
          this.args.message.message = this.taskdetail;
          this.args.occurrenceTime = new Date().getTime(),
              //正是   https://jzuser-api.jianzhinet.cn
              this.args.actionCode = 'web_website_004';
          this.args.type = 'website';
          this.$axios({
              url: "/mqtt/actionIncentive/actionLogPc",
              data:{
                  payload: JSON.stringify(this.args)
              },
              method:"POST",
          }).then((res)=>{

          })
      },
  }
}
</script>

<style src="@/assets/css/pc/positionDetail.css" scoped></style>
