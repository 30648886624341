<script src="../assets/utils/uitls.js"></script>
<template>
  <div class="home">
      <Header></Header>
      <div class="notBox">
          <img class="notImg" src="@/assets/images/pc/404.png" />
          <div class="notTitle">很抱歉，您访问的页面不存在！</div>
          <div class="notTitle">请仔细检查您输入的网址是否正确。</div>

          <div class="notFun" @click="goHome">返回首页</div>

          <div class="notThi">
              <div class="time-box">
                  <div>{{times}}</div>
                  <div>s</div>
              </div>
              <div>后自动返回至首页</div>
          </div>
      </div>

      <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/pc/header.vue'
import Footer from '@/components/pc/footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
  },
  data(){
      return {
          times:"3", //几秒
          timer:""  //定时器
      }
  },
  created () {

  },
  mounted() {
      this.setTimer();
  },
  methods: {
      //开启定时器
      setTimer(){
          this.timer = setInterval(()=>{
              this.times--;
              if(this.times == "0"){
                  clearInterval(this.timer);
                  this.$router.push({
                      path:"/"
                  })
              }
          },1000)
      },
      /*跳转首页*/
      goHome(){
          clearInterval(this.timer);
          this.$router.push({
              path:"/"
          })
      }
  },
}
</script>
<style scoped>
.notBox{
    width: 419.17px;
    margin: auto;
    padding: 39px 0px;
}
.notImg{
    width: 419.17px;
    height: 419.17px;
}
.notTitle{
    width: 100%;
    text-align: center;
    color: #595959ff;
    font-size: 15px;
    font-family: "PingFang SC";
    line-height: 26.67px;
}
.notFun{
    width: 136.67px;
    height: 44.17px;
    border-radius: 3.33px;
    border: 0.83px solid #e2e2e2ff;
    background: linear-gradient(90deg, #07bb7e 0%, #06ad94 100%);
    text-align: center;
    line-height: 44.17px;
    color: #ffffffff;
    font-size: 21.67px;
    margin: 26px auto;
    cursor: pointer;
}
.notThi{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.time-box{
    color: #07BE77;
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
}
.time-box>div:nth-child(1){
    font-size: 26.67px;
    height: 35px;
}
</style>