<template>
  <div class="detailClass" :style="{height:mh&&mh!=0?mh+'px':'100vh'}" v-loading="loadPage">
    <!-- 顶部返回部分 -->
    <div class="topBack">
      <div class="backIcon" @click="$common.returnEvent()">
        <i class="el-icon-arrow-left"></i>
      </div>
      <span>岗位详情</span>
    </div>
    <!-- 内容 -->
    <div class="courseContent" :style="{height:mh&&mh!=0?`calc(${mh}px - 2.75rem - 4.5rem)`:'calc(100vh - 2.75rem - 4.5rem)'}">
      <div class="courseContent_top_img">
        <img :src="courseDetail.courseCephalogram" />
      </div>
      <!-- 内容top区 -->
      <div class="courseContent_top">
        <div class="baoZhang" @click="toLink2">
          <img src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1680599192245ef0c0dfc17c48bd99752cf881b8d725b.png" />
          <span style="vertical-align: middle;">已参加求职者保障计划 安心赚钱</span>
          <i class="el-icon-arrow-right" style="vertical-align: middle;"></i>
        </div>
        <div class="detail_title">{{ courseDetail.positionTitle }}</div>
        <!-- 价格 -->
        <div class="taskMoneys">
          <span v-if="courseDetail.amountType == 2">
            {{courseDetail.amountBegin/100}}-{{courseDetail.amountEnd/100}}
          </span>
          <span v-else-if="courseDetail.amount">{{courseDetail.amount/100}}</span>
          <span v-else>0</span>
          <span>元</span>
          <span v-show="typeList[courseDetail.settlementUnit]">
          /{{typeList[courseDetail.settlementUnit]}}
          </span>
          <div v-if="courseDetail.royaltyType" style="display: inline-block;margin-left: 0.4rem;">
            <span v-if="courseDetail.royaltyType==0">
              {{ courseDetail.royaltyRatio || '' }}
            </span>
            <span v-else-if="courseDetail.royaltyType==1">
              业绩提成{{ courseDetail.royaltyRatio || 0 }}%
            </span>
            <span v-else-if="courseDetail.royaltyType==2">
              计件提成{{ courseDetail.royaltyRatio || 0 }}%
            </span>
          </div>
        </div>
        <!-- 标签 -->
        <div class="tags" v-show="courseDetail.tags&&courseDetail.tags.length>0">
          <span v-for="(tagName,tagIndex) in courseDetail.tags" :key="tagIndex">
            {{ tagName }}
          </span>
        </div>
        <!-- 报名人数 -->
        <!-- <div class="baomingDiv" v-if="avatarSize>=5">
          <div class="avatorArr">
            <img :style="`transform: translateX(-${0.4 * index}rem);`" :src="item" v-for="(item,index) in avatarList" :key="index">
          </div>
          <div class="baomingNum">已经有<span>{{ avatarSize }}</span>人报名</div>
        </div> -->
      </div>
      <!-- 工作地点 -->
      <div class="moduleTitle" style="width: 4.4rem;" v-if="courseDetail.isAtl == 2">
        <span class="moduleTitle_fst">工作地点</span>
        <span class="moduleTitle_sed"></span>
      </div>
      <div class="workModule" v-if="courseDetail.isAtl == 2">
        <div class="workModule_fst">
					<div>{{areaName || '山东省济南市'}}</div>
					<div class="workModule_fst_r" v-if="courseDetail.distance != -1">
						<span>距您</span>
						<span style="color: #F4351A;">{{courseDetail.distance}}</span>
						<span>km</span>
					</div>
          <div v-else></div>
				</div>
        <div class="workModule_sed">详细地址：{{ courseDetail.detailedProcess | handlerAdress(this.areaName) }}</div>
        <!-- @click="clickMap" -->
        <div class="workModule_thd">
          <img class="imgDontClick" src="http://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/1681889327021maps.png" alt="map">
        </div>
      </div>
      <!-- 要求模块 -->
      <div class="zhunbei">
        <div class="zhunbei_item">
          <div>
            <img src="@/assets/images/h5/detail/icon1.png" alt="icon">
            <span>工作时间</span>
          </div>
          <div>{{courseDetail.workDateTypeFirstName || '--'}} {{courseDetail.workDateTypeSecondName || '--'}}</div>
        </div>
        <div class="zhunbei_item">
          <div>
            <img src="@/assets/images/h5/detail/icon2.png" alt="icon">
            <span>工作时段</span>
          </div>
          <div>
            <div v-if="courseDetail.workingPeriod==0">不限</div>
            <div v-if="courseDetail.workingPeriod!=0&&courseDetail.workDateBegin">
              {{courseDetail.workDateBegin | formatDate }} - {{courseDetail.workDateEnd | formatDate }}
            </div>
          </div>
        </div>
        <div class="zhunbei_item">
          <div>
            <img src="@/assets/images/h5/detail/icon3.png" alt="icon">
            <span>岗位要求</span>
          </div>
          <div>
            <div>学历：{{courseDetail.educationName || '不限'}}</div>
            <div v-if="courseDetail.userOldName">年龄：{{courseDetail.userOldName || "--"}}</div>
            <div v-if="courseDetail.userSexName">性别：{{courseDetail.userSexName || "--"}}</div>
          </div>
        </div>
      </div>
      <!-- 岗位模块 -->
      <div v-if="courseDetail.positionDescription" class="moduleTitle" style="width: 4.4rem;">
        <span class="moduleTitle_fst">岗位详情</span>
        <span class="moduleTitle_sed"></span>
      </div>
      <div v-if="courseDetail.positionDescription" class="gangwei" :class="zksqBtn?'':'gangwei_sq'">
        <div ref="gwDetail" style="word-break:break-all;font-size: 0.81rem;" v-html="courseDetail.positionDescription"></div>
        <div v-if="contentNum>300&&!zksqBtn" class="zkSq zkSq2" @click="zksqBtn=true">
          <span>展开</span>
          <i class="el-icon-arrow-down"></i>
        </div>
        <div class="zkSq_up" v-if="contentNum>300&&zksqBtn" @click="zksqBtn=false">
          <span>收起</span>
          <i class="el-icon-arrow-up"></i>
        </div>
      </div>
      <!-- 发布企业 -->
      <div v-if="courseDetail.enterpriseVo" class="moduleTitle" style="width: 6.4rem;">
        <span class="moduleTitle_fst">发布企业</span>
        <span class="moduleTitle_sed"></span>
      </div>
      <div class="googsi" v-if="courseDetail.enterpriseVo" @click="toLink">
        <img :src="courseDetail.enterpriseVo.enterpriseLogo" />
        <div class="googsi_r">
          <div style="font-size: 0.8rem;">{{courseDetail.enterpriseVo.enterpriseName}}</div>
          <div v-if="courseDetail.enterpriseVo.remark" class="googsi_r_cont">
            {{courseDetail.enterpriseVo.remark}}
          </div>
          <div v-else class="googsi_r_cont_renzheng">
            <img src="@/assets/images/h5/detail/renzheng.png" alt="企业认证">
            <span>已通过企业认证</span>
          </div>
        </div>
        <i class="el-icon-arrow-right" style="position: absolute;top: 50%;right: 0.8rem;transform: translateY(-50%);"></i>
      </div>
      <!-- 推荐 -->
      <div class="tuijianClass">
        <div :class="chooseTj==1?'tuijianClass_spanCheck':''" @click="checkOut(1)">
          <span>相关推荐</span>
          <span></span>
        </div>
        <div :class="chooseTj==2?'tuijianClass_spanCheck':''" @click="checkOut(2)">
          <span>大家都在看</span>
          <span></span>
        </div>
      </div>
      <div class="elLoadingdiv" v-if="taskLoad">
        <i class="el-icon-loading"></i> 加载中
      </div>
      <template v-else>
        <div v-for="(item,index) in taskArray" :key="index">
          <jobItem :keyd="index" :itemObj="item" />
        </div>
        <div class="noMoreClass">- 没有更多内容了哦 -</div>
      </template>
    </div>
    <!-- 按钮区域 -->
    <div class="myBtns" v-if="!loadDetail">
      <div class="collectBtn" @click="hasCollect">
        <img v-if="isCollect" src="@/assets/images/h5/detail/collecting.jpg" alt="收藏">
        <img v-else src="@/assets/images/h5/detail/collect.jpg" alt="取消收藏">
        <div>收藏</div>
      </div>
      <!-- isTrue==2假职位 -->
      <template v-if="courseDetail.isTrue==2">
        <div class="myBtn" @click="fakeLink">立即沟通</div>
      </template>
      <!-- 已报名 -->
      <template v-else-if="['1','2','3'].includes(courseDetail.enrollmentStatus)">
        <div class="myBtn" @click="linkSuccess">联系商家</div>
      </template>
      <template v-else-if="offline">
        <div class="myBtn" style="background: #F3F3F3;color: #999999;font-weight: 500;">已抢光</div>
      </template>
      <!-- 未报名 -->
      <template v-else-if="!['1','2','3'].includes(courseDetail.enrollmentStatus)">
        <div class="myBtn" @click="applyChange">
          立即报名
          <!-- {{ courseDetail.positionType=='1'?'立即报名':courseDetail.formNum>10?'立即报名':'抢名额' }} -->
        </div>
      </template>
      <!-- 报名截止 -->
      <template v-else>
        <div class="myBtn" style="background: #F3F3F3;color: #999999;font-weight: 500;">已抢光</div>
      </template>
    </div>
    <!-- 任务弹框 -->
    <jobMask ref="jobMask" :popupNum="popupNum" :popupNumObj="popupNumObj"  :popupNumObj2="popupNumObj2" @submitBm="submitBm" @submitJlMb="submitJlMb" tagName="兼职" />
    <!-- error弹框 -->
    <van-popup class="vRrrorPopup" v-model="showError">
      <div class="errorMaskClass">
        <img src="@/assets/images/h5/detail/errorMask.png" alt="错误遮罩">
        <div class="errorMaskClass_div">
          <div>
            <div>温馨提示</div>
            <div class="error_cont">报名失败</div>
          </div>
          <div @click="toLinkError">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
  </template>
  <script>
  export default {
    name:'taskDetail-m',
    data() {
      return {
        typeList: ['其他', '小时', '天', '周', '月', '次', '单'],
        zksqBtn:false,
        contentNum:0,
        zksqMl:false,
        maskState:false,
        popupNum:3,
        popupNumObj:{},
        popupNumObj2:{},//弹框3需要的参数
        showError:false,
        courseDetail:{},
        loadDetail:false,
        resume:{},
        userInfo:{},
        loadPage:false,
        avatarList:[],
        avatarSize:0,
        mh:'',
        isCollect:false,
        positionId:'',
        chooseTj:1,
        taskArray: [], //任务列表
        taskLoad:false,
        //任务列表查询
				taskInfo: {
					pageNum: 1, //查询第几页
					pageSize: 10,
					//默认根据算法查
					queryTypes: ['3:1'], //[0: '1'、1: '0'、2: '0'、3: '0'、4: '0'、5: '0']算法0、曝光1、时间2、分类3、模糊4、榜单5
					positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
					positionType: 1, //1兼职职位，2课程职位
					longitude: localStorage.getItem('longitude') ? localStorage.getItem('longitude') : '0',
					latitude: localStorage.getItem('latitude') ? localStorage.getItem('latitude') : '0',
				},
        areaName: '', //地址
        mydata:null,
        lat:0,
        lgt:0,
        offline:false,//是否下架
      }
    },
    created () {
      let lat = localStorage.getItem('latitude')
      let lgt = localStorage.getItem('longitude')
      if(lat && lgt){
        this.lat = lat
        this.lgt = lgt
      }
      let qy = this.$router.getParams()
      if(qy.id){
        this.positionId=qy.id
        this.mh=this.$common.getHeight()
        this.getDetail()
        this.getUserResumeInfo()
        this.getTaskList()
      }else{
        this.$toast('职位id无效')
        this.$router.go(-1)
      }
    },
    mounted () {
      window.addEventListener('resize',this.resizeChange)
    },
    methods: {
      resizeChange(){
        this.mh=this.$common.getHeight()
      },
      fakeLink(){
        window.open('https://work.weixin.qq.com/kfid/kfceadcea55e01d2a7c')
      },
      // 经纬度解析
			mapregeo() {
				let longitude = this.courseDetail.longitude
				let latitude = this.courseDetail.latitude
        this.$axios({
          url:'/common/map/regeo',
          params:{
            longitude,
            latitude,
          }
        }).then(res=>{
          if (res.code == 200) {
            let dd = res.data.addressComponent
            this.areaName = dd.province + dd.city
          } else {
            this.areaName = ''
          }
        })
			},
      // 获取职位详情
      getDetail(){
        this.$common.showLoading()
        this.loadDetail=true
        this.$axios({
          method:'POST',
          url:'/app/home/getPositionInfo',
          params:{
            positionId:this.positionId,
            userLatitude:this.lat,
            userLongitude:this.lgt,
          },
          needPrivate:true,
        }).then(res=>{
          this.loadDetail=false
          this.$common.hideLoading()
          if(res.code == 200){
            res.data.positionDescription=res.data.positionDescription.replaceAll(/\n/g,'<br />')
            this.courseDetail = res.data
            let userOld = JSON.parse(res.data.userOld)
						if (userOld.length > 0) {
							if (userOld[1] == '99') {
								this.courseDetail['userOldName'] = `${userOld[0]}岁-不限`
							} else {
								this.courseDetail['userOldName'] = `${userOld[0]}岁-${userOld[1]}岁`
							}
						} else {
							this.courseDetail['userOldName'] = '不限'
						}
            this.isCollect = res.data.isCollect //收藏状态
            if(res.data.isAtl == 2){
              this.mapregeo()
            }
            this.handlerPopupNumObj2()
            //下架状态 2:已下架
						this.offline = Boolean(res.data.positionState == '2' || res.data.positionState == '3')
            this.$common.sendLogH5('detail',{message:res.data})//埋点
            this.$nextTick(()=>{
              this.contentNum=this.$refs.gwDetail.offsetHeight
            })
          }
        })
        this.getApplyList()
      },
      // 提交万简历信息进行报名
      submitJlMb(){
        this.getUserResumeInfo(true,res=>{
          if(res){
            this.applyChange(true)
          }
        })
      },
      // 处理弹框3需要的参数
      handlerPopupNumObj2(){
        this.popupNumObj2={
          time:'',
          address:this.courseDetail.detailedProcess,
          distance:this.courseDetail.distance + 'km',
        }
        if(this.courseDetail.workingPeriod==0){
          this.popupNumObj2.time='不限'
        }else{
          this.popupNumObj2.time = this.formatDate(this.courseDetail.workDateBegin) + '-' + this.formatDate(this.courseDetail.workDateEnd)
        }

      },
      formatDate(data) { //时间戳的过滤
				var date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
				return h + m;
			},
      submitBm(){
        this.applyChange(true)
      },
      // 报名
      applyChange(bool){//从打开的弹框进入
        let obj=this.isResumeInfo()
        console.log(obj,this.resume)
        if(obj.education || obj.usex || obj.uold){
          let obj2={}
          let obj3={}
          if(this.courseDetail.education===0){
            obj2.edu='其他'
            obj3.edu='0'
          }else if(this.courseDetail.education==3){
            obj2.edu='高中'
            obj3.edu='3'
          }else if(this.courseDetail.education==4){
            obj2.edu='大专'
            obj3.edu='4'
          }else if(this.courseDetail.education==5){
            obj2.edu='本科及以上'
            obj3.edu='5'
          }
          if(this.courseDetail.userOld){
            let dUold =JSON.parse(this.courseDetail.userOld);
            obj2.date=`${dUold[0]}岁~${dUold[1]<99?dUold[1]+'岁':'不限'}`
            obj3.date=dUold[0]
          }
          if(this.courseDetail.userSex=='0'){
            obj2.sex='男'
            obj3.sex='0'
          }else if(this.courseDetail.userSex=='1'){
            obj2.sex='女'
            obj3.sex='1'
          }
          this.popupNumObj={
            obj,
            obj2,
            obj3,
          }
          this.popupNum=2
          this.$nextTick(()=>{
            this.$refs.jobMask.maskSwitch(true)
          })
        }else if(bool){
          this.loadPage=true
          this.$axios({
            method:"POST",
            url: "/app/position/tikTok/dontPayApply?positionId="+this.positionId + "&positionTitle=" + this.courseDetail.positionTitle,
            needPrivate:true,
          }).then((res)=>{
            this.loadPage=false
            if(res.code == 200 || res.code==500&&res.data&&res.data.content&&res.data.content.indexOf('当前职位已报名')!=-1){
              if(res.code==200){
                this.$common.sendLogH5('apply',{message:this.courseDetail})//埋点
              }
              this.linkSuccess()
            }else if(res.code==204){
              this.offline=true
              this.mydata=JSON.stringify({content:res.msg})
              this.showError=true
            }else{
              this.mydata=JSON.stringify(res.data)
              this.showError=true
            }
          })
        }else{
          // 打开温馨提示弹框
          this.popupNum=3
          this.$nextTick(()=>{
            this.$refs.jobMask.maskSwitch(true)
          })
        }
      },
      // 跳转失败页面
      toLinkError(){
        this.$router.push({name:'results-m',params:{mydata:this.mydata}})
      },
      // 跳转成功页面
      linkSuccess(){
        this.$router.push({name:'CourseSuccess-m',params:{id:this.positionId,ptype:1}})
      },
      // 推荐&大家都在看
      checkOut(i){
        if (this.chooseTj == i) {
					return false;
				}
        this.chooseTj=i
        this.taskInfo.pageNum = 1 //初始化每页条数
				this.taskArray = [] //初始化
        if (this.chooseTj == 0) {
					this.taskInfo.queryTypes[0] = '1'
					this.taskInfo.queryTypes[1] = '0'
				} else {
					this.taskInfo.queryTypes[0] = '0'
					this.taskInfo.queryTypes[1] = '1'
				}
				this.getTaskList()
      },
			// 查询任务列表
			getTaskList() {
        this.taskLoad=true
        this.$axios({
          method:'POST',
          url:'/app/home/getLessonRecommendList',
          params:{
            pageNum:this.taskInfo.pageNum,
          },
          data:this.taskInfo,
        }).then(res=>{
          this.taskLoad=false
          if(res.code==200){
            if(this.taskInfo.pageNum == 1){
              this.taskArray = res.rows
            }else{
              this.taskArray.push(...res.rows)
            }
          }
        })
			},
      // 获取已报名的头像和人数
      getApplyList() {
        this.$axios({
          method:'POST',
          url:'/app/home/getApplyList?positionId=' + this.positionId
        }).then((res) => {
          if (res.code == 200) {
            this.avatarList = res.data.list
            this.avatarSize = res.data.size
          } else {
            this.avatarList = []
            this.avatarSize = 0
          }
        })
      },
      // 简历查询
      getUserResumeInfo(bool,callback) {
        this.$common.showLoading()
        this.$axios({
          method:'POST',
          url:'/app/userResume/getUserResInfo'
        }).then(res=>{
          this.$common.hideLoading()
          if (res.code == 200) {
            this.resume = res.data
            if(bool){
              callback(true)
            }
          } else {
            if(bool){
              callback(false)
            }
            this.resume = {}
          }
        })
      },
      // 是否有没填简历
      isResumeInfo() {
        let data = this.courseDetail
        let info = this.resume
        let obj={
          education:false,
          usex:false,
          uold:false,
        }
        //学历判断 true为显示弹窗
        if (data.education > -1 && !info.educationBackground) {
          obj.education=true
        }
        //性别
        if ((data.userSex == 1 || data.userSex == 0) && !info.gender) {
          obj.usex=true
        }
        //年龄
        let dUold = JSON.parse(data.userOld);
        if (dUold&&dUold.length>0 && !info.age) {
          obj.uold=true
        }
        return obj
      },
      // 收藏
      hasCollect(){
        if(this.isCollect){//已收藏准备要取消收藏
          this.$axios({
            method:'POST',
            url:'/app/collect/deList',
            params:{
              ids:this.positionId,
              collectStatus:'1',
            }
          }).then(res=>{
            if(res.code==200){
              this.isCollect=false
            }
          })
        }else{//未收藏准备要收藏
          this.$axios({
            method:'POST',
            url:'/app/collect/addList',
            params:{
              positionId:this.positionId,
              positionTitle:this.courseDetail.positionTitle,
            }
          }).then(res=>{
            if(res.code==200){
              this.isCollect=true
            }
          })
        }
      },
      // 跳转企业详情
      toLink(){
        let et = this.courseDetail.enterpriseVo
        let obj = {
          id: this.courseDetail.enterpriseId,
          name: et ? et.enterpriseName : '',
          logo: et ? et.enterpriseLogo : ''
        }
        this.$router.push({name:'company-m',params:obj})
      },
      // 跳转求职保障
      toLink2(){
        this.$router.push('/needJob-m')
      },
      // 线下点击地图
      clickMap(){
        let obj = {
          lgt:this.courseDetail.longitude,
          lat:this.courseDetail.latitude,
        }
        this.$router.push({name:'getAmap-m',params:obj})
      },
    },
    beforeDestroy(){
      window.removeEventListener('resize',this.resizeChange)
    },
    filters:{
      setage(e) {
        if(!e){
          return '--';
        }
        let dUold =JSON.parse(e);
        let str=`${dUold[0]}岁~${dUold[1]<99?dUold[1]+'岁':'不限'}`
        return str  
      },
      setedu(e){
        let str='不限'
        if(e===0){
          str='其他'
        }else if(e==3){
          str='高中'
        }else if(e==4){
          str='大专'
        }else if(e==5){
          str='本科及以上'
        }
        return str
      },
      formatDate(data) { //时间戳的过滤
				var date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
				return h + m;
			},
      handlerAdress(e,data){
        if(!e){
          return ''
        }else{
          let reg = new RegExp(data)
          let i = e.search(reg)
          if(i>-1){
            return e.slice(data.length)
          }else{
            return e
          }
        }
      },
    },
    // watch: {
    //   '$route'(newval,oldval) {
    //     if(newval.path == '/taskDetail-m' || newval.path == '/courseDetail-m'){
    //       if(newval.query.id&&newval.query.id!=oldval.query.id){
    //         this.$nextTick(()=>{
    //           this.$router.go(0)
    //         })
    //       }
    //     }
    //   },
    // },
  }
  </script>
  
  <style scoped>
  *{
    box-sizing: border-box;
  }
  .detailClass{
    padding: 2.75rem 0 4.5rem;
    position: relative;
    background-color: #F5F5F7;
    overflow: hidden;
  }
  /* 顶部返回区域 */
  .topBack{
    width: 100vw;
    height: 2.75rem;
    line-height: 2.75rem;
    text-align: center;
    background-color: #ffffff;
    color: #141419;
    font-size: 1.13rem;
    font-weight: 600;
    font-family: "PingFang SC";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .backIcon{
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
  }
  /* 底部按钮区域 */
  .myBtns{
    width: 100vw;
    height: 4.5rem;
    background-color: #ffffff;
    border-radius: 0.75rem 0.75rem 0 0;
    box-shadow: 0 -0.31rem 1.06rem 0 #0000000d;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.75rem 0 1.13rem;
  }
  .myBtn{
    width: 14.75rem;
    height: 3.25rem;
    line-height: 3.25rem;
    letter-spacing: 0.2rem;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    text-align: center;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: "PingFang SC";
    display: inline-block;
  }
  
  /* 中间内容部分 */
  .courseContent_top_img{
    width: 100vw;
    height: 9.06rem;
  }
  .courseContent_top_img>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .courseContent_top{
    width: 22.14rem;
    /* height: 11.81rem; */
    border-radius: 0.5rem;
    /* border: 0.13rem solid #ffbf0b; */
    background-color: #ffffff;
    margin: 1.25rem auto 0.63rem;
    padding: 0.38rem 0 0.63rem;
    overflow: hidden;
  }
  .baoZhang{
    color: #999999;
    font-size: 0.69rem;
    font-weight: 400;
    font-family: "PingFang SC";
    line-height: 1.25rem;
    margin: 0 0.63rem 0.38rem;
  }
  .baoZhang>img{
    width: 7.17rem;
    height: 2rem;
    vertical-align: middle;
  }
  .baoZhang>span{
    vertical-align: middle;
    margin-left: 0.4rem;
  }
  .detail_title{
    color: #333333;
    font-size: 1.13rem;
    font-weight: 600;
    font-family: "PingFang SC";
    line-height: 1.63rem;
    padding: 0 0.63rem;
  }
  .courseContent{
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .taskMoneys{
    height: 1.8rem;
    line-height: 1.8rem;
    color: #fe0000;
    font-weight: 600;
    font-family: "PingFang SC";
    font-size: 0.9rem;
    padding: 0 0.69rem;
  }
  .tags{
    padding-left: 0.63rem;
  }
  .tags>span{
    height: 1.25rem;
    border-radius: 0.13rem;
    border: 1px solid #999999;
    line-height: calc(1.25rem - 2px);
    text-align: center;
    color: #999999;
    font-size: 0.69rem;
    font-weight: 500;
    font-family: "PingFang SC";
    padding: 0 0.5rem;
  }
  .tags>span:not(:last-child){
    margin-right: 0.5rem;
  }
  .avatorArr>img{
    width: 1.38rem;
    height: 1.38rem;
    border-radius: 50%;
  }
  .baomingDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 0.93rem 0 0.63rem;
    margin-top: 0.2rem;
  }
  .baomingNum{
    color: #999999;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
  }
  .baomingNum>span{
    color: #F4351A;
  }
  
  
  /* 模块标题 */
  .moduleTitle{
    height: 1.41rem;
    line-height: 1.41rem;
    display: inline-block;
    color: #333333;
    font-size: 1rem;
    font-weight: 600;
    font-family: "PingFang SC";
    text-align: left;
    letter-spacing: -0.02rem;
    position: relative;
    margin: 0 0 0.59rem 0.81rem;
  }
  .moduleTitle>span{
    display: inline-block;
  }
  .moduleTitle_fst{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    letter-spacing: 0.05rem;
  }
  .moduleTitle_sed{
    width: 100%;
    height: 0.38rem;
    background: linear-gradient(-90deg, #06ad941a 0%, #07bb7eff 83%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .gangwei{
    width: 22.14rem;
    border-radius: 0.5rem;
    background: #ffffff;
    padding: 0.63rem;
    word-break: break-all;
    position: relative;
    margin: 0 auto 0.63rem;
  }
  .gangwei_sq{
    overflow: hidden;
    max-height: 19.38rem;
  }
  .zkSq{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 6.31rem;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff00 100%);
    line-height: 9rem;
    text-align: center;
    color: #07be77;
    font-size: 0.88rem;
    font-weight: bold;
    font-family: "PingFang SC";
    letter-spacing: 0.05rem;
  }
  .zkSq2{
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 40%, #ffffff00 100%);
  }
  .zkSq3{
    line-height: 10.4rem;
  }
  .zkSq_up{
    text-align: center;
    line-height: 2.4rem;
    color: #07be77;
    font-size: 0.88rem;
    font-weight: bold;
    font-family: "PingFang SC";
    letter-spacing: 0.05rem;
  }
  .zhunbei{
    width: 22.14rem;
    /* height: 13.25rem; */
    border-radius: 0.5rem;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 0.63rem;
    letter-spacing: 0.05rem;
    margin-bottom: 0.63rem;
  }
  .zhunbei_item>div>img{
    width: 0.75rem;
    height: 0.75rem;
    vertical-align: middle;
    margin-right: 0.24rem;
  }
  .zhunbei_item>div>span{
    color: #999999;
    font-size: 0.69rem;
    font-weight: 500;
    font-family: "PingFang SC";
    vertical-align: middle;
  }
  .zhunbei_item>div:last-child{
    padding-left: 0.99rem;
    color: #333333;
    font-size: 0.81rem;
    font-weight: 500;
    font-family: "PingFang SC";
  }
  
  .fuyeClass_outer{
    width: 100vw;
    padding: 0 0.63rem;
    overflow: scroll;
    margin-bottom: 0.63rem;
  }
  .fuyeClass{
    display: flex;
    justify-content: space-between;
  }
  .fuyeClass>img{
    width: 18.75rem;
    height: 6.38rem;
    margin-right: 0.63rem;
  }
  
  .muluClass{
    width: 22.14rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 0.63rem;
    margin: 0 auto 1rem;
    position: relative;
  }
  .muluClass_item{
    height: 2.44rem;
    line-height: 2.34rem;
  }
  .muluClass_item:not(.fst_class){
    border-top: 1px solid #f3f3f3;
  }
  .muluClass_item>span:first-child{
    margin-right: 1.63rem;
  }
  
  .teacherClass{
    width: 22.14rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 0.63rem;
    margin: 0 auto 0.63rem;
  }
  .teacherClass_top{
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 1rem;
    font-weight: 600;
    font-family: "PingFang SC";
    line-height: 1.63rem;
    padding-bottom: 0.63rem;
    border-bottom: 1px solid #F3F3F3;
  }
  .teacherClass_top>img{
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    margin-right: 0.75rem;
  }
  .teacherClass_top>div>img{
    width: 4rem;
    height: 1.13rem;
  }
  .teacherClass_bm{
    margin-top: 0.63rem;
    color: #999999;
    font-size: 0.69rem;
    font-weight: 500;
    font-family: "PingFang SC";
    text-align: left;
    line-height: 1.18rem;
  }
  .googsi{
    width: 22.14rem;
    height: 4rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    background-color: #ffffff;
    margin: 0 auto 0.63rem;
    padding: 0.63rem;
    position: relative;
  }
  .googsi>img{
    width: 2.76rem;
    height: 2.76rem;
    border-radius: 50%;
    margin-right: 0.55rem;
  }
  .googsi_r_cont{
    width: 14.38rem;
    height: 1.25rem;
    color: #999999;
    font-size: 0.63rem;
    font-weight: 600;
    font-family: "PingFang SC";
    line-height: 1.25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .googsi_r_cont_renzheng{
  color: #999999;
  font-size: 0.63rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.25rem;
}
.googsi_r_cont_renzheng>img{
  vertical-align: middle;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
}
.googsi_r_cont_renzheng>span{
  vertical-align: middle;
}

  
  .tuijianClass{
    color: #666666;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    letter-spacing: -0.02rem;
    padding-left: 0.63rem;
    margin-bottom: 1rem;
  }
  .tuijianClass>div{
    display: inline-block;
    padding-bottom: 0.3rem;
    margin-right: 1.03rem;
  }
  .tuijianClass_spanCheck{
    color: #07BB7E;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    position: relative;
  }
  .tuijianClass_spanCheck>span:last-child{
    width: 100%;
    height: 0.25rem;
    border-radius: 0.13rem;
    opacity: 1;
    background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .collectBtn{
    width: 2.6rem;
    text-align: center;
    display: inline-block;
    color: #000000;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: "PingFang SC";
    vertical-align: middle;
  }
  .collectBtn>img{
    width: 1.56rem;
    height: 1.56rem;
  }
  .elLoadingdiv{
    height: 3rem;
    line-height: 2rem;
    text-align: center;
    color: #999999;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: "PingFang SC";
  }
  .workModule{
    width: 22.14rem;
    height: 14.63rem;
    border-radius: 0.75rem;
    background-color: #ffffff;
    margin: 0 auto 0.63rem;
    padding: 0.63rem;
  }
  .workModule_fst{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
.workModule_fst>div:first-child{
  color: #000000;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  line-height: 1.25rem;
}
.workModule_fst_r{
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  line-height: 1.25rem;
}
.workModule_sed{
  /* width: 16rem; */
  color: #000000;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: left;
  line-height: 1.25rem;
}
.workModule_thd{
  width: 20.63rem;
  height: 10rem;
  margin-top: 0.5rem;
}
.workModule_thd>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.myBtn2{
  width: 14.75rem;
  height: 3.25rem;
  line-height: 3.25rem;
  letter-spacing: 0.2rem;
  border-radius: 0.75rem;
  background-color: #ffffff;
  text-align: center;
  color: #06AD94;
  font-size: 1.25rem;
  font-family: "PingFang SC";
  display: inline-block;
  border: 1px solid #06AD94;
}
.noMoreClass{
  height: 2rem;
  text-align: center;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}

.vRrrorPopup{
  background-color: transparent !important;
}
.errorMaskClass{
  width: 20.13rem;
  height: 14.69rem;
  position: relative;
}
.errorMaskClass>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.errorMaskClass_div{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.errorMaskClass_div>div:first-child{
  height: 11.66rem;
  width: 100%;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: 0.06rem;
  text-align: center;
  padding-top: 6.4rem;
}
.errorMaskClass_div>div:first-child>.error_cont{
  font-size: 1.25rem;
}
.errorMaskClass_div>div:last-child{
  width: 100%;
  height: 3.03rem;
  line-height: 3.03rem;
  text-align: center;
  color: #07BE77;
  font-size: 1.38rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: 1px;
}
</style>