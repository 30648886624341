<template>
  <!-- 弹窗汇总 -->
<div>
  <van-popup v-model="maskShow" round position="bottom" :safe-area-inset-bottom="true">
    <!-- 首页筛选弹框 -->
    <template v-if="popupNum==1">
      <div class="maskOne">
        <div class="mask_top_one mask_top">
          <span>筛选</span>
          <div class="maskClose" @click="maskSwitch(false)"><i class="el-icon-close"></i></div>
        </div>
        <div class="maskCont">
          <div class="maskCont_left">
            <div v-for="(item,index) in maskOneItems" :key="index" @click="oneLChoose(index,item.type)" :class="chooseIndex==index?'maskCont_left_checkout':''">
              {{ item.text }}
            </div>
          </div>
          <div class="maskCont_right">
            <div class="maskCont_right_sex" v-if="maskOneItems[chooseIndex].type==1">
              <span :class="sex==item.dictValue?'chooseSex':''" v-for="(item,index) in sexList" :key="index" @click="chooseSex(item)">
                {{ item.dictLabel | handlerSex }}
              </span>
            </div>
            <div class="maskCont_right_job" v-else-if="maskOneItems[chooseIndex].type==2">
              <div class="maskCont_right_job_fst">
                <div :class="positionFirst==item.id?'chooseFst':''" v-for="(item,index) in positionTypeList" :key="index" @click="chooseFirstPositionType(item)">
                  {{ item.name }}
                </div>
              </div>
              <div class="maskCont_right_job_sed hideScroll">
                <span :class="positionSecond==item.id?'chooseSed':''" v-for="(item,index) in positionSecondList" :key="index" @click="chooseSecondPositionType(item)">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mask_bottom mask_bottom_sy">
          <div class="mask_btn1" @click="reset">重置</div>
          <div class="mask_btn2" @click="confirm">确定</div>
        </div>
      </div>
    </template>
    <!-- 详情简历不完整弹框 -->
    <template v-else-if="popupNum==2">
      <div class="maskOne" style="padding: 5.13rem 0 6rem;">
        <div class="maskClose maskClose_One" @click="maskSwitch(false)">
          <i class="el-icon-close"></i>
        </div>
        <div class="mask_top mask_top_jianli" :style="`background: url(${require('@/assets/images/h5/detail/jianliBacc.png')}) no-repeat center/cover;`">
          <!-- 头像 -->
          <div class="mask_top_jsAvator">
            <img src="@/assets/images/h5/detail/teacherAvator.png" alt="讲师头像">
            <span :style="`background: url(${require('@/assets/images/h5/detail/tag2.png')}) no-repeat center/cover;`">{{ tagName }}</span>
          </div>
          <div class="mask_top_msgs">
            <div>{{ tagName=='兼职'?'商家':'老师' }}需要你确认信息</div>
            <div>诚实守信是一个人的美德哦~</div>
          </div>
        </div>
        <div v-if="checkJl==1" class="maskCont2" style="margin-bottom: 2.94rem;">
          <div class="maskCont2_title">兼职网提醒</div>
          <div class="maskCont2_card" style="margin-bottom: 1.63rem;">
            {{ tagName=='兼职'?'报名后请及时联系商家哦':'报名后需要联系老师，学习课程提升技能哦~' }}
          </div>
          <div class="maskCont2_title">确认信息</div>
          <div v-show="popupNumObj.obj&&popupNumObj.obj.uold" class="maskCont2_card maskCont2_card_two">
            <span class="maskCont2_card_dot"></span>
            <span class="maskCont2_card_tt">年龄</span>
            <span class="maskCont2_card_cont">{{ popupNumObj.obj2.date }}</span>
          </div>
          <div v-show="popupNumObj.obj&&popupNumObj.obj.usex" class="maskCont2_card maskCont2_card_two">
            <span class="maskCont2_card_dot"></span>
            <span class="maskCont2_card_tt">性别</span>
            <span class="maskCont2_card_cont">{{ popupNumObj.obj2.sex }}</span>
          </div>
          <div v-show="popupNumObj.obj&&popupNumObj.obj.education" class="maskCont2_card maskCont2_card_two">
            <span class="maskCont2_card_dot"></span>
            <span class="maskCont2_card_tt">学历</span>
            <span class="maskCont2_card_cont">{{ popupNumObj.obj2.edu }}</span>
          </div>
        </div>
        <div v-else class="maskCont2Two">
          <div class="maskCont2_title">确认信息<span>确认后暂无法修改哦</span></div>
          <div v-show="popupNumObj.obj&&popupNumObj.obj.uold" class="maskCont2_card maskCont2Two_date" @click="openDate">
            <span>{{ JlMsg.date?JlMsg.date:'请选择出生年月' }}</span>
            <span style="color: #c2c2c2;font-size: 1rem;"><i class="el-icon-arrow-right"></i></span>
          </div>
          <div v-show="popupNumObj.obj&&popupNumObj.obj.usex" class="maskCont2Two_sexEdu">
            <span>性别</span>
            <div>
              <span class="sexEduSpan" v-for="(item,index) in JlsexList" :key="index" :class="item.value==JlMsg.sex?'sexEduSpan_check':''" @click="JlMsg.sex=item.value">
                {{ item.name }}
              </span>
            </div>
          </div>
          <div v-show="popupNumObj.obj&&popupNumObj.obj.education" class="maskCont2Two_sexEdu">
            <span>学历</span>
            <div>
              <span class="sexEduSpan" :style="item.value=='5'?'width:5.13rem;':''" v-for="(item,index) in JleduList" :key="index" :class="item.value==JlMsg.edu?'sexEduSpan_check':''"   @click="JlMsg.edu=item.value">
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div v-show="checkJl==1" class="mask_bottom mask_bottom_jl">
          <div @click="perfectMsg">完善信息</div>
          <div @click="confirmBm">符合并确认报名</div>
        </div>
        <div v-show="checkJl==2" class="mask_bottom mask_bottom_jl2" :class="JlMsgRule.sex&&JlMsgRule.edu&&JlMsgRule.date?'mask_bottom_jl2_check':''">
          <div @click="submitJl">提交并确认报名</div>
        </div>
      </div>
    </template>
    <!-- 线下兼职提示弹框 -->
    <template v-else-if="popupNum==3">
      <div class="maskOne" style="padding: 5.13rem 0 6rem;" v-if="popupNumObj2">
        <div class="maskClose maskClose_One"><i class="el-icon-close"></i></div>
        <div class="mask_top mask_top_three">
          温馨提示
        </div>
        <div class="maskCont3">
          <template>
            <div class="maskCont3_title">工作时段</div>
            <div class="maskCont3_cont mb16rem">{{ popupNumObj2.time }}</div>
          </template>
          <template>
            <div class="maskCont3_title">工作地点</div>
            <div class="maskCont3_cont mb16rem">
              <div>{{ popupNumObj2.address }}</div>
              <div style="margin-top: 0.31rem;color: #F95536;font-size: 0.75rem;">距我{{ popupNumObj2.distance }}</div>
            </div>
          </template>
          <template>
            <div class="maskCont3_title">安全小贴士</div>
            <div class="maskCont3_cont">兼职过程中请拒绝向商家缴纳任何费用</div>
          </template>
        </div>
        <div class="mask_bottom mask_bottom_three">
          <div @click="submitBm">确认报名</div>
        </div>
      </div>
    </template>
    <van-popup v-model="showVanPop" position="bottom" safe-area-inset-bottom z-index="999">
      <van-datetime-picker
        @cancel="dateCancel"
        @confirm="dateConfirm"
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
      ></van-datetime-picker>
    </van-popup>
  </van-popup>
</div>
</template>

<script>
export default {
  props: {
    popupNum: {
      type: Number,
      default: 1
    },
    // 弹框2需要的参数
    popupNumObj: {
      type: Object
    },
    // 弹框3需要的参数
    popupNumObj2: {
      type: Object
    },
    // 兼职课程tag
    tagName: {
      type: String,
      default:'兼职'
    },
  },
  data() {
    return {
      maskShow: false,
      chooseIndex:0,
      maskOneItems:[
        // {text:'工作区域',id:0},
        {text:'性别要求',type:1},
        {text:'职位类型',type:2},
      ],
      sexList:[],
      positionTypeList:[],//一二级职位
      positionSecondList:[],//二级职位
      positionFirst:'',//选中的一级
      positionSecond:null,//选中的二级
      sex:'',//选中的性别
      checkJl:1,//简历弹框切换一二级页
      JlsexList:[
        {name:'男',value:'0'},
        {name:'女',value:'1'},
        {name:'未知',value:'2'},
      ],
      JleduList:[
        {name:'高中',value:'3'},
        {name:'大专',value:'4'},
        {name:'本科及以上',value:'5'},
        {name:'其他',value:'0'},
      ],
      JlMsg:{
        sex:'',
        edu:'',
        date:''
      },
      JlMsgRule:{
        sex:false,
        edu:false,
        date:false
      },
      minDate: new Date(1949, 10, 1),
      maxDate: new Date(),
      currentDate:new Date(),
      showVanPop:false,
      resumeInfo:{},//简历初始信息
    }
  },
  watch: {
    'JlMsg.sex':{
      handler(newval){
        this.JlMsgRule.sex=!!newval
      }
    },
    'JlMsg.edu':{
      handler(newval){
        this.JlMsgRule.edu=!!newval
      }
    },
    'JlMsg.date':{
      handler(newval){
        this.JlMsgRule.date=!!newval
      }
    },
  },
  created () {
    if(this.popupNum!=1){
      this.getUserResumeInfo()
    }else{
      // this.getRightList(this.maskOneItems[this.chooseIndex].type);
    }
  },
  methods: {
    // 弹框3确认报名
    submitBm(){
      this.$emit('submitBm',true)
    },
    // 弹框2完善简历信息
    perfectMsg(){
      this.checkJl=2
    },
    // 弹框2符合并确认报名
    confirmBm(){
      let obj3 = this.popupNumObj.obj3
      if(obj3.edu){
        this.objAssign({educationBackground:obj3.edu})
      }
      if(obj3.sex){
        this.objAssign({gender:obj3.sex})
      }
      if(obj3.date){
        let date=new Date()
        let year = date.getFullYear() - obj3.date
        let month = date.getMonth()+1
        if(month<10){
          month = '0' + month
        }
        this.objAssign({age:year+'-'+month})
      }
      this.saveUserResInfo(res=>{
        if(res){
          this.maskSwitch(false)
          // 简历已提交，进行报名
          this.$emit('submitJlMb',true)
        }
      })
    },
    // 弹框2提交简历信息
    submitJl(){
      if(this.JlMsgRule.sex && this.JlMsgRule.edu && this.JlMsgRule.date){
        if(this.popupNumObj.obj.education){
          this.objAssign({educationBackground:this.JlMsg.edu})
        }
        if(this.popupNumObj.obj.usex){
          this.objAssign({gender:this.JlMsg.sex})
        }
        if(this.popupNumObj.obj.uold){
          this.objAssign({age:this.JlMsg.date})
        }
        this.saveUserResInfo(res=>{
          if(res){
            this.maskSwitch(false)
            this.$nextTick(()=>{
              // 简历已提交，进行报名
              this.$emit('submitJlMb',true)
            });
          }
        })
      }
    },
    // 向对象添加修改参数
    objAssign(obj){
      Object.assign(this.resumeInfo, obj)
    },
    // 简历修改
		saveUserResInfo(callback) {
      this.$axios({
        method:'POST',
        url:'/app/userResume/saveUserResInfo',
        data:this.resumeInfo,
        needPrivate:true,
      }).then(res=>{
        if(res.code==200){
          callback(true)
        }else{
          this.$toast(res.msg)
          callback(false)
        }
      })
		},
    // 简历查询
		getUserResumeInfo() {
      this.$common.showLoading()
      this.$axios({
        method:'POST',
        url:'/app/userResume/getUserResInfo'
      }).then(res=>{
        this.$common.hideLoading()
        if (res.code == 200) {
					this.resumeInfo = res.data
          console.log(this.resumeInfo,'简历查询')
				} else {
					this.resumeInfo = {}
				}
      })
		},
    // 打开日期
    openDate(){
      this.showVanPop=true
    },
    // 取消
    dateCancel(){
      this.showVanPop=false
    },
    // 确定按钮
    dateConfirm(e){
      let date=new Date(e)
      let year=date.getFullYear()
      let month=date.getMonth()+1
      let day=date.getDate()
      if(month<=9){month='0'+month}
      if(day<=9){day='0'+day}
      this.JlMsg.date=year+'-'+month
      this.showVanPop=false
    },
    // 重置按钮
    reset(){
      this.positionFirst=''
      this.positionSecond=null
      this.sex=''
      this.maskSwitch(false)
      this.$emit('reset',true)
    },
    // 确定按钮
    confirm(){
      let obj={
        pSex: this.sex,
        levelId: this.positionSecond || this.positionFirst,
        positionSecond: this.positionSecond,
        positionFirst: this.positionFirst,
        level: this.positionSecond ? 2 : 1
      }
      this.maskSwitch(false)
      this.$emit('comfirm',obj)
    },
    // 弹框开关
    maskSwitch(bool) {
      if(this.popupNum==2&&bool){
        this.checkJl=1
        this.JlMsg={
          sex:'',
          edu:'',
          date:''
        }
        this.JlMsgRule={
          sex:!this.popupNumObj.obj.usex,
          edu:!this.popupNumObj.obj.education,
          date:!this.popupNumObj.obj.uold
        }
        this.currentDate=new Date()
      }
      this.maskShow=bool
    },
    // 弹框1左侧选中
    oneLChoose(i,type){
      this.chooseIndex=i
      // this.getRightList(type)
    },
    // 获取性别
    getRightList(type=1){
      this.$axios({
        method:'POST',
        url:'/app/home/getHomeScreen',
        data:{
          screenType:type
        }
      }).then(res=>{
        if(res.code==200){
          if(type==1){
            this.sexList = [{
              dictLabel: "不限",
              dictValue: "-1"
            }, ...res.data]
          }else if(type==2){
            let arr = res.data.map(item => {
              item.childrenList.unshift({id:'',name:'不限'})
              return item
            })
            this.positionTypeList = arr
          }
        }
      })
    },
    // 选中一级职位
    chooseFirstPositionType(item){
      if(!item){
        return false;
      }
      this.positionFirst = item.id
      this.positionSecondList = item.childrenList
      this.positionSecond = ''
    },
    // 选中二级职位
    chooseSecondPositionType(item){
      this.positionSecond = item.id
    },
    // 选中性别
    chooseSex(item) {
      this.sex = item.dictValue
    },
  },
  filters: {
    handlerSex(val){
      if(val != '不限'){
        return val + '生可做'
      }else{
        return val
      }
    }
  }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.maskOne{
  position: relative;
  padding: 4.5rem 0 4rem;
}
.mask_top{
  width: 100%;
  height: 4.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.mask_bottom{
  width: 100%;
  height: 4rem;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.maskClose{
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  display: inline-block;
}
.maskClose_One{
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  font-size: 1.8rem;
  color: #c3bfc3;
  z-index: 10;
}

.mask_top_one{
  line-height: 4.5rem;
  text-align: center;
  color: #323232;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.63rem;
}

.maskCont{
  height: 20rem;
  padding-left: 6.25rem;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
.maskCont_left{
  width: 6.25rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.maskCont_left>div{
  width: 4.75rem;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
  border-radius: 0.3rem;
  background-color: #f5f8ff;
  color: #5e5e5e;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  margin: 0 auto;
}
.maskCont_left>.maskCont_left_checkout{
  background-color: #ffffff;
  color: #383838;
}
.mask_bottom_sy{
  display: flex;
  justify-content: space-between;
  padding: 0 0.69rem;
  align-items: center;
}
.mask_bottom_sy>div{
  height: 3.25rem;
  line-height: 3.25rem;
  text-align: center;
  border-radius: 0.75rem;
  font-weight: 600;
  font-family: "PingFang SC";
  letter-spacing: 1px;
}
.mask_btn1{
  width: 7.88rem;
  background-color: #f5f7ff;
  color: #363839;
  font-size: 1.1rem;
}
.mask_btn2{
  width: 13.81rem;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  color: #ffffffff;
  font-size: 1.2rem;
}

/* 首页弹框职位选中 */
.maskCont_right{
}
.maskCont_right_job{
  display: flex;
}
.maskCont_right_job_fst{
  width: 5.2rem;
  height: 20rem;
  color: #5e5e5e;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  overflow-y: scroll;
  padding: 0 0 1rem;
  text-align: center;
}
.maskCont_right_job_fst>div{
  margin-bottom: 1rem;
  padding-right: 0.6rem;
}
.maskCont_right_job_sed{
  width: calc(100% - 5.2rem);
  height: 20rem;
  overflow-y: scroll;
  display: inline-grid;
  grid-template-columns:repeat(2,1fr);
  grid-gap:0.4rem;
  align-content: flex-start;
  padding: 0 0.2rem 1rem;
}
.maskCont_right_job_sed>span{
  width: 5rem;
  height: 2.42rem;
  line-height: calc(2.42rem - 2px);
  text-align: center;
  border: 1px solid #f4faff;
  color: #848589;
  background-color: #f4faff;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  border-radius: 0.44rem;
}
.maskCont_right_sex>span{
  width: 5.13rem;
  height: 2.44rem;
  line-height: 2.44rem;
  text-align: center;
  border-radius: 0.44rem;
  background-color: #F5F7FF;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  border-radius: 0.44rem;
  color: #88898f;
  display: inline-block;
  margin-right: 0.31rem;
}
.maskCont_right_sex>.chooseSex{
  background-color: #07bd77;
  color: #ffffff;
}
.chooseFst{
  color: #07bd77;
}
.maskCont_right_job_sed>.chooseSed{
  background-color: #ffffff;
  border: 1px solid #62c6ae;
  color: #62c6ae;
}
/* 首页弹框职位选中结束 */
/* 简历弹框开始 */
.mask_top_jsAvator{
  width: 3.63rem;
  height: 3.63rem;
  border-radius: 50%;
  position: relative;
  margin-right: 0.69rem;
}
.mask_top_jsAvator>img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.mask_top_jsAvator>span{
  width: 2.13rem;
  height: 1.13rem;
  line-height: 1.13rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.63rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  position: absolute;
  bottom: -0.38rem;
  right: 0;
}
.mask_top_msgs>div:first-child{
  color: #000000;
  font-size: 0.88rem;
  font-weight: 600;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  margin-bottom: 0.5rem;
}
.mask_top_msgs>div:last-child{
  color: #ababab;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}
.mask_top_jianli{
  height: 5.13rem;
  display: flex;
  align-items: center;
  padding-left: 0.81rem;
}
.maskCont2{
  padding: 1.13rem 0.75rem 0;
}
.mask_bottom_jl{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem;
  bottom: 2rem;
}
.mask_bottom_jl>div{
  width: 8.19rem;
  height: 3.25rem;
  line-height: 3.25rem;
  text-align: center;
  border-radius: 0.75rem;
  background: #F1F4F5;
  color: #414557ff;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "PingFang SC";
}
.mask_bottom_jl>div:last-child{
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  color: #ffffff;
  width: 12.5rem;
}
.maskCont2_title{
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  margin-bottom: 0.94rem;
}
.maskCont2_card{
  width: 21.94rem;
  height: 2.75rem;
  line-height: 2.75rem;
  border-radius: 0.38rem;
  background: #f1f4f5;
  color: #2f333e;
  font-size: 0.88rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  padding: 0 0.5rem;
}
.maskCont2_card_two{
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}
.maskCont2_card_dot{
  width: 0.38rem;
  height: 0.38rem;
  border-radius: 50%;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  display: inline-block;
}
.maskCont2_card_tt{
  color: #8c8c8c;
  font-size: 0.88rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  margin: 0 0.94rem 0 0.56rem;
}
.maskCont2_card_cont{
  color: #2f333e;
  font-size: 0.88rem;
  font-weight: 600;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}
.mask_bottom_jl2{
  bottom: 2rem;
  display: flex;
  align-items: center;
}
.mask_bottom_jl2>div{
  width: 21.44rem;
  height: 3.25rem;
  line-height: 3.25rem;
  text-align: center;
  border-radius: 0.75rem;
  background: #dce0e9;
  margin: 0 auto;
  color: #ffffff;
  font-size: 1.25rem;
  font-family: "PingFang SC";
  letter-spacing: 1px;
}
.maskCont2_title>span{
  color: #9d9d9d;
  font-size: 0.88rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  margin-left: 0.56rem;
}
.maskCont2Two{
  padding: 1.17rem 0.75rem 0;
  margin-bottom: 2.75rem;
}
.maskCont2Two_sexEdu{
  padding-left: 4rem;
  margin: 0.75rem 0;
  position: relative;
}
.maskCont2Two_sexEdu>span{
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
  font-family: "PingFang SC";
}
.sexEduSpan{
  width: 4.25rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.25rem;
  text-align: center;
  display: inline-block;
  background: #F2F2F2;
  color: #CCCCCC;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.88rem;
  font-weight: 600;
  font-family: "PingFang SC";
}
.sexEduSpan_check{
  color: #ffffff;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
}
.maskCont2Two_date{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mask_bottom_jl2_check>div{
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  color: #ffffff;
}
/* 简历弹框结束 */
/* 线下兼职提示框 */
.mask_top_three{
  height: 4rem;
  line-height: 4rem;
  color: #333333;
  font-size: 1.13rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: center;
}
.maskCont3{
  padding: 0.6rem 0.81rem;
}
.mb16rem{
  margin-bottom: 1.6rem;
}
.maskCont3_title{
  color: #333333;
  font-size: 0.88rem;
  font-weight: 600;
  font-family: "PingFang SC";
  margin-bottom: 0.31rem;
}
.maskCont3_cont{
  color: #999999;
  font-size: 0.88rem;
  font-weight: 500;
  font-family: "PingFang SC";
}
.mask_bottom_three{
  bottom: 2rem;
  display: flex;
  align-items: center;
}
.mask_bottom_three>div{
  width: 21.56rem;
  height: 3.13rem;
  line-height: 3.13rem;
  text-align: center;
  border-radius: 0.75rem;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  text-align: center;
  letter-spacing: 1px;
  margin: 0 auto;
}
/* 线下兼职提示框结束 */
</style>