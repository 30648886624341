<template>
  <div class="cooperation">
      <div style="position: relative">
          <div id="map"></div>

          <div class="navtop">
              <div style="margin-left: 5px;cursor: pointer;" @click="goHome">
                  首页 <i class="el-icon-arrow-right" style="margin-right: 5px"></i>
              </div>
              <span>
                  万企合作
          </span>
          </div>

          <div style="position: absolute;z-index: 3;top: 0;left: 80px;width: 300px;height: 500px;border-radius: 10px;background: #ffffff;padding: 27px 10px;" v-if="position.id">
              <div style="display: flex">
                <img style="width: 90px;height: 90px;" :src="position.enterpriseLogo">
                <div style="width: 180px; padding-left: 10px;display: flex;flex-direction: column;justify-content: space-between;flex: 1">
                    <div style="color: #333333; font-size: 14px; font-weight: 500;" class="oneLineText">
                        {{ position.enterpriseName }}
                    </div>
                    <div style="color: #3f3d3d; font-size: 10px;" class="oneLineText">
                        {{ getEnterpriseType(position.enterpriseType) }} | {{position.employeeNumber}}
                    </div>
                    <div style="color: #000000; font-size: 10px;" class="oneLineText">
                        距您<span style="color:#07B090;">{{ position.distance }}</span>km
                    </div>
                    <div style="color: #8D8D8D; font-size: 10px;" class="oneLineText">
                        {{ position.companyRegisteredAddress }}
                    </div>
                </div>
              </div>
              <div style="display: flex; align-items: center;margin: 10px 0;">
                  <div style="height: 1px; background: #d9d9d9; flex: 1"></div>
                  <div style=" color: #000000; font-size: 16px; font-weight: 700;padding: 0 10px">职位</div>
                  <div style="height: 1px; background: #d9d9d9; flex: 1"></div>
              </div>
              <div style="overflow-y: scroll;height: 330px;" class="wrapper" v-if="mapPositionList.length>0">
                  <div style="background: #F7F7F7;border-radius: 5px;padding: 10px;display: flex;flex-direction: column;justify-content: space-between;margin-bottom: 10px;cursor: pointer" v-for="item in mapPositionList" :key="item.id" @click="toPositionDetail(item.id)">
                    <div style="display: flex; justify-content: space-between;margin-bottom: 10px;">
                        <div style="color: #000000; font-size: 14px; font-weight: 500;flex: 1" class="oneLineText">
                            {{ item.positionTitle }}
                        </div>
                        <div style="color:#FE0000;font-size: 14px; font-weight: 600;">
                            {{ item.amountType == 1 ? item.amount/100 : item.amountBegin/100 + '-' + item.amountEnd/100 }}元/{{typeList[item.settlementUnit]}}
                        </div>
                    </div>
                      <div style="display:flex;margin-bottom: 10px;">
                          <div v-for="item in item.tags" style="border-radius: 1px;background: #edededff;font-size: 10px;color:#888888;padding: 2px 5px;margin-right: 5px;zoom: 0.7">
                              {{ item }}</div>
                      </div>
                      <div style="display:flex;color: #818897; font-size: 8px; font-weight: 600;line-height: 18px;margin-bottom: 10px;" v-if="item.distance>-1">
                          <img style="width: 18px;height: 18px;margin-right: 4px;" :src="position.enterpriseLogo">
                          <div style="zoom: 0.8;flex: 1;" class="oneLineText">距您<span style="color:#07BB7E;">{{item.distance}}</span>km {{ item.detailedProcess }}</div>
                      </div>
                      <div style="display: flex; justify-content: space-between">
                          <div style="display:flex;color: #333333; font-size: 8px; font-weight: 600;line-height: 18px">
                              <img style="width: 18px;height: 18px;margin-right: 4px;" :src="position.enterpriseLogo">
                              <div style="zoom: 0.8">{{ item.enterprise.enterpriseName }}</div>
                          </div>
                          <div style="width: 50px;height: 18px;border-radius: 21.67px;background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);color:#ffffff;text-align: center;line-height: 18px;font-size: 10px">
                              <span style="zoom: 0.8;">查看职位</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="flex flex_item_center" v-else>
                  <img style="width: 200px;height: 200px;margin-top: 20px" src="@/assets/images/pc/position_empty.png" >
              </div>
          </div>
      </div>
<!--    <div class="coop_top">-->
<!--      <img-->
<!--        src="https://shanchuangjiaoyu.oss-cn-beijing.aliyuncs.com/wangqitong/wqbanner.png"-->
<!--        alt="兼职网"-->
<!--        srcset=""-->
<!--      />-->
<!--    </div>-->
<!--    <div class="coop_ms">-->
<!--      &lt;!&ndash; <h2>我们能提供</h2>-->
<!--      <p class="title"></p> &ndash;&gt;-->
<!--      <ul class="coop_ms_t">-->
<!--        <li>-->
<!--          <img src="@/assets/images/pc/wqhz/zygh.png" alt="兼职网" />-->
<!--          <p>职业规划</p>-->
<!--          <span>职业定位-目标规划</span>-->
<!--        </li>-->
<!--        <li>-->
<!--          <img src="@/assets/images/pc/wqhz/yczz.png" alt="兼职网" />-->
<!--          <p>优才直招</p>-->
<!--          <span>技能评估-推荐就业</span>-->
<!--        </li>-->
<!--        <li>-->
<!--          <img src="@/assets/images/pc/wqhz/bjhh.png" alt="兼职网" />-->
<!--          <p>保驾护航</p>-->
<!--          <span>与时俱进-持续迭代</span>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div style="height: 80px; position: relative; top: -80px" id="target"></div>-->
    <div class="coop_list">
      <h3>我们拥有数万家<span style="color: #07bb7e">合作企业</span></h3>
      <div class="enListBox">
        <div
          class="enList"
          v-for="(item, index) in enList"
          :key="item.id"
          :class="enterpriseId - 1 == index ? 'enLists' : ''"
          @click="setEnList(item, index)"
        >
          {{ item.title }}
        </div>


      </div>

      <ul>
        <el-row :gutter="20">
          <el-col
            :md="8"
            :xs="24"
            v-for="(item, index) in compylist"
            :key="index"
          >
            <li>
              <div class="cl_pic">
                <img :src="item.enterpriseLogo" alt="兼职网" srcset="" />
              </div>
              <div class="cl_text">
                <h4 :title="item.company_name">{{ item.enterpriseName }}</h4>
                <p v-html="item.companyRegisteredAddress" class="dsc"></p>
              </div>
            </li>
          </el-col>
        </el-row>
      </ul>

        <div class="ts" v-if="!compylist.length">
            <img src="@/assets/images/pc/noDataT.png" />
        </div>
      <div class="page">
        <el-pagination
          :layout="layout"
          :pager-count="pageCount"
          :page-size="page.pageSize"
          :current-page.sync="page.currentPage"
          :total="page.total"
          @current-change="chagenum"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BScroll from '@better-scroll/core'
import Pullup from '@better-scroll/pull-up'
export default {
  data() {
    return {
      enterpriseId: 1,
      enList: [
        {
          title: "名企直聘",
          id: "1",
        },
        {
          title: "蓝领普工",
          id: "2",
        },
        {
          title: "超市零售",
          id: "3",
        },
        {
          title: "餐饮酒店",
          id: "4",
        },
        {
          title: "电子商务",
          id: "5",
        },
        {
          title: "广告营销",
          id: "6",
        },
        {
          title: "生活服务",
          id: "7",
        },
        {
          title: "互联网",
          id: "8",
        },
        {
          title: "物流仓储",
          id: "9",
        },
        {
          title: "企业服务",
          id: "10",
        },
        {
          title: "教育培训",
          id: "11",
        },
        {
          title: "其他行业",
          id: "12",
        },
      ],
      mapCompanyList: [],
      mapPositionList: [],
      position: {},
      isLoad:true,
      msg:"正在加载",
      compylist: [],
      typeList: ["其他", "小时", "天", "周", "月", "次", "单"],
      layout: "prev, pager, next, jumper",
      page: {
        total: 1, //总条目数
        pageSize: 18, //每页显示条目个数
        currentPage: 1, //当前页数
      },
        positionPage: {
            pageSize: 50, //每页显示条目个数
            currentPage: 1, //当前页数
        },
      pageCount: 5,
      map: null,
    };
  },
  mounted() {
    this.enterpriseId = this.$route.params.id || 1;
    this.getcomylist(this.enterpriseId);
    const that = this;
    this.map = new AMap.Map("map", {
      zoom: 13, //级别
      center: [117.12, 36.65], //中心点坐标
    });
    this.getMapCompanyList();
      this.map.on('moveend', this.mapMoveend);
      this.map.on('movestart', this.mapMovestart);
    return (() => {
      window.screenWidth = document.documentElement.clientWidth; //实时宽度
      window.screenHeight = document.documentElement.clientHeight; //实时高度
      if (window.screenWidth < 500) {
        that.layout = "pager";
      }
    })();
  },
    beforeDestroy() {
        this.map.off('movestart', this.mapMovestart);
        this.map.off('moveend', this.mapMoveend);
    },
    beforeMount() {
    this.$bus.$on("getEnList", (e) => {
      console.log(e);
      let id = e.id;
      this.enterpriseId = id;
      this.getcomylist(id);
    });
  },
  methods: {
      goHome(){
          this.$router.push({
              name:"homePage"
          })
      },
    setEnList(e, i) {
        var m = Math.floor(Math.random()*90) + 10;
      this.enterpriseId = e.id;
      this.page.pageSize = 18; //每页显示条目个数
      this.page.currentPage = 1; //当前页数
      this.getcomylist(this.enterpriseId);
        // this.$router.push({path: '/enterprise/en'+m})
    },
    jdzq() {
      document.getElementById("target").scrollIntoView();
    },

    chagenum(e) {
      // this.jdzq();
      this.page.currentPage = e;
      this.getcomylist(this.enterpriseId);
    },
      getEnterpriseType(type){
        let obj = this.enList.find(item=> item.id == type)
          return obj?.title || '其他行业'
      },
      mapMoveend(){
          console.log()
          this.getMapCompanyList(this.map.getCenter())
      },
      mapMovestart() {
        this.mapPositionList = []
        this.position = {}
      },
    getcomylist(e) {
      this.$axios({
        // baseURL: process.env.VUE_APP_JZWB_URLLICHANGWANG,
        method: "POST",
        url: "/official/enterprise/getCooperationList",
        params: {
          id: e,
          pageSize: this.page.pageSize,
          pageNum: this.page.currentPage,
        },
      })
        .then((res) => {
          console.log("res123=>", res);
          if (res.code == "200") {
            this.compylist = res.rows;
            this.page.total = res.total;
          }
        })
        .catch((error) => {});
    },
      toPositionDetail(id){
        console.log(this.$route.meta.metaInfo.title)
          this.$router.push({
              name: "positionDetail",
              params: {
                  id: id,
                  firstOrder: this.$route.meta.metaInfo.title,
                  type: 'fullTime'
              },
          });
      },
    setMarker(id) {
        this.map.clearMap();

      let markerList = this.mapCompanyList.map((item) => {
        let icon = new AMap.Icon({
          size: item.id == id ? new AMap.Size(66, 80) : new AMap.Size(66, 80),
          image:
            item.id == id
              ? require("@/assets/images/pc/marker_iconT.png")
              : require("@/assets/images/pc/marker_icon.png"),
          imageSize:
            item.id == id ? new AMap.Size(66, 80) : new AMap.Size(33, 40),
          imageOffset:
            item.id == id ? new AMap.Pixel(0, 0) : new AMap.Pixel(16, 40),
        });
        let marker = new AMap.Marker({
          position: new AMap.LngLat(
            Number(item.gcjLongitude),
            Number(item.gcjLatitude)
          ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: item.enterpriseName,
          icon: icon,
          zIndex: item.id == id ? 2 : 1
          // content
        });
        if(item.id == id){
            let position = `<div style="color: #000000ff;
 font-size: 16.67px;
 font-weight: 700;padding-bottom: 10px">${item.offPositionI?.positionTitle} | ${
                item.offPositionI?.amountType == 1
                    ? item.offPositionI?.amount/100
                    : item.offPositionI?.amountBegin/100 +
                    "-" +
                    item.offPositionI?.amountEnd/100
            }元/${this.typeList[item.offPositionI?.settlementUnit]}</div>`

            let enterprise = `<div class="map_enterprise" style="color: #333333;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;">
              <img style="width: 15px;height: 15px;" src="${item.enterpriseLogo}">
              ${item.enterpriseName}
            </div>`
            marker.content = `<div style="background: #FFFFFF; padding: 10px 7px;box-shadow: 0 3.33px 3.33px 0 rgba(149,149,149, 0.4);border-radius: 5px;">
            ${item.offPositionI ? position : ''}
            ${enterprise}
            </div>`;
            let infoWindow = new AMap.InfoWindow({
                isCustom: true,
                content: marker.content,
                offset: item.offPositionI ? new AMap.Pixel(16, 130) : new AMap.Pixel(16, 100)
            });
            infoWindow.open(this.map, marker.getPosition());
        }
        console.log("marker.content", marker.content);
        //给marker添加属性
        marker.setExtData(item);

        //给marker添加点击事件
        marker.on("click", this.markerClick);
        return marker;
      });
      console.log("markerList", markerList);
      this.map.add(markerList);
    },
    //marker点击事件
    markerClick(e) {
      this.setMarker(e.target.getExtData().id);
      this.position = e.target.getExtData()
        console.log(e.target.getExtData());
        // '33afd7a23e14493092fb23dd3a0edc42'
      this.getPositionList(e.target.getExtData().id)
    },
      getPositionList(id){
          this.$axios({
              // baseURL: process.env.VUE_APP_JZWB_URLLICHANGWANG,
              method: "POST",
              url: `/official/enterprise/getCooperationListRPC?pageNum=${this.positionPage.currentPage}&pageSize=${this.positionPage.pageSize}`,
              data: {
                  entId: id,
                  longitude: localStorage.getItem("longitude")
                      ? localStorage.getItem("longitude")
                      : 117.12,
                  latitude: localStorage.getItem("latitude")
                      ? localStorage.getItem("latitude")
                      : 36.65,
              },
          })
              .then((res) => {
                  if (res.code == "200") {
                      // this.$set(this, 'mapPositionList', res.rows)
                      this.mapPositionList = res.rows;
                  }
              })
              .catch((error) => {});
      },
    getMapCompanyList(center) {
        let lat = center ? center.lat : localStorage.getItem("latitude")
            ? localStorage.getItem("latitude")
            : 36.65
            let long = center ? center.lng : localStorage.getItem("longitude")
            ? localStorage.getItem("longitude")
            : 117.12
      this.$axios({
        // baseURL: process.env.VUE_APP_JZWB_URLLICHANGWANG,
        method: "POST",
        url: "/official/enterprise/getCooperationListIII",
        params: {
          itudeType: 2,
          longitude: long,
          latitude: lat,
          distance: 10,
          distanceUnit: 2,
          pageSize: 50,
        },
      })
        .then((res) => {
          console.log("res123=>", res);
          if (res.code == "200") {
            this.mapCompanyList = res.rows;

            this.setMarker(this.position.id);
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
<style scoped>
.coop_top img {
  width: 100%;
  height: 560px;
  object-fit: cover;
}
.coop_ms {
  width: 1200px;
  height: 375px;
  border-radius: 23px;
  background: rgba(255, 255, 255, 1);
  margin: 0 auto;
  padding: 20px 35px;
  margin-top: -330px;
  box-shadow: #f1f1f1 1px 18px 3px;
}
.coop_ms h2 {
  font-size: 36px;
  color: #3d3d3d;
  text-align: center;
}
.coop_ms .title {
  width: 96px;
  height: 15px;
  border-radius: 7.5px;
  background-image: linear-gradient(#ff6000, #ff9c00);
  margin: 0 auto;
  margin-top: 10px;
  box-shadow: #f5f4e0 0px 10px 5px 0px;
}
.coop_ms .coop_ms_t {
  display: flex;
  margin-top: 70px;
}
.coop_ms .coop_ms_t li {
  text-align: center;
  width: 33.33%;
}
.coop_ms .coop_ms_t li img {
  width: 138px;
}
.coop_ms .coop_ms_t li:nth-child(2) img {
  width: 116px;
}
.coop_ms .coop_ms_t li:nth-child(3) img {
  width: 125px;
}
.coop_ms .coop_ms_t p {
  font-size: 30px;
  color: #0c0c0c;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.coop_ms .coop_ms_t span {
  color: #3d3d3d;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.coop_list {
  /* transform: translateY(-220px); */
  margin-bottom: 55px;
    margin-top: 83px;
}
.coop_list h3 {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #333333;
}
.coop_list ul {
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}
.coop_list ul li {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.coop_list ul li .cl_pic {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  overflow: hidden;
}
.coop_list ul li img {
  width: 90px;
  height: 90px;
}
.coop_list ul li .cl_text {
  width: 245px;
}
.coop_list ul li h4 {
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.enListBox {
  width: 1200px;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
}
.enList {
  margin-right: 32px;
  margin-top: 15px;
  color: #707070ff;
  font-size: 18px;
  font-family: "PingFang SC";
  width: 118px;
  text-align: center;
  cursor: pointer;
}
.enList:hover{
    color: #07b190;
}
.enLists {
  color: #07b190;
}
.coop_list ul li .cl_text p {
  width: 245px;
  overflow: hidden;

  -webkit-line-clamp: 2;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;
  font-size: 14px;
  color: #666;
  margin-top: 15px;
  height: 40px;
  /*盒子模型*/

  display: -webkit-box;

  /*显示的文本行数,3行*/

  -webkit-line-clamp: 2;

  /*子元素的垂直排列方式*/

  -webkit-box-orient: vertical;
}
/* 分页样式 */
.page {
  margin: 0 auto;
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}
/deep/.page button {
  /*width: 85px;*/
  /*height: 36px;*/
  /*background-color: #fff !important;*/
}

/deep/.el-pagination.is-background .el-pager li {
  background: #ffffff;
  /*width: 36px;*/
  height: 36px;
  line-height: 36px;
}
/deep/ .el-pager li {
  background: none;
}
/deep/.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination .el-pager li {
  /*box-shadow: #eaeaea 0px 1px 10px 7px;*/
  background: transparent;
}

/deep/.el-pagination .el-pager li:not(.disabled).active,
/deep/.el-pagination .el-pager li:not(.disabled):hover {
  background-color: #00cc88;
  color: #fff;
}
/deep/.el-pagination button:disabled {
  background: none;
}
/deep/.number:hover {
  /*width: 30px;*/
  height: 30px;
  border-radius: 5.94px;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  text-align: center;
  line-height: 30px;
  color: #fff !important;
}
/deep/.el-pager li.active {
  height: 30px;
  border-radius: 5.94px;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  text-align: center;
  line-height: 30px;
  color: #fff !important;
}
/deep/.el-pagination__editor.el-input {
  width: 60px;
}
/deep/.el-pagination__editor.el-input .el-input__inner:focus {
  border-color: #00cc88 !important;
}
/deep/.el-pagination button:hover {
  /*background-color: #00cc88;*/
}
/deep/.number {
  border-radius: 5.94px;
  overflow: hidden;
}
/deep/ .el-pager li {
  margin: 0 2px;
  color: #606266 !important;
}
.el-pagination {
  margin: 0 auto;
  display: flex;
  align-items: center;
}
/*/deep/.page button {*/
/*  width: 85px;*/
/*  height: 36px;*/
/*  background-color: #fff !important;*/
/*}*/

/*/deep/.el-pagination.is-background .el-pager li {*/
/*  background: #ffffff;*/
/*  width: 45px;*/
/*  height: 36px;*/
/*  line-height: 36px;*/
/*}*/

/*/deep/.el-pagination.is-background .btn-next,*/
/*.el-pagination.is-background .btn-prev,*/
/*.el-pagination.is-background .el-pager li {*/
/*  box-shadow: #eaeaea 0px 1px 10px 7px;*/
/*}*/

/*/deep/.el-pagination.is-background .el-pager li:not(.disabled).active,*/
/*/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {*/
/*  background-color: #00CD64;*/
/*  color: #fff;*/
/*}*/
.ts{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ts>img{
    width: 541.67px;
    height: 507.17px;
    margin: 30px 0px;
}
.navtop{
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 20px;
    color: #CCCCCC;
    font-size: 16.67px;
    padding-bottom: 20px;
}
.navtop div{
    color: #ccc;
}
.navtop div:hover{
    color: #00A870;
}
.navtop div:hover span{
    color: #00A870;
}
.navtop div span{
    color: #ccc;
}
.navtop span{
    color: #00A870;
}
@media only screen and (max-width: 500px) {
  .coop_ms h2 {
    font-size: 25px;
  }
  .coop_ms {
    width: 90%;
    height: 240px;
    margin-top: -120px;
    padding: 30px 0px;
  }
  .coop_ms .coop_ms_t {
    margin-top: 25px;
  }
  .coop_ms .coop_ms_t span {
    padding: 30px 5px;
    font-size: 12px;
  }
  .coop_ms .coop_ms_t li:nth-child(2) img {
    width: 67px;
  }
  .coop_ms .coop_ms_t li:nth-child(3) img {
    width: 72px;
  }
  .coop_ms .title {
    width: 30px;
    height: 8px;
  }
  .coop_top img {
    width: 100%;
    height: 220px;
  }
  .coop_ms .coop_ms_t p {
    font-size: 15px;
  }
  .coop_list h3 {
    font-size: 20px;
  }
  .coop_list ul {
    width: 90%;
    margin-top: 30px;
  }
  .coop_list ul li .cl_pic {
    width: 65px;
    height: auto;
  }
  .coop_list ul li .cl_pic img {
    width: 100%;
    height: auto;
  }
  .coop_list ul li .cl_text p {
    margin-top: 0;
    height: 40px;
  }
  .coop_list ul li {
    align-items: center;
    padding: 10px;
  }
  .coop_ms .coop_ms_t li img {
    width: 83px;
  }
  .coop_list {
    transform: translateY(0);
  }
  .coop_list ul li h4 {
    font-size: 17px;
  }
  .coop_list ul li .cl_text {
    width: 75%;
  }
}
#map {
  height: 600px;
}
.map_enterprise{
    color: #333333;
    font-size: 11px;
    font-weight: 500;
}
.map_enterprise img{
    width: 15px;
    height: 15px;
}
</style>
