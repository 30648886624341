<template>
  <div>
    <!--轮播图-->
    <banner :bannerList="bannerList"></banner>

    <div class="wanqi_wd_bg" id="job">


      <div class="renwu_list wanqi_wd" id="job">
        <h2>
          <!-- <span></span><span style="background: #00e699"></span> -->
          <i>海量兼职岗位</i>
          <h6 style="color: #CCCCCC">涵盖280种岗位 高精准智能推荐</h6>
          <!-- <span></span><span style="background: #00e699"></span> -->
        </h2>
        <div class="r_list_nav">
          <!-- <p></p> -->
          <ul class="rlist_centerT">
            <li
                v-for="(item, index) in rwnav"
                :key="index"
                :class="acindex == index ? 'active' : ''"
                @click="qhtab(index,item)"
                style="position: relative;"
            >
            <img src="@/assets/images/pc/hot.png" class="hotIcon" v-if="item.hot" />
              {{ item.title }}
            </li>
          </ul>
          <div class="rlist_btT rlist_bt" v-show="rmnav.length"  style="margin-top: 25px">
            <ul>
              <a :href="item.id" onclick="return false"   v-for="(item, index) in rmnav.slice(0,rmnavIndex)"
                  :key="item.id">
              <li

                  :class="activeTabS == index ? 'active' : ''"
                  @click="click_tabS(item, index)"
              >
                {{ item.name }}
              </li>
            </a>
              <div class="actives" @click="setRmnav()" v-if="!rmnavshow && rmnav.length>9" >
                更多<img src="@/assets/images/pc/arrow-d.png" alt="展开" style="width: 15px"/>
              </div>
              <div class="actives" @click="setRmnavT" v-if="rmnavshow">
                收起<img src="@/assets/images/pc/arrow-d.png" alt="收起" style="transform: rotate(180deg);width: 15px"/>
              </div>
            </ul>
          </div>
          <!---广告位开始-->
          <div class="rlist_btl" v-if="taskData.length>0">
            <ul v-loading="fullscreenLoading" element-loading-text="拼命加载中">
              <el-row :gutter="20">
                <el-col
                    class="mt-50"
                    :md="8"
                    :xs="24"
                    v-for="(item, index) in taskData"
                    :key="index"
                >
                  <li class="grid-content bg-purple mycard rlist_btlT" :class="index == listindex?'':''"  @click="cys(item)">
                      <a onclick="return false" :href="item.id" >
                          <div class="wtitle-box">
                              <h4 :title="item.title">{{ item.positionTitle  }}</h4>
                          </div>
                          <div class="wtab">
                              <span v-if="item.pt_task_type">{{item.pt_task_type.type_name}}</span>
                              <span v-for="(item2,index) in item.tags">{{item2}}</span>
                          </div>
                          <div class="wtitle-box">
                              <h5  v-if="item.amountType == '1'">
                                  <span>￥{{item.amount / 100}}/</span>
                                  <span v-if="item.settlementUnit == 0">其他</span>
                                  <span v-if="item.settlementUnit == 1">小时</span>
                                  <span v-if="item.settlementUnit == 2">天</span>
                                  <span v-if="item.settlementUnit == 3">周</span>
                                  <span v-if="item.settlementUnit == 4">月</span>
                                  <span v-if="item.settlementUnit == 5">次</span>
                                  <span v-if="item.settlementUnit == 6">单</span>
                              </h5>
                              <h5 v-else>
                                  <span>￥{{item.amountBegin / 100}} ~ {{item.amountEnd / 100}}/</span>
                                  <span v-if="item.settlementUnit == 0">其他</span>
                                  <span v-if="item.settlementUnit == 1">小时</span>
                                  <span v-if="item.settlementUnit == 2">天</span>
                                  <span v-if="item.settlementUnit == 3">周</span>
                                  <span v-if="item.settlementUnit == 4">月</span>
                                  <span v-if="item.settlementUnit == 5">次</span>
                                  <span v-if="item.settlementUnit == 6">单</span></h5>

                          </div>
                          <div class="wrlist_bt_b">

                              <div class="wrlist_bt_bb">
                                  <div class="wrlist_bt_bbs">
                                      <div class="publisher-box">
                                          <div class="publisher-logo"  v-show="item.enterprise">
                                              <img v-if="item.enterprise" :src="item.enterprise.enterpriseLogo" alt="兼职网"/>
                                          </div>
                                          <div class="publisher-mess">
                                              <div class="publisher-mess-title" v-if="item.enterprise">{{item.enterprise.enterpriseName}}</div>
                                              <em class="fabuzhe" v-if="item.distance>1">距离{{item.distance}}km &nbsp; {{ item .detailedProcess }}</em>
                                          </div>
                                      </div>
                                      <!-- <span>征稿人数:{{ item.filling_number }} 人</span> -->
                                  </div>
                                  <i v-if="item.state == 3" class="jzcy">立即报名</i>
                                  <i v-else @click="cy(item)" class="jzcy">立即报名</i>
                              </div>
                          </div>
                      </a>
                  </li>
                </el-col>
              </el-row>
            </ul>
          </div>

            <div class="" style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;height: 1003px;" v-if="taskData == ''">
                <img src="@/assets/images/pc/noData.png" style="width: 541.67px;height: 507.17px;" />
            </div>

          <!---广告位结束-->


          <div class="page">
            <!--          <el-pagination-->
            <!--              background-->
            <!--              prev-text="上一页"-->
            <!--              next-text="下一页"-->
            <!--              :layout="layout"-->
            <!--              :pager-count="pageCount"-->
            <!--              :page-size="page.pageSize"-->
            <!--              :current-page.sync="page.currentPage"-->
            <!--              :total="page.total"-->
            <!--              @current-change="chagenum"-->
            <!--          >-->
            <!--          </el-pagination>-->

            <div class="jaizai" @click="morePosition"  v-if="taskData.length>0">查看更多</div>
          </div>
        </div>
      </div>

    </div>
    <div class="service_module">
      <div class="service_title" id="service">
        六大服务<span class="service_title_high">赋能企业</span>急速招聘
      </div>
      <div class="service_desc">不同招聘诉求都在这里找到解决答案</div>
      <div>
        <div class="service">
          <div class="service_box" v-for="item in serviceList">
            <div>
              <img class="service_box_img" :src="item.icon" :alt="item.desc"/>
              <img class="service_box_img_fill" :src="item.iconFill" :alt="item.desc"/>
              <div class="service_box_title">{{item.title}}</div>
            </div>
            <div class="service_box1_text">
              <div class="service_box_desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="news_module">
      <div class="service_title" id="news">
        <span class="service_title_high">新闻</span>活动
      </div>
      <div class="service_desc">了解更多兼职网信息都在这里</div>
      <div style="padding-bottom: 20px">
        <div class="news">
          <div class="news_detailed" v-if="informationList&&informationList.length>0" @click="goinformationDetail(informationList[0])">
              <a :href="informationList[0].id" onclick="return false">
              <div>
              <img class="news_detailed_img" :src="informationList[0].imgUrl" alt="兼职网">
            </div>
            <div class="news_detailed_content">
                <div class="news_detailed_title_one">
                    {{ informationList[0].title }}
                </div>
              <div class="news_detailed_time">{{ informationList[0].timeFormatter}}</div>
              <div class="news_detailed_desc">{{ getRichText(informationList[0].content) }}</div>

            </div>
              <div class="arrow">
                  <img class="noSelected" src="@/assets/images/pc/arrow.png" />
                  <img class="includeArrow" src="@/assets/images/pc/includeArrow.png" />
              </div>
              </a>
          </div>
          <div class="news_detailed" v-if="informationList&&informationList.length>1" @click="goinformationDetail(informationList[1])">
              <a :href="informationList[1].id" onclick="return false">
            <div>
              <img class="news_detailed_img" :src="informationList[1].imgUrl" alt="兼职网">
            </div>
            <div class="news_detailed_content">
                <div class="news_detailed_title_one">
                    {{ informationList[1].title }}
                </div>
              <div class="news_detailed_time">{{ informationList[1].timeFormatter }}</div>
              <div class="news_detailed_desc">{{ getRichText(informationList[1].content) }}</div>
            </div>
              <div class="arrow">
                  <img class="noSelected" src="@/assets/images/pc/arrow.png" />
                  <img class="includeArrow" src="@/assets/images/pc/includeArrow.png" />
              </div>
                  </a>
          </div>
          <div class="news_detailed_list">
            <div class="news_detailed_list_tip">
              新闻
            </div>
            <div class="news_detailed_list_content">
                <div class="news_detailed_item" v-for="item in informationList"  @click="goinformationDetail(item)">
                    <a :href="item.id" onclick="return false">
                        <div class="news_detailed_item_title">
                            {{item.title}}
                        </div>
                        <div class="news_detailed_item_time">
                            <span>{{ item.timeFormatter }}</span>
                            <span style="cursor: pointer">详情<i class="el-icon-arrow-right"></i></span>
                        </div>
                    </a>
                </div>
            </div>
          </div>
        </div>
        <div class="more" @click="moreNews">
          更多新闻
        </div>
      </div>
    </div>
    <div class="cooperation" v-if="false">
      <div class="service_title" id="company">
        全球合作商家
      </div>
      <div class="service_desc">65万+企业选择</div>
      <div class="globalImgs_outer">
        <vue-seamless-scroll :data="companyList" style="width: 995px;height:83px;overflow: hidden;" :class-option="optionLeft">
          <div class="globalImgs">
            <img v-for="(item,index) in companyList" :key="index" :src="item.src" :alt="item.name">
          </div>
        </vue-seamless-scroll>
        <vue-seamless-scroll :data="companyList2" style="width: 995px;height:83px;overflow: hidden;margin: 30px 0;" :class-option="optionLeft2">
          <div class="globalImgs">
            <img v-for="(item,index) in companyList2" :key="index" :src="item.src" :alt="item.name">
          </div>
        </vue-seamless-scroll>
        <vue-seamless-scroll :data="companyList3" style="width: 995px;height:83px;overflow: hidden;" :class-option="optionLeft">
          <div class="globalImgs">
            <img v-for="(item,index) in companyList3" :key="index" :src="item.src" :alt="item.name">
          </div>
        </vue-seamless-scroll>
      </div>
      <!-- <div class="cooperation_content" style="position: relative;overflow: hidden;height: 560px;">
        <img v-for="item in companyList" class="company" :src="item.src" :alt="item.name">
      </div> -->
    </div>

    <!---联系我们开始-->
    <div class="tj-box">
      <div class="renwu_list wanqi_wd">
        <div class="contact-title" id="contact">联系我们</div>
        <div class="contact-ftitle">专人1对1答疑协助入驻  免费获取新人专属扶持流量  优质商家案例随时学</div>

        <!---商家入驻快速通道-->
        <div class="tj-mess-box">
          <div class="tj-mess-left">
            <div class="tj-mess-left-title">商家入驻快速通道</div>

            <div class="tj-mess-left-list">
<!--              <div class="tj-list">众多真实在校大学生，智能精准匹配，满足企业主用工需求；</div>-->
              <div class="tj-list">快速招聘，最快2min完成发布；</div>
              <div class="tj-list">快速响应，获客成本远低于行业平均水平；</div>
            </div>
          </div>
          <div class="tj-mess-right">
            <div style="display: flex;align-items: center;justify-content:space-around;width: 100%;height:100%">
              <img  style="width:258px;height:271px"  src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/169891750323177.gif" alt="兼职网"/>
              <img  style="width:258px;height:271px"  src="https://jianzhiwang-app.oss-cn-hangzhou.aliyuncs.com/169891788447988.jpg" alt="兼职网"/>
            </div>
            <!-- <img class="tj-bg-T" src="@/assets/images/pc/tj-gbT.png" alt="兼职网"/>
            <div class="ti-mess-input">
              <div class="tj-mess-input-title">留下您的联系方式</div>
              <input placeholder='请输入您的姓名' v-model="name"/>
              <input placeholder="请输入您的手机号" type="tel" v-model="phone"/>
              <div class="tj-fun" @click="submit">提交</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="hot-position"></div>
  </div>
</template>

<script>
import banner from '@/components/pc/banner';
import Vue from "vue";
import axios from "axios";
export default {
  name: "homePage",
  data() {
    return {
      name:"",
      phone:"",
      rmnavIndex:8,
      rmnavshow:false,
      levelId:"",  //二级分类id
      classifyType:"1",//二级分类类型,
      pageNum:1,  //请求页数
      pageSize:12,  //请求条数
      url:"/official/enterprise/getPositionLevelList", //请求地址接口
      listindex:0,  //去掉一会
      layout: "prev, pager, next",
      complete_enterprise_num: [],
      service_enterprise_num: [], //解决需求数
      fullscreenLoading: false, //加载
      serviceList:[
        {
          icon:require('@/assets/images/pc/xshz.png'),
          iconFill:require('@/assets/images/pc/xshz-fill.png'),
          title:'线上合作',
          desc:'平台广告投放 产品CPA推广 平台资源置换、其它线上合作',
        },
        {
          icon:require('@/assets/images/pc/xxld.png'),
          iconFill:require('@/assets/images/pc/xxld-fill.png'),
          title:'线下落地',
          desc:'平台活动策划营销 校园市场推广 活动执行落地 产品地推拓展',
        },
        {
          icon:require('@/assets/images/pc/mthz.png'),
          iconFill:require('@/assets/images/pc/mthz-fill.png'),
          title:'媒体合作',
          desc:'品牌广告软文投放 品牌文案策划、撰写、公共媒体运营发稿 联合线上活动',
        },
        {
          icon:require('@/assets/images/pc/qydz.png'),
          iconFill:require('@/assets/images/pc/qydz-fill.png'),
          title:'企业代招',
          desc:'企业全职/临时招聘 活动/项目兼职招聘 节假日兼职招聘 寒暑假工招聘',
        },
        {
          icon:require('@/assets/images/pc/ptnl.png'),
          iconFill:require('@/assets/images/pc/ptnl-fill.png'),
          title:'平台能力',
          desc:'一站式解决方案 搜索&浏览场景打通 智能算法解析 全链路数据支持',
        },
        {
          icon:require('@/assets/images/pc/fwbz.png'),
          iconFill:require('@/assets/images/pc/fwbz-fill.png'),
          title:'服务标准',
          desc:'专业的服务团队 标准的服务流程 高效的服务质量 海量的用工岗位',
        }
      ],
      companyList: [{
        src: require('@/assets/images/pc/company/kfc.png'),
        name: '兼职网合作商家-kfc'
      },{
        src: require('@/assets/images/pc/company/zgrs.png'),
        name: '兼职网合作商家-中国人寿'
      },{
        src: require('@/assets/images/pc/company/dfrb.png'),
        name: '兼职网合作商家-东方阮白'
      },{
        src: require('@/assets/images/pc/company/pizza.png'),
        name: '兼职网合作商家-pizza'
      },{
        src: require('@/assets/images/pc/company/adidas.png'),
        name: '兼职网合作商家-adidas'
      },{
        src: require('@/assets/images/pc/company/nike.png'),
        name: '兼职网合作商家-nike'
      },{
        src: require('@/assets/images/pc/company/dd.png'),
        name: '兼职网合作商家-滴滴'
      },{
        src: require('@/assets/images/pc/company/hdl.png'),
        name: '兼职网合作商家-hdl'
      },{
        src: require('@/assets/images/pc/company/jjy.png'),
        name: '兼职网合作商家-jjy'
      }],
      companyList2:[{
        src: require('@/assets/images/pc/company/yd.png'),
        name: '兼职网合作商家-韵达'
      },{
        src: require('@/assets/images/pc/company/mtwm.png'),
        name: '兼职网合作商家-美团外卖'
      },{
        src: require('@/assets/images/pc/company/jdwl.png'),
        name: '兼职网合作商家-京东物流'
      },{
        src: require('@/assets/images/pc/company/mdl.png'),
        name: '兼职网合作商家-麦当劳'
      },{
        src: require('@/assets/images/pc/company/qcs.png'),
        name: '兼职网合作商家-屈臣氏'
      },{
        src: require('@/assets/images/pc/company/payh.png'),
        name: '兼职网合作商家-payh'
      },{
        src: require('@/assets/images/pc/company/u.png'),
        name: '兼职网合作商家-u'
      },{
        src: require('@/assets/images/pc/company/uniqlo.png'),
        name: '兼职网合作商家-uniqlo'
      },{
        src: require('@/assets/images/pc/company/UR.png'),
        name: '兼职网合作商家-UR'
      }],
      companyList3:[{
        src: require('@/assets/images/pc/company/coffee.png'),
        name: '兼职网合作商家-coffee'
      },{
        src: require('@/assets/images/pc/company/drf.png'),
        name: '兼职网合作商家-大润发'
      },{
        src: require('@/assets/images/pc/company/ikea.png'),
        name: '兼职网合作商家-滴滴'
      },{
        src: require('@/assets/images/pc/company/dkn.png'),
        name: '兼职网合作商家-迪卡侬'
      },{
        src: require('@/assets/images/pc/company/jdwl.png'),
        name: '兼职网合作商家-jdwl'
      },{
        src: require('@/assets/images/pc/company/xbk.png'),
        name: '兼职网合作商家-xbk'
      },{
        src: require('@/assets/images/pc/company/st.png'),
        name: '兼职网合作商家-st'
      },{
        src: require('@/assets/images/pc/company/uupt.png'),
        name: '兼职网合作商家-uupt'
      },{
        src: require('@/assets/images/pc/company/zaba.png'),
        name: '兼职网合作商家-zaba'
      }],
      icons: "el-icon-caret-bottom",
      activeTab: 0,
      activeTabS: 0,
      acindex: 0,
      laberKey: "0",
      //热门标签
      rmnav: [],
      bannerList:[],
      rwnav: [
        {
          title: "兼职职位",
          type: true,
          hot:false
        },{
          title:"在家做",
          type:false,
          hot:true
        },
        {
          title:"本地兼职",
          type:false,
          hot:false
        },
        {
          title:"全部岗位",
          type:false,
          hot:false
        }
      ], //任务类型
      taskData: [],
      page: {
        total: 0, //总条目数
        pageSize: 12, //每页显示条目个数
        currentPage: 1, //当前页数
      },
      pageCount: 5,
      show: true,
      kxlist: [],
      day: 15,
      dialogTableVisible:true,
      user:{},
      advertisingList:[],  //
      informationList:[],  //资讯列表
      guanggao:false,
      levelType:1,
      firstOrder:"兼职职位",  //一级标题
      secondLevel:"全部兼职",  //二级标题
      args:{
        occurrenceTime:"",
        user:{
          userId:"",
          phone:""
        },
        type:{},
        type2:"", //pc / h5的
        actionCode:"",
        message:{
          phone:""
        }
      },
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        loop: true,
      },
    }
  },
  computed: {
    optionLeft () {
      return {
        direction: 2,
        limitMoveNum: 3
      }
    },
    optionLeft2 () {
      return {
        direction: 2,
        limitMoveNum: 3,
        step:0.6
      }
    }
  },
  components: {
    banner
  },
    filters:{
        setTime(e){
            console.log("时间=>",e)
        }
    },
  mounted() {

    this.randomNum();
    this.getadvertising(1,9);
    this.exposureOW();
    this.getInformationList()
      this.$common.fixedPos().then(res=>{
          this.getnav();
      }).catch(err=>{
          this.getnav();
      })
    // this.getrwt();
    // this.jzkx();
    console.log('this.$router.params', this.$route)
      setTimeout(()=>{
          if(this.$route.params&&this.$route.params.element){
              // this.jumpToAnchor(this.$route.params.element);
              // this.$nextTick(()=> {
              let anchorName = this.$route.params.element
              //   document.querySelector(`#${anchorName}`).scrollIntoView(true);
              // })
              let anchorElement = document.getElementById(`${anchorName}`);
              console.log('anchorElement', anchorElement.offsetTop)
              this.$nextTick(()=>{
                  if(anchorElement) {
                      anchorElement.scrollIntoView({
                          behavior: 'smooth',
                          duration: 500,
                      })
                  }
              })
          }
      },300)


    const that = this;
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token")) {
      // this.getuserlist();
    }
    return (() => {
      window.screenWidth = document.documentElement.clientWidth; //实时宽度
      window.screenHeight = document.documentElement.clientHeight; //实时高度
      if (window.screenWidth < 500) {
        //  setTimeout(function(){that.pageCount=3},100)
        that.layout = "pager";
      }
    })();
  },
  beforeMount(){
    this.$common.fixedPos();
    let city = JSON.parse(localStorage.getItem('point'));
    console.log("city=>",city)
      if(city != null){
          let e = city.city
          AMap.plugin('AMap.Geocoder', function() {
              var geocoder = new AMap.Geocoder({
                  city: e // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
              })
              geocoder.getLocation( e, function (status, result) {
                  if (status === "complete" && result.info === "OK") {

                      let adcode = result.geocodes[0].adcode
                      let city = result.geocodes[0].formattedAddress
                      // result为对应的地理位置详细信息
                      // this.place为返回的具体地理位置信息，里面无法使用return回来！
                      // this.$router.push({
                      //     name: 'PartTJ',
                      //     params: {
                      //         // id: e.jumpUrl,
                      //         cityCode: adcode,
                      //     },
                      // });
                      localStorage.setItem("adcode",adcode)
                  }
              })
              // 使用geocoder做地理/逆地理编码
          })
      }

  },
  created() {
      this.getBanner();
    // this.getnum();
    this.$bus.$on('tc_marke',e=>{
      console.log(e)
      this.user={agreement_audit:1}
    })
  },
  methods: {
        //兼职职位 获取广告列表
        getPositionGGListT(){
          let latitude = JSON.parse(localStorage.getItem("point"))?.lat || '';
          let longitude =JSON.parse(localStorage.getItem("point"))?.lng || '';
      this.$axios({
        method: "POST",
        url:"/official/enterprise/getPositionGGList",
        data: {
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          longitude:longitude||'0', //经度
          latitude:latitude || '0',//维度
        },
      }).then(res=>{
        this.taskData = res.rows;
      })
    },
    goinformationDetail(e){
      this.$router.push({
          name: `informationDetail`,
          params: {
              id: e.id,
              name: "首页"
          },
      });
    },
    //提交联系方式
    submit(){
      if(this.name == ''){
        this.$notify.error({
          title: '错误',
          message: '姓名不可为空'
        });
        return;
      }
      var reg =/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;

      if(!reg.test(this.phone)){
        this.$notify.error({
          title: '错误',
          message: '手机号格式错误'
        });
        return;
      }

      axios({
        method:"POST",
        baseUrl:process.env.VUE_APP_JZWB_URL2,
          needPrivate:true,
        url:"/official/enterprise/insertExchange",
        data:{
          dataSource:'14',
          name:this.name,
          phone:this.phone
        }
      }).then((res)=>{
          console.log(res,"123")
        if(res.code == 200){
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          });
        }else if(res.code == 500) {
            this.name = "";
            this.phone = "";
            this.$notify({
                title: "成功",
                message: res.msg,
                type: 'success'
            });
        }
      }).catch((err) => {
          console.log(err);
      });
    },
    setRmnav(){
      this.rmnavIndex = this.rmnav.length;
      this.rmnavshow = true;
    },
    setRmnavT(){
      this.rmnavIndex = 8;
      this.rmnavshow = false;
    },

    getBanner(){
      this.$axios({
        baseURL:process.env.VUE_APP_JZWB_URL2,
        url: "/official/enterprise/getBanner?locationType="+'8',
      }).then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data
        }
      });
    },
    moreNews(){
      this.$router.push({path:'/informationHome'})
    },
    morePosition(){
      this.$router.push({path:'/PartTJ'})
    },
    getRichText(richText) {
      // 提取文字
      const myDiv = document.createElement("div");
      myDiv.innerHTML = richText;
      return myDiv.innerText;
    },
    //生成随机id
    randomNum() {
      let num = "";
      for (let i = 0; i < 6; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let userid = new Date().getTime() + num;
      if(localStorage.getItem('userID') == '' || localStorage.getItem('userID') == undefined || localStorage.getItem('userID') == null){
        localStorage.setItem("userID",userid)
      }
    },
    getInformationList(){
      this.$axios({
        method: "get",
        url: "/official/enterprise/getInformationList",
        params: {
          pageNum: 1,
          pageSize: 4,
          websiteType:2
        },
      }).then((res) => {
            if (res.code === 200) {
                console.log("新闻=>",res.rows)
              this.informationList = res.rows;
              this.page.total = res.total;
              // this.page.total = res.total;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    exposureOW(){
      let data = localStorage.getItem("userInfo")
      let phone = '';
      if(data != null){
        phone = JSON.parse(data).phone;
      }

      if(data != null && (phone != '' || phone != null || phone != undefined)){
        this.args.user.phone = phone;
        this.args.message.phone = phone;
          let user = JSON.parse(window.localStorage.getItem('userInfo'))
          let userId = user&&user.token?user.token:phone
        this.args.user.userId = userId;
      }else{
        this.randomNum();
          let user = JSON.parse(window.localStorage.getItem('userInfo'))
          let userId = user&&user.token?user.token: localStorage.getItem("userID")
        this.args.user.userId = userId
      }
      this.args.occurrenceTime = new Date().getTime(),
          //正是   https://jzuser-api.jianzhinet.cn
          this.args.actionCode = 'web_website_001';
      this.args.type = 'website';
      this.$axios({
        url: "/mqtt/actionIncentive/actionLogPc",
        data:{
          payload: JSON.stringify(this.args)
        },
        method:"POST",
      }).then((res)=>{
      })
    },
    getuserlist() {
      let _this = this;
      this.$axios({
        method: "POST",
        url: "center/my_info",
      }).then((res) => {
        if (res.code == 200) {
          this.user=res.data
        }
      });
    },

    //获取广告
    getadvertising(a,e){

      this.$axios({
        method:"POST",
        url: "/wanqitong/getTaskList",
        data: {
        },
      }).then((res)=>{
        if(res.code == 200 && res.rows != ''){
          this.guanggao = true;
          this.advertisingList = res.rows
          this.advertisingList = this.advertisingList.splice(a,e);
          this.fullscreenLoading = false;
        }else{
          // this.$message.error(res.msg);
        }
      })
    },
    // 兼职快讯
    // jzkx() {
    //   this.$axios({
    //     method: "POST",
    //     url: "/api/center/part_time_alerts",
    //   })
    //       .then((res) => {
    //         this.kxlist = res.data.data;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    // },

    change: function (content) {
      content = content.replace(/\n/g, "</br>");
      return content;
    },

    // getnum() {
    //   this.$axios({
    //     method: "POST",
    //     url: "/api/center/banner_digital",
    //   })
    //       .then((res) => {
    //         console.log(res);
    //         this.service_enterprise_num = res.data.service_enterprise_num.split(",");
    //         this.complete_enterprise_num = res.data.complete_enterprise_num
    //             .toString()
    //             .split("");
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    // },
    //热门任务
    hot() {
      this.show = !this.show;
      if (this.show) {
        this.icons = "el-icon-caret-bottom";
      } else {
        this.icons = "el-icon-caret-top";
      }
    },
    chagenum(e) {
      this.page.currentPage = e;
      if(this.page.currentPage <= 2){
        this.getadvertising(11,this.page.currentPage * 9)
        this.guanggao = true;
      }else{
        this.guanggao = false;
        this.getData();
      }
    },
    // 任务详情
    cy(e) {
      this.$router.push({
        name: "positionDetail",
        params: {
            id: e.id,
            name: this.$route.meta.metaInfo.title
        },
      });
      localStorage.setItem("erWeima",1)


    },
    //职位详情
    cys(e){
        this.$router.push({
            name: "positionDetail",
            params: {
                id: e.id,
                name: this.$route.meta.metaInfo.title,
                firstOrder:this.firstOrder,
                secondLevel:this.secondLevel
            },
        });
    },

    qhtab(e,i) {
        this.rmnavshow = false;
        this.rmnavIndex = 8;
        this.activeTabS = 0;
        this.rmnav = [];
        this.acindex = e;
        this.classifyType = "1";

        console.log("索引=>",e)

      if (e == 0) {
        this.firstOrder = "兼职职位"
        this.levelType = 1;
        this.getPositionGGListT();
        this.getnav();
      }else if (e == 1){
        this.firstOrder = "在家做"
        this.secondLevel = "全部兼职"
        this.levelId = "";
        this.levelType = 2;
        this.url = "/official/enterprise/getPositionCourseList"
        this.getnav();
      }else if(e == 2){
        this.firstOrder = "本地兼职"
        this.secondLevel = ""
        this.url = "/official/enterprise/getPositionLocallyList"
        this.levelType = ''
        this.getData();
      }else {
        this.firstOrder = "全部岗位"
        this.secondLevel = ""
        this.url = "/official/enterprise/getPositionAllList"
        this.levelType = ''
        // this.getData();
        this.positionList();
      }
      // this.getData();
      // this.getadvertising(1,9);
    },
    positionList(e){
        let cityType = "";
        if(e != '' && e!= undefined && e!= null){
        }else{
          if(localStorage.getItem('dJ') != null){
          cityType = localStorage.getItem('dJ')
          }
        }
        this.$axios({
              method: "POST",
              url:"/official/enterprise/getPositionAllList",
              data:{
                selectType:"12",
                cityType:cityType,
                // levelId:this.levelId,
                // classifyType:this.classifyType,
                code:"",
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                longitude:this.longitude || '0', //经度
                latitude:this.latitude || '0',//维度
                positionTitle:""
              }
            }).then(res=>{
                console.log(res)
                if(res.code == 200 && res.rows!= []){
                  this.taskData = res.rows;
                  this.page.total = Number(res.total);
                  this.fullscreenLoading = false;
                }
            })
      },
    // 获取任务列表
    getData() {
      this.fullscreenLoading = true;
        if(this.levelId  == '789456'){
            this.levelId = "";
        }
        let cityType = "";
        if(localStorage.getItem('dJ') != null){
          cityType = localStorage.getItem('dJ')
        }
      this.$axios({
        method: "POST",
        url: this.url,
        data: {
          // selectType:"12",
          cityType:"",
          levelId:this.levelId,
          classifyType:this.classifyType,
          code:localStorage.getItem("adcode"),
          // positionTitle:"",
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          longitude:this.longitude || '0', //经度
          latitude:this.latitude || '0',//维度
        },
      }).then((res) => {
        console.log("123=>",res);
        this.taskData = res.rows;
        // this.page.total = Number(res.data.total);
        this.fullscreenLoading = false;
      }).catch((error) => {
            console.log(error);
          });
    },
    onChange(){
      console.log('12')
    },
    //难易程度
    getnav() {
      this.$axios({
        method: "POST",
        params:{
          levelType:this.levelType,
        },
        url: "/official/enterprise/getPositionLevel",
      }).then((res) => {
        this.rmnav = res.data;
        this.rmnav.unshift({name:this.levelType == 1?"全部兼职":"全部职位",id:'789456'});
        this.levelId = this.rmnav[0].id;
        console.log("firstOrder=>",this.firstOrder)
        if(this.firstOrder == "兼职职位"){
          this.getPositionGGListT();
        }else{
          this.getData();

        }
      }).catch((error) => {
      console.log(error);
    });
    },
    //任务类型
    // getrwt() {
    //   this.$axios({
    //     method: "POST",
    //     baseURL:process.env.VUE_APP_JZWB_URL2,
    //     url: "/official/enterprise/getPositionLevel",
    //   }).then((res) => {
    //         // this.rwnav = res.data;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    // },
    click_tab(item, index) {
      this.page.currentPage = 1;
      this.activeTab = index;
      this.laberKey = item.id;
      this.getData();
      this.getadvertising(1,9);

    },
    click_tabS(item, index) {
            console.log("this.firstOrder=>",this.firstOrder)
      this.activeTabS = index;
      this.secondLevel = item.name;
      if(item.name == "全部职位" || item.name == "全部兼职"){
        this.classifyType = 1;//二级分类类型,
        this.levelId = '';
      }else {
        this.classifyType = 2;
        this.levelId = item.id;  //二级分类id
      }
      this.pageNum = 1;  //请求页数
      this.pageSize = 12;  //请求条数
        if(item.name == "全部兼职"){
            this.getPositionGGListT();
        }else {
            if(this.firstOrder == "兼职职位"){
                this.url = "/official/enterprise/getPositionLevelList"
            }else if(this.firstOrder == "在家做"){
                this.url ="/official/enterprise/getPositionCourseList"
            }
            this.getData();
        }
    },
  },
}
</script>

<style src="@/assets/css/pc/homePage.css" scoped></style>
