<template>
  <!-- 搜索 -->
<div class="search" :style="`background-color:${showRes?'#ffffff':'#f6f6f6'};min-height:${mh&&mh>0?mh+'px':'100vh'};`">
  <div class="searchTop" :style="`background-color:${showRes?'#ffffff':'#f6f6f6'};`">
    <i @click="backto" class="el-icon-arrow-left"></i>
    <van-field class="searchClass" v-model.trim="input" placeholder="搜索你想要的内容">
      <i slot="left-icon" class="el-icon-search"></i>
      <span class="searchBtn" slot="right-icon" @click="beginSearch">搜索</span>
    </van-field>
  </div>
  <template v-if="!showRes">
    <div class="search_sed" v-if="searchList.length>0">
      <span>搜索记录</span>
      <span @click="deleteSearch"><i class="el-icon-delete"></i></span>
    </div>
    <div class="searchList" v-if="searchList.length>0">
      <span @click="chooseListItem(index)" class="oneLineText" v-for="(item,index) in searchList" :key="index" v-show="searchList.length>8&&index<7 || searchList.length<=8 || searchList.length>8&&show">
        {{ item.cont }}
      </span>
      <span v-show="searchList.length>8&&!show" @click="showSearch">展开<i class="el-icon-arrow-down"></i></span>
      <span v-show="searchList.length>8&&show" @click="showSearch">收起<i class="el-icon-arrow-up"></i></span>
    </div>
    <div class="search_sed" v-if="hotList.length>0">
      <span>猜你喜欢</span>
      <span></span>
    </div>
    <div class="likelist" v-if="hotList.length>0">
      <span v-for="(item,index) in hotList" :key="index" @click="chooseListItem(item.dictLabel,true)">
        <span>{{ item.dictLabel }}</span>
        <img v-if="index==0" src="@/assets/images/h5/home/sHot.png" alt="hot">
        <img v-else-if="index==1" src="@/assets/images/h5/home/sNew.png" alt="new">
      </span>
    </div>
    <!-- 推荐 -->
    <div class="tuijianClass" style="margin-top: 1rem;">
      <div :class="chooseTj==1?'tuijianClass_spanCheck':''" @click="checkOut(1)">
        <span>相关推荐</span>
        <span></span>
      </div>
      <div :class="chooseTj==2?'tuijianClass_spanCheck':''" @click="checkOut(2)">
        <span>大家都在看</span>
        <span></span>
      </div>
    </div>
    <div class="elLoadingdiv" v-if="loadTj">
      <i class="el-icon-loading"></i> 加载中
    </div>
    <template v-else>
      <div v-for="(item,index) in taskArray" :key="index">
        <jobItem :keyd="index" :itemObj="item" />
      </div>
      <div v-if="noMoreTj" class="noMoreClass">- 没有更多内容了哦 -</div>
    </template>
  </template>
  <template v-else>
    <van-list
      class="list_cell"
      style="margin-top: 1rem;"
      v-model="loadResult"
      :finished="noMore"
      finished-text="- 没有更多内容了哦 -"
      @load="loadNext"
    >
      <van-cell v-for="(item,index) in taskArray2" :key="item.id">
        <jobItem :itemObj="item" :keyd="index" />
      </van-cell>
    </van-list>
  </template>
</div>
</template>

<script>
export default {
  name:'homeSearch-m',
  data() {
    return {
      input: '',//输入框内容
      searchList:[],//搜索记录
      hotList:[],//热搜记录
      showRes:false,//展示搜索结果
      loadTj:false,
      loadResult:false,
      //任务列表查询
      taskData: {
        pageNum: 1, //查询第几页
        pageSize: 10,
        queryTypes: ["0", "0", "0", "0", "0", "0"],//默认根据算法查
        positionState: 1, //1:发布中,2:已暂停,3:已结束,4:待审核,5:审核驳回
        positionType: 1, //1兼职职位，2课程职位
        optionId: '', //榜单id
        latitude:0,
        longitude:0,
      },
      noMoreTj:false,
      noMore:false,
      taskArray:[],
      taskArray2:[],
      chooseTj:0,
      show:false,
      mh:0,
    }
  },
  created () {
    let lat = localStorage.getItem('latitude')
    let lgt = localStorage.getItem('longitude')
    let list = localStorage.getItem('slist')
    if(lat && lgt){
      this.taskData.latitude = lat
      this.taskData.longitude = lgt
    }
    if(list){
      this.searchList = JSON.parse(list).sort((a,b)=>b.hot-a.hot)
    }else{
      this.searchList = []
    }
    this.mh = this.$common.getHeight()
    this.checkOut(1)
    this.getHotSearch()
  },
  methods: {
    // 获取搜索
    getSearchList(){
      this.loadResult=true
      this.$axios({
        method:'POST',
        url:'/app/home/getTaskList',
        params:{
          pageNum:this.taskData.pageNum,
        },
        data:this.taskData
      }).then(res=>{
        this.loadResult=false
        if(res.code==200){
          this.noMore=res.rows&&Boolean(res.rows.length<this.taskData.pageSize)
          if(this.taskData.pageNum==1){
            this.taskArray2=res.rows
          }else{
            this.taskArray2.push(...res.rows)
          }
        }
      })
    },
    // 获取推荐
    getTjList(){
      this.loadTj=true
      this.$axios({
        method:'POST',
        url:'/app/home/getLessonRecommendList',//改了接口
        params:{
          pageNum:this.taskData.pageNum,
        },
        data:this.taskData
      }).then(res=>{
        this.loadTj=false
        if(res.code==200){
          this.noMoreTj=true
          if(this.taskData.pageNum==1){
            this.taskArray=res.rows
          }else{
            this.taskArray.push(...res.rows)
          }
        }
      })
    },
    // 热门搜索
    getHotSearch(){
      this.$axios({
        url:'/app/message/getSearchType'
      }).then(res=>{
        if (res.code == 200) {
          this.hotList = res.rows
        } else {
          this.hotList = []
        }
      })
    },
    // 开始搜索
    beginSearch(){
      if(!this.input){
        return false;
      }
      this.taskData={
        pageNum: 1,
        pageSize: 10,
        queryTypes: ["0", "0", "0", "0", "0", "0"],
        positionState: 1,
        positionType: 1,
        optionId: '',
        latitude:0,
        longitude:0,
      }
      this.showRes=true
      //根据内容模糊查询
      this.taskData.queryTypes[4] = this.input
      this.chooseTj=0
      this.addSlist()
      this.taskArray=[]
      this.taskArray2=[]
      this.noMore=false
      this.noMoreTj=false
      this.getSearchList()
    },
    // 删除搜索记录
    deleteSearch() {
      if(this.searchList.length==0){
        return false
      }
      this.$dialog.confirm({
        title: '温馨提示',
        message: '确认删除搜索记录吗',
        confirmButtonText:'确定',
        confirmButtonColor:'#37af8f',
        className:'dialogSearch',
      }).then(res=>{
        this.searchList=[]
        localStorage.removeItem('slist')
      })
    },
    // 添加搜索记录
    addSlist(){
      if(!this.input){
        return false;
      }
      let i = this.searchList.findIndex(item=>item.cont == this.input)
      if(i>-1){
        this.searchList[i].hot++
      }else{
        this.searchList.push({
          cont:this.input,
          hot:0
        })
      }
      localStorage.setItem('slist',JSON.stringify(this.searchList))
    },
    // 选择搜索记录
    chooseListItem(i,bool){//bool,是否点击的热搜
      if(bool){
        this.input=i
      }else{
        this.input=this.searchList[i].cont
      }
      this.beginSearch()
    },
    // 返回
    backto(){
      if(this.showRes){
        this.showRes=false
        this.taskData={
          pageNum: 1,
          pageSize: 10,
          queryTypes: ["0", "0", "0", "0", "0", "0"],
          positionState: 1,
          positionType: 1,
          optionId: '',
          latitude:0,
          longitude:0,
        }
        this.input=''
        this.checkOut(1)
      }else{
        this.$common.returnEvent()
      }
    },
    // 推荐&大家都在看
    checkOut(i){
      if (this.chooseTj == i) {
        return false;
      }
      this.chooseTj=i
      this.taskData.pageNum = 1 //初始化每页条数
      this.taskArray = [] //初始化
      this.taskArray2 = []
      console.log('初始化');
      if (this.chooseTj == 0) {
        this.taskData.queryTypes[0] = '1'
        this.taskData.queryTypes[1] = '0'
      } else {
        this.taskData.queryTypes[0] = '0'
        this.taskData.queryTypes[1] = '1'
      }
      this.getTjList()
    },
    // 加载下一页
    loadNext(){
      if(!this.noMore){
        this.taskData.pageNum++
        this.getSearchList()
      }
    },
    // 搜索记录展开收起
    showSearch(){
      this.show=!this.show
    },
  },
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.search{
  padding: 3.2rem 0 0.81rem;
  position: relative;
}
.searchClass{
  width: 19.69rem;
  height: 1.88rem;
  line-height: calc(1.88rem - 2px);
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.6rem;
  border: 1px solid #343434;
  border-radius: 1.88rem;
}
.searchBtn{
  width: 3.25rem;
  height: calc(1.8rem - 2px);
  border-radius: calc(1.8rem - 2px);
  background-color: #07be77;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  margin: 0.04rem 0;
}
.searchClass /deep/ .van-field__right-icon{
  padding: 0;
}
.searchTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.94rem;
  color: #020202;
  font-size: 1.6rem;
  height: 3.2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.search_sed{
  display: flex;
  justify-content: space-between;
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  font-family: "PingFang SC";
  line-height: 1.25rem;
  padding: 0 0.75rem;
  margin: 1.25rem auto 1rem;

}
.search_sed>span:last-child{
  color: #999999;
  font-size: 1.3rem;
}
.searchList{
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.searchList>span{
  height: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  border-radius: 0.25rem;
  background-color: #ffffff;
  display: inline-block;
  padding: 0 0.75rem;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  margin: 0 0.5rem 0.63rem 0;
}

.tuijianClass{
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
  padding-left: 0.63rem;
  margin-bottom: 1rem;
}
.tuijianClass>div{
  display: inline-block;
  padding-bottom: 0.3rem;
  margin-right: 1.03rem;
}
.tuijianClass_spanCheck{
  color: #07BB7E;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  position: relative;
}
.tuijianClass_spanCheck>span:last-child{
  width: 100%;
  height: 0.25rem;
  border-radius: 0.13rem;
  opacity: 1;
  background: linear-gradient(90deg, #07bb7eff 0%, #06ad94ff 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.elLoadingdiv{
  height: 3rem;
  line-height: 2rem;
  text-align: center;
  color: #999999;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "PingFang SC";
}
.likelist{
  padding: 0 0.75rem;
}
.likelist>span{
  height: 1.75rem;
  line-height: calc(1.75rem - 2px);
  text-align: center;
  border-radius: 0.25rem;
  border: 1px solid #06AD94;
  padding: 0 0.5rem;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  display: inline-block;
  margin-right: 0.38rem;
  margin-bottom: 0.56rem;
}
.likelist>span>span{
  vertical-align: middle;
}
.likelist>span>img{
  width: 1.09rem;
  height: 1.13rem;
  vertical-align: middle;
  margin-left: 0.44rem;
}
.noMoreClass{
  text-align: center;
  color: #666666;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "PingFang SC";
  letter-spacing: -0.02rem;
}
.list_cell /deep/ .van-cell{
    padding: 0;
}
</style>
<style>
.dialogSearch{
  border-radius: 0 !important;
}
</style>